import { ClientArgs, InitClientReturn, initContract } from '@ts-rest/core';
import { z } from 'zod';

import { Op } from '@eluve/markdown-to-quill';

import {
  appointmentSchema,
  chartEntrySchema,
  chartPartSchema,
} from '../../vendors/jane/types';

const c = initContract();

const chartEntiresContract = c.router(
  {
    new: {
      method: 'GET',
      path: '/new',
      query: z.object({
        patient_id: z.number(),
        template_identifier: z.literal('system_template_note'),
      }),
      responses: {
        200: chartEntrySchema,
      },
    },
    setAppointment: {
      method: 'PUT',
      pathParams: z.object({
        chartEntryId: z.number(),
      }),
      path: '/:chartEntryId/set_appointment',
      body: z.object({
        appointment_id: z.number(),
      }),
      headers: z.object({
        staffMemberId: z.number(),
      }),
      responses: {
        204: z.any(),
      },
    },
    delete: {
      method: 'DELETE',
      path: '/:chartEntryId',
      pathParams: z.object({
        chartEntryId: z.number(),
      }),
      body: z.object({}),
      responses: {
        204: z.custom<undefined>(),
      },
    },
  },
  {
    pathPrefix: '/chart_entries',
  },
);

const chartPartsContract = c.router(
  {
    new: {
      method: 'GET',
      path: '/new',
      query: z.object({
        chart_entry_id: z.number(),
        template_identifier: z.literal('system_template_note'),
        insert_at: z.number(),
      }),
      responses: {
        200: z.array(chartPartSchema),
      },
    },
    update: {
      method: 'PUT',
      path: '/:chartPartId',
      pathParams: z.object({
        chartPartId: z.number(),
      }),
      headers: z.object({
        staffMemberId: z.number(),
      }),
      body: z.object({
        chart_part: z.object({
          text_delta: z.object({
            ops: z.array(z.custom<Op>()),
          }),
          scorable: z.boolean().default(false),
          text: z.string().optional(),
          label: z.string(),
          image_id: z.any().nullable().default(null),
          required: z.boolean().default(false),
        }),
      }),
      responses: {
        204: z.custom<undefined>(),
      },
    },
    delete: {
      method: 'DELETE',
      path: '/:chartPartId',
      pathParams: z.object({
        chartPartId: z.number(),
      }),
      body: z.object({}),
      responses: {
        204: z.custom<undefined>(),
      },
    },
  },
  { pathPrefix: '/chart_parts' },
);

const patientContract = c.router(
  {
    updateChartEntry: {
      method: 'PUT',
      path: '/chart_entries/:chartEntryId',
      pathParams: z.object({
        chartEntryId: z.number(),
      }),
      body: z.object({
        browser_tab_id: z.string().optional(),
        chart_entry: z.object({
          archived_at: z.null(),
          author_id: z.number(),
          display_to_patient: z.boolean().nullable().default(false),
          entered_on: z.string(),
          sign_now: z.boolean().default(false),
          starred_at: z.null(),
          title: z.string().nullable().default(null),
          viewable_by: z.null(),
        }),
        patient_id: z.number(),
      }),
      headers: z.object({
        staffMemberId: z.number(),
      }),
      responses: {
        204: z.custom<undefined>(),
      },
    },
  },
  {
    pathPrefix: '/patients/:patientId',
  },
);

const adminApiV2Routes = c.router(
  {
    chart: chartEntiresContract,
    chartPart: chartPartsContract,
    patient: patientContract,
  },
  { pathPrefix: '/admin/api/v2' },
);

const adminApiV3Routes = c.router(
  {
    appointment: {
      method: 'GET',
      path: '/appointments/:appointmentId',
      pathParams: z.object({
        appointmentId: z.string(),
      }),
      responses: {
        200: z.object({
          _batch_response: z.object({
            appointments: z.array(appointmentSchema.passthrough()),
          }),
        }),
      },
    },
  },
  { pathPrefix: '/admin/api/v3' },
);

const adminRootRoutes = c.router(
  {
    staffMember: {
      method: 'GET',
      path: '/prefs',
      responses: {
        200: z
          .object({
            staff_badges: z
              .object({
                id: z.number(),
              })
              .passthrough(),
          })
          .passthrough(),
      },
    },
  },
  { pathPrefix: '/admin' },
);

const apiV2Routes = c.router(
  {
    getUserInfo: {
      method: 'GET',
      path: '/user_accounts/contact_info',
      responses: {
        200: z
          .object({
            email: z.string().email(),
            email_status: z.union([
              z.literal('verified'),
              z.literal('unverified'),
            ]),
            fax_phone: z.string().optional(),
            mobile_phone: z.string().optional(),
            mobile_phone_status: z.union([
              z.literal('verified'),
              z.literal('unverified'),
            ]),
            work_phone: z.string().optional(),
          })
          .passthrough(),
        401: z
          .object({
            message: z.literal(
              'Hi there! We need you to sign in before continuing.',
            ),
          })
          .passthrough(),
      },
    },
  },
  {
    pathPrefix: '/api/v2',
  },
);

export const janeApiContract = c.router({
  admin: {
    ...adminApiV2Routes,
    ...adminApiV3Routes,
    ...adminRootRoutes,
  },
  ...apiV2Routes,
});

export type JaneApiClient = InitClientReturn<
  typeof janeApiContract,
  ClientArgs
>;
