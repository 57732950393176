import { FragmentOf, graphql } from '@eluve/graphql.tada';

export const locationFragment = graphql(`
  fragment Location on Locations @_unmask {
    __typename
    id
    name
    path
    depth
    isRoot
    timezone
    tenantId
    externalEhrId
    externalId
    address
    logoUrl
    phoneNumber
  }
`);

export type Location = FragmentOf<typeof locationFragment>;

export const tenantLocationsFragment = graphql(
  `
    fragment TenantLocations on Tenants @_unmask {
      __typename
      locations {
        ...Location
      }
    }
  `,
  [locationFragment],
);

export const externalEhrFragment = graphql(`
  fragment ExternalEhr on ExternalEhr @_unmask {
    __typename
    id
    vendor
    domain
  }
`);

export const externalEhrsFragment = graphql(
  `
    fragment ExternalEhrs on Tenants @_unmask {
      __typename
      id
      externalEhrs {
        ...ExternalEhr
      }
    }
  `,
  [externalEhrFragment],
);

export const updateLocationExternalEhrMutation = graphql(`
  mutation updateLocationExternalEhr(
    $locationId: String!
    $tenantId: uuid!
    $externalEhrId: uuid!
  ) {
    updateLocationsByPk(
      pkColumns: { id: $locationId, tenantId: $tenantId }
      _set: { externalEhrId: $externalEhrId }
    ) {
      __typename
      id
      externalEhrId
    }
  }
`);

export const insertNewExternalEhrMutation = graphql(
  `
    mutation insertNewExternalEhr($input: ExternalEhrInsertInput!) {
      insertExternalEhrOne(object: $input) {
        ...ExternalEhr
      }
    }
  `,
  [externalEhrFragment],
);

export const getLocationPageLayoutQuery = graphql(
  `
    query getLocationPageLayout($tenantId: uuid!, $locationId: String!) {
      locationsByPk(id: $locationId, tenantId: $tenantId) {
        ...Location
      }
      tenantsByPk(id: $tenantId) {
        __typename
        id
        ...ExternalEhrs
      }
    }
  `,
  [externalEhrsFragment, locationFragment],
);

export const getLocationExternalEhrsQuery = graphql(
  `
    query getLocationExternalEhrs {
      locations(
        distinctOn: externalEhrId
        where: { externalEhrId: { _isNull: false } }
      ) {
        externalEhr {
          ...ExternalEhr
        }
      }
    }
  `,
  [externalEhrFragment],
);

export const updateLocationDetailsMutation = graphql(`
  mutation updateLocationDetails(
    $locationId: String!
    $tenantId: uuid!
    $logoUrl: String
    $phoneNumber: String
    $address: String
    $externalEhrId: uuid
    $externalId: String
  ) {
    updateLocationsByPk(
      pkColumns: { id: $locationId, tenantId: $tenantId }
      _set: {
        logoUrl: $logoUrl
        address: $address
        phoneNumber: $phoneNumber
        externalEhrId: $externalEhrId
        externalId: $externalId
      }
    ) {
      __typename
      id
      logoUrl
      phoneNumber
      address
      externalEhrId
      externalId
    }
  }
`);
