import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';

import { useAppointmentId } from './appointment.context';

const appointmentNameFragment = graphql(`
  fragment AppointmentName on Appointments {
    __typename
    id
    name
  }
`);

export const useAppointmentName = () => {
  const appointmentId = useAppointmentId();

  const data = useCompleteFragment({
    fragment: appointmentNameFragment,
    key: {
      id: appointmentId,
    },
    strict: false,
  });

  return data?.name ?? '';
};
