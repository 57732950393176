import noop from 'lodash/noop';

import { useCompleteFragment } from '@eluve/apollo-client';
import { Box, P } from '@eluve/components';
import {
  appointmentDoctorInteractionFragment,
  appointmentPatientFragment,
  baseAppointmentInfoFragment,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';
import {
  SummaryFeedbackModal,
  SummarySectionMarkdown,
} from '@eluve/smart-blocks';
import {
  AppointmentSummaryKeys,
  summaryKeyToSectionTitleMap,
} from '@eluve/utils';

import { ClientRecapMailToAction } from './ClientRecapMailToAction';

export const signedAppointmentLayoutFragment = graphql(
  `
    fragment SignedAppointmentLayout on Appointments {
      ...baseAppointmentInfo
      ...appointmentPatient
      ...appointmentDoctorInteraction
    }
  `,
  [
    baseAppointmentInfoFragment,
    appointmentPatientFragment,
    appointmentDoctorInteractionFragment,
  ],
);

interface SignedSummaryResultProps {
  appointmentId: string;
}

export const SignedSummaryResult: React.FC<SignedSummaryResultProps> = ({
  appointmentId,
}) => {
  const appointment = useCompleteFragment({
    fragment: signedAppointmentLayoutFragment,
    key: {
      id: appointmentId,
    },
  });

  const interaction = appointment.doctor_interaction!;

  const currentSummary = useSummary();
  const summary = currentSummary?.summary ?? {};

  return (
    <Box vStack className="items-stretch gap-4 md:gap-6">
      <Box hStack className="self-end p-2">
        <P>Was this medical summary helpful?</P>
        <SummaryFeedbackModal
          appointmentId={appointmentId}
          llmOutputId={currentSummary.llmOutputId}
        />
      </Box>
      {Object.entries(summaryKeyToSectionTitleMap).map(
        ([summaryKey, sectionTitle]) => {
          const summaryValue = summary?.[summaryKey as AppointmentSummaryKeys];

          if (!summaryValue) {
            return null;
          }

          return (
            <SummarySectionMarkdown
              appointmentId={appointmentId}
              key={summaryKey}
              summaryKey={summaryKey as AppointmentSummaryKeys}
              sectionTitle={sectionTitle}
              content={summaryValue ?? ''}
              disabled={true}
              handleContentChange={noop}
              additionalActions={
                summaryKey === 'CLIENT_RECAP'
                  ? [ClientRecapMailToAction]
                  : undefined
              }
            />
          );
        },
      )}

      {interaction.additionalNotes && (
        <SummarySectionMarkdown
          appointmentId={appointmentId}
          key="AdditionalNotes"
          // TODO(jesse)[ELU-949]: This should be presentational only and doesn't need to reuse
          // the same component. The fact that we're passing in noop is a red flag
          summaryKey={`AdditionalNotes` as any}
          sectionTitle="Additional Notes"
          content={interaction.additionalNotes}
          disabled={true}
          handleContentChange={noop}
        />
      )}
    </Box>
  );
};
