import { add, format } from 'date-fns';
import isToday from 'date-fns/isToday';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import React, { useState } from 'react';

import { Box, Button } from '@eluve/components';

export interface DateNavigatorProps {
  startingDate?: Date;
  onDateChanged?: (newDate: Date) => void;
}

export const DateNavigator: React.FC<DateNavigatorProps> = ({
  onDateChanged,
  startingDate,
}) => {
  const [date, setDate] = useState(startingDate ? startingDate : new Date());

  const changeDate = (newDate: Date) => {
    setDate(newDate);
    onDateChanged?.(newDate);
  };

  const changeDay = (days: number) => {
    const newDate = add(date, { days });
    changeDate(newDate);
  };

  return (
    <Box hStack className="w-full flex-1 flex-wrap gap-2 sm:flex-nowrap">
      <Button
        onClick={() => changeDay(-1)}
        variant="outline"
        size="icon"
        className="size-8 sm:size-10"
      >
        <ChevronLeft className="size-5" />
      </Button>
      <Button
        onClick={() => changeDay(1)}
        variant="outline"
        size="icon"
        className="size-8 sm:size-10"
      >
        <ChevronRight className="size-5" />
      </Button>
      <Box
        hStack
        className="xs:text-base flex-1 flex-wrap items-start gap-1 px-2 text-sm font-[500]"
      >
        <p className="text-nowrap">{format(date, 'eeee,')}</p>
        <p className="text-nowrap">{format(date, 'MMMM	do')}</p>
      </Box>
      {!isToday(date) && (
        <Button
          className="h-8 px-2 sm:h-10 sm:px-4"
          variant="outline"
          onClick={() => changeDate(new Date())}
        >
          Today
        </Button>
      )}
    </Box>
  );
};
