import { Suspense } from 'react';

import { FCC, P, Skeleton } from '@eluve/components';

const SkeletonLine: React.FC = () => {
  return <Skeleton className="h-6 w-full bg-white" />;
};

export const AppointmentPageLoader: FCC = ({ children }) => {
  return (
    <Suspense
      fallback={
        <div className="container mt-24 flex flex-col gap-2">
          <P>Loading Appointment...</P>
          <SkeletonLine />
          <SkeletonLine />
          <SkeletonLine />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};
