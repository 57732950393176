import { useCompleteFragment } from '@eluve/apollo-client';

import { useAppointmentId } from './appointment.context';
import { appointmentTranscriptFragment } from './operations';

/**
 * Gives convenient access to the full concatenated transcript for an appointment
 * by reading it from the apollo cache
 */
export const useAppointmentTranscript = () => {
  const id = useAppointmentId();

  const data = useCompleteFragment({
    fragment: appointmentTranscriptFragment,
    key: {
      id,
    },
    strict: false,
  });

  return data?.transcription?.transcript ?? '';
};
