import { z } from 'zod';

import { MatchingRecord } from '@eluve/utils';

import { GlobalFeatureFlagsEnum } from './types';

export const GlobalFeatureFlagsTypesLookup = {
  SOURCE_ARTIFACT_CREATION_PIPELINE: 'SOURCE_ARTIFACT_CREATION_PIPELINE',
  USE_LLM_PII_REDACTION: 'USE_LLM_PII_REDACTION',
} as const satisfies MatchingRecord<GlobalFeatureFlagsEnum>;

export const globalFeatureFlagsTypesSchema = z.nativeEnum(
  GlobalFeatureFlagsTypesLookup,
);
