import React from 'react';

export interface StatProps {
  name: string;
  stat: string | number;
}

export const Stat: React.FC<StatProps> = ({ name, stat }) => {
  return (
    <div className="w-full overflow-hidden rounded-lg bg-white px-4 py-5 shadow-md sm:p-6">
      <dt className="text-gray-10 truncate text-sm font-medium">{name}</dt>
      <dd className="text-gray-12 mt-1 text-3xl font-semibold tracking-tight">
        {stat}
      </dd>
    </div>
  );
};
