import { useMutation, useSuspenseQuery } from '@apollo/client';
import React from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'sonner';

import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import {
  Box,
  Button,
  H1,
  PageTitle,
  ReskinContent,
  ReskinHeader,
  ReskinMain,
  Separator,
  SidebarDefaultTrigger,
  Textarea,
  titleTextStyles,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import {
  useAssignedTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

const customInstructionsFragment = graphql(`
  fragment CustomInstructions on TenantUserSettings {
    __typename
    userId
    tenantId
    customNoteInstructions
  }
`);

const updateCustomInstructionsMutation = graphql(
  `
    mutation UpdateCustomInstructions($instructions: String!) {
      insertTenantUserSettingsOne(
        object: { customNoteInstructions: $instructions }
        onConflict: {
          constraint: tenant_user_settings_pkey
          updateColumns: [customNoteInstructions]
        }
      ) {
        ...CustomInstructions
      }
    }
  `,
  [customInstructionsFragment],
);

const getTenantUserSettingsQuery = graphql(
  `
    query getTenantUserSettings($tenantId: uuid!, $userId: uuid!) {
      tenantUserSettingsByPk(tenantId: $tenantId, userId: $userId) {
        ...CustomInstructions
      }
    }
  `,
  [customInstructionsFragment],
);

export interface TenantUserSettingsPageProps {}

export const TenantUserSettingsPage: React.FC<
  TenantUserSettingsPageProps
> = () => {
  const tenantId = useAssignedTenantIdFromParams();
  const userId = useUserIdFromSession();

  useSuspenseQuery(getTenantUserSettingsQuery, {
    variables: { tenantId, userId },
  });

  const [setCustomInstructions] = useMutation(
    updateCustomInstructionsMutation,
    {
      onCompleted: () => toast.success('Instructions updated'),
      onError: () => toast.error('Failed to save instructions'),
    },
  );

  const { customNoteInstructions } =
    useCompleteFragment({
      fragment: customInstructionsFragment,
      key: { tenantId, userId },
      strict: false,
    }) ?? {};

  const saveInstructions = async () => {
    await setCustomInstructions({
      variables: { instructions: customNoteInstructions ?? '' },
    });
  };

  return (
    <>
      <Helmet>
        <title>Settings | Eluve</title>
      </Helmet>
      <ReskinMain>
        <ReskinHeader>
          <SidebarDefaultTrigger />
          <PageTitle>Settings</PageTitle>
        </ReskinHeader>
        <ReskinContent>
          <Box vStack className="mt-4 w-full gap-4">
            <H1>Preferences</H1>
            <Separator />
            <Box className="flex w-full flex-col justify-between gap-2 md:flex-row">
              <Box className="md:w-128 lg:w-176 w-80">
                <h3 className={titleTextStyles({ size: 1 })}>
                  Custom Instructions
                </h3>
              </Box>
              <Box vStack className="w-full">
                <h4 className={titleTextStyles({ size: 3 })}>
                  Customize your notes by providing specific guidelines. Try to
                  be as clear and concise as possible for best results.
                </h4>
                <Textarea
                  placeholder="Type your instructions here"
                  rows={6}
                  value={customNoteInstructions ?? ''}
                  onChange={(e) => {
                    cacheUtils.writeFragment({
                      fragment: customInstructionsFragment,
                      key: { tenantId, userId },
                      data: {
                        __typename: 'TenantUserSettings' as const,
                        customNoteInstructions: e.target.value,
                        tenantId,
                        userId,
                      },
                    });
                  }}
                ></Textarea>
                <Button onClick={saveInstructions}>Save</Button>
              </Box>
            </Box>
          </Box>
        </ReskinContent>
      </ReskinMain>
    </>
  );
};
