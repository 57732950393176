import { useMutation } from '@apollo/client';

import { graphql } from '@eluve/graphql.tada';

export const markAppointmentAsStartedMutation = graphql(`
  mutation setAppointmentStartedAt($id: uuid!) {
    updateAppointments(
      where: { startedAt: { _isNull: true }, id: { _eq: $id } }
      _set: { startedAt: NOW, status: ACTIVE }
    ) {
      returning {
        id
        startedAt
        status
      }
    }
  }
`);

export const useSetAppointmentStartedAt = () => {
  const [setAppointmentStartedAt] = useMutation(
    markAppointmentAsStartedMutation,
    {
      optimisticResponse: ({ id }) => {
        return {
          updateAppointments: {
            returning: [
              {
                id,
                startedAt: new Date().toISOString(),
                status: 'ACTIVE' as const,
              },
            ],
          },
        };
      },
    },
  );

  return { setAppointmentStartedAt };
};
