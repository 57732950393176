import { useQuery } from '@apollo/client';

import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';

const TenantNameFragment = graphql(`
  fragment TenantName on Tenants @_unmask {
    __typename
    id
    name
  }
`);

const TenantNameQuery = graphql(
  `
    query getTenantName($id: uuid!) {
      tenantsByPk(id: $id) {
        ...TenantName
      }
    }
  `,
  [TenantNameFragment],
);

export const useTenantName = (tenantId: string) => {
  const tenant = useCompleteFragment({
    fragment: TenantNameFragment,
    key: {
      id: tenantId,
    },
    strict: false,
  });

  useQuery(TenantNameQuery, {
    variables: { id: tenantId },
    skip: Boolean(tenant?.name),
  });

  return tenant?.name ?? '';
};
