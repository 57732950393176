import { useSuspenseQuery } from '@apollo/client';
import { ScrollText } from 'lucide-react';
import React, { Suspense } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Box, PageTitle } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { TabNav } from '@eluve/smart-blocks';

const appointmentNameQuery = graphql(`
  query appointmentName($tenantId: uuid!, $appointmentId: uuid!) {
    appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
      __typename
      id
      name
    }
  }
`);

export const AdminAppointmentOutlet: React.FC = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  const {
    data: { appointmentsByPk: appointment },
  } = useSuspenseQuery(appointmentNameQuery, {
    variables: { tenantId, appointmentId },
  });

  return (
    <div>
      <PageTitle
        subtitle={
          <Box hStack className="underline">
            <ScrollText />
            <a
              href={`https://us5.datadoghq.com/logs?query=%40appointmentId%3A${appointmentId}`}
              target="_blank"
              rel="noreferrer"
            >
              View Datadog Logs
            </a>
          </Box>
        }
      >
        {appointment?.name}
      </PageTitle>
      <TabNav
        tabs={[
          {
            name: 'Details',
            link: 'details',
          },
          {
            name: 'Summary and Transcript',
            link: 'artifacts',
          },
          {
            name: 'Files',
            link: 'files',
          },
          {
            name: 'Live vs Batch Transcript Comparison',
            link: 'transcript-comparison',
          },
          {
            name: 'Source Artifacts',
            link: 'source-artifacts',
          },
        ]}
      />
      <div className="mt-4">
        <Suspense fallback={'Loading...'}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
};
