import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@eluve/components';

import { FactVerificationWorkflowsList } from './FactVerificationWorkflowsList';

export const FactVerificationIndexPage: React.FC = () => {
  return (
    <div className="mt-4">
      <div className="flex items-center gap-2">
        <Link to="workflow/create">
          <Button>Create a Workflow</Button>
        </Link>
      </div>
      <FactVerificationWorkflowsList />
    </div>
  );
};
