import { zodResolver } from '@hookform/resolvers/zod';
import capitalize from 'lodash/capitalize';
import { RocketIcon } from 'lucide-react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';

import { useApiClient } from '@eluve/api-client-provider';
import {
  CreatePromptTemplate,
  CreatePromptTemplateSchema,
} from '@eluve/api-contract';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Form,
  FormField,
  FormItem,
  FormMessage,
  H2,
  Input,
  Label,
  P,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from '@eluve/components';
import { LLM_OUTPUT_TYPE_VARIABLES } from '@eluve/llm-outputs';
import { useNamedLogger } from '@eluve/logger';

const formDefaults: Partial<CreatePromptTemplate> = {
  name: '',
  outputType: 'SOAP_NOTE',
  template: [
    { role: 'system', content: '' },
    { role: 'user', content: '' },
    { role: 'assistant', content: '' },
  ],
};

export interface CreatePromptTemplateFormProps {
  initialFormValues?: Partial<CreatePromptTemplate>;
}

export const CreatePromptTemplateForm: React.FC<
  CreatePromptTemplateFormProps
> = ({ initialFormValues }) => {
  const logger = useNamedLogger('CreatePromptTemplateForm');
  const defaultValues = initialFormValues ? initialFormValues : formDefaults;

  const form = useForm<CreatePromptTemplate>({
    resolver: zodResolver(CreatePromptTemplateSchema),
    defaultValues,
    mode: 'onChange',
  });

  const selectedOutputType = form.watch('outputType');

  const client = useApiClient();

  const onSubmit = async (data: CreatePromptTemplate) => {
    try {
      const response = await client.prompts.createPromptTemplate({
        body: data,
      });

      if (response.status !== 201) {
        if (response.status === 403) {
          toast.error('You do not have permission to create prompt templates');
        } else {
          toast.error('Failed to create new prompt template');
        }
      } else {
        toast.success('Prompt template created');
        form.reset(defaultValues);
      }
    } catch (e) {
      toast.error('Failed to create new prompt template');
    }
  };

  const availableTemplateVariables =
    LLM_OUTPUT_TYPE_VARIABLES[selectedOutputType];

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, (invalid) =>
          logger.debug(
            `Invalid form submission attempted: ${JSON.stringify(invalid)}`,
          ),
        )}
      >
        <H2>Metadata</H2>
        <FormField
          control={form.control}
          name="name"
          render={({ field, fieldState: { error } }) => (
            <FormItem className="max-w-lg">
              <label htmlFor="name" className="sr-only">
                Name
              </label>
              <Input className="bg-white/5" placeholder="Name" {...field} />
              {error && (
                <FormMessage className="mt-4">{error.message}</FormMessage>
              )}
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field, fieldState: { error } }) => (
            <FormItem className="max-w-lg">
              <label htmlFor="description" className="sr-only">
                Description
              </label>
              <Textarea
                className="bg-white/5"
                placeholder="Description"
                {...field}
              />
              {error && (
                <FormMessage className="mt-4">{error.message}</FormMessage>
              )}
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="outputType"
          render={({ field, fieldState: { error } }) => (
            <FormItem className="mt-4 max-w-md">
              <Label>Output Type</Label>
              <Select
                value={field.value}
                onValueChange={(val) => {
                  field.onChange(val);
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Output Type" />
                </SelectTrigger>
                <SelectContent>
                  {Object.keys(LLM_OUTPUT_TYPE_VARIABLES)
                    .sort()
                    .map((type) => (
                      <SelectItem key={type} value={type}>
                        {type.split('_').map(capitalize).join(' ')}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
              {error && (
                <FormMessage className="mt-4">{error.message}</FormMessage>
              )}
            </FormItem>
          )}
        />
        <div className="border-gray-4 my-2 size-1 w-full border-b-2"></div>
        <FormField
          control={form.control}
          name="template"
          render={({ field, fieldState: { error } }) => (
            <>
              <H2>Prompt</H2>
              {Boolean(availableTemplateVariables?.length) && (
                <Alert className="my-2">
                  <RocketIcon className="h-4 w-4" />
                  <AlertTitle>Heads up!</AlertTitle>
                  <AlertDescription>
                    <P>
                      You can substitute the following variables in your
                      messages:{' '}
                      {availableTemplateVariables.map((variable) => (
                        <span key={variable}>
                          <code className="bg-gray-6 text-gray-12 p-0.5">{`{{${variable}}}`}</code>{' '}
                        </span>
                      ))}
                    </P>
                  </AlertDescription>
                </Alert>
              )}

              {error && <FormMessage>{error.message}</FormMessage>}
              <div className="grid gap-2">
                <FormItem className="w-full">
                  <Label>System Message</Label>
                  <Textarea
                    rows={10}
                    className="bg-white/5"
                    placeholder="Include system context to set the stage for the prompt"
                    value={field.value[0]?.content}
                    onChange={(e) => {
                      const fieldValue = field.value;
                      const systemMessage = fieldValue[0];
                      if (systemMessage) {
                        systemMessage.content = e.target.value;
                        field.onChange(fieldValue);
                      }
                    }}
                  />
                </FormItem>
                <FormItem className="w-full">
                  <Label>User Message</Label>
                  <Textarea
                    rows={10}
                    className="bg-white/5"
                    placeholder="Instructions and context for the assistant about what it should do"
                    value={field.value[1]?.content}
                    onChange={(e) => {
                      const fieldValue = field.value;
                      const userMessage = fieldValue[1];
                      if (userMessage) {
                        userMessage.content = e.target.value;
                        field.onChange(fieldValue);
                      }
                    }}
                  />
                </FormItem>
                <FormItem className="w-full">
                  <Label>Assistant Message</Label>
                  <Textarea
                    rows={10}
                    className="bg-white/5"
                    placeholder="A message to guide the assistant on what to return"
                    value={field.value[2]?.content}
                    onChange={(e) => {
                      const fieldValue = field.value;
                      const assistantMessage = fieldValue[2];
                      if (assistantMessage) {
                        assistantMessage.content = e.target.value;
                        field.onChange(fieldValue);
                      }
                    }}
                  />
                </FormItem>
                <Button
                  type="submit"
                  className="w-fit"
                  disabled={!form.formState.isDirty}
                >
                  Create
                </Button>
              </div>
            </>
          )}
        />
      </form>
    </Form>
  );
};
