// eslint-disable-next-line lodash/import-scope
import { last } from 'lodash';
import React from 'react';
import { NavLink, useMatches, useNavigate } from 'react-router-dom';

import { cn } from '@eluve/components';

type TabLink = {
  name: string;
  label?: React.ReactNode;
  link: string;
};

export interface TabNavProps {
  tabs: TabLink[];
}

export const TabNav: React.FC<TabNavProps> = ({ tabs }) => {
  const navigate = useNavigate();
  const matches = useMatches();

  const lastMatch = last(matches);

  const matchedValue = tabs.find((tab) =>
    lastMatch?.pathname?.replaceAll('/', '')?.endsWith(tab.link),
  );

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="focus:border-brand-6 focus:ring-brand-6 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:outline-none sm:text-sm"
          onChange={(e) => {
            const tab = tabs.find((tab) => tab.name === e.target.value);
            if (tab) {
              navigate(tab.link);
            }
          }}
          value={matchedValue?.name ?? tabs[0]?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden overflow-x-scroll sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <NavLink
                key={tab.link}
                to={tab.link}
                className={({ isActive }) =>
                  cn(
                    'hover:border-gray-8 whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
                    {
                      'border-brand-8 text-brand-11 hover:border-brand-9':
                        isActive,
                    },
                    { 'border-transparent': !isActive },
                  )
                }
              >
                {tab.label ?? tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
