import { fromCallback } from 'xstate';

export const preventBrowserClose = fromCallback(() => {
  const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = '';
  };

  window.addEventListener('beforeunload', beforeUnloadHandler);
  return () => {
    window.removeEventListener('beforeunload', beforeUnloadHandler);
  };
});
