import { useSuspenseQuery } from '@apollo/client';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { H3, P, PageTitle } from '@eluve/components';
import { formatToLocale } from '@eluve/date-utils';
import { graphql } from '@eluve/graphql.tada';

import { AddSourceArtifactToDatasetDialog } from './AddSourceArtifactToDatasetDialog';
import { ArtifactDatasetsList } from './ArtifactDatasetsList';
import { EditArtifactFactsForm } from './EditArtifactFactsForm';
import { RelatedArtifactsList } from './RelatedArtifactsList';

const getArtifactDetailsQuery = graphql(`
  query getArtifactDetails($sourceArtifactId: uuid!) {
    evalSourceArtifactByPk(id: $sourceArtifactId) {
      __typename
      id
      type
      name
      description
      content
      createdAt
      sourceArtifactAppointment {
        __typename
        tenantId
        appointmentId
        patientId
        userId
      }
      datasetSourceArtifacts {
        __typename
        id
        createdAt
        dataset {
          __typename
          id
          name
          description
        }
      }
    }
  }
`);

export const ArtifactDetailsPage: React.FC = () => {
  const { artifactId } = useParams() as {
    artifactId: string;
  };

  const { pathname } = useLocation();
  const isEluveEval = pathname.includes('/eval');

  const {
    data: { evalSourceArtifactByPk: artifactData },
  } = useSuspenseQuery(getArtifactDetailsQuery, {
    variables: {
      sourceArtifactId: artifactId,
    },
  });

  if (!artifactData) {
    return null;
  }

  const appt = artifactData.sourceArtifactAppointment;

  return (
    <div className="flex flex-col gap-5">
      <PageTitle
        subtitle={
          <div className="flex flex-col gap-2">
            <P className="text-black">{artifactData?.description}</P>
            <P>Created at: {formatToLocale(artifactData.createdAt)}</P>
          </div>
        }
      >
        {artifactData?.name}
      </PageTitle>

      <div className="flex flex-col gap-2">
        <H3 p={'pb-1'}>Details</H3>
        <P>
          Type: <span className="text-black">{artifactData.type}</span>
        </P>
        <P>
          Hashed Tenant ID:{' '}
          <span className="text-black">
            {artifactData?.sourceArtifactAppointment?.tenantId}
          </span>
        </P>
        <P>
          Hashed Appointment ID:{' '}
          <span className="text-black">
            {artifactData?.sourceArtifactAppointment?.appointmentId}
          </span>
        </P>
        <P>
          Hashed User ID:{' '}
          <span className="text-black">
            {artifactData?.sourceArtifactAppointment?.userId}
          </span>
        </P>
        <P>
          Hashed Patient ID:{' '}
          <span className="text-black">
            {artifactData?.sourceArtifactAppointment?.patientId}
          </span>
        </P>
      </div>
      <div className="flex flex-col gap-5 md:flex-row">
        <div className="w-full flex-grow md:w-1/2">
          <div className="flex justify-between">
            <div
              onClick={() =>
                navigator.clipboard.writeText(artifactData.content)
              }
            >
              <H3 p="pb-1" cn="hover:cursor-pointer">
                Content
              </H3>
            </div>

            <P>
              Content Length:
              <span className="text-black">{artifactData.content.length}</span>
            </P>
          </div>
          <div className="bg-gray-3 flex max-h-[800px] flex-col gap-4 overflow-y-scroll rounded-md p-5">
            <P>{artifactData.content}</P>
          </div>
        </div>
      </div>

      <div>
        <EditArtifactFactsForm sourceArtifactId={artifactId} />
      </div>

      {!isEluveEval && (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row justify-between">
            <H3 p="pb-1">Datasets</H3>

            <AddSourceArtifactToDatasetDialog sourceArtifactId={artifactId} />
          </div>

          <ArtifactDatasetsList sourceArtifactId={artifactId} />
        </div>
      )}

      <div>
        <H3 p="pb-1">Related Artifacts</H3>
        {appt?.tenantId && appt?.appointmentId && (
          <RelatedArtifactsList
            tenantId={appt.tenantId}
            appointmentId={appt.appointmentId}
            excludeSourceArtifactId={artifactId}
          />
        )}
      </div>
    </div>
  );
};
