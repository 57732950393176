import { millisecondsToSeconds } from 'date-fns';
import { useRef } from 'react';
import { FaMicrophoneSlash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useUnmount } from 'react-use';

import { Box, P, Waveform } from '@eluve/components';
import { formatSecondsToTime } from '@eluve/date-utils';
import { useAppointmentTotalDuration } from '@eluve/frontend-appointment-hooks';
import { useNamedLogger } from '@eluve/logger';
import { useTenantIdFromParams } from '@eluve/session-helpers';
import { useTotalAudioDurationFromLocalFiles } from '@eluve/user-local-files';
import { useAppointmentTasksSelector } from '@eluve/user-local-files';

import { useTranscriptionMachineSelector } from '../TranscriptionMachineProvider';

const WaveformWhenRecording = () => {
  const logger = useNamedLogger('WaveformWhenRecording');
  const clonedWaveForm = useRef<MediaStream | null>(null);

  const appointmentId = useTranscriptionMachineSelector(
    (s) => s.context.sessionAppointmentId,
  );
  const tenantId = useTranscriptionMachineSelector((s) => s.context.tenantId);

  if (!appointmentId) {
    throw new Error('No appointmentId found in context');
  }

  const persistedTotalDuration = useAppointmentTotalDuration(appointmentId);
  const totalDurationFromLocalFiles = useTotalAudioDurationFromLocalFiles(
    tenantId,
    appointmentId,
    logger,
  );
  const totalAppointmentDurationSeconds = millisecondsToSeconds(
    persistedTotalDuration + totalDurationFromLocalFiles,
  );

  const { mediaStream } = useTranscriptionMachineSelector((state) => {
    const { mediaStream } = state.context;
    if (
      mediaStream &&
      mediaStream.active &&
      (!clonedWaveForm.current || !clonedWaveForm.current.active)
    ) {
      clonedWaveForm.current = mediaStream.clone();
    }

    return {
      mediaStream: clonedWaveForm.current,
    };
  });

  useUnmount(() => {
    if (clonedWaveForm.current) {
      clonedWaveForm.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
  });

  if (!mediaStream) {
    return <div />;
  }

  return (
    <Waveform
      stream={mediaStream}
      totalDuration={totalAppointmentDurationSeconds}
    />
  );
};

export const TranscriptionWaveform: React.FC<{ appointmentStatus: string }> = ({
  appointmentStatus,
}) => {
  const isRecording = useTranscriptionMachineSelector((state) =>
    state.matches({
      InSession: 'Recording',
    }),
  );
  const isMuted = useTranscriptionMachineSelector(
    (state) => state.context.microphoneStatus === 'muted',
  );
  const logger = useNamedLogger('TranscriptionWaveform');
  const tenantId = useTenantIdFromParams() ?? '';
  const { appointmentId: appointmentIdFromParams } = useParams<{
    appointmentId?: string;
  }>();

  const appointmentId =
    useTranscriptionMachineSelector((s) => s.context.sessionAppointmentId) ??
    appointmentIdFromParams ??
    '';

  const uploadTasks = useAppointmentTasksSelector((s) =>
    s.context.tasks.filter(
      (t) => t.type === 'TASK.UPLOAD_FILE' && t.appointmentId === appointmentId,
    ),
  );

  const persistedTotalDuration = useAppointmentTotalDuration(appointmentId);
  const totalDurationFromLocalFiles = useTotalAudioDurationFromLocalFiles(
    tenantId,
    appointmentId,
    logger,
  );

  if (appointmentStatus !== 'ACTIVE') {
    return <div />;
  }

  if (!isRecording) {
    return (
      <div>
        {!uploadTasks.length && (
          <Box hStack className="gap-6 rounded-lg p-2 pr-4">
            <span className="font-mono text-sm">
              {formatSecondsToTime(
                millisecondsToSeconds(
                  persistedTotalDuration + totalDurationFromLocalFiles,
                ),
              )}
            </span>
          </Box>
        )}
      </div>
    );
  }

  if (isMuted) {
    return (
      <Box hStack className="gap-4 rounded-lg p-2 pr-4">
        <Box className="rounded-lg bg-red-500/40 p-2 text-red-800">
          <FaMicrophoneSlash className="size-5" />
        </Box>
        <P>Muted</P>
      </Box>
    );
  }

  return <WaveformWhenRecording />;
};
