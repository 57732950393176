import { useSubscription } from '@apollo/client';
import { Sparkles } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import { Box, Button, ReskinContent, ReskinMain } from '@eluve/components';
import {
  activeAppointmentLayoutFragment,
  listenAppointmentStatusSubscription,
  useAppointmentStatus,
  useAppointmentTranscriptionSync,
  useIsAppointmentReadonly,
} from '@eluve/frontend-appointment-hooks';
import { BillingCodeStoreProvider } from '@eluve/frontend-feature-appointment';
import { LlmOutputTypesLookup } from '@eluve/graphql-types';
import { AppointmentName, FeatureFlaggedComponent } from '@eluve/smart-blocks';

import { AdditionalNotes } from './AdditionalNotes';
import { EluveAdminControls } from './EluveAdminControls';
import { appointmentLayoutStyles } from './appointmentLayoutStyles';
import { AppointmentChiefComplaint } from './components/AppointmentChiefComplaint';
import { AppointmentDetails } from './components/AppointmentDetails';
import { AppointmentSummaryResult } from './components/AppointmentSummaryResult';
import {
  AppointmentTabs,
  SummaryTabContent,
  TranscriptTabContent,
} from './components/AppointmentTabs';
import { AppointmentTranscription } from './components/AppointmentTranscription';
import { AppointmentTranscriptionFooter } from './components/AppointmentTranscriptionFooter';
import { NewAppointmentContent } from './components/NewAppointmentContent';
import { useListenForSummary } from './useListenForSummary';

export interface ActiveAppointmentLayoutProps {
  tenantId: string;
  appointmentId: string;
}
export const ActiveAppointmentLayout: React.FC<
  ActiveAppointmentLayoutProps
> = ({ appointmentId, tenantId }) => {
  const appointment = useCompleteFragment({
    fragment: activeAppointmentLayoutFragment,
    key: {
      id: appointmentId,
    },
  });

  const isReadonly = useIsAppointmentReadonly();

  useListenForSummary();

  // Listen for meaningful status changes for this appointment
  useSubscription(listenAppointmentStatusSubscription, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  // Sync chunked transcript data into the apollo cache for use across the component tree
  useAppointmentTranscriptionSync(appointment?.transcription?.updatedAt);

  const appointmentStatus = useAppointmentStatus();

  return (
    <BillingCodeStoreProvider
      initializer={() => ({
        highlightedId: null,
        highlightedTranscriptExcerpts: [],
      })}
    >
      <ReskinMain>
        <AppointmentDetails appointmentId={appointmentId} tenantId={tenantId} />
        {appointment.status === 'NOT_STARTED' ? (
          <NewAppointmentContent
            appointmentId={appointmentId}
            tenantId={tenantId}
          />
        ) : (
          <>
            <ReskinContent className="py-0">
              <Box vStack className={appointmentLayoutStyles()}>
                <Box vStack fullWidth>
                  <Box hStack spaceBetween fullWidth className="flex-wrap p-2 ">
                    <AppointmentName readonly={isReadonly} />
                    {appointment.status === 'COMPLETED' && (
                      <FeatureFlaggedComponent flag="LLM_OUTPUT_DETAILS">
                        <Link
                          to={`details/${LlmOutputTypesLookup.BILLING_CODE_RECOMMENDATION}`}
                        >
                          <Button variant="outline" size="sm">
                            <Sparkles className="mr-1" />
                            Experiments
                          </Button>
                        </Link>
                      </FeatureFlaggedComponent>
                    )}
                  </Box>
                  <EluveAdminControls />
                </Box>
                <FeatureFlaggedComponent flag="CHIEF_COMPLAINT">
                  <AppointmentChiefComplaint />
                </FeatureFlaggedComponent>
                <AppointmentTabs
                  disabledSummary={appointmentStatus !== 'COMPLETED'}
                />
                <TranscriptTabContent>
                  <AppointmentTranscription />
                  <AdditionalNotes />
                </TranscriptTabContent>
                <SummaryTabContent>
                  <AppointmentSummaryResult />
                </SummaryTabContent>
              </Box>
            </ReskinContent>
            <AppointmentTranscriptionFooter />
          </>
        )}
      </ReskinMain>
    </BillingCodeStoreProvider>
  );
};
