import React from 'react';

import { PageTitle } from '@eluve/components';
import { SourceArtifactPendingReviewList } from '@eluve/frontend-feature-eval';

export const SourceArtifactPendingReviewListPage: React.FC = () => {
  return (
    <div>
      <div className="flex items-center gap-4">
        <PageTitle cName="flex-1">Source Artifacts Pending Review</PageTitle>
      </div>
      <SourceArtifactPendingReviewList />
    </div>
  );
};
