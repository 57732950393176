import times from 'lodash/times';
import React from 'react';

import { Box } from './box';
import { Skeleton } from './skeleton';

export interface TableSkeletonProps {
  numRows?: number;
  numCols?: number;
}

export const TableSkeleton: React.FC<TableSkeletonProps> = ({
  numCols = 5,
  numRows = 8,
}) => {
  return (
    <Box vStack className="w-full">
      {times(numRows).map((r, rowIndex) => (
        <Box hStack key={rowIndex} className="w-full">
          {times(numCols).map((c, colIndex) => (
            <Skeleton key={colIndex} className="h-6 w-full flex-1" />
          ))}
        </Box>
      ))}
    </Box>
  );
};
