import { VariantProps, tv } from 'tailwind-variants';

export const inputVariants = tv({
  base: 'focus:border-brand-8 rounded border border-transparent bg-transparent p-2 outline-none',
  variants: {
    color: {
      default: 'text-gray-11',
      error: 'border-red-700 hover:border-red-500 focus:border-red-600',
    },
    size: {
      xs: 'text-xs',
      sm: 'p-1 text-sm',
      md: '',
      lg: 'text-lg',
    },
    variant: {
      default: 'border-gray-3 hover:border-gray-5 focus:border-brand-8',
      subtle: 'hover:bg-gray-3 focus:border-gray-2 border-transparent',
    },
    disabled: {
      true: 'pointer-events-none',
    },
  },
  defaultVariants: {
    color: 'default',
    size: 'md',
    variant: 'default',
    disabled: false,
  },
});

export type InputVariants = VariantProps<typeof inputVariants>;
