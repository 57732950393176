import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';

import {
  PageTitle,
  ReskinContent,
  ReskinHeader,
  ReskinMain,
  SidebarDefaultTrigger,
  TableSkeleton,
} from '@eluve/components';
import { useCaptureEventOnMount } from '@eluve/posthog-react';

import { PatientsList } from './PatientsList';

export const PatientsListPage: React.FC = () => {
  useCaptureEventOnMount('page_view_patients_list');
  return (
    <>
      <Helmet>
        <title>Patients | Eluve</title>
      </Helmet>
      <ReskinMain>
        <ReskinHeader>
          <SidebarDefaultTrigger />
          <PageTitle>Patients</PageTitle>
        </ReskinHeader>
        <ReskinContent variant="fullWidth">
          <Suspense fallback={<TableSkeleton numRows={20} />}>
            <PatientsList />
          </Suspense>
        </ReskinContent>
      </ReskinMain>
    </>
  );
};
