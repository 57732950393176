import React from 'react';
import { useParams } from 'react-router-dom';

import { Box, H3, P } from '@eluve/components';

import { AddModelArgsToPromptTemplateDialog } from './AddModelArgsToPromptTemplateDialog';
import { PromptTemplateModelArgsList } from './PromptTemplateModelArgsList';

export const PromptTemplateModelArgsListPage: React.FC = () => {
  const { promptTemplateId } = useParams() as { promptTemplateId: string };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <Box vStack className="gap-1">
          <H3>Model Prompt Templates</H3>
          <P className="font-normal">
            This prompt template is currently configured to work with the
            following Model Arg configurations
          </P>
        </Box>
        <AddModelArgsToPromptTemplateDialog
          promptTemplateId={promptTemplateId}
        />
      </div>
      <PromptTemplateModelArgsList promptTemplateId={promptTemplateId} />
    </div>
  );
};
