import { usePostHog } from 'posthog-js/react';
import { useState } from 'react';

import {
  Box,
  Button,
  Label,
  ReskinContent,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@eluve/components';
import {
  AppointmentName,
  AppointmentUserMicSettings,
  FeatureFlaggedComponent,
  useSelectedMicrophoneDevice,
} from '@eluve/smart-blocks';

import { EluveAdminControls } from '../EluveAdminControls';
import {
  useTranscriptionMachineActor,
  useTranscriptionMachineSelector,
} from '../machines/transcription/TranscriptionMachineProvider';

import { AppointmentTemplateSelector } from './AppointmentTemplateSelector';
import { PlayingDisclaimer } from './PlayingDisclaimer';

interface NewAppointmentContentProps {
  appointmentId: string;
  tenantId: string;
}

export const NewAppointmentContent: React.FC<NewAppointmentContentProps> = ({
  appointmentId,
  tenantId,
}) => {
  const actor = useTranscriptionMachineActor();
  const isPlayingDisclaimer = useTranscriptionMachineSelector((state) =>
    state.matches({ InSession: 'PlayingDisclaimer' }),
  );
  const [selectedMicId, setSelectedMicId] = useSelectedMicrophoneDevice();
  const [playDisclaimer, setPlayDisclaimer] = useState(false);
  const postHog = usePostHog();

  const startSession = async () => {
    const microphoneName = (
      await navigator.mediaDevices.enumerateDevices()
    ).find((device) => device.deviceId === selectedMicId)?.label;

    postHog.capture('started_session', {
      appointmentId,
      microphoneName,
    });

    actor.send({
      type: 'START',
      appointmentId,
      deviceId: selectedMicId ?? undefined,
      microphoneName,
      playDisclaimer,
    });
  };

  const NewAppointmentForm = (
    <>
      <Box vStack className="w-full justify-stretch gap-4 pt-4">
        <AppointmentName />
        <EluveAdminControls />
        <h4 className="text-semibold text-gray-12 mt-4">Note Settings</h4>
        <AppointmentUserMicSettings
          selectedMicId={selectedMicId}
          setSelectedMicId={setSelectedMicId}
        />
        <AppointmentTemplateSelector
          tenantId={tenantId}
          appointmentId={appointmentId}
        />

        <FeatureFlaggedComponent flag="SHOW_PLAY_DISCLAIMER_OPTION">
          <Box hStack className="my-4 gap-4">
            <Switch
              id="play-disclaimer"
              checked={playDisclaimer}
              onCheckedChange={setPlayDisclaimer}
            />
            <Label htmlFor="play-disclaimer">
              Play disclaimer before recording
            </Label>
          </Box>
        </FeatureFlaggedComponent>
      </Box>
      <Box hStack fullWidth className="justify-end py-4">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="w-full">
                <Button
                  onClick={startSession}
                  className="w-full"
                  variant="dark"
                  disabled={!selectedMicId}
                >
                  Start Session
                </Button>
              </div>
            </TooltipTrigger>
            {!selectedMicId && (
              <TooltipContent>
                {'Select a microphone to start the session.'}
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>
      </Box>
    </>
  );

  return (
    <ReskinContent variant="smallContainer">
      {isPlayingDisclaimer ? <PlayingDisclaimer /> : NewAppointmentForm}
    </ReskinContent>
  );
};
