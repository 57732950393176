import { assign, fromPromise, setup } from 'xstate';

import { logMessage } from '@eluve/frontend-machine-utils';
import { Logger } from '@eluve/logger';

import { calculateDrift } from './actors/calculateDrift.actor';
import { reconcileDrift } from './actors/reconcileDrift.actor';
import {
  AppointmentFilesTableDependency,
  FileDriftReport,
  GetFileReferences,
} from './types';

type Input = {
  checkForFileDriftIntervalMs: number;
  appointmentFilesTable: AppointmentFilesTableDependency;
  logger: Logger;
};

type Context = {
  iterations: number;
  logger: Logger;
  checkForFileDriftIntervalMs: number;
  fileRefs: GetFileReferences;
  drift: FileDriftReport;
  appointmentFilesTable: AppointmentFilesTableDependency;
};

const getFiles = fromPromise<GetFileReferences>(async () => {
  throw new Error('getFiles not implemented');
});

export const periodicFileSyncMachine = setup({
  types: {
    input: {} as Input,
    context: {} as Context,
  },
  actors: {
    getFiles,
    calculateDrift,
    reconcileDrift,
  },
  guards: {
    driftDetected: (_, report: FileDriftReport) => {
      return report.type === 'DriftDetected';
    },
  },
  actions: {
    incrementIterations: assign({
      iterations: ({ context }) => {
        return context.iterations + 1;
      },
    }),
    logMessage,
  },

  delays: {
    nextIteration: ({ context }) => {
      return context.checkForFileDriftIntervalMs;
    },
  },
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QAcwCcCWB7CGDGAYhgDZgDKAngHZ4B0AsgIZWNQZVRGkAimAZgBdaAJTCNcHLnAJosAWwDyyPrACCVCAEkIAIwDEELFTC12ANywBrE6kw58UyjQbNW7TiTC8MgkWIkepLAy8koq6lq6COZYeIwC2FQA2gAMALqpaYgoWLAYCUbZIAAeiAC0AMwpAIy0AGwA7AAsTQCcAKztTQAcDVV17QA0IBTl1QBMdfUVM20pFU0V463VTQC+a8O22LiEnk50TCxskp7evgDCABZgeJbuBFho5wIGRiYx1rTb9nukBy5jg8zvwhNdbvdJE8XtEqBY4gVkulMkVkLl8okiqUEGV2nUUrRqiluilWh08Skak1hqMEESGtMKu1qtU6uMSXjqt0Nlt0DsHPtqIdXCdAl5QbRwXcHtDQW9jKY4VYbHzfo4hYC3KceBKpZDOLKfAJYfD4olMklqlkkCA0XlEVjyuN5rQGg1xhVup7+i1Wg0aYhqlVaE12t1mVSmez5jzbardurnEctWKXn48EY8CR3C95R8lV8fgnBUmRcCdUb05nsxwYTEEebkelUeiHTbsaHaJM6q1Saylq1xg0hiNEN3XXU6l6vU1Kc71ps43Zi-8NcnRVI0xd5MhSAJIJp92gzUY9MVYAJ4iZGIJ0AAKYzFASH9AnqgASj0RYFq9LQO14qVtuci7mA+5aEeb4ojadoYoU7aBjMrrjBMZITA0k4NK03QBjiXJNLQ7RkuGKTjMyHQpGGGyLlQOBwKi8Y-uQQotvamIITiAxTESJJkp0+JUrhZRNA03SEjMzrjE0dQVNUHQVLG35-Mxf4ppuoKsXBVCOpxxKEsSpLkgJqxCQsBHzLJ3ThpJXR1IpjHKQC67loBviiOILnBLIijKGoGjaDomltqA2KVE0tQDBUg7SRhs7dNU-qjjiZFTH0lkycODRcni9nLkxTllgBW43NKULPBpMGtuxIXlBJXZSW08WhmRWHUklZQobQhkkkSXQYSyfS5fyjlroVqYSqIGY0DWUAvEF1UlE6jQhs67TDvFVlhgMuGyVMeKNPik4yUSQZDWqJbCv+41ATue4HpBwW2lV8E1XSqwhqRXThQsKGUXUuFBmJsnNGRa0rCkDQQ9RaxAA */
  id: 'periodicFileSync',
  context: ({
    input: { logger, appointmentFilesTable, checkForFileDriftIntervalMs },
  }) => ({
    iterations: 0,
    appointmentFilesTable,
    fileRefs: {
      dbFileRecords: [],
      opfsFiles: {
        type: 'fileSystemUnavailable',
      },
    },
    checkForFileDriftIntervalMs,
    logger,
    drift: { type: 'NoDriftDetected' },
  }),
  type: 'parallel',
  states: {
    ManagingFileDrift: {
      states: {
        ReadingFilesFromOpfsAndIdb: {
          entry: ['incrementIterations'],
          invoke: {
            src: 'getFiles',
            onDone: {
              target: 'CheckingForDrift',
              reenter: true,
              actions: assign({
                fileRefs: ({ event }) => event.output,
              }),
            },
          },
        },
        CheckingForDrift: {
          entry: [
            {
              type: 'logMessage',
              params: ({ context }) => ({
                level: 'info',
                message: 'Checking for drift',
                logMeta: { iterations: context.iterations },
              }),
            },
          ],
          invoke: {
            src: 'calculateDrift',
            input: ({ context }) => ({
              logger: context.logger,
              fileRefs: context.fileRefs,
            }),
            onDone: [
              {
                target: 'ReconcilingDrift',

                actions: assign({
                  drift: ({ event }) => event.output,
                }),

                guard: {
                  type: 'driftDetected',
                  params: ({ event }) => event.output,
                },

                reenter: true,
              },
              {
                target: 'CompletedIteration',
              },
            ],
          },
        },
        ReconcilingDrift: {
          entry: [
            {
              type: 'logMessage',
              params: ({ context }) => ({
                level: 'warn',
                message: 'File drift detected. Attempting to reconcile',
                logMeta: { drift: context.drift },
              }),
            },
          ],
          invoke: {
            src: 'reconcileDrift',
            input: ({ context: { drift, appointmentFilesTable, logger } }) => ({
              driftReport: drift,
              appointmentFilesTable: appointmentFilesTable,
              logger,
            }),
            onDone: [
              {
                target: 'CompletedIteration',
                actions: assign({
                  drift: { type: 'NoDriftDetected' },
                }),
              },
            ],
          },
        },
        CompletedIteration: {
          entry: [
            {
              type: 'logMessage',
              params: ({ context }) => ({
                level: 'info',
                message: 'Iteration completed',
                logMeta: { iterations: context.iterations },
              }),
            },
          ],
          after: {
            nextIteration: 'ReadingFilesFromOpfsAndIdb',
          },
        },
      },
      initial: 'ReadingFilesFromOpfsAndIdb',
    },
  },
  initial: 'ManagingFileDrift',
  entry: [
    {
      type: 'logMessage',
      params: {
        level: 'info',
        message: 'Starting periodic file sync machine',
      },
    },
  ],
});
