import React from 'react';

import { P } from '@eluve/components';
import {
  useAppointmentStatus,
  useSummary,
} from '@eluve/frontend-appointment-hooks';

export const SummarizationErrorCard: React.FC = () => {
  const appointmentStatus = useAppointmentStatus();
  const summary = useSummary()?.summary ?? {};

  const summaryDetected = Object.values(summary).some((s) => s?.length);

  if (summaryDetected || appointmentStatus !== 'COMPLETED') {
    return null;
  }

  return (
    <div className="flex h-96 w-full flex-col items-center justify-center gap-1 rounded-xl border bg-white">
      <P>No summary available for this appointment</P>
    </div>
  );
};
