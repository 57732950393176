import { createContext, useContext, useRef } from 'react';
import { Mutate, StateCreator, StoreApi, UseBoundStore } from 'zustand';
import { shallow } from 'zustand/shallow';
// import { persist } from 'zustand/middleware';
import {
  createWithEqualityFn,
  useStoreWithEqualityFn,
} from 'zustand/traditional';

export type UseStoreFunc<T> = {
  (): T;
  <S>(selector: (state: T) => S): S;
};

export type Store<S> = UseBoundStore<
  Mutate<StoreApi<S>, [['zustand/subscribeWithSelector', never]]>
>;

/**
 * Creates a non-global zustand store that is initialized from React.
 *
 * Follows the patterns as outlined in https://docs.pmnd.rs/zustand/guides/initialize-state-with-props
 * @returns
 */
export const createZustandContext = <State,>() => {
  const StoreContext = createContext<StoreApi<State> | undefined>(undefined);

  const StoreProvider: React.FC<{
    initializer: StateCreator<State>;
    children: React.ReactNode;
    // isPersisted?: boolean;
  }> = ({ children, initializer }) => {
    const storeRef = useRef<StoreApi<State> | undefined>();

    if (!storeRef.current) {
      // TODO(jesse)[ELU-985]: Hook up option for Zustand persistence in a future PR for device preferences
      // storeRef.current = isPersisted
      //   ? createWithEqualityFn(persist(initializer, { name: 'asdf' }))
      // : createWithEqualityFn(initializer, shallow);

      storeRef.current = createWithEqualityFn(initializer, shallow);
    }

    return (
      <StoreContext.Provider value={storeRef.current}>
        {children}
      </StoreContext.Provider>
    );
  };

  const useStoreApi = () => {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error('Missing StoreProvider');
    }
    return store;
  };

  function useStoreFromContext<T>(selector: (state: State) => T): T {
    const store = useContext(StoreContext);
    if (!store) throw new Error('Missing StoreProvider');
    return useStoreWithEqualityFn(store, selector);
  }

  return { StoreProvider, useStore: useStoreFromContext, useStoreApi };
};
