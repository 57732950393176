import { PostHogProvider } from 'posthog-js/react';
import React from 'react';

import { FCC } from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';
import { bootstrappedFlags } from '@eluve/posthog';
import { useUserIdFromSession } from '@eluve/session-helpers';

import { FeatureFlagProvider } from './FeatureFlagProvider';
import { appConfig } from './config';

export const AnalyticsProvider: FCC = ({ children }) => {
  const logger = useNamedLogger('AnalyticsProvider');
  const userId = useUserIdFromSession();

  return (
    <PostHogProvider
      apiKey={appConfig.VITE_REACT_APP_PUBLIC_POSTHOG_KEY}
      options={{
        bootstrap: {
          distinctID: userId,
          ...bootstrappedFlags,
        },
        autocapture: false,
        person_profiles: 'identified_only',
        api_host: appConfig.VITE_REACT_APP_PUBLIC_POSTHOG_HOST,
        loaded: () => {
          logger.info('Posthog loaded');
        },
      }}
    >
      <FeatureFlagProvider>{children}</FeatureFlagProvider>
    </PostHogProvider>
  );
};
