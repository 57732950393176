import { z } from 'zod';

export const pfshSchema = z
  .object({
    allergies: z.array(z.string()).describe('Any relevant allergies'),
    medications: z
      .array(
        z.object({
          name: z.string(),
          note: z.string().optional(),
          dose: z.string().optional(),
          frequency: z.string().optional(),
          startDate: z.string().optional(),
          endDate: z.string().optional(),
        }),
      )
      .default([])
      .describe('Any relevant medications the patient is taking'),
    existingConditions: z
      .array(z.string())
      .default([])
      .describe('Any existing conditions'),
    surgeries: z
      .array(z.string())
      .default([])
      .describe('Any surgeries the patient has had'),
    socialHistory: z
      .array(z.string())
      .default([])
      .describe('Any relevant social history'),
    familyHistory: z
      .array(z.string())
      .default([])
      .describe('Any relevant family history'),
  })
  .describe(
    'A complete snapshot of patient history, including past medical, family, and social history.',
  );
