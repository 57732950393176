import { Font, Text, View } from '@react-pdf/renderer';
import { MarkdownToJSX } from 'markdown-to-jsx';

import { tw } from './pdf-tw';

Font.register({
  family: 'Inter',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf',
      fontWeight: 100,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf',
      fontWeight: 200,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
      fontWeight: 300,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
      fontWeight: 400,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
      fontWeight: 500,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
      fontWeight: 600,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
      fontWeight: 700,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf',
      fontWeight: 800,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf',
      fontWeight: 900,
    },
  ],
});

type MarkdownRenderer = (args: {
  children?: React.ReactNode;
}) => React.ReactElement;

const DefaultComponent: MarkdownRenderer = ({ children }) => (
  <Text style={tw('text-sm')}>{children}</Text>
);

export const MarkdownOptions: MarkdownToJSX.Options = {
  enforceAtxHeadings: true,
  overrides: {
    h1: ({ children }) => (
      <Text style={[tw('text-xl font-sans font-semibold')]}>{children}</Text>
    ),
    h2: ({ children }) => (
      <Text style={tw('text-lg font-medium text-base')}>{children}</Text>
    ),
    h3: ({ children }) => <Text style={tw('text-base')}>{children}</Text>,
    h4: DefaultComponent,
    p: ({ children }) => {
      return <Text style={tw('text-sm')}>{children}</Text>;
    },
    ul: ({ children }) => <View style={tw('mb-4')}>{children}</View>,
    li: ({ children }) => (
      <Text>
        <Text style={tw('text-sm')}> - </Text>
        <Text style={tw('text-sm ml-4')}>{children}</Text>
      </Text>
    ),
  },
};
