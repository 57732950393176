import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { useParams } from 'react-router-dom';

import {
  Box,
  H2,
  H3,
  P,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  useToast,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

const getAppointmentSegmentIdsQuery = graphql(`
  query getAppointmentSegmentIds($tenantId: uuid!, $appointmentId: uuid!) {
    appointmentSegments(
      where: {
        tenantId: { _eq: $tenantId }
        appointmentId: { _eq: $appointmentId }
      }
    ) {
      __typename
      id
    }
  }
`);

const getTranscriptsQuery = graphql(`
  query getTranscripts($segmentId: uuid!) {
    transcripts(
      where: { segmentId: { _eq: $segmentId } }
      orderBy: { createdAt: ASC }
    ) {
      __typename
      transcript
      transcriptType
      confidence
    }
  }
`);

interface LiveTranscript {
  string: string;
  confidence: number;
}

interface TranscriptWithConfidenceScoresProps {
  transcripts: LiveTranscript[];
}

const TranscriptWithConfidenceScores: React.FC<
  TranscriptWithConfidenceScoresProps
> = ({ transcripts }) => {
  return (
    <div>
      {transcripts.map((transcript, index) => (
        <div key={index} className="mb-1">
          <div>{transcript.string}</div>
          <div className="text-gray-8 text-xs">
            Confidence: {transcript.confidence}
          </div>
        </div>
      ))}
    </div>
  );
};

export const AdminTranscriptsPage: React.FC = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  const { toast } = useToast();

  const [getTranscripts] = useLazyQuery(getTranscriptsQuery);

  const { data } = useQuery(getAppointmentSegmentIdsQuery, {
    variables: { appointmentId, tenantId },
  });

  const segmentIds = data?.appointmentSegments?.map((s) => s.id) ?? [];

  const [selectedSegmentId, setSelectedSegmentId] = useState<string | null>(
    null,
  );
  const [isFetchingTranscripts, setIsFetchingTranscripts] = useState(false);
  const [batchTranscript, setBatchTranscript] = useState<string | null>(null);
  const [liveTranscript, setLiveTranscript] = useState<string | null>(null);
  const [
    liveTranscriptWithConfidenceScores,
    setLiveTranscriptWithConfidenceScores,
  ] = useState<LiveTranscript[]>([]);

  // Strip formatting and reformat both types of transcripts in order
  // minimize extraneous differences in the diff viewer
  const processText = (text: string) => {
    // Remove extra spaces, new lines, and tabs
    const cleanedText = text.replace(/\s+/g, ' ').trim();

    // Split at punctuation points (. ? !)
    const splitText = cleanedText.split(/(?<=[.!?])\s*/);

    // Join the split text with new lines
    const formattedText = splitText.join('\n');

    return formattedText;
  };

  const handleSegmentSelect = async (segmentId: string) => {
    setSelectedSegmentId(segmentId);
    setIsFetchingTranscripts(true);

    const { data } = await getTranscripts({
      variables: { segmentId },
    });

    const transcripts = data?.transcripts ?? [];

    if (!transcripts.length) {
      toast({
        title: 'No transcripts found',
        variant: 'destructive',
      });
      setIsFetchingTranscripts(false);
      return;
    }

    const batch = transcripts.find((t) => t.transcriptType === 'BATCH');

    setBatchTranscript(processText(batch?.transcript ?? ''));

    const liveTranscripts = transcripts.filter(
      (t) => t.transcriptType === 'LIVE',
    );

    setLiveTranscriptWithConfidenceScores(
      liveTranscripts.map((t) => ({
        string: t.transcript ?? '',
        confidence: t.confidence ?? 0,
      })),
    );

    setLiveTranscript(
      processText(liveTranscripts.map((t) => t.transcript).join('\n')),
    );

    setIsFetchingTranscripts(false);
  };

  return (
    <div>
      <Box vStack className="gap-10">
        {Boolean(segmentIds.length) && (
          <Box vStack className="w-full max-w-[600px]">
            <H3>Select a Segment ID</H3>
            <P className="text-xs italic text-red-700">
              Warning: This will show the transcript for the segment.
            </P>
            <Select
              onValueChange={handleSegmentSelect}
              disabled={isFetchingTranscripts}
            >
              <SelectTrigger className="text-gray-12">
                <SelectValue className="text-gray-12">
                  {selectedSegmentId
                    ? selectedSegmentId
                    : 'Select a segment ID'}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {segmentIds.map((segmentId) => (
                  <SelectItem
                    key={segmentId}
                    className="focus:bg-brand-4 pl-7"
                    value={segmentId}
                  >
                    {segmentId}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </Box>
        )}

        {liveTranscript && batchTranscript && (
          <ReactDiffViewer
            leftTitle={'Live'}
            oldValue={liveTranscript ?? ''}
            rightTitle={'Batch'}
            newValue={batchTranscript ?? ''}
            splitView={true}
            showDiffOnly={true}
          />
        )}

        {Boolean(liveTranscriptWithConfidenceScores.length) && (
          <Box vStack>
            <H2>Confidence Scores</H2>
            <P className="text-gray-8 text-xs italic">
              This metadata is for the Live Transcript.
            </P>
            <TranscriptWithConfidenceScores
              transcripts={liveTranscriptWithConfidenceScores}
            />
          </Box>
        )}
      </Box>
    </div>
  );
};
