import { useSuspenseQuery } from '@apollo/client';
import React, { useMemo } from 'react';

import { ColDefBuilder, DataTable, P } from '@eluve/components';
import { EvalSourceArtifactTypesEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';

const getRelatedArtifactsQuery = graphql(`
  query getRelatedArtifacts($tenantId: uuid!, $appointmentId: uuid!) {
    evalSourceArtifact(
      where: {
        sourceArtifactAppointment: {
          tenantId: { _eq: $tenantId }
          appointmentId: { _eq: $appointmentId }
        }
      }
    ) {
      __typename
      id
      name
      type
      createdAt
    }
  }
`);
type DatasetRow = {
  id: string;
  name: string | null;
  type: EvalSourceArtifactTypesEnum;
  createdAt: string;
};

const columns = new ColDefBuilder<DatasetRow>()
  .linkSortable('name', (row) => `/admin/fact-verification/artifacts/${row.id}`)
  .defaultSortable('type')
  .dateSortable('createdAt', 'Created At')
  .build();

export interface RelatedArtifactsListProps {
  tenantId: string;
  appointmentId: string;
  excludeSourceArtifactId?: string;
}

export const RelatedArtifactsList: React.FC<RelatedArtifactsListProps> = ({
  tenantId,
  appointmentId,
  excludeSourceArtifactId,
}) => {
  const { data: relatedArtifacts } = useSuspenseQuery(
    getRelatedArtifactsQuery,
    {
      variables: {
        tenantId,
        appointmentId,
      },
    },
  );

  const sourceArtifacts = excludeSourceArtifactId
    ? relatedArtifacts?.evalSourceArtifact.filter(
        (sa) => sa.id !== excludeSourceArtifactId,
      )
    : relatedArtifacts?.evalSourceArtifact;

  const rows: DatasetRow[] = useMemo(() => {
    return (
      sourceArtifacts.map((sa) => {
        return {
          id: sa.id,
          name: sa.name,
          type: sa.type as EvalSourceArtifactTypesEnum,
          createdAt: sa.createdAt,
        };
      }) ?? []
    );
  }, [sourceArtifacts]);

  return (
    <div>
      {!rows.length && <P>No related artifacts found </P>}
      {Boolean(rows.length) && (
        <DataTable
          columns={columns}
          data={rows}
          initialSortingState={[
            {
              id: 'createdAt',
              desc: true,
            },
          ]}
        />
      )}
    </div>
  );
};
