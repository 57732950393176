import { graphql } from '@eluve/graphql.tada';

export const sessionTypeFragment = graphql(`
  fragment SessionType on SessionTypes @_unmask {
    __typename
    id
    isInitialVisitType
    name
    context
    createdAt
  }
`);

export const sessionTypesFragment = graphql(
  `
    fragment SessionTypes on Tenants @_unmask {
      __typename
      id
      session_types {
        ...SessionType
      }
    }
  `,
  [sessionTypeFragment],
);
