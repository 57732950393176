import { CaptureOptions } from 'posthog-js';
import { usePostHog } from 'posthog-js/react';
import { useCallback } from 'react';
import { useLatest } from 'react-use';

export const useCaptureEvent = (context: Record<string, unknown> = {}) => {
  const contextRef = useLatest(context);
  const postHog = usePostHog();
  return useCallback(
    (
      eventName: string,
      properties: Record<string, unknown> = {},
      captureOptions?: CaptureOptions,
    ) => {
      postHog.capture(
        eventName,
        {
          ...contextRef.current,
          ...properties,
        },
        captureOptions,
      );
    },
    [contextRef, postHog],
  );
};
