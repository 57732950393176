import { AlertOctagon, AlertTriangle, BadgeCheck, Info } from 'lucide-react';
import { Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const SonnerToast = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      theme={'system'}
      className="toaster group"
      visibleToasts={5}
      icons={{
        success: <BadgeCheck />,
        error: <AlertOctagon className="mr-6" />,
        info: <Info />,
        warning: <AlertTriangle />,
      }}
      toastOptions={{
        classNames: {
          toast:
            'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
          description: 'group-[.toast]:text-muted-foreground',
          actionButton:
            'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton:
            'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
          success:
            'group toast group-[.toaster]:bg-green-200 group-[.toaster]:text-green-800 group-[.toaster]:border-border group-[.toaster]:shadow-lg',
          error:
            'group toast group-[.toaster]:bg-red-200 group-[.toaster]:text-red-800 group-[.toaster]:border-border group-[.toaster]:shadow-lg',
          warning:
            'group toast group-[.toaster]:bg-orange-200 group-[.toaster]:text-orange-800 group-[.toaster]:border-border group-[.toaster]:shadow-lg',
          info: 'group toast group-[.toaster]:bg-blue-200 group-[.toaster]:text-blue-800 group-[.toaster]:border-border group-[.toaster]:shadow-lg',
        },
      }}
      {...props}
    />
  );
};

export { SonnerToast };
