import React from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import { ColDefBuilder, DataTable } from '@eluve/components';
import { FeatureFlagsEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';

import { TenantFeatureFlagToggle } from './TenantFeatureFlagToggle';
import { adminTenantFeatureFlagFragment } from './gql-operations';

export const adminTenantFeatureFlagsFragment = graphql(
  `
    fragment AdminTenantFeatureFlags on Tenants @_unmask {
      __typename
      id
      featureFlags {
        ...AdminTenantFeatureFlag
      }
    }
  `,
  [adminTenantFeatureFlagFragment],
);

type TableRow = {
  name: FeatureFlagsEnum;
  isEnabled: boolean;
  tenantId: string;
  updatedAt: string;
  description: string | null;
};

const colDefs = new ColDefBuilder<TableRow>()
  .defaultSortable('name')
  .defaultSortable('isEnabled', {
    label: 'Is Enabled',
    cellRenderer: (row) => {
      return (
        <TenantFeatureFlagToggle tenantId={row.tenantId} flag={row.name} />
      );
    },
  })
  .defaultSortable('description')
  .dateSortable('updatedAt', 'Updated At')
  .build();

export const TenantFeatureFlags: React.FC<{ tenantId: string }> = ({
  tenantId,
}) => {
  const data = useCompleteFragment({
    fragment: adminTenantFeatureFlagsFragment,
    key: {
      id: tenantId,
    },
    strict: false,
  });

  const rows = (data?.featureFlags ?? []).map<TableRow>((flag) => ({
    isEnabled: flag.isEnabled,
    name: flag.flag,
    description: flag.featureFlag?.description ?? null,
    tenantId: flag.tenantId,
    updatedAt: flag.updatedAt,
  }));

  return (
    <DataTable
      data={rows}
      columns={colDefs}
      isPaginated={false}
      enableGlobalSearch
      isCompact
      initialSortingState={[{ id: 'name', desc: false }]}
    />
  );
};
