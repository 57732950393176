import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useSearchParamValue<T extends string>(paramName: string) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const value = params.get(paramName) as T | null;
  const setValue = (value?: T) => {
    if (value) {
      params.set(paramName, value);
    } else {
      params.delete(paramName);
    }

    navigate(`?${params.toString()}`, { replace: true });
  };

  return [value, setValue] as const;
}
