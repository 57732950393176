import { useQuery, useSuspenseQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { CopyTextButton } from '@eluve/blocks';
import {
  Button,
  ColDefBuilder,
  DataTable,
  PageTitle,
  makeDefaultSortableColumnDef,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import {
  useTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

import {
  TenantActionsColumn,
  usersTenantsFragment,
} from '../components/TenantActionsColumn';
import {
  TenantApprovalToggle,
  tenantPendingApprovalFragment,
} from '../components/TenantApprovalToggle';

import { UserFragment } from './$tenantId/users/operations';

const getUsersTenantsQuery = graphql(
  `
    query getTenantUsersByUserId($userId: uuid!) {
      usersByPk(id: $userId) {
        ...UsersTenants
        ...User
      }
    }
  `,
  [usersTenantsFragment, UserFragment],
);

const getAdminTenantsSummaryQuery = graphql(
  `
    query getAdminTenantSummary {
      tenants {
        __typename
        id
        name
        ...TenantPendingApproval
        appointmentsAggregate {
          aggregate {
            count
          }
        }
        tenantUsersAggregate {
          aggregate {
            count
          }
        }
        createdAt
      }
    }
  `,
  [tenantPendingApprovalFragment],
);

type TenantRow = {
  isActiveTenant: boolean;
  id: string;
  name: string;
  isPendingApproval: boolean;
  userCount: number;
  appointmentCount: number;
  createdAt: string;
};

const cols = new ColDefBuilder<TenantRow>()
  .colDef({
    id: 'id',
    accessorKey: 'id',
    header: 'Id',
    cell: ({ row }) => <CopyTextButton copyText={row.original.id} />,
  })
  .defaultBoolean('isActiveTenant', 'Active Tenant')
  .linkSortable('name', (row) => row.id)
  .defaultSortable('userCount', '# Users')
  .defaultSortable('appointmentCount', '# Appointments')
  .colDef(
    makeDefaultSortableColumnDef('isPendingApproval', {
      label: 'Is Approved',
      cellRenderer: (row) => <TenantApprovalToggle tenantId={row.id} />,
    }),
  )
  .dateSortable('createdAt', 'Created At')
  .colDef({
    header: 'Actions',
    cell: ({ row }) => <TenantActionsColumn tenantId={row.original.id} />,
  })
  .build();

export const AdminTenants: React.FC = () => {
  const { data: adminTenantSummary } = useSuspenseQuery(
    getAdminTenantsSummaryQuery,
  );
  const tenantId = useTenantIdFromParams();
  const userId = useUserIdFromSession();

  useQuery(getUsersTenantsQuery, {
    variables: {
      userId,
    },
  });

  const rows = useMemo(
    () =>
      (adminTenantSummary?.tenants ?? []).map<TenantRow>((t) => {
        return {
          id: t.id,
          isActiveTenant: t.id === tenantId,
          name: t.name,
          isPendingApproval: t.isPendingApproval,
          appointmentCount: t.appointmentsAggregate?.aggregate?.count ?? 0,
          userCount: t.tenantUsersAggregate?.aggregate?.count ?? 0,
          createdAt: t.createdAt,
        };
      }),
    [adminTenantSummary, tenantId],
  );

  return (
    <div>
      <PageTitle>All Tenants</PageTitle>
      <div>
        <DataTable
          data={rows}
          columns={cols}
          enableGlobalSearch
          initialSortingState={[{ id: 'createdAt', desc: true }]}
        />
      </div>
      <div className="mt-5">
        <Button className="m-1" variant="default">
          <Link to={`audit-trail`}>View Audit Trail</Link>
        </Button>
      </div>
    </div>
  );
};
