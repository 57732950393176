export class EluveMutationObserver {
  private flushPeriodMS: number;
  private mutationsFound: boolean;
  private observer: MutationObserver;
  private handler: () => Promise<void>;
  private intervalId: NodeJS.Timeout | null = null;

  constructor(handler: () => Promise<void>, flushPeriodMS = 500) {
    this.flushPeriodMS = flushPeriodMS;
    this.mutationsFound = false;
    this.observer = new MutationObserver(() => {
      this.mutationsFound = true;
    });
    this.handler = handler;
  }

  start = () => {
    this.intervalId = setInterval(async () => {
      if (this.mutationsFound) {
        try {
          await this.handler();
        } catch (e) {
          this.disconnect();
        }
        this.mutationsFound = false;
      }
    }, this.flushPeriodMS);
    try {
      const container = document.documentElement || document.body;
      this.observer.observe(container, {
        childList: true,
        subtree: true,
      });
    } catch (error) {
      // Observer failed to start
    }
  };

  disconnect = () => {
    this.observer.disconnect();
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  };

  static waitForElementToLoad = async (
    handler: () => boolean,
    timeoutMs = 5000,
  ): Promise<boolean> => {
    return new Promise((resolve) => {
      const timeout = setTimeout(() => {
        mutationObserver.disconnect();
        resolve(false);
      }, timeoutMs);
      const loaded = handler();
      if (loaded) {
        clearTimeout(timeout);
        resolve(true);
        return;
      }
      const mutationObserver = new EluveMutationObserver(async () => {
        const loaded = handler();
        if (loaded) {
          clearTimeout(timeout);
          mutationObserver.disconnect();
          resolve(true);
          return;
        }
      });
      mutationObserver.start();
    });
  };
}
