import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { supportedVendorsSchema } from '@eluve/graphql-types';

const c = initContract();

export const llmOutputsContract = c.router(
  {
    importTemplates: {
      method: 'POST',
      path: '/import',
      responses: {
        201: z.object({}),
      },
      body: z.object({
        data: z.array(z.unknown()),
        vendor: supportedVendorsSchema,
        domain: z.string(),
      }),
      summary: 'Import chart templates from EHR',
    },
  },
  {
    pathPrefix: '/:tenantId/llm-outputs',
  },
);
