export type LogMetadata = Record<string, unknown>;
export type LogLevel = 'info' | 'warn' | 'error' | 'debug';

export interface Loggable {
  info(message: string, metadata?: LogMetadata): void;
  warn(message: string, metadata?: LogMetadata): void;
  error(message: string, metadata?: LogMetadata): void;
  debug(message: string, metadata?: LogMetadata): void;
}

export const isDebugEnabled = (): boolean => {
  if (typeof window !== 'undefined') {
    const windowObject = window as any;
    // react native
    if (windowObject.__DEV__) {
      return true;
    }
    if (typeof windowObject.LOG_LEVEL === 'string') {
      return windowObject.LOG_LEVEL.toLowerCase() === 'debug';
    }
    if (windowObject.location?.host?.includes('localhost')) {
      return true;
    }
    if (windowObject.location?.host?.includes('eluve.com')) {
      return windowObject.location.host !== 'app.eluve.com';
    }
    // Allow offscreen to print to console
    if (windowObject.location?.origin?.includes('chrome-extension')) {
      return true;
    }
  }
  return false;
};
