import React from 'react';

import { SelectItem } from '@eluve/components';
import { P } from '@eluve/components';

export interface PromptTemplateSelectItemProps {
  templateId: string;
  templateName: string;
  templateDescription: string | null;
  icon?: React.ReactNode;
}

export const PromptTemplateSelectItem: React.FC<
  PromptTemplateSelectItemProps
> = ({ templateId, templateName, templateDescription, icon }) => {
  const optionContent = (
    <div className="max-w-96 py-1">
      <P className="text-gray-12">{templateName}</P>
      <P className="font-normal  sm:text-sm">{templateDescription}</P>
    </div>
  );

  return (
    <SelectItem
      key={templateId}
      className="focus:bg-brand-4"
      value={templateId ?? ''}
    >
      {icon ? (
        <div className="flex items-center gap-2">
          {icon}
          {optionContent}
        </div>
      ) : (
        optionContent
      )}
    </SelectItem>
  );
};
