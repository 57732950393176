import { useMutation } from '@apollo/client';
import { Gem } from 'lucide-react';
import React from 'react';
import { toast } from 'sonner';

import { cacheUtils } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

const setDefaultPromptTemplateModelMutation = graphql(`
  mutation SetDefaultPromptTemplateModelMutation(
    $promptTemplateId: uuid!
    $modelArgsId: uuid!
  ) {
    resetDefault: updateLlmModelPromptTemplates(
      where: { promptTemplateId: { _eq: $promptTemplateId } }
      _set: { isDefault: false }
    ) {
      affectedRows
    }
    updateLlmModelPromptTemplates(
      where: {
        _and: [
          { modelArgsId: { _eq: $modelArgsId } }
          { promptTemplateId: { _eq: $promptTemplateId } }
        ]
      }
      _set: { isDefault: true }
    ) {
      affectedRows
    }
  }
`);

const promptModelDefaultsFragment = graphql(`
  fragment PromptModelDefaults on PromptTemplates {
    __typename
    id
    prompt_models {
      __typename
      id
      modelArgsId
      isDefault
    }
  }
`);

export interface SetDefaultPromptTemplateModelActionProps {
  isDefault: boolean;
  promptTemplateId: string;
  modelArgsId: string;
}

export const SetDefaultPromptTemplateModelAction: React.FC<
  SetDefaultPromptTemplateModelActionProps
> = ({ modelArgsId, promptTemplateId, isDefault }) => {
  const [setDefaultPromptTemplateModel] = useMutation(
    setDefaultPromptTemplateModelMutation,
    {
      onCompleted: () =>
        toast.success('Successfully updated default model for this prompt'),
      onError: () =>
        toast.error('Failed to update default model for this prompt'),
      optimisticResponse: {
        resetDefault: {
          affectedRows: 1,
        },
        updateLlmModelPromptTemplates: {
          affectedRows: 1,
        },
      },
      update: (_cache) => {
        cacheUtils.updateFragment(
          {
            fragment: promptModelDefaultsFragment,
            key: {
              id: promptTemplateId,
            },
          },
          (existing) => {
            return {
              __typename: 'PromptTemplates' as const,
              id: promptTemplateId,
              prompt_models: (existing?.prompt_models ?? []).map((pm) => ({
                __typename: 'LlmModelPromptTemplates' as const,
                id: pm.id,
                isDefault: pm.modelArgsId === modelArgsId,
                modelArgsId: pm.modelArgsId,
              })),
            };
          },
        );
      },
    },
  );

  const setDefault = () => {
    setDefaultPromptTemplateModel({
      variables: {
        modelArgsId,
        promptTemplateId,
      },
    });
  };

  if (isDefault) {
    return null;
  }

  return (
    <AlertDialog>
      <TooltipLabel label="Make this model the default when this prompt is used">
        <AlertDialogTrigger asChild>
          <Button size="icon" variant="outline">
            <Gem />
          </Button>
        </AlertDialogTrigger>
      </TooltipLabel>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="text-gray-11">
            Are you sure?
          </AlertDialogTitle>
          <AlertDialogDescription>
            This will make this model configuration the default any time a user
            uses this prompt
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel autoFocus>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            onClick={setDefault}
          >
            Set Default
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
