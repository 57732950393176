import sumBy from 'lodash/sumBy';
import { Check } from 'lucide-react';
import React, { useMemo } from 'react';

import { QUERY_ROOT_ID, useCompleteFragment } from '@eluve/apollo-client';
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
  P,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

export const artifactsFragment = graphql(`
  fragment artifacts on query_root @_unmask {
    evalDatasets {
      __typename
      id
      name
      description
      datasetSourceArtifacts {
        __typename
        id
        sourceArtifact {
          __typename
          id
          name
          factsAggregate {
            __typename
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`);

export type DataSetItem = {
  id: string;
  name: string;
  totalArtifacts: number;
  totalFacts: number;
};

export interface ArtifactSearchProps {
  selectedDataSetItems: DataSetItem[];
  onSelectionChanged: (selectedDataSetItems: DataSetItem[]) => void;
}

export const ArtifactSearch: React.FC<ArtifactSearchProps> = ({
  onSelectionChanged,
  selectedDataSetItems,
}) => {
  const { evalDatasets } = useCompleteFragment({
    fragment: artifactsFragment,
    key: QUERY_ROOT_ID,
  });

  const commandItems = useMemo(() => {
    return (evalDatasets ?? []).map<DataSetItem>((d) => ({
      id: d.id,
      name: d.name,
      totalArtifacts: d.datasetSourceArtifacts.length,
      totalFacts: sumBy(
        d.datasetSourceArtifacts,
        (a) => a.sourceArtifact?.factsAggregate?.aggregate?.count ?? 0,
      ),
    }));
  }, [evalDatasets]);

  return (
    <Command className="border">
      <CommandInput placeholder="Search for a dataset(s) to include artifacts" />
      <CommandEmpty>No datasets found</CommandEmpty>
      <CommandList>
        {commandItems.map((item) => (
          <div key={item.id} className="flex w-full items-center">
            <CommandItem
              key={item.id}
              className="flex-1"
              value={`${item.id}-${item.name}`}
              onSelect={() => {
                onSelectionChanged(
                  selectedDataSetItems.some((s) => s.id === item.id)
                    ? selectedDataSetItems.filter((s) => s.id !== item.id)
                    : [...selectedDataSetItems, item],
                );
              }}
            >
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center gap-2">
                  {selectedDataSetItems.some((s) => s.id === item.id) && (
                    <Check className="size-4 text-green-600" />
                  )}
                  <P className="text-gray-12">{item.name}</P>
                </div>
                <P>{`${item.totalArtifacts} artifact(s) with ${item.totalFacts} fact(s)`}</P>
              </div>
            </CommandItem>
          </div>
        ))}
      </CommandList>
    </Command>
  );
};
