import clsx from 'clsx';
import React from 'react';

import { MedicalCode } from '@eluve/blocks';
import { Box, cn } from '@eluve/components';

import { BillingCodeRecommendationProps } from './BillingCodeRecommendationProps';

export const BillingCodeRecommendationChip: React.FC<
  BillingCodeRecommendationProps
> = (props) => {
  const { type, code, isHighlighted } = props;
  return (
    <Box
      hStack
      className={cn(
        'w-fit border-spacing-2 cursor-pointer gap-2 overflow-hidden rounded-lg border border-dashed p-2 shadow-sm hover:shadow-md',
        clsx({
          'border-yellow-500 shadow-lg': isHighlighted,
        }),
      )}
      onClick={() => {
        if (isHighlighted) {
          props.onClearHighlight();
        } else {
          props.onHighlight();
        }
      }}
    >
      <MedicalCode type={type} code={code} />
    </Box>
  );
};
