import { useSuspenseQuery } from '@apollo/client';
import React, { Suspense } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { P, Skeleton } from '@eluve/components';
import {
  AppointmentContextProvider,
  getAppointmentDetailsQuery,
} from '@eluve/frontend-appointment-hooks';

import { SummaryComparison } from './SummaryComparison';

const SkeletonLine: React.FC = () => {
  return <Skeleton className="h-6 w-full bg-white" />;
};

export const SummaryComparisonPage: React.FC = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  const {
    data: { appointmentsByPk: appointment },
  } = useSuspenseQuery(getAppointmentDetailsQuery, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  if (appointment === null) {
    return <Navigate to="/404" />;
  }

  return (
    <Suspense
      fallback={
        <div className="container mt-24 flex flex-col gap-2">
          <P>Loading Summary Comparison...</P>
          <SkeletonLine />
          <SkeletonLine />
          <SkeletonLine />
        </div>
      }
    >
      <AppointmentContextProvider
        tenantId={tenantId}
        appointmentId={appointmentId}
      >
        <SummaryComparison />
      </AppointmentContextProvider>
    </Suspense>
  );
};
