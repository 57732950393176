import { z } from 'zod';

export const appointmentSchema = z
  .object({
    appointmentId: z.number().nullish(),
    appointmentUUID: z.string().nullish(),
    serviceLocationId: z.number().nullish(),
    appointmentStatus: z.number().nullish(),
    startTime: z.number().nullish(),
    endTime: z.number().nullish(),
    occurrenceId: z.unknown().nullish(),
    patientSummary: z
      .object({
        patientId: z.number(),
        guid: z.string().nullish(),
        genderId: z.number().nullish(),
        dateOfBirth: z.number().nullish(),
        firstName: z.string().nullish(),
        lastName: z.string().nullish(),
        preferredPhoneType: z.string().nullish(),
        preferredEmailType: z.string().nullish(),
        homePhone: z.unknown().nullish(),
        email: z.string().nullish(),
        middleName: z.unknown().nullish(),
        workPhone: z.unknown().nullish(),
        mobilePhone: z.string().nullish(),
        otherPhone: z.string().nullish(),
        workEmail: z.unknown().nullish(),
        otherEmail: z.unknown().nullish(),
      })
      .passthrough(),
    appointmentReasonId: z.number().nullish(),
    recurrenceRule: z.unknown().nullish(),
    providerId: z.number().nullish(),
    resourceId: z.unknown().nullish(),
    notes: z.string().nullish(),
    resourceIds: z.array(z.unknown()).nullish(),
    appointmentType: z.number().nullish(),
    createdAt: z.number().nullish(),
    practiceId: z.number().nullish(),
    updatedAt: z.number().nullish(),
    reminders: z.array(z.unknown()).nullish(),
    appointmentName: z.string().nullish(),
    maxAttendees: z.number().nullish(),
    attendeesCount: z.number().nullish(),
    patientSummaries: z.unknown().nullish(),
    patientCaseId: z.unknown().nullish(),
    recurring: z.boolean().nullish(),
    groupAppointment: z.boolean().nullish(),
  })
  .passthrough();

export const patientSchema = z
  .object({
    patientId: z.number(),
    practiceId: z.number().nullish(),
    guid: z.string().nullish(),
    firstName: z.string().nullish(),
    middleName: z.string().nullish(),
    lastName: z.string().nullish(),
    mrn: z.unknown().nullish(),
    dob: z.string().nullish(),
    gender: z.string().nullish(),
    email: z.string().nullish(),
    ageInYears: z.number().nullish(),
    monthsSinceBirthday: z.number().nullish(),
    primaryInsurance: z.string().nullish(),
    secondaryInsurance: z.unknown().nullish(),
    alertMessage: z.string().nullish(),
    phoneNumber: z.string().nullish(),
    phoneType: z.string().nullish(),
    address1: z.string().nullish(),
    address2: z.string().nullish(),
    city: z.string().nullish(),
    zipCode: z.string().nullish(),
    state: z.string().nullish(),
  })
  .passthrough();

export type Appointment = z.infer<typeof appointmentSchema>;
export type AppointmentsResponse = Appointment[];
export type Patient = z.infer<typeof patientSchema>;
