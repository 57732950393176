import { z } from 'zod';

export const appointmentSchema = z
  .object({
    ServiceDate: z.string().nullish(),
    Clinic: z.string().nullish(),
    LogNumber: z.number().nullish(),
    TimeIn: z.string().nullish(),
    ScheduledTime: z.string().nullish(),
    ScheduledMethod: z.number().nullish(),
    KioskPin: z.string().nullish(),
    KioskStatus: z.number().nullish(),
    PatientNumber: z.number().nullish(),
    LastName: z.string().nullish(),
    FirstName: z.string().nullish(),
    CheckInPrintOutDateTime: z.unknown().nullish(),
    CheckInPrintUserProfilePk: z.unknown().nullish(),
    CheckInPrintUserName: z.unknown().nullish(),
    PrivateStatus: z.unknown().nullish(),
    EpsStatus: z.unknown().nullish(),
    WorkCompStatus: z.unknown().nullish(),
    MiscStatus: z.unknown().nullish(),
    PrivateSelfPay: z.unknown().nullish(),
    PrivateChargeHeaderStatus: z.unknown().nullish(),
    EpsChargeHeaderStatus: z.unknown().nullish(),
    WorkCompChargeHeaderStatus: z.unknown().nullish(),
    PrivateTimeOut: z.string().nullish(),
    EpsTimeOut: z.string().nullish(),
    WorkCompTimeOut: z.unknown().nullish(),
    MiscTimeOut: z.string().nullish(),
    PrvNoPivotFlag: z.unknown().nullish(),
    EpsNoPivotFlag: z.unknown().nullish(),
    WcNoPivotFlag: z.unknown().nullish(),
    MiscNoPivotFlag: z.unknown().nullish(),
    PrevPayAmt: z.unknown().nullish(),
    PoaPaidAmount: z.number().nullish(),
    RteStatus: z.number().nullish(),
    VirtualVisitTypeMisc: z.unknown().nullish(),
    VirtualVisitTypePriv: z.unknown().nullish(),
    VirtualVisitTypeWc: z.unknown().nullish(),
    VirtualVisitTypeEps: z.unknown().nullish(),
    ProcCodes: z.unknown().nullish(),
    MiscLogBookStatus: z.string().nullish(),
    PreferredName: z.unknown().nullish(),
    PrivateIsChartSigned: z.unknown().nullish(),
    WorkCompIsChartSigned: z.unknown().nullish(),
    EpsIsChartSigned: z.unknown().nullish(),
    MiscIsChartSigned: z.unknown().nullish(),
    LogBookRegistrationHoldingPk: z.string().nullish(),
    AppointmentId: z.number().nullish(),
    VisitStatusId: z.number().nullish(),
    VisitStatusStartTime: z.string().nullish(),
    VisitStatusStopTime: z.unknown().nullish(),
    PreviousVisitStatusId: z.number().nullish(),
    CellPhone: z.string().nullish(),
    ContactName: z.unknown().nullish(),
    Birthday: z.string().nullish(),
    QuickVisitFlag: z.unknown().nullish(),
    Suffix: z.string().nullish(),
    IsPatientTelemedicineEligible: z.unknown().nullish(),
    TelemedicineType: z.unknown().nullish(),
    TelemedicineStatus: z.unknown().nullish(),
    SmsStatus: z.number().nullish(),
    CrtUserId: z.string().nullish(),
  })
  .passthrough();

export const patientSchema = z
  .object({
    LastName: z.string(),
    FirstName: z.string(),
    PatientNumber: z.number(),
  })
  .passthrough();

export type Appointment = z.infer<typeof appointmentSchema>;
export type Patient = z.infer<typeof patientSchema>;

export type AppointmentsResponse = { appointments: Appointment[] };
