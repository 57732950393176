import { useParams } from 'react-router-dom';

export const useTenantIdFromParams = () => {
  const { tenantId } = useParams() as { tenantId: string | undefined };
  return tenantId;
};

/**
 * Assumes that there will definitely be a tenantId in the URL params.
 * This hook is safe to use if you know you're in a :tenantId route
 */
export const useAssignedTenantIdFromParams = () => {
  const { tenantId } = useParams() as { tenantId: string };
  return tenantId;
};
