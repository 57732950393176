import { useSuspenseQuery } from '@apollo/client';
import { ColumnDef } from '@tanstack/react-table';
import { UserSquareIcon } from 'lucide-react';
import React, { useMemo } from 'react';

import { CopyTextButton } from '@eluve/blocks';
import { GetFullAuditTrailDocument } from '@eluve/client-gql-operations';
import {
  DataTable,
  P,
  PageTitle,
  SortableColumnHeader,
  makeDefaultSortableColumnDef,
  makeVisibilityToggleColumnDef,
} from '@eluve/components';

type TenantRow = {
  id: string;
  operation: string;
  createdAt: string;
  data: Record<string, unknown> | null;
  tableName: string | null;
  userId?: string;
  userName: string;
  userEmail: string | null;
};

const columns: ColumnDef<TenantRow>[] = [
  {
    ...makeVisibilityToggleColumnDef({
      ...makeDefaultSortableColumnDef('id', 'Id'),
      id: 'id',
      accessorFn: (row) => row.id,
      hiddenCell: ({ row }) => <CopyTextButton copyText={row.original.id} />,
    }),
  },
  {
    accessorKey: 'operation',
    header: ({ column }) => (
      <SortableColumnHeader column={column} label="Operation" />
    ),
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => (
      <SortableColumnHeader column={column} label="Created At" />
    ),
  },
  {
    accessorKey: 'tableName',
    header: ({ column }) => (
      <SortableColumnHeader column={column} label="Table" />
    ),
  },
  {
    ...makeVisibilityToggleColumnDef({
      ...makeDefaultSortableColumnDef('userId', 'User Id'),
      id: 'userId',
      accessorFn: (row) => row.userId,
      hiddenCell: ({ row }) =>
        row.original.userId && (
          <div className="flex justify-center">
            <CopyTextButton copyText={row.original.userId}>
              <UserSquareIcon className="h-6 w-6" />
            </CopyTextButton>
          </div>
        ),
    }),
  },
  {
    accessorKey: 'userName',
    header: ({ column }) => (
      <SortableColumnHeader column={column} label="Name" />
    ),
  },
  {
    accessorKey: 'userEmail',
    header: ({ column }) => (
      <SortableColumnHeader column={column} label="Email" />
    ),
  },
  {
    accessorKey: 'data',
    header: ({ column }) => (
      <SortableColumnHeader column={column} label="Data" />
    ),
    accessorFn: (row) => JSON.stringify(row.data),
    cell: ({ row }) => {
      return <P>{JSON.stringify(row.original.data)}</P>;
    },
  },
];

export const AdminAuditTrail: React.FC = () => {
  const { data: auditTrail } = useSuspenseQuery(GetFullAuditTrailDocument);

  const rows = useMemo(
    () =>
      (auditTrail?.auditTrail ?? []).map<TenantRow>((t) => {
        return {
          id: t.id,
          operation: t.operation,
          createdAt: t.createdAt,
          data: t.data,
          tableName: t.tableName,
          userId: t.user?.id,
          userName: [t.user?.firstName, t.user?.lastName]
            .filter(Boolean)
            .join(' '),
          userEmail: t.user?.email ?? null,
        };
      }),
    [auditTrail],
  );

  return (
    <div>
      <PageTitle>Complete Audit Trail</PageTitle>
      <div>
        <DataTable data={rows} columns={columns} enableGlobalSearch />
      </div>
    </div>
  );
};
