import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';

import { PatientDetails } from '../../../components/PatientDetails';

interface AppointmentPatientDetailsProps {
  appointmentId: string;
}

const AppointmentPatientDetailsFragment = graphql(`
  fragment AppointmentPatientDetails on Appointments {
    __typename
    id
    patient {
      __typename
      id
    }
  }
`);

export const AppointmentPatientDetails: React.FC<
  AppointmentPatientDetailsProps
> = ({ appointmentId }) => {
  const { patient } = useCompleteFragment({
    fragment: AppointmentPatientDetailsFragment,
    key: {
      id: appointmentId,
    },
  });

  if (!patient?.id) {
    return null;
  }

  return <PatientDetails patientId={patient.id} />;
};
