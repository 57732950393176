import {
  ApolloClient,
  NormalizedCacheObject,
  createHttpLink,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { Observable } from 'apollo-link';
import { createClient } from 'graphql-ws';

import { Logger } from '@eluve/logger';

import {
  ApolloClientFactoryOptions,
  defaultApolloClientOptions,
} from './client-options';

const webClientLogger = new Logger('apollo-client.factory');

export const apolloClientFactory: (
  options: ApolloClientFactoryOptions & {
    logger?: Logger;
  },
) => ApolloClient<NormalizedCacheObject> = (options) => {
  const {
    uri,
    cacheInstance,
    defaultOptions = defaultApolloClientOptions,
    enableSubscriptions = true,
    desiredRole,
    accessTokenProvider,
    logger = webClientLogger,
  } = options;

  const retrieveToken = async () => {
    const { jwt, role: sessionRole } = await accessTokenProvider();

    const role = desiredRole ? desiredRole : sessionRole;

    return {
      jwt,
      role,
    };
  };

  const authLink = setContext(async (_request, { headers }) => {
    const { jwt, role } = await retrieveToken();

    const newHeaders = {
      ...headers,
      Authorization: `Bearer ${jwt}`,
      'x-hasura-role': headers?.['x-hasura-role'] ?? role,
    };

    return {
      headers: newHeaders,
    };
  });

  /**
   * If the request fails due to a JWTExpired error from Hasura, we will attempt to refresh the token
   * and then re-try the request one time
   */
  const retryErrorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      const { operationName } = operation;
      if (
        graphQLErrors &&
        graphQLErrors.some((e) => e.message.includes('JWTExpired'))
      ) {
        return new Observable((observer) => {
          retrieveToken()
            .then(({ jwt }) => {
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  Authorization: `Bearer ${jwt}`,
                },
              }));
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };

              forward(operation).subscribe(subscriber);
            })
            .catch((error) => {
              logger.error('Failed to retry with refreshed token', {
                error,
                operationName,
              });
              observer.error(error);
            });
          // Typing this is wonky but this is the recommended approach from a bunch of research into Apollo client workarounds
          // for async refresh code
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any;
      }

      if (graphQLErrors) {
        logger.error('Unhandled error in session client factory', {
          operationName,
          graphQLErrors,
        });
      }

      if (networkError) {
        logger.warn('Network error in session client factory', {
          networkError,
        });
      }

      return;
    },
  );

  const retryNetworkLink = new RetryLink({
    attempts: {
      max: 3,
    },
  });

  const authFlowLink = authLink.concat(retryNetworkLink).concat(retryErrorLink);

  const httpLink = authFlowLink.concat(
    createHttpLink({
      uri,
      fetch,
    }),
  );

  let finalLink = httpLink;

  // If subscriptions are enabled we need to set up a websocket and then split control
  // over the operations so that subscriptions will be handled by the WS Link
  if (enableSubscriptions) {
    const [protocol, endpoint] = uri.split('//');
    if (!protocol || !endpoint) {
      throw new Error(`Invalid URI provided: ${uri}`);
    }
    const wsUri = protocol.startsWith('https')
      ? `wss://${endpoint}`
      : `ws://${endpoint}`;

    const wsLink = new GraphQLWsLink(
      createClient({
        url: wsUri,
        retryAttempts: 2,
        shouldRetry: () => true,
        connectionParams: async () => {
          const { jwt, role } = await retrieveToken();

          return {
            headers: {
              Authorization: `Bearer ${jwt}`,
              'x-hasura-role': desiredRole ? desiredRole : role,
            },
          };
        },
      }),
    );

    finalLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink,
    );
  }

  return new ApolloClient({
    cache: cacheInstance,
    link: finalLink,
    defaultOptions,
    connectToDevTools: process.env.NODE_ENV === 'development',
  });
};
