import { AudioLines, Info } from 'lucide-react';
import React from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import { Alert, AlertDescription, AlertTitle } from '@eluve/components';
import { useAppointmentContext } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';

const chiefComplaintFragment = graphql(`
  fragment ChiefComplaint on Appointments {
    __typename
    id
    chiefComplaint
  }
`);

const updateChiefComplaintMutation = graphql(`
  mutation UpdateChiefComplaint(
    $tenantId: uuid!
    $appointmentId: uuid!
    $chiefComplaint: String!
  ) {
    updateAppointmentsByPk(
      pkColumns: { tenantId: $tenantId, id: $appointmentId }
      _set: { chiefComplaint: $chiefComplaint }
    ) {
      __typename
      id
      chiefComplaint
    }
  }
`);

export const AppointmentChiefComplaint: React.FC = () => {
  const { appointmentId } = useAppointmentContext();

  const result = useCompleteFragment({
    fragment: chiefComplaintFragment,
    key: {
      id: appointmentId,
    },
    strict: false,
  });

  if (!result?.chiefComplaint) {
    return (
      <Alert variant="default" className="-my-5 ml-1 animate-pulse">
        <AudioLines className="animate-pulse" />
        <AlertTitle>Chief Complaint</AlertTitle>
        <AlertDescription>Determining Chief Complaint...</AlertDescription>
      </Alert>
    );
  }

  return (
    <Alert variant="default" className="-my-5 ml-1">
      <Info />
      <AlertTitle>Chief Complaint</AlertTitle>
      <AlertDescription className="font-medium">
        {result.chiefComplaint}
      </AlertDescription>
    </Alert>
  );
};
