import { useCompleteFragment } from '@eluve/apollo-client';
import {
  PatientDetail as Patient,
  patientDetailsFragment,
} from '@eluve/smart-blocks';

interface PatientSelectorProps {
  patientId: string;
}

export const PatientDetails: React.FC<PatientSelectorProps> = ({
  patientId,
}) => {
  const patient = useCompleteFragment({
    fragment: patientDetailsFragment,
    key: {
      id: patientId,
    },
  });

  return <Patient {...patient} />;
};
