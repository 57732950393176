import { ColumnDef } from '@tanstack/react-table';

import { TooltipLabel } from '@eluve/blocks';
import { P, SortableColumnHeader } from '@eluve/components';

export interface ModelArgsColDefProps {
  modelArgs: {
    id: string;
    args: Record<string, any> | null;
  } | null;
}

export const ModelArgsColDef = <
  T extends ModelArgsColDefProps,
>(): ColumnDef<T> => ({
  accessorKey: 'modelArgs',
  accessorFn: (row) => JSON.stringify(row.modelArgs?.args ?? {}),
  header: ({ column }) => (
    <SortableColumnHeader column={column} label="Model args" />
  ),
  cell: ({ row }) => {
    const model = row.original.modelArgs;
    if (!model || !model.args) {
      return <P className="text-gray-8">No summary generated.</P>;
    }

    return (
      <TooltipLabel label={`id: ${model.id}`}>
        <div className="text-gray-11 flex flex-col gap-1">
          {Object.entries(model.args).map(([key, value]) => (
            <div key={key}>
              <strong>{key}</strong>: {JSON.stringify(value)}
            </div>
          ))}
        </div>
      </TooltipLabel>
    );
  },
});
