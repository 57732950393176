import React from 'react';

import { H3 } from '@eluve/components';

export interface DetailsListProps {
  title?: {
    label: string;
    subtitle?: string;
  };
  items: {
    label: string;
    value: string | number | boolean | null | undefined;
  }[];
}

export const DetailsList: React.FC<DetailsListProps> = ({ title, items }) => {
  return (
    <div>
      {title && <H3>{title.label}</H3>}

      <div className="mt-2">
        <dl className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {items.map((item, index) => {
            return (
              <div
                key={index}
                className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0"
              >
                <dt className="text-gray-12 text-sm font-medium leading-6">
                  {item.label}
                </dt>
                <dd className="text-gray-10 mt-1 text-sm leading-6 sm:mt-2">
                  {item.value ?? '---'}
                </dd>
              </div>
            );
          })}
        </dl>
      </div>
    </div>
  );
};
