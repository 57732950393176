import { Editor, Editor as TipTapEditor } from '@tiptap/core';
import { EditorProps } from '@tiptap/pm/view';
import {
  EditorContent,
  Extension,
  JSONContent,
  UseEditorOptions,
  useEditor,
} from '@tiptap/react';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { EditorBubbleMenu } from './editor/bubble-menu';
import { defaultExtensions } from './editor/extensions';
import { defaultEditorProps } from './props';

export type EluveEditorProps = {
  /**
   * The editor content
   */
  content?: JSONContent | string;
  /**
   * A list of extensions to use for the editor, in addition to the default Novel extensions.
   * Defaults to [].
   */
  extensions?: Extension[];
  /**
   * Props to pass to the underlying Tiptap editor, in addition to the default Novel editor props.
   * Defaults to {}.
   */
  editorProps?: EditorProps;
  /**
   * A callback function that is called whenever the editor is updated.
   * Defaults to () => {}.
   */
  onUpdate: (editor?: TipTapEditor) => void | Promise<void>;
  className?: string;
  disabled?: boolean;
};

export type EluveEditorHandle = {
  setEditable: (editable: boolean) => void;
  getText: () => string;
};

const fallbackExtensions: Extension[] = [];
const fallbackEditorProps: EditorProps = {};

export const EluveEditor = forwardRef<EluveEditorHandle, EluveEditorProps>(
  (
    {
      content,
      className,
      onUpdate,
      extensions = fallbackExtensions,
      editorProps = fallbackEditorProps,
      disabled = false,
    },
    ref,
  ) => {
    const [editable, setEditable] = useState<boolean>(!disabled);

    const editorExtensions = useMemo(() => {
      return [...defaultExtensions, ...extensions];
    }, [extensions]);

    const tiptapEditorProps = useMemo(() => {
      return { ...defaultEditorProps, ...editorProps };
    }, [editorProps]);

    const editorOnUpdate = useMemo(() => {
      return ({ editor }: { editor: Editor }) => onUpdate(editor);
    }, [onUpdate]);

    const options: Partial<UseEditorOptions> = useMemo(
      () => ({
        extensions: editorExtensions,
        editorProps: tiptapEditorProps,
        onUpdate: editorOnUpdate,
        content,
        // https://tiptap.dev/blog/release-notes/say-hello-to-tiptap-2-5-our-most-performant-editor-yet
        immediatelyRender: true,
        shouldRerenderOnTransaction: false,
      }),
      [content, editorExtensions, editorOnUpdate, tiptapEditorProps],
    );

    const editor = useEditor(options, []);

    useImperativeHandle(ref, () => ({
      setEditable(editable: boolean) {
        setEditable(editable);
      },
      getText() {
        return editor?.getText() ?? '';
      },
    }));

    // the editor isn't available right away, so we manage
    // the editable state in this component and set it on
    // the editor when the editor becomes available
    useEffect(() => {
      editor?.setEditable(editable);
    }, [editor, editable]);

    return (
      <>
        <EditorContent editor={editor} className={className} />
        <EditorBubbleMenu editor={editor} />
      </>
    );
  },
);
