import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';

import { useAppointmentId } from './appointment.context';

const fragment = graphql(`
  fragment AppointmentStartedAt on Appointments {
    __typename
    id
    startedAt
  }
`);

export const useAppointmentStartedAt = () => {
  const id = useAppointmentId();

  const data = useCompleteFragment({
    fragment,
    key: {
      id,
    },
  });

  return data.startedAt;
};
