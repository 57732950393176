import { AlertOctagon } from 'lucide-react';
import React from 'react';

import { Alert, AlertDescription, AlertTitle, Button } from '@eluve/components';

export interface ErrorAlertProps {
  title?: string;
  message: string;
  reset?: () => void;
}

export const ErrorAlert: React.FC<ErrorAlertProps> = ({
  title = 'Error',
  message,
  reset,
}) => {
  return (
    <Alert variant="destructive">
      <AlertOctagon className="h-6 w-6" />
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>
        {message}
        {reset ? (
          <Button
            onClick={reset}
            className="text-red-500 underline"
            variant="link"
          >
            Try again
          </Button>
        ) : null}
      </AlertDescription>
    </Alert>
  );
};
