import { useEffect, useState } from 'react';
import { useLatest } from 'react-use';
import { toast } from 'sonner';

import { Button } from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';
import { useCaptureEvent, useCaptureEventOnMount } from '@eluve/posthog-react';

type VersionCheckerProps = {
  children: React.ReactNode;
  intervalMinutes?: number;
};
const ReloadForNewVersion: React.FC<{ newAppVersion: string }> = ({
  newAppVersion,
}) => {
  useCaptureEventOnMount('user_viewed_new_version_toast', {
    newAppVersion,
  });

  const captureEvent = useCaptureEvent({
    newAppVersion,
  });

  return (
    <div className="flex w-full flex-col items-center justify-center sm:flex-row">
      <div className="mr-3">A new version of Eluve is available!</div>
      <div className="flex flex-col">
        <Button
          variant="toastprimary"
          size="xs"
          className="h-7"
          onClick={() => {
            captureEvent('user_reloaded_for_new_version', undefined, {
              send_instantly: true,
            });
            // Give analytics events a chance to be sent before reloading
            setTimeout(() => {
              window.location.reload();
            }, 50);
          }}
        >
          Reload now
        </Button>
        <Button
          variant="toastsecondary"
          size="xs"
          className="mt-1"
          onClick={() => {
            captureEvent('user_dismissed_new_version_toast');
            toast.dismiss();
          }}
        >
          Dismiss
        </Button>
      </div>
    </div>
  );
};
export const VersionChecker: React.FC<VersionCheckerProps> = ({
  children,
  intervalMinutes = 5,
}) => {
  const logger = useNamedLogger('VersionChecker');
  const [version, setVersion] = useState<string | null>(null);
  const [toastOpen, setToastOpen] = useState<boolean>(false);

  const toastOpenRef = useLatest(toastOpen);
  const versionRef = useLatest(version);

  useEffect(() => {
    const checkVersion = async () => {
      const currentVersion = versionRef.current;
      const currentToastOpen = toastOpenRef.current;
      try {
        const headers = new Headers();
        if (currentVersion) {
          headers.append('If-None-Match', currentVersion);
        }
        const response = await fetch(window.location.href, {
          method: 'HEAD',
          headers,
        });

        const newVersion = response.headers.get('ETag');
        if (!newVersion) {
          return;
        }
        if (!currentVersion) {
          setVersion(newVersion);
          return;
        }
        if (newVersion !== currentVersion && !currentToastOpen) {
          logger.info(
            'Prompting user to reload the page to get the latest updates',
            { newVersion, currentVersion },
          );
          setToastOpen(true);
        }
      } catch (error) {
        logger.warn('Error checking version:', {
          error: (error as Error)?.message,
        });
      }
    };
    const intervalId = setInterval(checkVersion, intervalMinutes * 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [
    versionRef,
    setVersion,
    setToastOpen,
    intervalMinutes,
    logger,
    toastOpenRef,
  ]);

  useEffect(
    function displayToast() {
      if (!toastOpen) {
        return;
      }
      toast(<ReloadForNewVersion newAppVersion={version ?? ''} />, {
        position: 'bottom-right',
        duration: Infinity,
        onDismiss: () => {
          setToastOpen(false);
        },
      });
    },
    [toastOpen, setToastOpen, version],
  );

  return children;
};
