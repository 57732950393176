import { useCompleteFragment } from '@eluve/apollo-client';

import { useAppointmentId } from './appointment.context';
import { appointmentDoctorInteractionFragment } from './operations';

/**
 * Indicates whether this appointment is "signed" or not by
 * checking to see if we have a Signed At timestamp on a related
 * doctor interaction record
 */
export const useIsAppointmentSigned = () => {
  const appointmentId = useAppointmentId();
  const interaction = useCompleteFragment({
    fragment: appointmentDoctorInteractionFragment,
    key: { id: appointmentId },
    strict: false,
  });

  return Boolean(interaction?.doctor_interaction?.noteSignedAt);
};
