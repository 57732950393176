import { catchError, of, switchMap } from 'rxjs';
import { Subscription, fromCallback } from 'xstate';

import { DexieDb, UserFileSystem } from '@eluve/user-local-files';

import { AudioPacketSubject } from '../types';

interface Input {
  userFileSystem: UserFileSystem;
  audioPacketSubject: AudioPacketSubject;
  appointmentId: string;
  fileExtension: string;
  recordingStartedAt: string;
  db: DexieDb;
}

export type AudioFileSystemEvents =
  | {
      type: 'fileSystem.initialize';
    }
  | {
      type: 'fileSystem.ready';
      fileName: string;
    }
  | {
      type: 'fileSystem.close';
    }
  | {
      type: 'fileSystem.error';
      message: string;
    };

export const audioFileSystem = fromCallback<AudioFileSystemEvents, Input>(
  ({ input, sendBack, receive }) => {
    const {
      userFileSystem,
      appointmentId,
      fileExtension,
      recordingStartedAt,
      db,
    } = input;

    let writableFile: FileSystemWritableFileStream | undefined;
    let audioPacketSubscription: Subscription | undefined;

    const initializeFileSystem = async () => {
      try {
        if (userFileSystem.type !== 'available') {
          sendBack({
            type: 'fileSystem.error',
            message: 'File system not available',
          });
          return;
        }

        const appointmentDir =
          await userFileSystem.appointmentsDir.getDirectoryHandle(
            appointmentId,
            {
              create: true,
            },
          );

        const fileName = `${recordingStartedAt}.${fileExtension}`;

        const localFile = await appointmentDir.getFileHandle(fileName, {
          create: true,
        });

        if ('createWritable' in localFile) {
          const file = await localFile.createWritable();

          // Create a file reference in the DB so we can track the status until it ultimately
          // gets flushed to our backend
          await db.appointmentFiles.add({
            id: fileName,
            appointmentId,
            attemptCount: 0,
            lastUploadAttemptedAt: new Date().toISOString(),
            isBackgroundUpload: false,
            wasDegraded: false,
          });

          writableFile = file;
          audioPacketSubscription = input.audioPacketSubject
            .asObservable()
            .pipe(
              switchMap(async (packet) => {
                await file.write(packet.data);
              }),
              catchError((e) => {
                sendBack({
                  type: 'fileSystem.error',
                  message:
                    (e as { message?: string })?.message ?? 'Unexpected error',
                });
                return of(e);
              }),
            )
            .subscribe();

          sendBack({
            type: 'fileSystem.ready',
            fileName,
          });
        } else {
          sendBack({
            type: 'fileSystem.error',
            message: 'Incompatible Browser. Cannot save local file',
          });
        }
      } catch (e) {
        sendBack({
          type: 'fileSystem.error',
          message: (e as { message?: string })?.message ?? 'Unexpected error',
        });
      }
    };

    initializeFileSystem();

    receive((event) => {
      if (event.type === 'fileSystem.close') {
        writableFile?.close();
      }
    });

    return () => {
      audioPacketSubscription?.unsubscribe();
      writableFile?.close();
    };
  },
);
