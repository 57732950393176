import { P, match } from 'ts-pattern';
import { z } from 'zod';

/**
 * Validates a string that should represent a boolean value.
 * Useful for processing environment variables where input will
 * always be a string but we need to ensure a valid true/false value
 */
export const stringBooleanSchema = z
  .union([z.string(), z.boolean()])
  .default('false')
  .transform((val) =>
    match(val)
      .with(P.boolean, (v) => v.toString())
      .otherwise((v) => v.toLowerCase()),
  )
  .refine((val) => val === 'true' || val === 'false')
  .transform((val) => val === 'true');
