import { useQuery } from '@apollo/client';

import { getTenantLocationUsersQuery } from '@eluve/frontend-feature-location-hierarchy';
import {
  useTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

export const useUserLocations = () => {
  const tenantId = useTenantIdFromParams();
  const userId = useUserIdFromSession();
  const { data } = useQuery(getTenantLocationUsersQuery, {
    variables: {
      tenantId: tenantId!,
    },
    skip: !tenantId,
  });
  const locations = data?.tenantsByPk?.locations ?? [];
  const isUserPartOfAnyLocation = locations.some((location) =>
    location.location_users?.some((user) => user.userId === userId),
  );
  const userLocations = locations.filter((location) =>
    location.location_users?.some((user) => user.userId === userId),
  );
  const areLocationsAvailable = locations.length > 1;
  const isAnyLocationEhrEnabled = userLocations.some(
    (location) => location.externalEhr,
  );

  return {
    isUserPartOfAnyLocation,
    userLocations,
    areLocationsAvailable,
    isAnyLocationEhrEnabled,
  };
};
