import React from 'react';

import { useNamedLogger } from '@eluve/logger';
import {
  PeriodicFileSyncMachineProvider,
  PeriodicFileSyncProvider,
  readAllAppointmentFilesFromOpfs,
  useAppointmentTasksSelector,
  useDb,
  useUserFileSystemStore,
} from '@eluve/user-local-files';

import { useTranscriptionMachineSelector } from './appointments/machines/transcription/TranscriptionMachineProvider';

/**
 * This component is a bridge between the PeriodicFileSyncProvider and the current state of the TranscriptionMachine.
 * It provides information about whether appointment transcription is currently in progress or not so that
 * we can defer background file syncing until we detect an Idle state.
 */
export const PeriodicFileSyncProviderBridge: React.FC = () => {
  const logger = useNamedLogger('PeriodicFileSyncProviderBridge');
  const db = useDb();
  const isIdle = useTranscriptionMachineSelector((state) =>
    state.matches('Idle'),
  );

  const isUploadInProgress = useAppointmentTasksSelector((s) =>
    s.matches({ InProgress: 'UploadFile' }),
  );

  const fileSystem = useUserFileSystemStore((state) => state.userFileSystem);

  return (
    <>
      <PeriodicFileSyncProvider
        isAppointmentInProgress={!isIdle}
        isUploadInProgress={isUploadInProgress}
      />
      <PeriodicFileSyncMachineProvider
        getFileReferences={async () => {
          const [opfsFiles, dbFileRecords] = await Promise.all([
            readAllAppointmentFilesFromOpfs(fileSystem, logger),
            db.appointmentFiles.toArray(),
          ]);

          return {
            dbFileRecords,
            opfsFiles,
          };
        }}
        appointmentFilesTable={db.appointmentFiles}
      />
    </>
  );
};
