import { useSuspenseQuery } from '@apollo/client';
import React, { useMemo } from 'react';

import { ColDefBuilder, DataTable } from '@eluve/components';
import { ResultOf, graphql } from '@eluve/graphql.tada';

const getPatientsQuery = graphql(`
  query getPatients {
    patients {
      __typename
      id
      firstName
      lastName
      dateOfBirth
      sex
      appointments(orderBy: { startDate: DESC }, limit: 1) {
        __typename
        id
        startDate
      }
      appointmentsAggregate {
        aggregate {
          count
        }
      }
    }
  }
`);

type Row = Omit<
  ResultOf<typeof getPatientsQuery>['patients'][number],
  'appointmentsAggregate' | 'appointments'
> & { appointmentCount: number; lastAppointmentDate: string | null };

const columns = new ColDefBuilder<Row>()
  .detailsLink((r) => `${r.id}`, 'Details')
  .defaultSortable('firstName', { label: 'First Name', isPrivate: true })
  .defaultSortable('lastName', { label: 'Last Name', isPrivate: true })
  .dateSortable('lastAppointmentDate', 'Last Appointment')
  .dateSortable('dateOfBirth', { label: 'Date of Birth', isPrivate: true })
  .defaultSortable('appointmentCount', '# Appointments')
  .build();

export const PatientsList: React.FC = () => {
  const {
    data: { patients },
  } = useSuspenseQuery(getPatientsQuery);

  const data = useMemo(() => {
    return patients.map<Row>((p) => {
      const { appointmentsAggregate, ...rest } = p;

      return {
        ...rest,
        appointmentCount: appointmentsAggregate?.aggregate?.count ?? 0,
        lastAppointmentDate: p.appointments?.[0]?.startDate ?? null,
      };
    });
  }, [patients]);

  return <DataTable enableGlobalSearch={true} columns={columns} data={data} />;
};
