import { PlayCircleIcon } from 'lucide-react';

import { Box, Button } from '@eluve/components';
import { useIsAppointmentReadonly } from '@eluve/frontend-appointment-hooks';
import { useSelectedMicrophoneDevice } from '@eluve/smart-blocks';
import {
  useAppointmentTasksActor,
  useAppointmentTasksSelector,
} from '@eluve/user-local-files';

import { useTranscriptionMachineActor } from '../machines/transcription/TranscriptionMachineProvider';

interface TranscriptionControlsProps {
  appointmentId: string;
}

export const AppointmentTranscriptionIdleControls: React.FC<
  TranscriptionControlsProps
> = ({ appointmentId }) => {
  const isEndingSession = useAppointmentTasksSelector((s) =>
    s.context.tasks.some(
      (t) =>
        t.appointmentId === appointmentId &&
        t.type === 'TASK.MARK_APPOINTMENT_AS_COMPLETE',
    ),
  );

  const { send } = useTranscriptionMachineActor();
  const { send: sendAppointmentTask } = useAppointmentTasksActor();
  const isReadonly = useIsAppointmentReadonly();

  const [selectedMicId] = useSelectedMicrophoneDevice();

  const startSession = async () => {
    const microphoneName = (
      await navigator.mediaDevices.enumerateDevices()
    ).find((device) => device.deviceId === selectedMicId)?.label;

    send({
      type: 'START',
      appointmentId,
      deviceId: selectedMicId ?? undefined,
      microphoneName,
      // inputLanguage,
      // outputLanguage,
    });
  };

  if (isReadonly) {
    return null;
  }

  return (
    <Box hStack>
      <Button
        onClick={startSession}
        variant="outline"
        disabled={isEndingSession}
      >
        <PlayCircleIcon className="h-5 sm:mr-2" />
        <span className="hidden sm:block">Resume</span>
      </Button>
      <Button
        onClick={() =>
          sendAppointmentTask({
            type: 'TASK.MARK_APPOINTMENT_AS_COMPLETE',
            appointmentId,
          })
        }
        disabled={isEndingSession}
        variant="dark"
      >
        End Session
      </Button>
    </Box>
  );
};
