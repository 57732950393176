import * as Sentry from '@sentry/react';
import { Loader2, SparklesIcon } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

import { useApiClient } from '@eluve/api-client-provider';
import { TooltipLabel } from '@eluve/blocks';
import { Select, SelectTrigger } from '@eluve/components';
import {
  PromptTemplateSelectContent,
  PromptTemplateSelectItem,
} from '@eluve/frontend-feature-user-settings';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { FeatureFlaggedComponent } from './FeatureFlagged';
import { useAppointmentPromptTemplate } from './useAppointmentPromptTemplate';

interface TemplateSelectorWithAutoSelectOptionProps {
  appointmentId: string;
}

export const TemplateSelectorWithAutoSelectOption: React.FC<
  TemplateSelectorWithAutoSelectOptionProps
> = ({ appointmentId }) => {
  const apiClient = useApiClient();
  const [autoSelecting, setAutoSelecting] = useState(false);
  const tenantId = useTenantIdFromParams();

  const { selectedPromptTemplate, updateAppointmentPromptTemplate } =
    useAppointmentPromptTemplate();

  const handleAutoSelect = async () => {
    setAutoSelecting(true);
    try {
      const data = await apiClient.llm.recommendTemplate({
        params: {
          tenantId: tenantId!,
          appointmentId,
        },
        body: {},
      });

      if (data.status === 200) {
        updateAppointmentPromptTemplate(data.body.id);
        toast.success(`"${data.body.name}" template has been auto selected.`);
        return;
      }
      toast.error('Failed to auto select template.');
    } catch (e) {
      Sentry.captureException(e);
      toast.error('Failed to auto select template.');
    } finally {
      setAutoSelecting(false);
    }
  };

  const handleSelect = (templateId: string) => {
    if (templateId === 'recommended') {
      handleAutoSelect();
      return;
    }

    updateAppointmentPromptTemplate(templateId);
  };

  return (
    <Select
      value={selectedPromptTemplate?.id ?? ''}
      onValueChange={handleSelect}
    >
      {autoSelecting ? (
        <div className="text-brand-8 flex items-center">
          Auto selecting.. <Loader2 className="text-brand-8 h-4 animate-spin" />
        </div>
      ) : (
        <>
          <SelectTrigger className="h-auto w-auto border-0 border-none bg-transparent p-0 focus:border-transparent focus:ring-0 focus:ring-transparent">
            <span className=" text-brand-8 underline underline-offset-4 hover:cursor-pointer">
              {selectedPromptTemplate?.name}
            </span>{' '}
          </SelectTrigger>
          <PromptTemplateSelectContent
            additionalOptions={
              <FeatureFlaggedComponent flag="AUTO_RECOMMEND_PROMPT_TEMPLATE">
                <hr className="border-gray-3 my-1" />
                <PromptTemplateSelectItem
                  templateId="recommended"
                  icon={
                    <TooltipLabel label="Powered by AI">
                      <SparklesIcon className="text-primary -ml-2 mr-2 h-6 w-6" />
                    </TooltipLabel>
                  }
                  templateName="Auto select"
                  templateDescription="Based on given transcript auto select the prompt template."
                />
              </FeatureFlaggedComponent>
            }
          />
        </>
      )}
    </Select>
  );
};
