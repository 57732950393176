import React from 'react';

import { useTextHighlight } from '@eluve/components';

import { TranscriptText } from './TranscriptText';

export interface TranscriptWithHighlightsProps {
  transcript: string;
  excerpts: string[];
}

export const TranscriptWithHighlights: React.FC<
  TranscriptWithHighlightsProps
> = ({ transcript, excerpts }) => {
  const highlightRef = useTextHighlight(
    excerpts,
    {
      separateWordSearch: false,
      iframes: true,
      acrossElements: true,
      ignorePunctuation: ':;.,-–—‒_(){}[]!\'"+=’'.split(''),
    },
    true,
  );

  return <TranscriptText ref={highlightRef}>{transcript}</TranscriptText>;
};
