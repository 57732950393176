import { z } from 'zod';

import { makeFeatureFlagPayloadValidator } from './utils';

export const userPreferencesCustomInstructions =
  makeFeatureFlagPayloadValidator({
    defaultEnabled: false,
    schema: z.object({}),
    defaultValue: {},
  });
