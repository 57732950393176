import random from 'lodash/random';
import { useRef } from 'react';

import { Skeleton } from '@eluve/components';

export const InterimResultLoading = () => {
  const sentencesSize = useRef(random(100, 240));
  return (
    <Skeleton
      as="span"
      className="mx-2 inline-block h-3"
      style={{ width: sentencesSize.current }}
    />
  );
};
