export class MuteDetector extends EventTarget {
  private audioContext;
  private analyser;
  private source;
  private state: 'muted' | 'unmuted' = 'unmuted';
  private dataArray: Uint8Array | null = null;
  private mediaStream: MediaStream;
  private intervalTimer: number | null = null;
  private mutedTimeout: number | null = null;

  private get isStreamMuted() {
    return this.mediaStream.getAudioTracks().every((track) => track.muted);
  }

  private get isSilent() {
    if (!this.dataArray) {
      return false;
    }
    this.analyser.getByteFrequencyData(this.dataArray);
    for (let i = 0; i < this.dataArray.length; i++) {
      if (this.dataArray[i] !== 0) {
        return false;
      }
    }

    return true;
  }

  constructor(
    _mediaStream: MediaStream,
    private silenceDuration = 4,
  ) {
    super();

    this.audioContext = new AudioContext();
    this.mediaStream = _mediaStream.clone();

    this.analyser = this.audioContext.createAnalyser();
    this.source = this.audioContext.createMediaStreamSource(this.mediaStream);
  }

  private silent() {
    if (this.state === 'muted' || this.mutedTimeout) {
      return;
    }

    if (this.isStreamMuted) {
      this.state = 'muted';
      this.dispatchEvent(new Event('mute'));
      return;
    }

    this.mutedTimeout = window.setTimeout(() => {
      this.state = 'muted';
      this.dispatchEvent(new Event('mute'));
    }, this.silenceDuration * 1000);
  }

  private unmute() {
    if (this.mutedTimeout) {
      clearTimeout(this.mutedTimeout);
      this.mutedTimeout = null;
    }

    if (this.state === 'unmuted') {
      return;
    }

    this.state = 'unmuted';
    this.dispatchEvent(new Event('unmute'));
  }

  private checkSilence() {
    if (this.isSilent) {
      this.silent();
    } else {
      this.unmute();
    }
  }

  public start() {
    this.analyser.fftSize = 256;
    this.source.connect(this.analyser);
    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
    this.intervalTimer = window.setInterval(() => this.checkSilence(), 100);
  }

  public async stop(): Promise<void> {
    this.mutedTimeout && clearTimeout(this.mutedTimeout);
    this.intervalTimer && clearInterval(this.intervalTimer);

    this.mediaStream.getTracks().forEach((track) => {
      track.stop();
    });

    this.source.disconnect();
    await this.audioContext.close();
  }
}
