import { useQuery } from '@apollo/client';

import {
  getAllCompletedOutputs,
  getAllSummaries,
  useAppointmentContext,
} from '@eluve/frontend-appointment-hooks';
import { AppointmentSummary, AppointmentSummarySchema } from '@eluve/utils';

type LlmSummary = {
  summaryId: string;
  llmModelPromptTemplateId: string | null;
  promptTemplateId: string | null;
  summary: AppointmentSummary;
  isSummaryAvailable: boolean;
  summarizationCompletedAt: string | null;
};

export const useAllSummaries = (opts?: {
  soapNotesOnly: boolean;
}): {
  summaries: LlmSummary[];
} => {
  const { soapNotesOnly = true } = opts ?? {};
  const { tenantId, appointmentId } = useAppointmentContext();
  const { data } = useQuery(
    soapNotesOnly ? getAllSummaries : getAllCompletedOutputs,
    {
      variables: {
        tenantId,
        appointmentId,
      },
    },
  );

  const llmOutputs = data?.appointmentsByPk?.appointment_llm_outputs ?? [];

  const summaries = llmOutputs.map<LlmSummary>((llmSummary) => {
    const parsed = AppointmentSummarySchema.safeParse(
      llmSummary?.output?.content,
    );

    const shared: Omit<
      LlmSummary,
      'summary' | 'isSummaryAvailable' | 'summarizationCompletedAt'
    > = {
      summaryId: llmSummary.llmOutputId,
      llmModelPromptTemplateId: llmSummary.output.modelPromptTemplate.id,
      promptTemplateId: llmSummary.output.modelPromptTemplate.promptTemplateId,
    };

    if (parsed.success) {
      return {
        ...shared,
        summary: parsed.data,
        isSummaryAvailable: true,
        summarizationCompletedAt: llmSummary.output.completedAt,
      };
    }
    return {
      ...shared,
      summarizationCompletedAt: null,
      summary: {
        SUBJECTIVE: null,
        OBJECTIVE: null,
        ASSESSMENT: null,
        CLIENT_RECAP: null,
      },
      isSummaryAvailable: false,
    };
  });

  return { summaries };
};
