import { useLazyQuery } from '@apollo/client';
import { useRef } from 'react';
import { usePrevious } from 'react-use';

import { useToast } from '@eluve/components';
import {
  getCurrentSummary,
  useAppointmentActivity,
  useAppointmentContext,
} from '@eluve/frontend-appointment-hooks';

import { SUMMARY_TAB, useAppointmentTabNav } from './useAppointmentTabNav';

export const useListenForSummary = () => {
  const { isSummarizationInProgress } = useAppointmentActivity();
  const isCompleteRef = useRef(false);
  const { tenantId, appointmentId } = useAppointmentContext();
  const { setTab } = useAppointmentTabNav();
  const { toast } = useToast();

  const [getLatest] = useLazyQuery(getCurrentSummary, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  const updateSummary = async () => {
    await getLatest();
    setTab(SUMMARY_TAB);
    toast({
      title: 'Summary is ready!',
      description: 'Your summary is ready to be reviewed.',
      variant: 'success',
      duration: 5000, // milliseconds
    });
  };

  const wasSummarizationInProgress = usePrevious(
    isSummarizationInProgress ?? false,
  );

  if (
    wasSummarizationInProgress &&
    !isSummarizationInProgress &&
    !isCompleteRef.current
  ) {
    updateSummary();
  }
};
