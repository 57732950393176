/*
 * This function calculates the duration of an audio file in milliseconds.
 * It creates an audio element, sets the source to the file, and listens for the 'loadedmetadata' event.
 * If the duration is Infinity, it sets the currentTime to the maximum safe integer and listens for the 'timeupdate' event.
 * When the duration is finally calculated, it resolves the promise with the duration in milliseconds.
 * If there is an error, it rejects the promise.
 */
export const getAudioDurationFromFile = async (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const audioElement = document.createElement('audio');
    const objectURL = URL.createObjectURL(file);

    audioElement.src = objectURL;
    audioElement.preload = 'metadata';

    const timeoutId = setTimeout(() => {
      URL.revokeObjectURL(objectURL);
      reject(new Error('Duration calculation timed out'));
    }, 10000);

    audioElement.addEventListener('loadedmetadata', () => {
      if (audioElement.duration !== Infinity) {
        clearTimeout(timeoutId);
        URL.revokeObjectURL(objectURL);
        // Resolve with the duration in milliseconds
        resolve(Math.round(audioElement.duration * 1000));
      } else {
        audioElement.currentTime = Number.MAX_SAFE_INTEGER;
        audioElement.addEventListener('timeupdate', () => {
          if (audioElement.duration !== Infinity) {
            clearTimeout(timeoutId);
            URL.revokeObjectURL(objectURL);
            // Resolve with the duration in milliseconds
            resolve(Math.round(audioElement.duration * 1000));
          }
        });
      }
    });

    audioElement.addEventListener('error', () => {
      clearTimeout(timeoutId);
      URL.revokeObjectURL(objectURL);
      reject(new Error('Error loading audio file'));
    });
  });
};
