import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';

import { useAppointmentId } from './appointment.context';

export const appointmentSessionTypeFragment = graphql(`
  fragment SessionType on Appointments @_unmask {
    __typename
    id
    session_type {
      __typename
      id
      name
    }
  }
`);

export const useAppointmentSessionType = () => {
  const appointmentId = useAppointmentId();

  const { session_type } = useCompleteFragment({
    fragment: appointmentSessionTypeFragment,
    key: {
      id: appointmentId,
    },
  });

  return session_type;
};
