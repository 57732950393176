import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from './cn';

const buttonVariants = cva(
  'whitespace-nowrap inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-10',
  {
    variants: {
      variant: {
        default: 'bg-brand-10 text-gray-1 hover:bg-brand-10/80',
        dark: 'bg-brand-12 hover:bg-brand-12/80 text-gray-1',
        secondary:
          'bg-brand-3 border-none text-brand-9 hover:bg-brand-4 hover:text-brand-9',

        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background hover:text-accent-foreground',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        gray: 'text-background1 bg-content1 hover:bg-content1/90',
        lightgray: 'border-none bg-zinc-100 font-normal text-black',
        toastprimary:
          'bg-brand-12 hover:bg-brand-12/80 text-gray-1 w-auto min-w-[100px] whitespace-nowrap px-4 text-xs',
        toastsecondary:
          'border border-input bg-background hover:text-accent-foreground w-auto min-w-[100px] whitespace-nowrap px-4 text-xs',
        outlinesecondary:
          'border border-input bg-gray-50 hover:text-accent-foreground',
      },
      size: {
        default: 'h-10 px-4 py-2',
        xs: 'h-7 rounded-sm px-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'size-10',
        smallIcon: 'size-8',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
