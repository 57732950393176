import { RotateCwIcon } from 'lucide-react';

import { Button } from '@eluve/components';

import { useSummarizeAppointment } from '../../hooks/useSummarizeAppointment';

interface RegenerateSummaryProps {
  disabled?: boolean;
  tenantId: string;
  appointmentId: string;
}

export const RegenerateSummary: React.FC<RegenerateSummaryProps> = ({
  disabled,
  tenantId,
  appointmentId,
}) => {
  const regenerateSummary = useSummarizeAppointment();

  return (
    <Button
      type="button"
      variant="outline"
      className="w-fit"
      disabled={disabled}
      onClick={() => regenerateSummary(tenantId, appointmentId)}
    >
      <RotateCwIcon className="mr-2 h-4 w-4" />
      Regenerate Summary
    </Button>
  );
};
