import { z } from 'zod';

/**
 * @deprecated Use validators from @eluve/llm-outputs instead
 */
export const AppointmentSummarySchema = z.object({
  SUBJECTIVE: z.string().nullish(),
  OBJECTIVE: z.string().nullish(),
  ASSESSMENT: z.string().nullish(),
  PLAN: z.string().nullish(),
  CLIENT_RECAP: z.string().nullish(),
});

export type AppointmentSummary = z.infer<typeof AppointmentSummarySchema>;
export type AppointmentSummaryKeys = keyof AppointmentSummary;

export const summaryKeyToSectionTitleMap: Record<string, string> = {
  SUBJECTIVE: 'Subjective',
  OBJECTIVE: 'Objective',
  ASSESSMENT: 'Assessment',
  PLAN: 'Plan',
  CLIENT_RECAP: 'Client Recap',
};
