import { z } from 'zod';

import { MatchingRecord } from '@eluve/utils';

import { VendorTypesEnum } from './types';

export const VendorTypesLookup = {
  JANE: 'JANE',
  EXPERITY: 'EXPERITY',
  TEBRA: 'TEBRA',
  MOVEMENT_X: 'MOVEMENT_X',
} as const satisfies MatchingRecord<VendorTypesEnum>;

export const supportedVendorsSchema = z.nativeEnum(VendorTypesLookup);

export type SupportedVendors = z.infer<typeof supportedVendorsSchema>;
