import { QueryRef } from '@apollo/client';

import { ResultOf } from '@eluve/graphql.tada';
import { createZustandContext } from '@eluve/stores';

import { getPatientsQuery } from './getPatientsQuery';

type TenantStoreState = {
  patientsQueryRef: QueryRef<ResultOf<typeof getPatientsQuery>>;
};

export const {
  StoreProvider: TenantStoreProvider,
  useStore: useTenantStore,
  useStoreApi: useTenantStoreApi,
} = createZustandContext<TenantStoreState>();
