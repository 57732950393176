import * as Sentry from '@sentry/react';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { FCC } from '@eluve/components';
import {
  LoggerArgs,
  LoggingContextProvider,
  useExtendedLogger,
} from '@eluve/logger';
import {
  useUserDisplayFromSession,
  useUserIdFromSession,
} from '@eluve/session-helpers';

export const UserContextProvider: FCC = ({ children }) => {
  const userId = useUserIdFromSession();
  const user = useUserDisplayFromSession();
  const postHog = usePostHog();

  const { pathname } = useLocation();

  useEffect(
    function setUserContextForServices() {
      postHog.identify(userId, {
        email: user.email,
      });

      Sentry.setUser({
        id: userId,
        email: user.email,
      });
    },
    [userId, user.email, postHog],
  );

  useEffect(() => {
    postHog.capture('$pageview');
  }, [pathname, postHog]);

  const loggerArgs: LoggerArgs = useMemo(() => {
    return {
      name: 'UserScopedLogger',
      metadata: { userId },
    };
  }, [userId]);

  const userLogger = useExtendedLogger(loggerArgs);

  return (
    <LoggingContextProvider loggerInput={userLogger}>
      {children}
    </LoggingContextProvider>
  );
};
