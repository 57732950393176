import React from 'react';

import { FCC } from '@eluve/components';

import { DepthContainer } from './DepthContainer';

export const TreeNodeContainer: FCC<{ depth: number; bottom?: boolean }> = ({
  children,
  depth,
  bottom = false,
}) => {
  return (
    <DepthContainer depth={depth}>
      <div className="border-gray-8 flex-1 rounded-lg border p-4 shadow-md">
        {children}
      </div>
    </DepthContainer>
  );
};
