import { cn } from './cn';

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { as?: 'div' | 'span' }) {
  const { as: Component = 'div', ...rest } = props;
  return (
    <Component
      className={cn('bg-muted animate-pulse rounded-md', className)}
      {...rest}
    />
  );
}

export { Skeleton };
