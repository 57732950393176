import { useEffect, useState } from 'react';

import { Logger } from '@eluve/logger';

import { getAudioDurationFromFile } from './getAudioDurationFromFile';
import { readAppointmentFilesFromOpfs } from './opfs';
import { useUserFileSystemStore } from './user-file-system.store';

export const useTotalAudioDurationFromLocalFiles = (
  tenantId: string,
  appointmentId: string,
  logger: Logger,
) => {
  const userFileSystem = useUserFileSystemStore(
    (state) => state.userFileSystem,
  );
  const [totalDuration, setTotalDuration] = useState(0);

  useEffect(
    function calculateTotalDuration() {
      (async () => {
        if (!appointmentId) {
          return;
        }
        let updatedTotalDuration = 0;
        const readResponse = await readAppointmentFilesFromOpfs(
          userFileSystem,
          appointmentId,
        );
        if (readResponse.type !== 'directoryExists') {
          return;
        }
        for (const fileRow of readResponse.files) {
          try {
            const duration = await getAudioDurationFromFile(fileRow.file);
            if (duration) {
              updatedTotalDuration += duration;
            }
          } catch (error) {
            logger.warn('Failed to get audio duration from file', {
              error,
              errorMessage: (error as Error)?.message,
              fileName: fileRow.name,
              fileSize: fileRow.size,
              appointmentId,
            });
          }
        }
        setTotalDuration(updatedTotalDuration);
      })();
    },
    [userFileSystem, tenantId, appointmentId, logger],
  );

  return totalDuration;
};
