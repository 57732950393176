import { useLocalStorage } from '@uidotdev/usehooks';

import { useUserIdFromSession } from '@eluve/session-helpers';

export const useShowAppointmentsOwner = () => {
  const userId = useUserIdFromSession();
  return useLocalStorage<boolean>(
    `show-appointments-owner-for-user-${userId}`,
    false,
  );
};
