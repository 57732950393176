import { ContactRoundIcon } from 'lucide-react';

import { BackBreadcrumbItem } from '@eluve/blocks';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  DetailsSidebarTrigger,
  ReskinHeader,
  SidebarTrigger,
} from '@eluve/components';
import { useAppointmentName } from '@eluve/frontend-appointment-hooks';

import { NewAppointmentButton } from '../../components/NewAppointmentButton';
import { OverviewBreadcrumbItem } from '../../components/OverviewBreadcrumbItem';

export const AppointmentHeader: React.FC = () => {
  const name = useAppointmentName();

  return (
    <ReskinHeader>
      <SidebarTrigger />
      <Breadcrumb className="flex-1 overflow-hidden">
        <BreadcrumbList>
          <BackBreadcrumbItem />
          <OverviewBreadcrumbItem />
          <BreadcrumbSeparator className="hidden sm:block">
            /
          </BreadcrumbSeparator>
          <BreadcrumbItem className="text-gray-12 hidden truncate sm:block">
            {name}
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <NewAppointmentButton />
      <DetailsSidebarTrigger asChild>
        <Button size="icon" variant="ghost">
          <ContactRoundIcon />
        </Button>
      </DetailsSidebarTrigger>
    </ReskinHeader>
  );
};
