import { ColumnDef } from '@tanstack/react-table';

import { TooltipLabel } from '@eluve/blocks';
import { P, SortableColumnHeader } from '@eluve/components';

export interface PromptTemplateColDefProps {
  promptTemplate: {
    id: string;
    name: string;
  } | null;
}

export const PromptTemplateColDef = <
  T extends PromptTemplateColDefProps,
>(): ColumnDef<T> => ({
  accessorKey: 'promptTemplate',
  accessorFn: (row) => row.promptTemplate?.name ?? '',
  header: ({ column }) => (
    <SortableColumnHeader column={column} label="Prompt template" />
  ),
  cell: ({ row }) => {
    const template = row.original.promptTemplate;
    if (!template) {
      return <P className="text-gray-8">No summary generated.</P>;
    }

    return (
      <TooltipLabel label={`id: ${template.id}`}>
        <div>
          <P>{template.name}</P>
        </div>
      </TooltipLabel>
    );
  },
});
