import React, { useEffect } from 'react';
import { useLatest } from 'react-use';
import { createActor, fromPromise } from 'xstate';

import { useNamedLogger } from '@eluve/logger';

import { periodicFileSyncMachine } from './periodicFileSync.machine';
import { AppointmentFilesTableDependency, GetFileReferences } from './types';

export interface PeriodicFileSyncMachineProviderProps {
  appointmentFilesTable: AppointmentFilesTableDependency;
  getFileReferences: () => Promise<GetFileReferences>;
}

export const PeriodicFileSyncMachineProvider: React.FC<
  PeriodicFileSyncMachineProviderProps
> = ({ appointmentFilesTable, getFileReferences }) => {
  const logger = useNamedLogger('PeriodicFileSyncMachineProvider');

  const latestAppointmentFilesTable = useLatest(appointmentFilesTable);
  const latestGetFileReferences = useLatest(getFileReferences);

  useEffect(() => {
    const machineActor = createActor(
      periodicFileSyncMachine.provide({
        actors: {
          getFiles: fromPromise<GetFileReferences>(
            latestGetFileReferences.current,
          ),
        },
      }),
      {
        input: {
          appointmentFilesTable: latestAppointmentFilesTable.current,
          logger,
          checkForFileDriftIntervalMs: 1000 * 60 * 5,
        },
      },
    );

    machineActor.start();

    return () => {
      logger.info('Stopping periodicFileSyncMachine');
      machineActor.stop();
    };
  }, [latestAppointmentFilesTable, latestGetFileReferences, logger]);

  return null;
};
