import { JsonType } from 'posthog-js';
import { createContext, useContext } from 'react';
import { z } from 'zod';

import { Logger } from '@eluve/logger';
import {
  FeatureFlagDefinitions,
  FeatureFlagKeys,
  featureFlagsWithPayloads,
} from '@eluve/posthog';

export type FeatureFlagContextType = {
  flagDefinitions: FeatureFlagDefinitions;
  getFeatureFlagData: (flagKey: FeatureFlagKeys) => JsonType;
  logger: Logger;
};

const FeatureFlagContext = createContext<FeatureFlagContextType | null>(null);

export const FeatureFlagProvider = FeatureFlagContext.Provider;

const useFeatureFlagContext = () => {
  const ctx = useContext(FeatureFlagContext);
  if (!ctx) {
    throw new Error(
      'useFeatureFlagContext must be used within a FeatureFlagProvider',
    );
  }

  return ctx;
};

export const useFeatureFlagPayload = <T extends FeatureFlagKeys>(
  flagKey: T,
): z.infer<(typeof featureFlagsWithPayloads)[T]['schema']> => {
  const { getFeatureFlagData, flagDefinitions, logger } =
    useFeatureFlagContext();

  const flagData = getFeatureFlagData(flagKey);
  const flagDefinition = flagDefinitions[flagKey];
  if (!flagDefinition) {
    throw new Error(`Missing flag definition for ${flagKey}`);
  }

  const { defaultValue, schema } = flagDefinition;

  const { success, data } = schema.safeParse(flagData);
  if (success) {
    return data as z.infer<(typeof featureFlagsWithPayloads)[T]['schema']>;
  } else {
    logger.warn(
      'Got feature flag data that did not match schema. Returning fallback',
      {
        flag: flagKey,
        data: flagData,
      },
    );
    return defaultValue as z.infer<
      (typeof featureFlagsWithPayloads)[T]['schema']
    >;
  }
};
