import React, { useMemo } from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import { ColDefBuilder, DataTable } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

export interface PromptTemplateUsersProps {
  promptTemplateId: string;
}

export const promptTemplateUsersFragment = graphql(`
  fragment PromptTemplateUsers on PromptTemplates @_unmask {
    __typename
    id
    user_settings {
      __typename
      userId
      user {
        __typename
        email
      }
    }
  }
`);

type PromptTemplateUserRow = {
  email: string;
};

const columns = new ColDefBuilder<PromptTemplateUserRow>()
  .defaultSortable('email')
  .build();

export const PromptTemplateUsers: React.FC<PromptTemplateUsersProps> = ({
  promptTemplateId,
}) => {
  const data = useCompleteFragment({
    fragment: promptTemplateUsersFragment,
    key: {
      id: promptTemplateId,
    },
    strict: false,
  });

  const rows = useMemo(() => {
    return (data?.user_settings ?? []).map<PromptTemplateUserRow>((us) => ({
      email: us.user.email,
    }));
  }, [data]);

  return (
    <div className="rounded-md bg-white p-2">
      <DataTable columns={columns} data={rows} />
    </div>
  );
};
