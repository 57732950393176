import fromPairs from 'lodash/fromPairs';
import { z } from 'zod';

const NoteSectionSchema = z.union([z.string(), z.record(z.string(), z.any())]);

const OptionalNoteSectionSchema = NoteSectionSchema.optional();

export const SOAP_KEYS = ['SUBJECTIVE', 'OBJECTIVE', 'ASSESSMENT', 'PLAN'];

export const SOAPSchema = z
  .object({
    SUBJECTIVE: OptionalNoteSectionSchema,
    OBJECTIVE: OptionalNoteSectionSchema,
    ASSESSMENT: OptionalNoteSectionSchema,
    PLAN: OptionalNoteSectionSchema,
    CLIENT_RECAP: OptionalNoteSectionSchema,
  })
  .refine(
    (val) => {
      const keys = Object.keys(val);
      return SOAP_KEYS.some((key) => keys.includes(key));
    },
    {
      message: `At least one key from "${SOAP_KEYS.join(',')}" must be present`,
    },
  )
  .transform((val) => {
    const entries = Object.entries(val);

    const missing = SOAP_KEYS.filter(
      (key) => !entries.some(([k]) => k === key),
    ).map((key) => [key, null]);

    return fromPairs([...entries, ...missing]);
  });
