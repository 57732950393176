import { z } from 'zod';

export const differentialDiagnosesSchema = z.array(
  z
    .object({
      diagnosis: z.string().describe('The name of the potential diagnosis'),
      description: z
        .string()
        .optional()
        .describe('A description of the diagnosis and what it could entail'),
      positiveIndicators: z
        .array(z.string())
        .describe('Any indicators that positively identify this diagnosis'),
      negativeIndicators: z
        .array(z.string())
        .describe('Any indicators that rule out this diagnosis'),
    })
    .describe('A potential diagnosis and its supporting evidence'),
);
