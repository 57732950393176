import { useLocalStorage } from '@uidotdev/usehooks';

import {
  useTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

export const useShowAllAppointmentsToggle = () => {
  const userId = useUserIdFromSession();
  const tenantId = useTenantIdFromParams();

  if (!userId || !tenantId) {
    throw new Error(
      'useShowAllAppointmentsToggle: userId or tenantId is not available',
    );
  }

  return useLocalStorage<boolean>(
    `eluve-config-show-all-appointments-${tenantId}-${userId}`,
    true,
  );
};
