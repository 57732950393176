import { useMutation, useQuery, useSubscription } from '@apollo/client';
import chunk from 'lodash/chunk';
import noop from 'lodash/noop';
import { Check, LogOut, Star } from 'lucide-react';
import React, { useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import { useApiClient } from '@eluve/api-client-provider';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  P,
  Progress,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@eluve/components';
import {
  allCompletedSoapNotesFragment,
  listenAppointmentStatusSubscription,
  updateSoapNote,
  useAppointmentActivity,
  useIsAppointmentSigned,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';
import { useUserIdFromSession } from '@eluve/session-helpers';
import {
  SummaryFeedbackModal,
  SummarySectionMarkdown,
  useAllSummaries,
} from '@eluve/smart-blocks';
import { AppointmentSummary, AppointmentSummaryKeys } from '@eluve/utils';

import { useListenForAnySummaries } from './useListenForAnySummaries';

export const getPromptTemplates = graphql(`
  query getPromptTemplates($userId: uuid!) {
    activePromptTemplates(orderBy: { name: ASC }) {
      __typename
      id
      description
      name
      isCurrentDefault
    }
    userSettingsByPk(userId: $userId) {
      userId
      __typename
      promptTemplateIdOrDefault
    }
  }
`);

const listenAllSoapNotes = graphql(
  `
    subscription listenAllSoapNotes($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        ...AllCompletedSoapNotes
      }
    }
  `,
  [allCompletedSoapNotesFragment],
);

export const SummaryComparison: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedSummaryId = searchParams.get('summaryId') ?? undefined;
  const [progress, setProgress] = useState(0);
  const userId = useUserIdFromSession();

  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  const isNoteSigned = useIsAppointmentSigned();

  const [selectedPromptTemplates, setSelectedPromptTemplates] = useState<
    string[]
  >([]);

  const { data: promptTemplatesAndSettings } = useQuery(getPromptTemplates, {
    variables: { userId },
  });

  const apiClient = useApiClient();

  const { isAnySummarizationInProgress } = useAppointmentActivity();

  const currentSummary = useSummary();

  const [updateSummary] = useMutation(updateSoapNote, {
    optimisticResponse: (data) => {
      return {
        updateHumanOutputsByPk: {
          __typename: 'HumanOutputs' as const,
          id: data.humanOutputId,
          content: data.content,
          editedFromLlmOutputId: data.llmOutputId,
        },
      };
    },
  });

  const generateSummaries = async () => {
    setProgress(0);
    const internvalId = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(internvalId);
        }
        return prev + 1;
      });
    }, 750);
    if (
      !promptTemplatesAndSettings?.activePromptTemplates?.length ||
      !selectedPromptTemplates.length
    ) {
      return;
    }

    await apiClient.llm.summarizeAppointmentWithMultipleTemplates({
      body: {
        promptTemplateIds: selectedPromptTemplates,
      },
      params: {
        tenantId,
        appointmentId,
      },
    });

    setSelectedPromptTemplates([]);
  };

  const showFeedback = true;

  useListenForAnySummaries();

  useSubscription(listenAppointmentStatusSubscription, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  useSubscription(listenAllSoapNotes, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  const { summaries } = useAllSummaries({ soapNotesOnly: false });

  return (
    <div>
      <div className="flex flex-col">
        <div className="container pt-3">
          <Link to={`/tenants/${tenantId}/appointments/${appointmentId}`}>
            <Button variant="outline">
              Return to your session <LogOut className="ml-2" />
            </Button>
          </Link>
        </div>
        <div className="container mb-10 flex w-full flex-col items-center gap-10 pb-24 pt-10">
          <div className="flex flex-row">
            {Boolean(
              promptTemplatesAndSettings?.activePromptTemplates?.length,
            ) &&
              chunk(promptTemplatesAndSettings?.activePromptTemplates, 4).map(
                (chunkPart, chunkIndex) =>
                  chunkPart.map((promptTemplate, index) => {
                    const isUserDefaultTemplate =
                      promptTemplatesAndSettings?.userSettingsByPk
                        ?.promptTemplateIdOrDefault ??
                      promptTemplatesAndSettings?.activePromptTemplates?.find(
                        (template) => template.isCurrentDefault,
                      )?.id;
                    return (
                      <div
                        key={`${chunkIndex}-${index}`}
                        className="flex flex-row"
                      >
                        <Card
                          key={promptTemplate.id}
                          className={`min-h-900 mx-2 w-56 rounded-none border-2`}
                        >
                          <CardHeader className="bg-gray-1 flex h-16 flex-col items-center justify-center border-b p-3">
                            <div className="flex flex-row justify-between">
                              {isUserDefaultTemplate === promptTemplate.id && (
                                <TooltipProvider>
                                  <Tooltip>
                                    <TooltipTrigger className="hover:cursor-default">
                                      <Star className="text-brand-8 h-5 pr-2" />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                      This is set as your default template. You
                                      can change it in your settings.
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              )}
                              <P>{promptTemplate.name}</P>
                            </div>
                          </CardHeader>
                          <CardContent className="flex h-56 flex-col justify-between bg-white p-0 pt-7">
                            <div className="flex-row justify-between p-3 lg:flex-row">
                              {promptTemplate.description}
                            </div>
                            <div className="flex w-full flex-col items-center">
                              <Button
                                variant="gray"
                                className={`w-full rounded-none`}
                                onClick={() => {
                                  setSelectedPromptTemplates((prev) => {
                                    if (prev.includes(promptTemplate.id!)) {
                                      return prev.filter(
                                        (id) => id !== promptTemplate.id,
                                      );
                                    }
                                    return [...prev, promptTemplate.id!];
                                  });
                                }}
                              >
                                {selectedPromptTemplates.includes(
                                  promptTemplate.id ?? '',
                                ) ? (
                                  <Check className="text-brand-8 h-5 text-white" />
                                ) : (
                                  'Select'
                                )}
                              </Button>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    );
                  }),
              )}
          </div>
          <div className="flex flex-row">
            <Button
              disabled={
                !selectedPromptTemplates.length ||
                Boolean(isAnySummarizationInProgress)
              }
              type="submit"
              variant="gray"
              className="relative w-52 border-none p-0"
              onClick={generateSummaries}
            >
              <div className="absolute flex h-full w-full items-center justify-center">
                <div className="absolute flex h-full w-full items-center justify-center">
                  <Progress
                    value={progress}
                    className={`absolute left-0 top-0 h-10 w-full rounded-sm ${isAnySummarizationInProgress ? 'opacity-30' : 'opacity-0'}`}
                  />
                </div>
                <div className="absolute flex h-full w-full items-center justify-center">
                  {isAnySummarizationInProgress
                    ? 'Generating...'
                    : 'Generate Summaries'}
                </div>
              </div>
            </Button>
          </div>

          <Tabs
            value={
              selectedSummaryId ?? currentSummary?.llmOutputId ?? undefined
            }
          >
            <TabsList className="flex h-full w-full flex-col">
              {Boolean(summaries.length) &&
                chunk(
                  summaries.sort((a, b) => {
                    if (
                      !a.summarizationCompletedAt ||
                      !b.summarizationCompletedAt
                    ) {
                      // if value is undefined, push to end of list
                      return -1;
                    }
                    return (
                      new Date(a.summarizationCompletedAt).valueOf() -
                      new Date(b.summarizationCompletedAt).valueOf()
                    );
                  }),
                  4,
                ).map((chunkPart, chunkIndex) => (
                  <div key={chunkIndex} className="flex flex-row">
                    {chunkPart
                      .filter((llmSummary) => llmSummary.isSummaryAvailable)
                      .map((llmSummary, index) => {
                        const promptTemplateId = llmSummary.promptTemplateId;
                        const promptTemplate =
                          promptTemplatesAndSettings?.activePromptTemplates?.find(
                            (p) => p.id === promptTemplateId,
                          );
                        const promptTemplateName =
                          promptTemplate?.name ?? `Inactive Template`;
                        return (
                          <TabsTrigger
                            key={llmSummary.summaryId}
                            value={llmSummary.summaryId}
                            onClick={() => {
                              const urlSearchParams = new URLSearchParams();
                              urlSearchParams.set(
                                'summaryId',
                                llmSummary.summaryId,
                              );
                              setSearchParams(urlSearchParams, {
                                replace: true,
                              });
                            }}
                            className="bg-gray-4 m-2 flex px-10"
                          >
                            {promptTemplateName}{' '}
                            {`(#${chunkIndex * 4 + index + 1})`}
                            {llmSummary.summaryId ===
                              currentSummary?.llmOutputId && (
                              <Check className="text-brand-8 h-5" />
                            )}
                          </TabsTrigger>
                        );
                      })}
                  </div>
                ))}
            </TabsList>
            {summaries?.map((llmSummary) => {
              const canSetNewSummary =
                !isNoteSigned &&
                llmSummary.summaryId !== currentSummary?.llmOutputId;
              return (
                <div className="flex flex-col" key={llmSummary.summaryId}>
                  <TabsContent
                    value={llmSummary.summaryId}
                    key={llmSummary.summaryId}
                  >
                    <div className="m-4 flex flex-col items-center">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              type="submit"
                              disabled={!canSetNewSummary}
                              variant="gray"
                              onClick={async () => {
                                await updateSummary({
                                  variables: {
                                    content:
                                      llmSummary.summary as AppointmentSummary,
                                    llmOutputId: llmSummary.summaryId,
                                    tenantId,
                                    humanOutputId:
                                      currentSummary!.humanOutputId!,
                                  },
                                });
                              }}
                            >
                              Use this summary
                            </Button>
                          </TooltipTrigger>
                          {!canSetNewSummary && (
                            <TooltipContent>
                              {isNoteSigned && 'This note is already signed'}
                              {!isNoteSigned &&
                                llmSummary.summaryId ===
                                  currentSummary?.llmOutputId &&
                                'This note is already set as current summary'}
                            </TooltipContent>
                          )}
                        </Tooltip>
                      </TooltipProvider>

                      <P className="mb-2 mt-2">
                        Template ID: {llmSummary.promptTemplateId}
                      </P>
                    </div>
                    <div className="flex w-full flex-col gap-2">
                      {llmSummary?.isSummaryAvailable &&
                        Object.entries(llmSummary?.summary).map(
                          ([summaryKey, summaryValue]) => {
                            return (
                              <SummarySectionMarkdown
                                appointmentId={appointmentId}
                                key={summaryKey}
                                summaryKey={
                                  summaryKey as AppointmentSummaryKeys
                                }
                                sectionTitle={summaryKey}
                                content={summaryValue ?? ''}
                                disabled={true}
                                handleContentChange={noop}
                              />
                            );
                          },
                        )}

                      {showFeedback && (
                        <div className="flex w-full flex-row items-center justify-end gap-2">
                          <P className="text-gray-10 font-normal">
                            Was this medical summary helpful?
                          </P>
                          <SummaryFeedbackModal
                            appointmentId={appointmentId}
                            llmOutputId={llmSummary.summaryId}
                          />
                        </div>
                      )}
                    </div>
                  </TabsContent>
                </div>
              );
            })}
          </Tabs>
        </div>
      </div>
    </div>
  );
};
