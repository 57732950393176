import { forwardRef } from 'react';
import { Link as LinkPrimitive, LinkProps } from 'react-router-dom';

import { cn } from './cn';

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, ...props }, ref) => {
    return (
      <LinkPrimitive
        ref={ref}
        className={cn(
          'cursor-pointer hover:underline hover:underline-offset-2 focus:underline focus:underline-offset-2 focus:outline-none',
          className,
        )}
        {...props}
      />
    );
  },
);
