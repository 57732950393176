import { Suspense } from 'react';
import { useParams } from 'react-router-dom';

import { Card, CardContent, PageTitle, Skeleton } from '@eluve/components';
import { LayersTree } from '@eluve/frontend-feature-location-hierarchy';

export const LayersPage: React.FC = () => {
  const { tenantId } = useParams() as { tenantId: string };

  return (
    <div className="container">
      <PageTitle subtitle="Organize Eluve to best fit your practice">
        Layers
      </PageTitle>

      <Card className="pt-4">
        <CardContent>
          <Suspense fallback={<Skeleton />}>
            <LayersTree tenantId={tenantId} />
          </Suspense>
        </CardContent>
      </Card>
    </div>
  );
};
