import { Contract } from './types';

type MessageEnvelope = {
  procedureName: string;
  args: Record<string, unknown>[];
};

export class MessageClient<TContract extends Contract> {
  private readonly _proxy: TContract;

  constructor(args: {
    messageSender: (
      envelope: MessageEnvelope,
    ) => Promise<Record<string, unknown>>;
  }) {
    const { messageSender } = args ?? {};

    const proxy = new Proxy<TContract>({} as any, {
      get: (_target, prop) => {
        return async (...args: any[]) => {
          const procedureName = String(prop);
          const result = await messageSender({
            procedureName,
            args: args ?? {},
          });

          return result;
        };
      },
    });

    this._proxy = proxy;
  }

  get proxy() {
    return this._proxy;
  }
}
