import { AppointmentModel } from '../../models/appointment';

import { Appointment } from './types';

/*
 * This map was captured as part of the response from:
 * https://app.kareo.com/dashboard-calendar-ui/api/AppointmentReminder/ReminderEnums
 *
 * Tebra/Kareo makes the following request when user views calendar page
 * There is a possibility that this map is different across different Tebra accounts
 */
const appointmentStatusMap: Record<number, string> = {
  0: 'UNKNOWN',
  1: 'SCHEDULED',
  2: 'REMINDERSENT',
  3: 'CONFIRMED',
  4: 'CHECKEDIN',
  5: 'ROOMED',
  6: 'CHECKEDOUT',
  7: 'NEEDSRESCHEDULE',
  8: 'READYTOBESEEN',
  9: 'NOSHOW',
  10: 'CANCELLED',
  11: 'RESCHEDULED',
  12: 'TENTATIVE',
} as const;

/*
 * This map was captured as part of the response from:
 * https://app.kareo.com/dashboard-calendar-ui/api/AppointmentReason?deleted=false
 *
 * Tebra makes the following request when user views an inidividual appointment
 * There is a possibility that this map is different across different Tebra accounts
 */
const appointmentReasonMap: Record<number, string> = {
  1782786: 'Office Visit',
  1782806: 'Procedure Follow Up',
  1782807: 'Initial Consultation',
  1782808: 'Hemorrhoid Banding',
  1782878: 'Telemedicine',
  1792234: 'Colonoscopy',
  1792235: 'Colonoscopy + EGD',
  1792236: 'EGD',
  1795730: 'Unavailable',
  1816427: 'ERCP',
  1956784: 'Orbera ',
  1972424: 'Colonoscopy CDSC',
  1972426: 'Colonoscopy+EGD CDSC',
  2019837: 'FIBROSCAN',
  2067837: 'Report Follow Up',
  2070413: 'INFUSION',
  2083440: 'Capsule Endoscopy',
  2112687: 'SCHEDULE',
  2125286: 'Weight Loss Consult',
} as const;

export const convertTebraDataToAppointmentModel = (
  data: Appointment,
): AppointmentModel => {
  const { patientSummary } = data;

  const appointmentModel: AppointmentModel = {
    externalId: `${data.appointmentId}`,
    externalPatientId: `${patientSummary.patientId}`,
    firstName: patientSummary?.firstName ?? '',
    lastName: patientSummary?.lastName ?? '',
    startTime: data.startTime ? new Date(data.startTime).toISOString() : '',
    endTime: data.endTime ? new Date(data.endTime).toISOString() : '',
    name: [
      data.appointmentName,
      appointmentReasonMap[data.appointmentReasonId ?? 0],
    ]
      .filter(Boolean)
      .join(' - '),
    description: data.notes ?? '',
    rawEhrData: data,
    status:
      appointmentStatusMap[data.appointmentStatus ?? 0] === 'CANCELLED'
        ? 'CANCELLED'
        : undefined,
    isArchived: data.appointmentStatus === 10,
    isImportable: data.appointmentStatus !== 10,
    sessionType: undefined,
  };

  return appointmentModel;
};
