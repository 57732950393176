import flow from 'lodash/flow';
import { MarkdownToQuill } from 'md-to-quill-delta';

// Op type is defined here: https://github.com/slab/delta/blob/main/src/Op.ts
export type Op = {
  insert?: string | Record<string, unknown>;
  delete?: number;
  retain?: number | Record<string, unknown>;
  attributes?: {
    header?: boolean;
    underline?: boolean;
    bold?: boolean;
    [key: string]: unknown;
  };
};

const markdownConverter = new MarkdownToQuill({ debug: false });

/**
 * Convert markdown summary to Quill delta format expected by Jane
 * Supports bold, bullets, italic, underline and headers
 * Additional, adds bold to all headers
 * Does not support strikethrough
 */
export const convertMarkdownToQuill = (summaryValue: string): Op[] => {
  let ops: Op[] = [{ insert: summaryValue }];
  try {
    ops = markdownConverter.convert(summaryValue) as unknown as Op[];
  } catch (e) {
    return ops;
  }
  const convertPipeline = flow([
    addBoldToHeaders,
    addUnderlines,
    formatNewLines,
  ]);
  const x = convertPipeline(ops);
  return x;
};

const addBoldToHeaders = (ops: Op[]): Op[] => {
  const newOps: Op[] = [];
  for (let i = 0; i < ops.length; i++) {
    const op = ops[i]!;
    if (op.attributes?.header && op.insert === '\n') {
      const prevOp = ops[i - 1];
      if (prevOp) {
        // If we detect header, previous op should be bold to match up with Eluve note format
        prevOp.attributes = { ...prevOp.attributes, bold: true };
      }
    } else {
      newOps.push(op);
    }
  }
  return newOps;
};

const addUnderlines = (ops: Op[]): Op[] => {
  const newOps: Op[] = [];
  for (let i = 0; i < ops.length; i++) {
    const op = ops[i]!;
    if (!op.insert || typeof op.insert !== 'string') {
      continue;
    }
    if (
      typeof op?.insert === 'string' &&
      op.insert.includes('<u>') &&
      op.insert.includes('</u>')
    ) {
      const parts = op.insert.split('<u>');
      for (const part of parts) {
        if (!part.includes('</u>')) {
          newOps.push({
            insert: part,
          });
          continue;
        }
        const partsWithCloseTag = part.split('</u>');
        if (partsWithCloseTag.length) {
          newOps.push({
            insert: partsWithCloseTag[0],
            attributes: { underline: true },
          });
          if (partsWithCloseTag.length > 1) {
            const newOp: Op = {
              insert: partsWithCloseTag[1],
            };
            if (op.attributes) {
              newOp.attributes = op.attributes;
            }
            newOps.push(newOp);
          }
        }
      }
      continue;
    }
    newOps.push(op);
  }
  return newOps;
};

const formatNewLines = (ops: Op[]): Op[] => {
  const newOps: Op[] = [];
  for (let i = 0; i < ops.length; i++) {
    const op = ops[i]!;
    if (!op.insert || typeof op.insert !== 'string') {
      continue;
    }
    if (op.insert.includes('\n\n')) {
      const lines = op.insert.split('\n\n');
      for (let j = 0; j < lines.length; j++) {
        if (j === lines.length - 1) {
          const newOp: Op = {
            insert: `${lines[j]}`,
          };
          if (op.attributes) {
            newOp.attributes = op.attributes;
          }
          newOps.push(newOp);
        } else {
          newOps.push({
            insert: `${lines[j]}\n`,
          });
        }
      }
      continue;
    }
    newOps.push(op);
  }
  return newOps;
};
