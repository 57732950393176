import { useSubscription } from '@apollo/client';
import { Loader2Icon } from 'lucide-react';
import { useMemo } from 'react';

import { Alert, AlertDescription, AlertTitle } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { pendingFilesFragment } from './useAppointmentTranscriptionPendingFiles';

const PendingTranscriptionFilesSubscriptions = graphql(
  `
    subscription AppointmentPendingFiles($tenantId: uuid!, $id: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $id) {
        __typename
        id
        ...AppointmentPendingFiles
      }
    }
  `,
  [pendingFilesFragment],
);

interface PendingTranscriptionLoaderProps {
  tenantId: string;
  appointmentId: string;
}

export const PendingTranscriptionLoader: React.FC<
  PendingTranscriptionLoaderProps
> = ({ tenantId, appointmentId }) => {
  const { data: pendingFiles } = useSubscription(
    PendingTranscriptionFilesSubscriptions,
    {
      variables: { tenantId, id: appointmentId },
    },
  );

  const pendingFilesCountToTranscribe = useMemo(() => {
    const files = pendingFiles?.appointmentsByPk?.appointmentFiles;
    return files?.length ?? 0;
  }, [pendingFiles]);

  if (pendingFilesCountToTranscribe === 0) {
    return null;
  }

  return (
    <Alert variant="default" ref={(e) => e?.scrollIntoView()}>
      <Loader2Icon className="mr-4 size-5 animate-spin" />
      <AlertTitle>Analyzing audio</AlertTitle>
      <AlertDescription>
        Analyzing audio to improve accuracy. ({pendingFilesCountToTranscribe})
        files remain.
      </AlertDescription>
    </Alert>
  );
};
