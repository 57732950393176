import { useMutation } from '@apollo/client';
import { CheckCircle2Icon, Loader2 } from 'lucide-react';
import { usePostHog } from 'posthog-js/react';
import React, { useState } from 'react';
import { toast } from 'sonner';

import { useApiClient } from '@eluve/api-client-provider';
import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  H2,
  Input,
  P,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useToast,
} from '@eluve/components';
import {
  appointmentDoctorInteractionFragment,
  doctorInteractionFragment,
  externalChartFragment,
  externalChartsFragment,
  useAppointmentActivity,
  useAppointmentId,
  useAppointmentName,
  useAppointmentPatient,
  useAppointmentStatus,
  useExternalAppointmentId,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';
import { useNamedLogger } from '@eluve/logger';
import { useCaptureEvent } from '@eluve/posthog-react';
import { useSession, useTenantIdFromParams } from '@eluve/session-helpers';
import { useEluveExtExists } from '@eluve/smart-blocks';
import { formatHumanName, toTitleCase } from '@eluve/utils';
import {
  HumanFriendlyError,
  SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS,
  getHumanFriendlyError,
  getVendorProvider,
} from '@eluve/vendor';

import { useSyncToEhr } from '../useSyncToEhr';

export const upsertExternalChartMutation = graphql(
  `
    mutation createExternalChart(
      $appointmentId: uuid!
      $chartUrl: String!
      $externalAppointmentId: String
      $patientId: uuid!
      $externalPatientId: String!
      $externalEhrId: uuid!
      $externalChartId: String!
    ) {
      insertExternalChartsOne(
        object: {
          appointmentId: $appointmentId
          chartUrl: $chartUrl
          externalPatientId: $externalPatientId
          patientId: $patientId
          externalAppointmentId: $externalAppointmentId
          externalEhrId: $externalEhrId
          externalChartId: $externalChartId
        }
        onConflict: {
          constraint: unique_external_charts_external_chart_id
          updateColumns: []
        }
      ) {
        ...externalChart
      }
    }
  `,
  [externalChartFragment],
);

export const markAppointmentAsSignedMutation = graphql(
  `
    mutation insertDoctorInteractionSignedNote($appointmentId: uuid!) {
      insertAppointmentDoctorInteractionsOne(
        object: { appointmentId: $appointmentId, noteSignedAt: NOW }
        onConflict: {
          constraint: appointment_doctor_interactions_pkey
          updateColumns: [noteSignedAt]
        }
      ) {
        ...doctorInteraction
      }
    }
  `,
  [doctorInteractionFragment],
);

export interface SignAndSyncAppointmentButtonProps {}

export const SignAndSyncAppointmentButton: React.FC<
  SignAndSyncAppointmentButtonProps
> = () => {
  const logger = useNamedLogger('SignAndSyncAppointmentButton');
  const [userProvidedChartUrl, setUserProvidedChartUrl] = useState<
    string | null
  >(null);
  const [manualChartUrlEntry, setManualChartUrlEntry] = useState(false);

  const { toast: toaster } = useToast();
  const syncToEhr = useSyncToEhr();
  const postHog = usePostHog();

  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);
  const [signNoteDialogOpen, setSignNoteDialogOpen] = useState<boolean>(false);
  const [signingInProgress, setSigningInProgress] = useState<boolean>(false);
  const [signingEhrError, setSigningEhrError] =
    useState<HumanFriendlyError | null>(null);
  const [shouldSignExternalChart, setShouldSignExternalChart] = useState(false);

  const tenantId = useTenantIdFromParams() ?? '';
  const { firstName: userFirstName, lastName: userLastName } = useSession();
  const { isSummaryAvailable, summary } = useSummary();

  const { isSummarizationInProgress } = useAppointmentActivity();
  const appointmentStatus = useAppointmentStatus();

  const appointmentId = useAppointmentId();
  const appointmentExternalId = useExternalAppointmentId();
  const appointmentName = useAppointmentName();
  const apiClient = useApiClient();

  const captureEvent = useCaptureEvent({
    tenantId,
    appointmentId,
    externalAppointmentId: appointmentExternalId,
  });

  const patient = useAppointmentPatient();
  const patientId = patient?.id;
  const patientFullName = formatHumanName(
    patient?.firstName ?? '',
    patient?.lastName,
  );

  const externalPatientInfo = patient?.external_patients_info?.[0];
  const externalEhr = externalPatientInfo?.external_ehr;
  const ehrName = toTitleCase(externalEhr?.vendor ?? '');
  const ehrVendorProvider = externalEhr?.vendor
    ? getVendorProvider(externalEhr.vendor)
    : null;
  const patientExternalId = externalPatientInfo?.externalPatientId;
  const patientEhrUrl = ehrVendorProvider?.getPatientEhrUrl({
    domain: externalEhr?.domain ?? '',
    externalPatientId: patientExternalId,
  });
  const { eluveExtExists } = useEluveExtExists();

  const upsertExternalChart = async (data: {
    appointmentId: string;
    chartUrl: string;
    externalChartId: string;
    externalAppointmentId: string | null;
    patientId: string;
    externalPatientId: string;
    externalEhrId: string;
  }) => {
    const {
      chartUrl,
      externalChartId,
      externalAppointmentId,
      patientId,
      externalPatientId,
      externalEhrId,
    } = data;
    await apiClient.charts.createChart({
      body: {
        appointmentId,
        chartUrl,
        externalChartId,
        externalAppointmentId,
        patientId,
        externalPatientId,
        externalEhrId,
      },
      params: {
        tenantId,
      },
    });

    cacheUtils.updateFragment(
      {
        fragment: externalChartsFragment,
        key: { id: appointmentId },
      },
      (existingData) => {
        if (!appointmentId) {
          return existingData;
        }

        return {
          id: appointmentId,
          __typename: 'Appointments' as const,
          external_charts: [
            {
              __typename: 'ExternalCharts' as const,
              id: '-1',
              appointmentId,
              externalEhrId,
              chartUrl,
              signedAt: null,
              externalChartId,
              patientId,
              externalPatientId,
              externalAppointmentId,
            },
          ],
        };
      },
    );
  };

  const [markAppointmentAsSigned] = useMutation(
    markAppointmentAsSignedMutation,
    {
      optimisticResponse: () => ({
        insertAppointmentDoctorInteractionsOne: {
          __typename: 'AppointmentDoctorInteractions' as const,
          appointmentId,
          externalChartUrl: null,
          additionalNotes: '',
          noteSignedAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          user: {
            __typename: 'Users' as const,
            id: '-1',
            firstName: userFirstName,
            lastName: userLastName,
          },
        },
      }),
      update(_, { data }) {
        cacheUtils.updateFragment(
          {
            fragment: appointmentDoctorInteractionFragment,
            key: { id: appointmentId },
          },
          (existingData) => {
            if (!data?.insertAppointmentDoctorInteractionsOne) {
              return existingData;
            }

            return {
              id: data.insertAppointmentDoctorInteractionsOne.appointmentId,
              __typename: 'Appointments' as const,
              doctor_interaction: {
                ...existingData?.doctor_interaction,
                ...data.insertAppointmentDoctorInteractionsOne,
              },
            };
          },
        );
      },
    },
  );

  const showSuccessToast = (text: string) => {
    toaster({
      duration: 4 * 1000, // 4 seconds
      variant: 'success',

      title: (
        <div className="flex w-full items-center">
          <CheckCircle2Icon className="mr-2 h-10 w-10" />
          <div className="flex flex-col gap-0.5">
            <span className="text-base font-semibold first-letter:capitalize">
              Successfully Signed
            </span>
            <span className="font-normal">{text}</span>
          </div>
        </div>
      ),
    });
  };

  const onButtonClick = async () => {
    // Open the dialog with the confirmation content
    setConfirmationDialogOpen(true);
  };

  const insertSignedNote = async (data?: {
    chartUrl: string;
    chartId: string;
  }) => {
    if (data) {
      const { chartUrl, chartId } = data;
      if (
        chartUrl &&
        chartId &&
        externalEhr &&
        patientExternalId &&
        patientId
      ) {
        await upsertExternalChart({
          appointmentId,
          chartUrl,
          externalChartId: chartId,
          externalAppointmentId: appointmentExternalId ?? null,
          patientId: patientId,
          externalPatientId: patientExternalId,
          externalEhrId: externalEhr.id,
        });
      }
    }
    const { errors } = await markAppointmentAsSigned({
      variables: {
        appointmentId,
      },
    });
    if (!errors) {
      return true;
    } else {
      return false;
    }
  };

  const signNoteOnEluveOnly = async (): Promise<void> => {
    setConfirmationDialogOpen(false);
    setSigningEhrError(null);
    const isSubmitted = await insertSignedNote();

    if (isSubmitted) {
      showSuccessToast(`Your note has been signed.`);
      captureEvent('signed_note_on_eluve_only');
    } else {
      captureEvent('failed_to_sign_note_on_eluve_only');
      toast.error('Failed to sign note.');
    }
  };

  const signNoteOnEluveWithUserProvidedChartUrl = async (): Promise<void> => {
    if (!userProvidedChartUrl) {
      return;
    }
    const chartId =
      ehrVendorProvider?.getChartIdFromChartUrl?.(userProvidedChartUrl);
    if (!chartId) {
      return;
    }

    setConfirmationDialogOpen(false);
    setSignNoteDialogOpen(false);
    setSigningEhrError(null);
    const isSubmitted = await insertSignedNote({
      chartUrl: userProvidedChartUrl,
      chartId,
    });

    if (isSubmitted) {
      captureEvent('signed_note_on_eluve_only_with_user_provided_chart');
      showSuccessToast(`Your note has been signed.`);
    } else {
      captureEvent(
        'failed_to_sign_note_on_eluve_only_with_user_provided_chart',
      );
      toast.error('Failed to sign note.');
    }
  };

  const data = useCompleteFragment({
    fragment: appointmentDoctorInteractionFragment,
    key: { id: appointmentId },
    strict: false,
  });

  const interaction = data?.doctor_interaction;
  const additionalUserNotes = interaction?.additionalNotes ?? null;

  const syncToExternalEHRAndSignOnEluve = async (): Promise<void> => {
    setConfirmationDialogOpen(false);
    setSigningEhrError(null);
    setSignNoteDialogOpen(true);
    setSigningInProgress(true);

    if (!externalEhr) {
      logger.warn(
        `Attempted to sign note for appointment ${appointmentId} without external EHR`,
      );
      return;
    }

    try {
      const response = await syncToEhr({
        tenantId,
        shouldSignExternalChart,
        appointmentId,
        vendor: externalEhr.vendor,
        domain: externalEhr.domain,
        patientExternalId: patientExternalId!,
        appointmentName,
        additionalUserNotes,
        summary,
        appointmentExternalId,
      });
      logger.info('Sync to EHR response', response);

      if (!response.ok || !response.data) {
        logger.warn('SyncToEhr response was not successful', {
          response,
        });
        const humanFriendlyErrorMessage = getHumanFriendlyError(response.error);
        setSigningEhrError(humanFriendlyErrorMessage);
        return;
      }

      setSignNoteDialogOpen(false);

      const successSigningOnEluve = await insertSignedNote({
        chartUrl: response.data.chartUrl,
        chartId: response.data.chartId,
      });

      if (successSigningOnEluve) {
        captureEvent('signed_note_and_synced_to_external_ehr');
        showSuccessToast(
          `Your note has been successfully synced to ${ehrName}.`,
        );
      } else {
        captureEvent('failed_to_sign_note_and_sync_to_external_ehr');
        toast.error(
          `Your note has been successfully synced to ${ehrName}, but we experienced an error signing the note on Eluve.`,
        );
      }
    } catch (error) {
      logger.error('Error syncing to EHR', {
        error,
        errorMessage: (error as Error)?.message,
      });
      setSigningEhrError(SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS.TIMEOUT);
    } finally {
      setSigningInProgress(false);
    }
  };

  const externalSyncNoteAvailable =
    eluveExtExists &&
    ehrVendorProvider?.canSyncNoteToEhr({
      externalPatientId: patientExternalId,
      externalAppointmentId: appointmentExternalId,
    });

  const externalSignNoteAvailable =
    eluveExtExists && ehrVendorProvider?.canSignNoteInEhr();

  if (
    isSummarizationInProgress ||
    !isSummaryAvailable ||
    appointmentStatus !== 'COMPLETED'
  ) {
    return null;
  }

  const signAndSyncButtonDisabled = !patientId;

  return (
    <div className="w-full">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              disabled={signAndSyncButtonDisabled}
              onClick={onButtonClick}
              className="w-full"
              variant="dark"
            >
              {externalSyncNoteAvailable ? 'Sync to EHR' : 'Sign Note'}
            </Button>
          </TooltipTrigger>
          {signAndSyncButtonDisabled && (
            <TooltipContent>
              {!patientId && 'Select a patient before signing the note'}
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>

      <Dialog
        open={confirmationDialogOpen}
        onOpenChange={setConfirmationDialogOpen}
      >
        {/**
         * SIGN AND SYNC TO EXTERNAL EHR DIALOG
         */}
        {externalSyncNoteAvailable && (
          <DialogContent className="flex flex-col items-center justify-center gap-5 p-10">
            <H2>Are you sure?</H2>
            {externalSignNoteAvailable && (
              <div className="flex items-center gap-2">
                <Switch
                  checked={shouldSignExternalChart}
                  onCheckedChange={setShouldSignExternalChart}
                />
                <P className="text-gray-10 text-center text-base font-medium">
                  Sign external chart
                </P>
              </div>
            )}

            <P className="text-gray-10 text-center text-base font-medium">
              The contents of this note will be exported to {ehrName}{' '}
              {externalSignNoteAvailable && (
                <span>
                  {' '}
                  as a{' '}
                  <span className="font-black text-black">
                    {shouldSignExternalChart ? 'signed note' : 'draft'}
                  </span>
                </span>
              )}{' '}
              for patient{' '}
              <a
                className="text-indigo-400"
                href={patientEhrUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {patientFullName}
              </a>
              .
            </P>

            <P className="text-gray-10 text-center text-base font-medium">
              You will no longer be able to edit this note in Eluve.
            </P>

            <div className="flex w-full items-center justify-center gap-3">
              <Button
                className="border-gray-5 text-gray-10 border bg-white hover:bg-white"
                onClick={() => setConfirmationDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="border-none bg-blue-500 text-white hover:bg-blue-500"
                onClick={syncToExternalEHRAndSignOnEluve}
              >
                {shouldSignExternalChart
                  ? 'Yes, Sync and Sign Note'
                  : 'Yes, Sync Note as Draft'}
              </Button>
            </div>
          </DialogContent>
        )}

        {/**
         * SIGN ON ELUVE DIALOG
         */}
        {!externalSyncNoteAvailable && (
          <DialogContent className="flex flex-col items-center justify-center gap-5 p-0">
            <div className="flex flex-col items-center gap-5 p-10">
              <H2>Are you sure?</H2>

              <P className="text-gray-10 text-center font-medium">
                You will no longer be able to edit this note in Eluve.
              </P>
              <div className="flex w-full items-center justify-center gap-3">
                <Button
                  className="border-gray-5 text-gray-10 border bg-white hover:bg-white"
                  onClick={() => setConfirmationDialogOpen(false)}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  className="border-none bg-blue-500 text-white hover:bg-blue-500"
                  onClick={() => signNoteOnEluveOnly()}
                >
                  Yes, Sign Note
                </Button>
              </div>
            </div>

            {!eluveExtExists && (
              <DialogFooter className="bg-gray-4 w-full rounded-b-md">
                <P className="text-gray-9 w-full p-3 text-center font-medium sm:text-xs">
                  We support syncing to your EHR on Chrome.{' '}
                  <a
                    className="text-indigo-400"
                    href={
                      'https://eluveinc.notion.site/Beta-Testers-fd09901e7a674ca1bf28314a15d3f86b?pvs=25'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get Started Here.
                  </a>
                </P>
              </DialogFooter>
            )}
          </DialogContent>
        )}
      </Dialog>

      <Dialog open={signNoteDialogOpen} onOpenChange={setSignNoteDialogOpen}>
        <DialogContent className="flex flex-col items-center justify-center gap-5 p-10">
          {signingInProgress && (
            <div>
              <div className="flex flex-col items-center">
                <H2>Syncing to {ehrName}</H2>
              </div>
              <P className="text-gray-10 m-3 text-center text-base font-medium">
                We are in the process of syncing to {ehrName}. This should only
                take a few moments.
              </P>
              <div className="flex items-center justify-center">
                <Loader2 className="m-8 h-12 w-12 animate-spin text-blue-500" />
              </div>
            </div>
          )}

          {signingEhrError && (
            <div>
              {manualChartUrlEntry ? (
                <div>
                  <div className="m-3 flex flex-col items-center">
                    <H2>Add Chart URL</H2>

                    <Input
                      className="m-5"
                      type="text"
                      placeholder="Chart URL"
                      onChange={(e) => setUserProvidedChartUrl(e.target.value)}
                    />
                    <div className="flex w-full items-center justify-center gap-3">
                      <Button
                        className="border-gray-5 text-gray-10 border bg-white hover:bg-white"
                        onClick={() => setManualChartUrlEntry(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        disabled={
                          !userProvidedChartUrl ||
                          !userProvidedChartUrl.startsWith('http') ||
                          !ehrVendorProvider?.getChartIdFromChartUrl?.(
                            userProvidedChartUrl,
                          )
                        }
                        className="border-none bg-blue-500 text-white hover:bg-blue-500"
                        onClick={async () => {
                          await signNoteOnEluveWithUserProvidedChartUrl();
                          setSigningEhrError(null);
                          setSigningInProgress(false);
                        }}
                      >
                        Add Chart URL and Sign
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="m-3 flex flex-col items-center">
                    <H2>{signingEhrError.title}</H2>
                  </div>
                  <div className="mb-6">
                    <P className="text-gray-10 text-center text-base font-medium">
                      {signingEhrError.message}
                    </P>
                  </div>
                  <div className="flex w-full items-center justify-center gap-3">
                    <Button
                      className="border-gray-5 text-gray-10 border bg-white hover:bg-white"
                      onClick={() => setSignNoteDialogOpen(false)}
                    >
                      Cancel
                    </Button>

                    {ehrVendorProvider?.canSyncWithManualChartUrl() &&
                      ehrVendorProvider?.getChartIdFromChartUrl && (
                        <Button
                          className="border-gray-5 text-gray-10 border bg-white hover:bg-white"
                          onClick={() => {
                            setManualChartUrlEntry(true);
                          }}
                        >
                          Manually Add Chart URL
                        </Button>
                      )}
                    <Button
                      type="submit"
                      className="border-none bg-blue-500 text-white hover:bg-blue-500"
                      onClick={syncToExternalEHRAndSignOnEluve}
                    >
                      Try Again
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
