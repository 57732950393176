import { useSuspenseQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { DetailsList } from '@eluve/blocks';
import {
  Card,
  CardContent,
  CardHeader,
  ColDefBuilder,
  DataTable,
  P,
} from '@eluve/components';
import { formatToLocale } from '@eluve/date-utils';
import { latestHumanSOAPNoteFragment } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';
import { formatHumanName } from '@eluve/utils';

type SummaryFeedbackRow = {
  type: string | null;
  comment: string | null;
  tags: string[];
  createdAt: string;
  rating: number | null;
  llmSummaryId?: string;
  promptTemplateName?: string;
};

const feedbackColumns = new ColDefBuilder<SummaryFeedbackRow>()
  .defaultSortable('type', 'Type')
  .defaultSortable('rating', 'Rating')
  .defaultSortable('comment', 'Comment')
  .colDef({
    accessorKey: 'tags',
    header: () => {
      return <div>Tags</div>;
    },
    cell: ({ row }) => {
      return <div>{row.original.tags.join(', ')}</div>;
    },
  })
  .defaultSortable('llmSummaryId', 'LLM Summary ID')
  .defaultSortable('promptTemplateName', 'Prompt Template')
  .defaultSortable('createdAt', 'Created At')
  .build();

// prettier-ignore
const getAdminTenantAppointmentSummary = graphql(
  `#graphql
    query getAdminTenantAppointmentSummary($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        __typename
        id
        name
        startDate
        endDate
        createdAt
        patient {
          id
        }
        user {
          id
          __typename
          firstName
          lastName
          email
        }
        feedback(orderBy: { createdAt: DESC }) {
          __typename
          id
          type
          comment
          createdAt
          summaryFeedbackTags {
            feedbackTag {
              name
            }
          }
          rating
          llmOutputId
        }
        doctor_interaction {
          __typename
          appointmentId
          noteSignedAt
          additionalNotes
          externalChartUrl
          externalEhrSyncStatus
        }
        external_charts {
          __typename
          id
          externalChartId
          chartUrl
        }
        appointment_llm_outputs {
          __typename
          llmOutputId
          output {
            __typename
            id
            modelPromptTemplate {
              __typename
              id
              promptTemplateId
              template {
                __typename
                id
                name
              }
            }
          }
        }
      }
    }
  `,
  [latestHumanSOAPNoteFragment],
);

export const AdminAppointment: React.FC = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  const {
    data: { appointmentsByPk: appointment },
    error,
  } = useSuspenseQuery(getAdminTenantAppointmentSummary, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  useEffect(
    function showToastOnError() {
      if (error) {
        toast.error('Failed to load appointment details');
      }
    },
    [error],
  );

  const user = appointment?.user;

  const noteSignedAt = appointment?.doctor_interaction?.noteSignedAt;
  const externalChartUrl = appointment?.external_charts?.[0]?.chartUrl;
  const externalEhrSyncStatus =
    appointment?.doctor_interaction?.externalEhrSyncStatus;

  return (
    <div>
      <DetailsList
        title={{ label: 'Info' }}
        items={[
          { label: 'Name', value: appointment?.name ?? '' },
          { label: 'User Id', value: user?.id },
          {
            label: 'User Name',
            value: formatHumanName(user?.firstName, user?.lastName),
          },
          { label: 'Email', value: user?.email },
          {
            label: 'Start Date',
            value: formatToLocale(appointment?.startDate),
          },
          {
            label: 'End Date',
            value: formatToLocale(appointment?.endDate) || undefined,
          },
          {
            label: 'Created At',
            value: formatToLocale(appointment?.createdAt),
          },
        ]}
      />

      <Card className="border-gray-4 min-h-900 mt-5 w-full rounded-xl">
        <CardHeader className="bg-gray-1 flex h-10 flex-row items-center justify-between justify-items-center space-y-0 rounded-t-xl border-b p-0 px-5 tracking-normal">
          <div>
            <P>Sync Status</P>
          </div>
        </CardHeader>
        <CardContent>
          <div>
            <div>
              <P>Note signed at: {formatToLocale(noteSignedAt)}</P>
            </div>

            <div>
              <P>
                {externalChartUrl && (
                  <a
                    className="text-blue-600"
                    href={externalChartUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View External Chart
                  </a>
                )}
              </P>
            </div>
            <div>
              <P>EHR Sync Status: {externalEhrSyncStatus}</P>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card className="border-gray-4 min-h-900 mt-5 w-full rounded-xl">
        <CardHeader className="bg-gray-1 flex h-10 flex-row items-center justify-between justify-items-center space-y-0 rounded-t-xl border-b p-0 px-5 tracking-normal">
          <div>
            <P>Feedback Details</P>
          </div>
        </CardHeader>
        <CardContent>
          <div className="m-5 flex flex-col gap-2">
            <P>Number of feedbacks: {appointment?.feedback?.length ?? 0}</P>
          </div>
          <div className="m-4">
            {Boolean(appointment?.feedback?.length) && (
              <DataTable
                data={
                  appointment?.feedback?.map((sf) => {
                    const matchingOutput =
                      appointment?.appointment_llm_outputs?.find(
                        (llmSummary) =>
                          llmSummary.llmOutputId === sf.llmOutputId,
                      )?.output;

                    return {
                      comment: sf.comment,
                      type: sf.type,
                      createdAt: formatToLocale(sf.createdAt),
                      tags: (sf.summaryFeedbackTags || []).map(
                        (sft) => sft.feedbackTag.name,
                      ),
                      rating: sf.rating,
                      llmSummaryId: matchingOutput?.id,
                      promptTemplateName:
                        matchingOutput?.modelPromptTemplate?.template?.name,
                    };
                  }) ?? []
                }
                columns={feedbackColumns}
                enableGlobalSearch
              />
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
