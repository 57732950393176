import { JsonType } from 'posthog-js';
import { ZodSchema, z } from 'zod';

export type SchemaWithDefaults<T extends ZodSchema<JsonType>> = {
  defaultEnabled: boolean;
  schema: T;
  defaultValue: z.infer<T>;
};

export const makeFeatureFlagPayloadValidator = <T extends JsonType>({
  defaultEnabled,
  defaultValue,
  schema,
}: {
  schema: ZodSchema<T>;
  defaultValue: T;
  defaultEnabled: boolean;
}): SchemaWithDefaults<ZodSchema<T>> => {
  return {
    defaultEnabled,
    schema,
    defaultValue,
  };
};
