import { useEffect, useState } from 'react';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';

import { formatSecondsToTime } from '@eluve/date-utils';

import { Box } from './box';

interface WaveformProps {
  stream: MediaStream;
  muted?: boolean;
  totalDuration?: number;
}

export const Waveform: React.FC<WaveformProps> = ({
  stream,
  muted,
  totalDuration = 0,
}) => {
  const [bars, setBars] = useState<number[]>(Array(24).fill(0));
  const [elapsedTime, setElapsedTime] = useState<number>(totalDuration);

  useEffect(() => {
    setElapsedTime(totalDuration);
  }, [totalDuration]);

  useEffect(() => {
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);

    const draw = () => {
      if (audioContext.state === 'closed') {
        return;
      }
      analyser.getByteFrequencyData(dataArray);
      setBars(Array.from(dataArray).splice(8, 24)); // Select a portion of the frequency data
      requestAnimationFrame(draw);
    };

    draw();

    return () => {
      audioContext.close();
    };
  }, [stream]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  return (
    <Box hStack className="gap-6 rounded-lg p-2 pr-4">
      <Box className="text-brand-8 bg-brand-5/40 flex items-center gap-2 rounded-lg p-2">
        {!muted ? (
          <FaMicrophone className="size-5" />
        ) : (
          <FaMicrophoneSlash className="size-5" />
        )}
      </Box>
      <span className="font-mono text-sm">
        {formatSecondsToTime(elapsedTime)}
      </span>
      <div className="flex h-[28px] items-center gap-1">
        {bars.map((bar, i) => (
          <div
            key={i}
            className="bg-gray-11 max-h-[28px] min-h-1 w-[2px] rounded-full"
            style={{ height: `${bar / 6}px` }}
          />
        ))}
      </div>
    </Box>
  );
};
