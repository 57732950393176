import { CornerDownRight } from 'lucide-react';

import { FCC } from '@eluve/components';

const indent = (depth: number) => `${depth * 2}rem`;

export const DepthContainer: FCC<{ depth: number }> = ({ children, depth }) => {
  return (
    <div
      style={{ paddingLeft: indent(depth) }}
      className="items-top flex w-full gap-2"
    >
      {depth > 0 && <CornerDownRight className="mt-4 h-6 w-6" />}
      {children}
    </div>
  );
};
