import { useParams } from 'react-router-dom';

import { BreadcrumbItem, Link } from '@eluve/components';

export const OverviewBreadcrumbItem = () => {
  const { tenantId } = useParams() ?? {};

  return (
    <BreadcrumbItem className="hidden sm:block">
      <Link to={`/tenants/${tenantId}/home`}>Overview</Link>
    </BreadcrumbItem>
  );
};
