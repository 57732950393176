import React from 'react';

import { QUERY_ROOT_ID, useCompleteFragment } from '@eluve/apollo-client';
import { Box } from '@eluve/components';
import { LlmOutputTypesEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import { LlmOutputTypeLabels } from '@eluve/llm-outputs';
import { TabNav } from '@eluve/smart-blocks';

export const outputTypesFragment = graphql(`
  fragment outputTypes on query_root {
    llmOutputTypes {
      __typename
      name
    }
  }
`);

export interface AppointmentOutputsTabNavProps {
  appointmentId: string;
}

export const AppointmentOutputsTabNav: React.FC<
  AppointmentOutputsTabNavProps
> = ({ appointmentId }) => {
  const outputs = useCompleteFragment({
    fragment: outputTypesFragment,
    key: QUERY_ROOT_ID,
  });

  const tabs = (outputs.llmOutputTypes ?? []).map((o) => ({
    name: LlmOutputTypeLabels[o.name as LlmOutputTypesEnum],
    link: o.name,
  }));

  return (
    <Box className="py-2">
      <TabNav tabs={tabs} />
    </Box>
  );
};
