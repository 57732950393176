import maxBy from 'lodash/maxBy';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  tenantLayersFragment,
  tenantLocationsAndExternalEhrsFragment,
} from '@eluve/frontend-feature-tenants';

export const useAppointmentSelectableLocations = (tenantId: string) => {
  const layerData = useCompleteFragment({
    fragment: tenantLayersFragment,
    key: { id: tenantId },
    strict: false,
  });

  const locationData = useCompleteFragment({
    fragment: tenantLocationsAndExternalEhrsFragment,
    key: { id: tenantId },
  });

  if (!layerData || !locationData) {
    return [];
  }

  const deepestLayer =
    maxBy(layerData?.layers ?? [], (l) => l.depth)?.depth ?? 0;

  const locations = locationData.locations;
  const selectableLocations = locations.filter((l) => l.depth === deepestLayer);

  const options = selectableLocations.map((sl) => {
    // Check to see if there's a parent location to help build a breadcrumb label
    const parentLocation = locations.find(
      (parent) =>
        parent.depth === sl.depth - 1 &&
        parent.depth >= 1 &&
        sl.path.startsWith(parent.path),
    );

    const locationLabel = parentLocation
      ? `${parentLocation.name} > ${sl.name}`
      : sl.name;

    return {
      id: sl.id,
      label: locationLabel,
      externalEhr: sl.externalEhr,
    };
  });

  return options;
};
