import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import { ColDefBuilder, DataTable, P } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

const sourceArtifactDatasetsFragment = graphql(`
  fragment SourceArtifactDatasetsFragment on EvalSourceArtifact @_unmask {
    __typename
    id
    datasetSourceArtifacts {
      __typename
      id
      createdAt
      dataset {
        __typename
        id
        name
        description
      }
    }
  }
`);

type DatasetRow = {
  id: string;
  name: string;
  description: string | null;
  createdAt: string;
};

const columns = new ColDefBuilder<DatasetRow>()
  .linkSortable('name', (row) => `/admin/fact-verification/datasets/${row.id}`)
  .defaultSortable('description')
  .dateSortable('createdAt', 'Added To Dataset At')
  .build();

export interface ArtifactDatasetsListProps {
  sourceArtifactId: string;
}

export const ArtifactDatasetsList: React.FC<ArtifactDatasetsListProps> = ({
  sourceArtifactId,
}) => {
  const { datasetSourceArtifacts } = useCompleteFragment({
    fragment: sourceArtifactDatasetsFragment,
    key: {
      id: sourceArtifactId,
    },
  });

  const rows: DatasetRow[] = useMemo(
    () =>
      orderBy(
        datasetSourceArtifacts.map((dsa) => {
          return {
            id: dsa.dataset.id,
            name: dsa.dataset.name,
            description: dsa.dataset.description,
            createdAt: dsa.createdAt,
          };
        }),
        (m) => m.createdAt,
        ['desc'],
      ),
    [datasetSourceArtifacts],
  );

  return (
    <div>
      {!rows.length && <P>This artifact is not part of any datasets</P>}
      {Boolean(rows.length) && <DataTable columns={columns} data={rows} />}
    </div>
  );
};
