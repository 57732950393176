import { useQuery } from '@apollo/client';
import React from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

const promptTemplateFragment = graphql(`
  fragment PromptTemplateName on PromptTemplates {
    __typename
    id
    name
  }
`);

const promptTemplateQuery = graphql(
  `
    query getPromptTemplateName($id: uuid!) {
      promptTemplatesByPk(id: $id) {
        ...PromptTemplateName
      }
    }
  `,
  [promptTemplateFragment],
);

export const PromptBreadCrumbs: React.FC = () => {
  const { promptTemplateId } = useParams() as {
    promptTemplateId?: string;
  };

  const promptTemplate = useCompleteFragment({
    fragment: promptTemplateFragment,
    key: {
      id: promptTemplateId ?? '',
    },
    strict: false,
  });

  useQuery(promptTemplateQuery, {
    variables: {
      id: promptTemplateId ?? '',
    },
    skip: !promptTemplateId || !promptTemplate?.name,
  });

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink asChild>
            <NavLink to="/admin/prompts">Prompt Templates</NavLink>
          </BreadcrumbLink>
        </BreadcrumbItem>
        {promptTemplateId && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <NavLink to={`/admin/prompts/${promptTemplateId}`}>
                  {promptTemplate?.name}
                </NavLink>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
