import { Box } from '@eluve/components';

export const NoAppointmentPlaceholder: React.FC = () => (
  <Box
    center
    className="text-gray-7 pointer-events-none h-full w-full pl-[62px] md:text-lg"
  >
    <span className="bg-gray-3/30 text-gray-8 rounded-lg p-3 backdrop-blur-sm">
      No appointments found
    </span>
  </Box>
);
