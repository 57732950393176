import { fromPromise } from 'xstate';

import { Logger } from '@eluve/logger';

import { UserFileSystem } from '../user-file-system.store';

export interface Input {
  appointmentId: string;
  fileName: string;
  fileSystem: UserFileSystem;
  logger: Logger;
}

export const cleanupAppointmentFiles = fromPromise<void, Input>(
  async ({ input }) => {
    const { appointmentId, fileName, fileSystem, logger } = input;

    try {
      if (fileSystem.type !== 'available') {
        throw new Error('File system not available');
      }

      const appointmentDir =
        await fileSystem.appointmentsDir.getDirectoryHandle(appointmentId);

      await appointmentDir.removeEntry(fileName);
    } catch (error) {
      logger.warn('Failed to remove file', {
        errorMessage: (error as Error).message,
        fileName,
        appointmentId,
      });
    }
  },
);
