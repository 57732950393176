import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Converts a time string and optional timezone offset to a Date object in UTC timezone
 */
export const convertTimeToUTC = (time: string, offset?: string | null) => {
  let timestamp = time;
  if (!timestamp) {
    return null;
  }
  if (!isTimezoneAware(timestamp)) {
    if (!isValidTimezoneOffset(offset)) {
      return null;
    }
    timestamp = `${timestamp}${offset}`;
  }

  const parsedTime = dayjs(timestamp);
  if (!parsedTime.isValid()) {
    return null;
  }
  return parsedTime.toDate();
};

/**
 * checks if an ISO time string has a timezone offset (-7:00, +5:00, etc)
 */
const isTimezoneAware = (timeString: string) => {
  // Regular expression to check for timezone offset information
  const timezoneRegex = /([+-][0-9]{2}:[0-9]{2}|Z)$/;
  return timezoneRegex.test(timeString);
};

/**
 * checks if provided timezone offset is in valid format (+/-HH:MM)
 */
const isValidTimezoneOffset = (offset?: string | null) => {
  if (!offset) {
    return false;
  }
  const timezoneRegex = /^([+-])([01]\d|2[0-3]):([0-5]\d)$/;
  return timezoneRegex.test(offset);
};
