import { useMutation } from '@apollo/client';
import { toast } from 'sonner';

import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';
import { TemplateSelector } from '@eluve/smart-blocks';

interface NewAppointmentTemplateProps {
  tenantId: string;
  appointmentId: string;
}

const AppointmentTemplateFragment = graphql(`
  fragment AppointmentTemplate on Appointments {
    __typename
    id
    initialPromptTemplateId
  }
`);

const updateAppointmentTemplateMutation = graphql(`
  mutation UpdateAppointmentTemplateMutation(
    $tenantId: uuid!
    $id: uuid!
    $templateId: uuid!
  ) {
    updateAppointmentsByPk(
      pkColumns: { tenantId: $tenantId, id: $id }
      _set: { initialPromptTemplateId: $templateId }
    ) {
      __typename
      id
      initialPromptTemplateId
    }
  }
`);

export const AppointmentTemplateSelector: React.FC<
  NewAppointmentTemplateProps
> = ({ tenantId, appointmentId }) => {
  const data = useCompleteFragment({
    fragment: AppointmentTemplateFragment,
    key: {
      id: appointmentId,
    },
  });

  const [updateAppointmentTemplate] = useMutation(
    updateAppointmentTemplateMutation,
    {
      optimisticResponse: ({ templateId, id }) => ({
        __typename: 'mutation_root',
        updateAppointmentsByPk: {
          __typename: 'Appointments' as const,
          id,
          initialPromptTemplateId: templateId,
        },
      }),
      onError: () => {
        toast.error('Unable to update appointment template');
      },
    },
  );

  return (
    <TemplateSelector
      selectedTemplateId={data.initialPromptTemplateId ?? undefined}
      onTemplateSelected={(templateId) =>
        updateAppointmentTemplate({
          variables: {
            tenantId,
            id: appointmentId,
            templateId,
          },
        })
      }
    />
  );
};
