import { ApolloProvider } from '@apollo/client';
import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useApiClient } from '@eluve/api-client-provider';
import {
  apolloClientFactory,
  defaultCache,
  tenantAccessTokenProvider,
} from '@eluve/apollo-client';
import { FCC, useToast } from '@eluve/components';
import { useSession, useTenantIdFromParams } from '@eluve/session-helpers';

import { appConfig } from '../../../../config';

export const TenantGuard: FCC = ({ children }) => {
  const apiClient = useApiClient();
  const { toast } = useToast();

  const { pathname } = useLocation();

  const tenantParamsId = useTenantIdFromParams();

  const allowedTenants = useSession().allowedTenantIds;

  if (!tenantParamsId) {
    throw new Error('Expected tenant id in route params in TenantGuard');
  }

  const tenantScopedApolloClient = useMemo(() => {
    return apolloClientFactory({
      uri: appConfig.VITE_APOLLO_CLIENT_URI,
      cacheInstance: defaultCache,
      accessTokenProvider: tenantAccessTokenProvider(
        tenantParamsId,
        async () => {
          await apiClient.auth.createTenantSession({
            body: {
              tenantId: tenantParamsId,
            },
          });
        },
      ),
    });
  }, [apiClient.auth, tenantParamsId]);

  if (!allowedTenants.includes(tenantParamsId)) {
    const redirectTo = pathname.includes('/admin') ? '/admin' : '/';
    toast({
      title: 'Error',
      description: 'Access denied',
      variant: 'destructive',
    });

    return <Navigate to={redirectTo} />;
  }

  return (
    <ApolloProvider client={tenantScopedApolloClient}>
      {children}
    </ApolloProvider>
  );
};
