import React, { Suspense } from 'react';

import { useAppointmentId } from '@eluve/frontend-appointment-hooks';

import { AppointmentLayout } from './AppointmentLayout';
import { AppointmentFileSyncProvider } from './components/AppointmentFileSyncProvider';
import { AppointmentPageLoader } from './components/AppointmentPageLoader';

export const AppointmentPage: React.FC = () => {
  const appointmentId = useAppointmentId();

  return (
    <Suspense fallback={<AppointmentPageLoader />}>
      <AppointmentFileSyncProvider appointmentId={appointmentId}>
        <AppointmentLayout />
      </AppointmentFileSyncProvider>
    </Suspense>
  );
};
