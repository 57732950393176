import { createContext, useContext, useMemo } from 'react';

import { FCC } from '@eluve/components';

type AppointmentContextState = {
  tenantId: string;
  appointmentId: string;
};

const AppointmentContext = createContext<AppointmentContextState | null>(null);

export const useAppointmentContext = () => {
  const context = useContext(AppointmentContext);
  if (!context) {
    throw new Error(
      'useAppointmentContext must be used within a AppointmentContextProvider',
    );
  }

  return context;
};

/**
 * Provides the id of the current appointment
 */
export const useAppointmentId = () => {
  const { appointmentId } = useAppointmentContext();
  return appointmentId;
};

export const useTenantId = () => {
  const { tenantId } = useAppointmentContext();
  return tenantId;
};

export const AppointmentContextProvider: FCC<{
  tenantId: string;
  appointmentId: string;
}> = ({ tenantId, appointmentId, children }) => {
  const value = useMemo(() => {
    return {
      tenantId,
      appointmentId,
    };
  }, [appointmentId, tenantId]);

  return (
    <AppointmentContext.Provider value={value}>
      {children}
    </AppointmentContext.Provider>
  );
};
