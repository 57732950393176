import { useSuspenseQuery } from '@apollo/client';
import { PDFViewer } from '@react-pdf/renderer';
import React, { Suspense } from 'react';

import { graphql } from '@eluve/graphql.tada';
import { SoapPDF, SoapPDFProps } from '@eluve/pdf-rendering';

export const patientAndLocationDataFragment = graphql(`
  fragment PatientAndLocationData on Appointments @_unmask {
    __typename
    id
    location {
      __typename
      id
      name
      address
      phoneNumber
      inherited_logo_url {
        __typename
        locationId
        logoUrl
      }
    }
    patient {
      __typename
      id
      firstName
      lastName
      dateOfBirth
      sex
      external_patients_info {
        __typename
        id
        externalPatientId
        patientId
      }
    }
  }
`);

const appointmentDataFragment = graphql(`
  fragment AppointmentDate on Appointments @_unmask {
    __typename
    id
    startDate
  }
`);

const appointmentPdfDataQuery = graphql(
  `
    query AppointmentPdfData($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        ...AppointmentDate
        ...PatientAndLocationData
      }
    }
  `,
  [appointmentDataFragment, patientAndLocationDataFragment],
);

export const AppointmentPdf: React.FC<
  Pick<SoapPDFProps, 'data' | 'showSoapHeaders'> & {
    tenantId: string;
    appointmentId: string;
  }
> = (props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AppointmentPdfContent {...props} />
    </Suspense>
  );
};

export const AppointmentPdfContent: React.FC<
  Pick<SoapPDFProps, 'data' | 'showSoapHeaders'> & {
    tenantId: string;
    appointmentId: string;
  }
> = ({ data, showSoapHeaders, tenantId, appointmentId }) => {
  const {
    data: { appointmentsByPk: appointment },
  } = useSuspenseQuery(appointmentPdfDataQuery, {
    variables: { tenantId, appointmentId },
  });

  if (!appointment) {
    throw new Error('Appointment not found');
  }

  const { location, patient } = appointment;

  const logoUrl = location?.inherited_logo_url?.logoUrl ?? undefined;

  // TODO(jesse)[ELU-839]: Once we schematize the patient info better we can do a much better job here
  const patientInfoLines = (() => {
    const lines: string[] = [];
    if (!patient) {
      return lines;
    }

    lines.push(`${patient?.firstName} ${patient?.lastName}`);

    const info = patient.external_patients_info?.[0];
    if (!info) {
      return lines;
    }

    if (patient.dateOfBirth) {
      lines.push(`Date of Birth: ${patient.dateOfBirth}`);
    }

    lines.push(`Patient ID: ${info.externalPatientId}`);

    return lines;
  })();

  const locationInfoLines = (() => {
    const lines: string[] = [];

    if (!location) {
      return lines;
    }

    lines.push(location.name);

    if (location.address) {
      lines.push(location.address);
    }

    if (location.phoneNumber) {
      lines.push(location.phoneNumber);
    }

    return lines;
  })();

  return (
    <div>
      <PDFViewer className="h-[1024px] w-full">
        {SoapPDF({
          data,
          showSoapHeaders,
          logoUrl,
          patientInfoLines,
          locationInfoLines,
        })}
      </PDFViewer>
    </div>
  );
};
