import { useSubscription } from '@apollo/client';
import React, { useMemo } from 'react';

import {
  InterimResultLoading,
  TranscriptText,
  TranscriptionWarning,
} from '@eluve/blocks';
import { Box } from '@eluve/components';
import { PendingTranscriptionLoader } from '@eluve/frontend-feature-appointment';
import { graphql } from '@eluve/graphql.tada';
import { toSentences } from '@eluve/utils';

import { UploadingFileLoader } from '../../../components/UploadingFileLoader';
import { useAutoScrollContainer } from '../../../hooks/useAutoScrollContainer';
import { useTranscriptionMachineSelector } from '../TranscriptionMachineProvider';
import { useInSessionTranscript } from '../hooks/useInSessionTranscript';

const InSessionTranscript: React.FC = () => {
  const { interimTranscript, isTranscriptionDegraded, transcript } =
    useInSessionTranscript();

  const containerRef = useAutoScrollContainer([transcript, interimTranscript]);

  return (
    <>
      {isTranscriptionDegraded && <TranscriptionWarning />}
      <Box
        className="h-full w-full flex-1 overflow-y-auto p-4"
        ref={containerRef}
      >
        <TranscriptText widgetTranscript>{transcript}</TranscriptText>
        <TranscriptText interim widgetTranscript>
          {interimTranscript ?? <InterimResultLoading />}
        </TranscriptText>
      </Box>
    </>
  );
};

const CurrentTranscriptSubscription = graphql(`
  subscription CurrentTranscriptSubscription(
    $tenantId: uuid!
    $appointmentId: uuid!
  ) {
    appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
      __typename
      id
      transcription {
        __typename
        id
        transcript
      }
    }
  }
`);

const CurrentTranscript: React.FC = () => {
  const tenantId = useTranscriptionMachineSelector((s) => s.context.tenantId);

  const appointmentId = useTranscriptionMachineSelector(
    (s) => s.context.sessionAppointmentId,
  );

  if (!appointmentId) {
    throw new Error('No appointmentId found in context');
  }

  const { data } = useSubscription(CurrentTranscriptSubscription, {
    variables: { tenantId, appointmentId },
  });

  const transcriptSentences = useMemo(
    () => toSentences(data?.appointmentsByPk?.transcription?.transcript ?? ''),
    [data?.appointmentsByPk?.transcription?.transcript],
  );

  return (
    <Box className="h-full w-full flex-1 overflow-y-auto p-4">
      <TranscriptText widgetTranscript>
        {transcriptSentences ?? 'No Transcript'}
      </TranscriptText>
      <UploadingFileLoader appointmentId={appointmentId} />
      <PendingTranscriptionLoader
        tenantId={tenantId}
        appointmentId={appointmentId}
      />
    </Box>
  );
};

export const TranscriptionWidgetTranscript: React.FC = () => {
  const currentAppointmentInSession = useTranscriptionMachineSelector((s) =>
    s.matches({ InSession: 'Recording' }),
  );

  return currentAppointmentInSession ? (
    <InSessionTranscript />
  ) : (
    <CurrentTranscript />
  );
};
