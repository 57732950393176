import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';

import { useAppointmentId } from './appointment.context';

const appointmentExternalInfoFragment = graphql(`
  fragment AppointmentExternalInfo on Appointments @_unmask {
    __typename
    id
    external_appointments_infos {
      __typename
      id
      externalAppointmentId
    }
  }
`);

/**
 * Returns the external appointment id from the
 * relevant EHR if it is available for the current appointment
 */
export const useExternalAppointmentId = () => {
  const appointmentId = useAppointmentId();

  const data = useCompleteFragment({
    fragment: appointmentExternalInfoFragment,
    key: {
      id: appointmentId,
    },
    strict: false,
  });

  return data?.external_appointments_infos?.[0]?.externalAppointmentId;
};
