import React from 'react';
import { Link } from 'react-router-dom';

import { Button, H1 } from '@eluve/components';

export const ErrorPage: React.FC<{ reset?: () => void }> = ({ reset }) => {
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-brand-12 text-base font-semibold">Error</p>
        <H1 xl m="mt-4">
          We're sorry
        </H1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          It seems like something went wrong. The team has been notified and
          we're working on fixing it.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button variant={'outline'} onClick={reset}>
            Try again
          </Button>
          <Link
            to="/"
            className="bg-brand-10 hover:bg-brand-9 rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={reset}
          >
            Go back home
          </Link>
        </div>
      </div>
    </main>
  );
};
