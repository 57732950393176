import { PanelLeftOpenIcon } from 'lucide-react';
import React from 'react';
import { Outlet } from 'react-router-dom';

import {
  Button,
  H1,
  ReskinContent,
  ReskinHeader,
  ReskinMain,
  SidebarTrigger,
} from '@eluve/components';

import { AdminLocationsBreadcrumbs } from '../../admin/components/AdminLocationsBreadcrumbs';

export const TenantSettingsMainLayout: React.FC = () => {
  return (
    <ReskinMain>
      <ReskinHeader>
        <SidebarTrigger asChild>
          <Button size="icon" variant="ghost">
            <PanelLeftOpenIcon />
          </Button>
        </SidebarTrigger>
        <H1 p="p-2">Tenant Settings</H1>
        <AdminLocationsBreadcrumbs />
      </ReskinHeader>
      <ReskinContent variant="container">
        <Outlet />
      </ReskinContent>
    </ReskinMain>
  );
};
