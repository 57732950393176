import { fromCallback } from 'xstate';

import { Logger } from '@eluve/logger';

import { UserFileSystem } from '../user-file-system.store';

export type GetFileDataEvents =
  | {
      type: 'GetFileData.received';
      data: File;
    }
  | {
      type: 'GetFileData.empty';
    }
  | {
      type: 'GetFileData.error';
      error: Error;
    };

export const getFileData = fromCallback<
  GetFileDataEvents,
  {
    appointmentId: string;
    fileName: string;
    fileSystem: UserFileSystem;
    logger: Logger;
  }
>(({ input, sendBack }) => {
  const { appointmentId, fileName, fileSystem, logger } = input;

  if (fileSystem.type !== 'available') {
    sendBack({
      type: 'GetFileData.error',
      error: new Error('File system not available'),
    });
    return;
  }

  const getFileData = async () => {
    try {
      const appointmentDir =
        await fileSystem.appointmentsDir.getDirectoryHandle(appointmentId);

      const file = await appointmentDir.getFileHandle(fileName);
      const fileData = await file.getFile();
      if (fileData.size === 0) {
        logger.warn('Got empty file data', { appointmentId, fileName });
        return sendBack({ type: 'GetFileData.empty' });
      }

      sendBack({ type: 'GetFileData.received', data: fileData });
    } catch (error) {
      logger.warn('Failed to get file data', {
        appointmentId,
        fileName,
        error,
      });
      sendBack({ type: 'GetFileData.error' });
    }
  };

  getFileData();
});
