import { useMutation } from '@apollo/client';
import { toast } from 'sonner';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  TenantUserRoleFragmentDoc,
  UpdateUserTenantRoleDocument,
} from '@eluve/client-gql-operations';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@eluve/components';

type RoleSelect = {
  tenantId: string;
  userId: string;
};

const useTenantUserRole = (tenantId: string, userId: string) => {
  const data = useCompleteFragment({
    fragment: TenantUserRoleFragmentDoc,
    key: {
      tenantId,
      userId,
    },
    strict: false,
  });

  return data?.role ?? '';
};

export const RoleSelector: React.FC<RoleSelect> = ({ tenantId, userId }) => {
  const [updateUserTenantRole] = useMutation(UpdateUserTenantRoleDocument, {
    onCompleted: () => toast.success('Updated User Role'),
    onError: () => toast.error('Error updating user role'),
    optimisticResponse: ({ tenantId, userId, role: newRole }) => {
      return {
        updateTenantUsersByPk: {
          __typename: 'TenantUsers' as const,
          tenantId,
          userId,
          role: newRole,
        },
      };
    },
  });

  const updateRole = async (
    tenantId: string,
    userId: string,
    newRole: string,
  ) => {
    await updateUserTenantRole({
      variables: {
        tenantId,
        userId,
        role: newRole,
      },
    });
  };

  const tenantRole = useTenantUserRole(tenantId, userId);

  return (
    <div className="flex justify-center">
      <Select
        value={tenantRole}
        onValueChange={(newRole: string) => {
          if (newRole === tenantRole) {
            return;
          }
          return updateRole(tenantId, userId, newRole);
        }}
      >
        <SelectTrigger className="text-gray-12">{tenantRole}</SelectTrigger>
        <SelectContent>
          <SelectItem className="focus:bg-brand-4" value="tenant-admin">
            tenant-admin
          </SelectItem>
          <SelectItem className="focus:bg-brand-4" value="tenant-member">
            tenant-member
          </SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};
