import { createZustandContext } from '@eluve/stores';

export type UserFileSystem =
  | { type: 'pending' }
  | {
      type: 'available';
      topLevelUserDir: FileSystemDirectoryHandle;
      tenantDir: FileSystemDirectoryHandle;
      appointmentsDir: FileSystemDirectoryHandle;
      availableStorage?: number;
    }
  | { type: 'unavailable' };

type UserFileSystemStoreState = {
  userId: string;
  tenantId: string;
  userFileSystem: UserFileSystem;
};

export const {
  StoreProvider: UserFileSystemStoreProvider,
  useStore: useUserFileSystemStore,
  useStoreApi: useUserFileSystemStoreApi,
} = createZustandContext<UserFileSystemStoreState>();
