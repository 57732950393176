import { WandSparkles } from 'lucide-react';
import { useState } from 'react';
import { match } from 'ts-pattern';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  H4,
  P,
} from '@eluve/components';
import {
  useAppointmentActivity,
  useAppointmentId,
  useIsAppointmentReadonly,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { FeatureFlagKeysLookup } from '@eluve/posthog';
import { useCaptureEvent, useFeatureFlagPayload } from '@eluve/posthog-react';
import { useTenantIdFromParams } from '@eluve/session-helpers';
import {
  CustomInstructions,
  CustomInstructionsSidebarTrigger,
  RegenerateSummaryButton,
  RollbackPreviousSummaryButton,
  SummaryFeedbackModal,
  SummarySectionMarkdown,
  UserFeatureFlaggedComponent,
} from '@eluve/smart-blocks';
import { AppointmentSummaryKeys } from '@eluve/utils';

import { SummarizationErrorCard } from '../SummarizationErrorCard';
import { useEditSummary } from '../useEditSummary';

import { usePendingUnsavedChanges } from './AppointmentLastSaved';
import { ClientRecapMailToAction } from './ClientRecapMailToAction';

const SummaryInprogress = () => (
  <Box
    vStack
    fullWidth
    className="max-w-[500px] items-center gap-8 self-center pt-9 text-center"
  >
    <h4 className="text-gray-12 text-2xl">Generating Notes...</h4>
    <p className="text-gray-11 text-lg">
      You can leave this page and start a new session! We&apos;ll let you know
      when your note is ready.
    </p>
  </Box>
);

const SummaryResult = () => {
  const currentSummary = useSummary();
  const summary = currentSummary?.summary ?? {};
  const summaryDetected = Object.values(summary).some((s) => s?.length);
  const editSummary = useEditSummary();
  const appointmentId = useAppointmentId();

  const isReadonly = useIsAppointmentReadonly();
  const flag = FeatureFlagKeysLookup.NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS;
  const exampleChips = useFeatureFlagPayload(flag);
  const [instructions, setInstructions] = useState<string>('');
  const tenantId = useTenantIdFromParams();

  const captureEvent = useCaptureEvent({
    tenantId,
    appointmentId,
  });

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full justify-start gap-4 pb-7">
        <RegenerateSummaryButton />
        <UserFeatureFlaggedComponent flag="NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS">
          <CustomInstructions
            instructions={instructions}
            setInstructions={setInstructions}
          />
        </UserFeatureFlaggedComponent>
        <RollbackPreviousSummaryButton />
      </div>
      <SummarizationErrorCard />

      {match(summaryDetected)
        .with(true, () => (
          <Box vStack className="gap-4 lg:gap-6">
            {Object.entries(summary).map(([summaryKey, summaryValue]) => {
              return (
                <SummarySectionMarkdown
                  className="w-full"
                  appointmentId={appointmentId}
                  key={summaryKey + currentSummary?.llmOutputId}
                  summaryKey={summaryKey as AppointmentSummaryKeys}
                  sectionTitle={summaryKey}
                  content={summaryValue ?? ''}
                  disabled={isReadonly}
                  handleContentChange={(key, content) => {
                    usePendingUnsavedChanges.setState(true);
                    editSummary(key, content);
                  }}
                  additionalActions={
                    summaryKey === 'CLIENT_RECAP'
                      ? [ClientRecapMailToAction]
                      : undefined
                  }
                />
              );
            })}
          </Box>
        ))
        .otherwise(() => null)}

      {summaryDetected && (
        <div>
          <Box className="my-8 flex w-full flex-row items-center justify-between gap-2 rounded-lg bg-zinc-100 p-4">
            <P className="text-gray-10 font-normal">
              Was this medical summary helpful?
            </P>
            <SummaryFeedbackModal
              appointmentId={appointmentId}
              llmOutputId={currentSummary?.llmOutputId}
            />
          </Box>

          <UserFeatureFlaggedComponent flag="NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS">
            <Card className="border-gray-4 mb-10 w-full rounded-xl">
              <CardHeader className="flex h-10 flex-row rounded-t-xl bg-white p-5">
                <div className="flex flex-row gap-3">
                  <WandSparkles />
                  <H4>Improve your notes</H4>
                </div>
              </CardHeader>
              <CardContent className="flex flex-col items-start rounded-b-xl bg-white p-3">
                {exampleChips?.map((chip, index) => {
                  return (
                    <div key={chip} className="w-full pl-2 pr-2">
                      <CustomInstructionsSidebarTrigger alwaysShown={true}>
                        <Button
                          variant="outline"
                          className="border-none bg-white pb-6 pl-0 pr-0 pt-6"
                          onClick={() => {
                            captureEvent(
                              'user_clicked_top_level_improve_notes_chip',
                              {
                                instructions,
                              },
                            );
                            setInstructions(chip);
                          }}
                        >
                          <P className="font-normal">{chip}</P>
                        </Button>
                      </CustomInstructionsSidebarTrigger>
                      {index !== exampleChips.length - 1 && (
                        <hr className="bg-gray-4" />
                      )}
                    </div>
                  );
                })}
              </CardContent>
            </Card>
          </UserFeatureFlaggedComponent>
        </div>
      )}
    </div>
  );
};

export const AppointmentSummaryResult = () => {
  const { isSummarizationInProgress } = useAppointmentActivity();

  if (isSummarizationInProgress) return <SummaryInprogress />;

  return <SummaryResult />;
};
