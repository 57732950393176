import { useMutation } from '@apollo/client';

import { AppointmentStatusEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';

export const markAppointmentAsCompletedMutation = graphql(`
  mutation updateAppointmentStatus($tenantId: uuid!, $appointmentId: uuid!) {
    updateAppointmentsByPk(
      pkColumns: { tenantId: $tenantId, id: $appointmentId }
      _set: { status: COMPLETED }
    ) {
      __typename
      id
      status
    }
  }
`);

export const useMarkAppointmentAsCompleted = () => {
  const [markAppointmentAsCompleted] = useMutation(
    markAppointmentAsCompletedMutation,
    {
      optimisticResponse: ({ appointmentId }) => {
        return {
          updateAppointmentsByPk: {
            __typename: 'Appointments' as const,
            id: appointmentId,
            status: 'COMPLETED' as AppointmentStatusEnum,
          },
        };
      },
    },
  );

  return { markAppointmentAsCompleted };
};
