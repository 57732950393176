import { FileAudio, FilesIcon } from 'lucide-react';
import React, { useEffect } from 'react';

import {
  Button,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  P,
} from '@eluve/components';
import { useAppointmentId } from '@eluve/frontend-appointment-hooks';
import { useNamedLogger } from '@eluve/logger';
import {
  useAppointmentFilesStore,
  useAppointmentFilesStoreApi,
  useUserFileSystemStore,
} from '@eluve/user-local-files';

export interface AppointmentFilesDrawerProps {}

export const AppointmentFilesDrawer: React.FC<
  AppointmentFilesDrawerProps
> = () => {
  const appointmentId = useAppointmentId();
  const logger = useNamedLogger('AppointmentFilesDrawer');
  const storeApi = useAppointmentFilesStoreApi();
  const localFiles = useAppointmentFilesStore((state) => state.files);

  const readFilesFromOpfs = useAppointmentFilesStore(
    (store) => store.readFilesFromOpfs,
  );
  const downloadFileFromOpfs = useAppointmentFilesStore(
    (store) => store.downloadFileFromOpfs,
  );
  const userFileSystem = useUserFileSystemStore(
    (store) => store.userFileSystem,
  );

  // TODO(jesse)[ELU-963]: We can probably make this more reactive in the future
  // but due to the nature of the FileSystemAPI polling for changes to sync
  // into react land is suitable for the short term
  useEffect(
    function syncFilesFromOpfsToStore() {
      let isScanning = false;
      const syncFilesToStore = async () => {
        if (isScanning) {
          return;
        }

        const result = await readFilesFromOpfs();
        if (result.type === 'directoryExists') {
          const { files } = result;
          storeApi.setState({ files });
        }
        isScanning = false;
      };

      syncFilesToStore();

      const interval = setInterval(syncFilesToStore, 5000);

      return () => {
        clearInterval(interval);
      };
    },
    [readFilesFromOpfs, storeApi, logger, userFileSystem, appointmentId],
  );

  if (userFileSystem.type === 'unavailable') {
    return null;
  }

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <Button variant="outline" size="sm" className="flex items-center gap-2">
          <FilesIcon />
          Files
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <div className="container">
          <DrawerHeader>
            <DrawerTitle>Appointment Files</DrawerTitle>
            <DrawerDescription>
              {localFiles?.length
                ? 'Files will be removed from your device once the appointment is complete'
                : 'There are no local files on this device.'}
            </DrawerDescription>
          </DrawerHeader>
          <div className="mx-6 grid gap-1">
            {localFiles.map((f) => (
              <div
                key={f.name}
                className="border-1 flex w-full max-w-xl items-center justify-between border p-2"
              >
                <div className="flex items-center gap-2">
                  <FileAudio className="text-gray-12 h-6 w-6" />
                  <P>{f.name}</P>
                </div>
                <div className="flex items-center gap-4">
                  <P className="font-bold">{f.size}</P>
                  <Button
                    size="sm"
                    onClick={() => downloadFileFromOpfs(f.name)}
                  >
                    Download
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <DrawerFooter></DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
