import { createZustandContext } from '@eluve/stores';

type BillingCodeStoreState = {
  highlightedId: string | null;
  highlightedTranscriptExcerpts: string[];
};

export const {
  StoreProvider: BillingCodeStoreProvider,
  useStore: useBillingCodeStore,
  useStoreApi: useBillingCodeStoreApi,
} = createZustandContext<BillingCodeStoreState>();
