import { useSuspenseQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Box, H4, P, PageTitle } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import {
  FactVerificationInputResultsListItem,
  inputResultsFragment,
} from './FactVerificationInputResultsListItem';

const query = graphql(
  `
    query getFactVerificationModelPromptTemplate($id: uuid!) {
      evalFactVerificationWorkflowModelPromptTemplatesByPk(id: $id) {
        __typename
        id
        inputs {
          ...inputResults
        }
        model_prompt_template {
          __typename
          id
          model_args {
            __typename
            id
            args
            modelType
          }
          template {
            __typename
            id
            name
          }
        }
      }
    }
  `,
  [inputResultsFragment],
);

export interface FactVerificationModelPromptTemplatePageProps {}

export const FactVerificationModelPromptTemplatePage: React.FC<
  FactVerificationModelPromptTemplatePageProps
> = () => {
  const { modelPromptTemplateId } = useParams() as {
    workflowId: string;
    modelPromptTemplateId: string;
  };

  const {
    data: {
      evalFactVerificationWorkflowModelPromptTemplatesByPk:
        workflowModelPromptTemplate,
    },
  } = useSuspenseQuery(query, {
    variables: {
      id: modelPromptTemplateId,
    },
  });

  const modelArgs =
    workflowModelPromptTemplate?.model_prompt_template?.model_args;
  const promptTemplate =
    workflowModelPromptTemplate?.model_prompt_template?.template;

  const inputs = workflowModelPromptTemplate?.inputs ?? [];

  return (
    <>
      <PageTitle>Workflow Results</PageTitle>
      <Box hStack className="items-start gap-10 pb-8">
        <Box vStack>
          <H4>Model Info</H4>
          <P>{modelArgs?.modelType ?? ''}</P>
          <code className="bg-gray-3 text-gray-12 rounded-sm p-0.5 text-xs">
            {JSON.stringify(modelArgs?.args)}
          </code>
        </Box>

        <Box vStack>
          <H4>Prompt Template</H4>
          <P>{promptTemplate?.name ?? ''}</P>
        </Box>
      </Box>

      <H4> Results </H4>
      <ul className="divide-gray-4 mt-4 divide-y">
        {inputs.map((i) => (
          <FactVerificationInputResultsListItem key={i.id} id={i.id} />
        ))}
      </ul>
    </>
  );
};
