import { MapPinIcon } from 'lucide-react';

import {
  Box,
  Combobox,
  ComboboxDropdown,
  ComboboxOption,
  ComboboxSelectButton,
  ComboboxSelectCheck,
  InputVariants,
} from '@eluve/components';

import { useAppointmentSelectableLocations } from '../useAppointmentSelectableLocations';

interface LocationOption {
  id: string;
  label: string;
}

interface LocationSelectorProps extends InputVariants {
  tenantId: string;
  selectedLocationId?: string;
  onLocationSelected?: (location?: LocationOption) => void;
  disabled?: boolean;
}

export const LocationSelector: React.FC<LocationSelectorProps> = ({
  tenantId,
  onLocationSelected,
  selectedLocationId,
  color,
  size,
  variant,
  disabled = false,
}) => {
  const locations = useAppointmentSelectableLocations(tenantId);

  const selectedLocation = locations.find(
    (location) => location.id === selectedLocationId,
  );

  const handleLocationSelected = (location?: LocationOption) => {
    onLocationSelected?.(location);
  };

  return (
    <Combobox disabled={disabled}>
      <ComboboxSelectButton
        className="hover:bg-gray-3 w-full overflow-hidden border-transparent"
        color={color}
        size={size}
        variant={variant}
        title={selectedLocation?.label}
      >
        <Box hStack className="w-full overflow-hidden">
          <MapPinIcon size={16} />
          <p className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-left text-sm">
            {selectedLocation?.label ?? 'Select a location'}
          </p>
        </Box>
      </ComboboxSelectButton>
      <ComboboxDropdown searchPlaceholder="Search locations...">
        {locations.map((location) => (
          <ComboboxOption
            key={location.id}
            onSelect={() => handleLocationSelected(location)}
          >
            <ComboboxSelectCheck
              className="mr-2"
              selected={selectedLocation?.id === location.id}
            />
            {location.label}
          </ComboboxOption>
        ))}
        <ComboboxOption onSelect={() => handleLocationSelected(undefined)}>
          <ComboboxSelectCheck
            selected={selectedLocation === undefined}
            className="mr-2"
          />
          No location
        </ComboboxOption>
      </ComboboxDropdown>
    </Combobox>
  );
};
