import { InMemoryCache } from '@apollo/client';

import { Query_Root } from '@eluve/graphql-types';

import { createCacheUtils } from './cacheUtils/createCacheUtils';
import { createCacheUtilsHooks } from './cacheUtils/createCacheUtilsHooks';
import { typePolicies, typeSafePolicies } from './policies';
import { CapitalizeKeys } from './types';

export const defaultCache = new InMemoryCache({
  typePolicies,
});

export const cacheUtils =
  createCacheUtils<CapitalizeKeys<Query_Root>>(defaultCache).withPolicies(
    typeSafePolicies,
  );

export const { useCompleteFragment } = createCacheUtilsHooks(cacheUtils);

/**
 * The id of the root query object in the cache.
 */
export const QUERY_ROOT_ID = 'ROOT_QUERY';
