import { ErrorBoundary } from '@sentry/react';
import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';

import { ErrorAlert } from '@eluve/blocks';
import { Card, CardContent, PageTitle, Skeleton } from '@eluve/components';
import { LocationsTree } from '@eluve/frontend-feature-location-hierarchy';

export const LocationsPage: React.FC = () => {
  const { tenantId } = useParams() as { tenantId: string };

  return (
    <div className="container">
      <PageTitle subtitle="Add locations for your practice to help keep things organized">
        Locations
      </PageTitle>

      <Card className="pt-4">
        <CardContent>
          <ErrorBoundary
            fallback={({ resetError }) => (
              <ErrorAlert
                message="Failed to load locations"
                reset={resetError}
              />
            )}
          >
            <Suspense fallback={<Skeleton />}>
              <LocationsTree tenantId={tenantId} />
            </Suspense>
          </ErrorBoundary>
        </CardContent>
      </Card>
    </div>
  );
};
