import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import capitalize from 'lodash/capitalize';
import Markdown from 'markdown-to-jsx';

import { SOAPSchema, SOAP_KEYS } from '@eluve/llm-outputs';

import { MarkdownOptions } from './pdf-rendering';
import { tw } from './pdf-tw';

export type SoapPDFProps = {
  data: unknown;
  showSoapHeaders?: boolean;
  logoUrl?: string;
  patientInfoLines: string[];
  locationInfoLines?: string[];
};

export const SoapPDF = (props: SoapPDFProps) => {
  const {
    data,
    logoUrl,
    patientInfoLines,
    locationInfoLines = [],
    showSoapHeaders = true,
  } = props;

  const soapNote = SOAPSchema.parse(data);

  return (
    <Document>
      <Page size="A4" style={tw('p-4')}>
        <View fixed style={tw('flex flex-row flex-wrap justify-between mb-4')}>
          <View style={tw('text-sm font-sans')}>
            {patientInfoLines.map((line, i) => (
              <Text key={i} style={tw(`${i === 0 ? 'font-bold' : ''}`)}>
                {line}
              </Text>
            ))}
          </View>
          {logoUrl && <Image style={tw('w-64')} src={logoUrl}></Image>}
          {locationInfoLines.length > 0 && (
            <View style={tw('text-sm font-sans')}>
              {locationInfoLines.map((line, i) => (
                <Text style={tw(`${i === 0 ? 'font-bold' : ''}`)} key={i}>
                  {line}
                </Text>
              ))}
            </View>
          )}
        </View>

        {SOAP_KEYS.map((key) => {
          const sectionContent = soapNote[key];
          if (!sectionContent) {
            return null;
          }

          return (
            <View key={key} style={[tw('font-sans')]}>
              {showSoapHeaders && (
                <Text style={[tw('text-xl font-sans font-semibold')]}>
                  {capitalize(key)}
                </Text>
              )}
              <Markdown key={key} options={MarkdownOptions}>
                {sectionContent.replaceAll('\n', '\n\n')}
              </Markdown>
            </View>
          );
        })}
      </Page>
    </Document>
  );
};
