import { Loader2 } from 'lucide-react';
import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { doesSessionExist } from 'supertokens-auth-react/recipe/session';

import { useApiClient } from '@eluve/api-client-provider';

export interface SsoPageProps {}

const makeLoginErrorRedirect = (errorMessage: string) => {
  const params = new URLSearchParams({
    errorMessage,
  });

  return `/login?${params.toString()}`;
};

export const SsoPage: React.FC<SsoPageProps> = () => {
  const [search] = useSearchParams();
  const apiClient = useApiClient();
  const navigate = useNavigate();

  // Needed for React 18 strict mode double mounts
  // https://github.com/reactwg/react-18/discussions/18
  const attemptingFlow = useRef(false);

  const code = search.get('code') ?? '';
  const state = search.get('state') ?? '';

  useEffect(() => {
    const sso = async () => {
      await apiClient.auth.sso({
        body: {
          code,
          state,
        },
      });

      const validSession = await doesSessionExist();
      if (validSession) {
        navigate('/');
      } else {
        navigate(makeLoginErrorRedirect('Sign in failed'));
      }
    };

    if (!code || !state) {
      navigate(makeLoginErrorRedirect('Invalid SSO parameters'));
    } else if (attemptingFlow.current === false) {
      attemptingFlow.current = true;
      sso();
    }
  }, [code, navigate, apiClient.auth, state]);

  return (
    <div className="grid h-full place-items-center">
      <Loader2 className="text-brand-8 size-8 animate-spin" />
    </div>
  );
};
