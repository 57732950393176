import capitalize from 'lodash/capitalize';
import React from 'react';

import { H1, H4 } from '@eluve/components';
import { reviewOfSystemsSchema } from '@eluve/llm-outputs';

import { OutputParsingError } from './OutputParsingError';

export interface ReviewOfSystemsOutputProps {
  content: unknown;
}

export const ReviewOfSystemsOutput: React.FC<ReviewOfSystemsOutputProps> = ({
  content,
}) => {
  const ros = reviewOfSystemsSchema.safeParse(content);

  if (!ros.success) {
    return <OutputParsingError />;
  }

  return (
    <div className="space-y-4">
      {Object.entries(ros.data)
        .filter(([, items]) => items.length)
        .map(([category, items]) => {
          return (
            <div key={category}>
              <H1>{capitalize(category)}</H1>
              <div>
                {items.map((item) => {
                  return (
                    <div key={item.name}>
                      <H4 m="my-1">{item.name}</H4>
                      <div className="italic">{item.justification}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};
