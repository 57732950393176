import dayjs from 'dayjs';

export const formatToLocale = (
  timestamp: string | undefined | null,
  config?: {
    noTime?: boolean;
  },
): string => {
  const format = config?.noTime ? 'M/D/YYYY' : 'M/D/YYYY HH:mm';
  return timestamp ? dayjs(timestamp).format(format) : '';
};
