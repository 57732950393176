import { ApolloCache } from '@apollo/client';

import { CacheUtils } from './CacheUtils';
import { ValidQueryRoot } from './types';

export const createCacheUtils = <QueryRoot extends ValidQueryRoot>(
  cache?: ApolloCache<any>,
) => {
  return new CacheUtils<QueryRoot>(cache);
};
