import { createZustandContext } from '@eluve/stores';

import { AppointmentFileRow, AppointmentFilesResponse } from './opfs';

type AppointmentFilesStoreState = {
  files: AppointmentFileRow[];
  readFilesFromOpfs: () => Promise<AppointmentFilesResponse>;
  downloadFileFromOpfs: (fileName: string) => Promise<void>;
};

export const {
  StoreProvider: AppointmentFilesStoreProvider,
  useStore: useAppointmentFilesStore,
  useStoreApi: useAppointmentFilesStoreApi,
} = createZustandContext<AppointmentFilesStoreState>();
