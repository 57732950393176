import { forwardRef } from 'react';
import { tv } from 'tailwind-variants';

const kbdVariant = tv({
  base: 'bg-gray-1 border-gray-5 text-gray-12 rounded-md border border-b-2 px-1 py-0.5 text-xs',
});

export const Kbd = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return <kbd className={kbdVariant({ className })} {...props} ref={ref} />;
  },
);
