import React from 'react';

import { useIsEluveAdmin } from '@eluve/session-helpers';

export interface EluveAdminOnlyProps {
  children: React.ReactNode;
}

export const EluveAdminOnly: React.FC<EluveAdminOnlyProps> = ({ children }) => {
  const isEluveAdmin = useIsEluveAdmin();
  if (!isEluveAdmin) {
    return null;
  }

  return children;
};
