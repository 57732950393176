export type SupportedAudioFormat = {
  encoding: 'opus' | 'aac';
  mimeType: string;
  extension: 'webm' | 'mp4';
};

export const supportedAudioFormats: SupportedAudioFormat[] = [
  {
    mimeType: 'audio/webm;opus',
    encoding: 'opus',
    extension: 'webm',
  },
  {
    mimeType: 'audio/mp4;codecs="mp4a.40.2"',
    encoding: 'aac',
    extension: 'mp4',
  },
];

export const getSupportedAudioFormat = () => {
  return supportedAudioFormats.find((audioFormat) =>
    MediaRecorder.isTypeSupported(audioFormat.mimeType),
  );
};
