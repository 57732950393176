import { useMutation, useSuspenseQuery } from '@apollo/client';
import React from 'react';
import { toast } from 'sonner';

import { cacheUtils } from '@eluve/apollo-client';
import { Box } from '@eluve/components';
import {
  appointmentBillingCodeRecommendationsFragment,
  dismissBillingCodeRecommendationMutation,
  getBillingCodeRecommendationsQuery,
} from '@eluve/frontend-appointment-hooks';

import { BillingCodeRecommendation } from './BillingCodeRecommendation';
import { BillingCodeRecommendationChip } from './BillingCodeRecommendationChip';
import { BillingCodeRecommendationProps } from './BillingCodeRecommendationProps';
import {
  useBillingCodeStore,
  useBillingCodeStoreApi,
} from './billing-code.store';

export interface BillingCodeRecommendationChipsProps {
  tenantId: string;
  appointmentId: string;
}

export const BillingCodeRecommendationChips: React.FC<
  BillingCodeRecommendationChipsProps
> = ({ tenantId, appointmentId }) => {
  const {
    data: { appointmentsByPk: appointment },
  } = useSuspenseQuery(getBillingCodeRecommendationsQuery, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  const billingCodesStoreApi = useBillingCodeStoreApi();
  const highlightedId = useBillingCodeStore((s) => s.highlightedId);

  const [dismissBillingCode] = useMutation(
    dismissBillingCodeRecommendationMutation,
    {
      onError: () => toast.error('Failed to dismiss billing code'),
      onCompleted: () => toast.success('Billing code dismissed'),
      optimisticResponse: ({ id }) => ({
        updateAppointmentBillingCodeRecommendationsByPk: {
          __typename: 'AppointmentBillingCodeRecommendations' as const,
          id,
          visible: false,
        },
      }),
      update: (_cache, { data }) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodeRecommendationsFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            if (
              !existing ||
              !data?.updateAppointmentBillingCodeRecommendationsByPk
            ) {
              return existing;
            }

            return {
              ...existing,
              billingCodes: existing.billing_code_recommendations.filter(
                (code) =>
                  code.id !==
                  data.updateAppointmentBillingCodeRecommendationsByPk?.id,
              ),
            };
          },
        );
      },
    },
  );

  const billingCodeRecommendations =
    appointment?.billing_code_recommendations ?? [];

  const billingCodeProps =
    billingCodeRecommendations.map<BillingCodeRecommendationProps>(
      ({ code, codeType, id, label, supportingEvidence, excerpts }) => ({
        id,
        code,
        type: codeType,
        description: label,
        isHighlighted: highlightedId === id,
        justification: supportingEvidence ?? '',
        onHighlight: () => {
          const highlights = (excerpts as string[])
            .map((s) =>
              s
                .split('.')
                .map((i) => i.trim().replaceAll("'", ''))
                .filter(Boolean),
            )
            .flat();

          return billingCodesStoreApi.setState({
            highlightedId: id,
            highlightedTranscriptExcerpts: highlights,
          });
        },
        onClearHighlight: () => {
          billingCodesStoreApi.setState({
            highlightedId: null,
            highlightedTranscriptExcerpts: [],
          });
        },
        onDismiss: () => {
          dismissBillingCode({ variables: { id } });
        },
      }),
    );

  const selectedBillingCodeProps = billingCodeProps.find(
    (b) => b.id === highlightedId,
  );

  return (
    <div className="max-w-80 space-y-2">
      <Box hStack className="max-w-80 flex-wrap">
        {billingCodeProps.map((props) => {
          return <BillingCodeRecommendationChip key={props.id} {...props} />;
        })}
      </Box>
      {selectedBillingCodeProps && (
        <BillingCodeRecommendation {...selectedBillingCodeProps} />
      )}
    </div>
  );
};
