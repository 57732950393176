import { useMemo } from 'react';

import { toSentences } from '@eluve/utils';

import { useTranscriptionMachineSelector } from '../TranscriptionMachineProvider';

export const useInSessionTranscript = () => {
  const { transcript, interimTranscript, isTranscriptionDegraded } =
    useTranscriptionMachineSelector((s) => {
      const {
        transcript,
        interimTranscript,
        isTranscriptionDegraded,
        sessionAppointmentId,
      } = s.context;
      return {
        transcript,
        interimTranscript,
        isTranscriptionDegraded,
        sessionAppointmentId,
      };
    });

  const transcriptSentences = useMemo(
    () => toSentences(transcript ?? ''),
    [transcript],
  );

  const interimTranscriptSentences = useMemo(() => {
    if (!interimTranscript) return;
    return toSentences(interimTranscript);
  }, [interimTranscript]);

  return {
    transcript: transcriptSentences,
    interimTranscript: interimTranscriptSentences,
    isTranscriptionDegraded,
  };
};
