import { motion } from 'framer-motion';

import { Box, P, Skeleton } from '@eluve/components';

const MBox = motion(Box);

export const AppointmentsLoader = () => (
  <MBox
    vStack
    center
    className="bg-gray-1/80 pointer-events-none h-full w-full gap-4 rounded-lg backdrop-blur-sm"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.2, delay: 0.2 }}
  >
    <P className="text-gray-9 ml-16">Loading Appointments ...</P>
    <Box vStack className="w-full max-w-[320px] gap-0">
      {[0, 0.8, 1.6].map((delay, index) => (
        <motion.div
          className="flex h-14 w-full gap-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.6,
            delay,
          }}
          key={index}
        >
          <Skeleton className="bg-gray-4 h-4 w-16" />
          <div className="bg-gray-4 h-full w-[1.5px]" />
          <Skeleton className="bg-gray-4 h-10 w-full py-2" />
        </motion.div>
      ))}
    </Box>
  </MBox>
);
