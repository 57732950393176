import React from 'react';
import { match } from 'ts-pattern';

import { Badge, cn, labelTextStyles } from '@eluve/components';
import { AppointmentStatusEnum } from '@eluve/graphql-types';

export interface AppointmentStatusLabelProps {
  isSigned: boolean;
  isSummarized: boolean;
  status: AppointmentStatusEnum | null;
}

export const AppointmentStatusLabel: React.FC<AppointmentStatusLabelProps> = (
  props,
) => {
  const { message, className } = match(props)
    .with({ isSigned: true }, () => ({ message: 'Signed', className: '' }))
    .with({ isSummarized: true, isSigned: false }, () => ({
      message: 'Summary Available',
      className: 'bg-blue-500 hover:bg-blue-500',
    }))
    .with({ status: 'COMPLETED' }, () => ({
      message: 'Completed',
      className: 'bg-cyan-500 hover:bg-cyan-500',
    }))
    .with({ status: 'ACTIVE' }, () => ({
      message: 'Active',
      className: 'bg-yellow-500 hover:bg-yellow-500',
    }))
    .otherwise(() => ({
      message: 'Not Started',
      className: 'bg-gray-10 hover:bg-gray-10',
    }));

  return (
    <Badge
      className={cn(
        className,
        'whitespace-nowrap',
        labelTextStyles({ size: 2, className: 'text-white' }),
      )}
    >
      {message}
    </Badge>
  );
};
