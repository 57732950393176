import { ArrowLeftIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { BreadcrumbItem } from '@eluve/components';

import { TooltipLabel } from './TooltipLabel';

export const BackBreadcrumbItem = () => {
  const navigate = useNavigate();

  return (
    <TooltipLabel label="Back">
      <BreadcrumbItem
        className="text-gray-11 hover:text-gray-12 mr-4 cursor-pointer"
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowLeftIcon size={16} />
      </BreadcrumbItem>
    </TooltipLabel>
  );
};
