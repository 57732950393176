import { create } from 'zustand';

import { Box, P, Switch } from '@eluve/components';

export const useIsSimulatedDegradedTranscription = create(() => false);

export const SimulateDegradedTranscription = () => {
  const isSimulatedDegradedTranscription =
    useIsSimulatedDegradedTranscription();

  return (
    <Box hStack>
      <P>Simulate degraded transcription</P>
      <Switch
        checked={isSimulatedDegradedTranscription}
        onCheckedChange={(checked) =>
          useIsSimulatedDegradedTranscription.setState(checked)
        }
      />
    </Box>
  );
};
