import { CheckIcon, ChevronsUpDown } from 'lucide-react';
import { HTMLProps, useCallback, useState } from 'react';
import React from 'react';
import { tv } from 'tailwind-variants';
import { match } from 'ts-pattern';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from './command';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { FCC } from './types';
import { InputVariants, inputVariants } from './variants';

const comboboxSelectVariant = tv({
  extend: inputVariants,
  base: 'flex h-auto w-fit items-center justify-between gap-2 outline-0',
});

const SearchInputValueContext = React.createContext<null | string>(null);
const SetSearchInputValueContext = React.createContext<React.Dispatch<
  React.SetStateAction<string>
> | null>(null);
const CloseComboboxContext = React.createContext<null | (() => void)>(null);
const DisabledComboboxContext = React.createContext<boolean>(false);

const useIsComboboxDisabled = () => {
  return React.useContext(DisabledComboboxContext);
};

const Combobox: FCC<{ disabled?: boolean }> = ({
  children,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const handleComboboxClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <DisabledComboboxContext.Provider value={disabled}>
      <SearchInputValueContext.Provider value={inputValue}>
        <SetSearchInputValueContext.Provider value={setInputValue}>
          <CloseComboboxContext.Provider value={handleComboboxClose}>
            <Popover open={open} onOpenChange={setOpen}>
              {children}
            </Popover>
          </CloseComboboxContext.Provider>
        </SetSearchInputValueContext.Provider>
      </SearchInputValueContext.Provider>
    </DisabledComboboxContext.Provider>
  );
};

interface ComboboxSelectProps
  extends Omit<HTMLProps<HTMLButtonElement>, 'color' | 'size'> {
  dropdownIcon?: React.ReactNode;
}

const ComboboxSelectButton = React.forwardRef<
  HTMLButtonElement,
  ComboboxSelectProps & InputVariants
>(
  (
    { children, className, dropdownIcon, size, variant, color, ...props },
    ref,
  ) => {
    const disabled = useIsComboboxDisabled();
    return (
      <PopoverTrigger asChild disabled={disabled}>
        <button
          ref={ref}
          className={comboboxSelectVariant({
            className,
            size,
            variant,
            color,
            disabled,
          })}
          {...props}
          type="button"
        >
          {children ?? 'Select ...'}
          {match(disabled)
            .with(true, () => null)
            .otherwise(
              () =>
                dropdownIcon ?? (
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0" />
                ),
            )}
        </button>
      </PopoverTrigger>
    );
  },
);

interface ComboboxDropdownProps {
  children: React.ReactNode;
  className?: string;
  searchPlaceholder?: string;
}

const comboboxDropdownVariant = tv({
  base: 'p-0',
});

const ComboboxDropdown: React.FC<ComboboxDropdownProps> = ({
  children,
  className,
  searchPlaceholder,
}) => {
  const setValue = React.useContext(SetSearchInputValueContext);
  const value = React.useContext(SearchInputValueContext);

  if (setValue === null) {
    throw new Error('ComboboxSearchInput must be used within a Combobox');
  }

  return (
    <PopoverContent className={comboboxDropdownVariant({ className })}>
      <Command
        filter={(value, search) => {
          if (value === '*') {
            return 1;
          }
          if (value.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
            return 1;
          }

          return 0;
        }}
      >
        <CommandInput
          value={value ?? ''}
          onValueChange={setValue}
          placeholder={searchPlaceholder ?? 'Search'}
          className={className}
        />
        <CommandGroup className="max-h-[400px] overflow-auto">
          {children}
        </CommandGroup>
      </Command>
    </PopoverContent>
  );
};

interface ComboboxOptionProps {
  children: React.ReactNode;
  onSelect: () => void;
  searchValue?: string;
}

const ComboboxOption = React.forwardRef<HTMLDivElement, ComboboxOptionProps>(
  ({ children, onSelect, searchValue }, ref) => {
    const closeCombobox = React.useContext(CloseComboboxContext);

    if (closeCombobox === null) {
      throw new Error('ComboboxNewOption must be used within a Combobox');
    }

    return (
      <CommandItem
        onSelect={() => {
          onSelect();
          closeCombobox();
        }}
        value={searchValue}
        ref={ref}
        className="cursor-pointer"
      >
        {children}
      </CommandItem>
    );
  },
);

interface ComboboxNewOptionProps {
  children: React.ReactNode;
  onSelect: (inputValue: string) => void;
}

const ComboboxNewOption = React.forwardRef<
  HTMLDivElement,
  ComboboxNewOptionProps
>(({ children, onSelect }, ref) => {
  const value = React.useContext(SearchInputValueContext);
  const closeCombobox = React.useContext(CloseComboboxContext);

  if (value === null || closeCombobox === null) {
    throw new Error('ComboboxNewOption must be used within a Combobox');
  }

  if (!value) {
    return null;
  }

  return (
    <CommandItem
      onSelect={() => {
        onSelect(value);
        closeCombobox();
      }}
      ref={ref}
      value="*"
    >
      {children}
    </CommandItem>
  );
});

const ComboboxEmpty = CommandEmpty;

ComboboxEmpty.displayName = 'ComboboxEmpty';

const comboboxSelectCheckVariant = tv({
  base: 'text-brand-9 h-4 min-h-4 w-4 min-w-4',
  variants: {
    selected: { true: 'opacity-100', false: 'opacity-0' },
  },
});

interface ComboboxSelectCheckProps {
  selected?: boolean;
  className?: string;
}

const ComboboxSelectCheck: React.FC<ComboboxSelectCheckProps> = ({
  selected,
  className,
}) => {
  return (
    <CheckIcon
      className={comboboxSelectCheckVariant({ selected, className })}
    />
  );
};

interface CommandInputProps {
  placeholder?: string;
  className?: string;
}

const ComboboxSearchInput = React.forwardRef<
  React.ElementRef<typeof CommandInput>,
  CommandInputProps
>(({ className, placeholder }, ref) => {
  const setValue = React.useContext(SetSearchInputValueContext);

  if (setValue === null) {
    throw new Error('ComboboxSearchInput must be used within a Combobox');
  }

  return (
    <CommandInput
      onInput={(e) => setValue(e.currentTarget.value)}
      placeholder={placeholder}
      className={className}
      ref={ref}
    />
  );
});

const ComboboxSearchInputValue = () => {
  const value = React.useContext(SearchInputValueContext);
  if (value === null) {
    throw new Error('ComboboxSearchInputValue must be used within a Combobox');
  }

  return value;
};

const useComboboxInputValue = () => {
  const value = React.useContext(SearchInputValueContext);

  if (value === null) {
    throw new Error('useInputValue must be used within a Combobox');
  }

  return value;
};

const useSetComboboxInputValue = () => {
  const value = React.useContext(SetSearchInputValueContext);

  if (value === null) {
    throw new Error('useInputValue must be used within a Combobox');
  }

  return value;
};

export {
  Combobox,
  ComboboxSelectButton,
  ComboboxDropdown,
  ComboboxOption,
  ComboboxNewOption,
  ComboboxSearchInput,
  ComboboxSelectCheck,
  ComboboxEmpty,
  ComboboxSearchInputValue,
  useComboboxInputValue,
  useSetComboboxInputValue,
};
