import { useQuery } from '@apollo/client';

import { AppointmentSummary, AppointmentSummarySchema } from '@eluve/utils';

import { useAppointmentContext } from './appointment.context';
import { getCurrentSummary } from './operations';

type SummaryReturn = {
  summary: AppointmentSummary;
  isSummaryAvailable: boolean;
  llmOutputId: string | null;
  humanOutputId: string | null;
  refetch: () => void;
};

/**
 * Returns the current summary for an appointment if it is available
 *
 */
export const useSummary = (): SummaryReturn => {
  const { tenantId, appointmentId } = useAppointmentContext();

  const { data, refetch } = useQuery(getCurrentSummary, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  const currentSoapNote = data?.appointmentsByPk?.humanOutputs?.[0];
  const summary = currentSoapNote?.output?.content ?? null;

  const parsed = AppointmentSummarySchema.safeParse(summary);

  const shared: Omit<
    SummaryReturn,
    'summary' | 'isSummaryAvailable' | 'refetch'
  > = {
    humanOutputId: currentSoapNote?.humanOutputId ?? null,
    llmOutputId: currentSoapNote?.output?.editedFromLlmOutputId ?? null,
  };

  // Ensure that object keys are always in a predictable order since
  // the UI binds to the entries of this
  return parsed.success
    ? {
        ...shared,
        isSummaryAvailable: true,
        refetch,
        summary: {
          SUBJECTIVE: parsed.data.SUBJECTIVE,
          OBJECTIVE: parsed.data.OBJECTIVE,
          ASSESSMENT: parsed.data.ASSESSMENT,
          PLAN: parsed.data.PLAN,
          CLIENT_RECAP: parsed.data.CLIENT_RECAP,
        },
      }
    : {
        ...shared,
        isSummaryAvailable: false,
        refetch,
        summary: {
          SUBJECTIVE: null,
          OBJECTIVE: null,
          ASSESSMENT: null,
          CLIENT_RECAP: null,
        },
      };
};
