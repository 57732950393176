import { MicIcon } from 'lucide-react';
import React from 'react';

import { Alert, AlertDescription } from '@eluve/components';

export const TranscriptionWarning: React.FC = () => {
  return (
    <Alert variant="default" className="bg-white">
      <MicIcon className="h-8 w-8" />
      <div className="ml-5">
        <AlertDescription className="font-semibold">
          Live transcription has been paused.
        </AlertDescription>
        <AlertDescription>
          You are still in session. We will transcribe the full conversation
          when the appointment is over.
        </AlertDescription>
      </div>
    </Alert>
  );
};
