import { useEffect, useState } from 'react';

import { useApiClient } from '@eluve/api-client-provider';
import { useTenantIdFromParams } from '@eluve/session-helpers';

type Location = {
  id: string;
  name: string;
};

export const useTenantLocations = () => {
  const apiClient = useApiClient();
  const tenantId = useTenantIdFromParams();
  const [locations, setLocations] = useState<Location[]>([]);

  useEffect(() => {
    if (tenantId) {
      (async () => {
        const locationsResponse = await apiClient.locations.getLocations({
          params: {
            tenantId,
          },
        });
        if (locationsResponse.status === 200) {
          setLocations(locationsResponse.body.locations);
        }
      })();
    }
  }, [apiClient, tenantId]);

  return { locations };
};
