import { cacheUtils } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';

const appointmentTranscriptFragment = graphql(`
  fragment appointmentTranscription on Appointments {
    __typename
    transcription {
      __typename
      transcript
    }
  }
`);

export const getCurrentTranscript = (appointmentId: string) => {
  const appointment = cacheUtils.readFragment({
    fragment: appointmentTranscriptFragment,
    key: {
      id: appointmentId,
    },
    strict: false,
  });

  return appointment?.transcription?.transcript ?? '';
};
