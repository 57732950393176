import React from 'react';

import { Badge } from '@eluve/components';

export interface WorkflowMatrixDetailsProps {
  modelPromptTemplatesCount: number;
  totalFacts: number;
  totalArtifacts: number;
}

export const WorkflowMatrixDetails: React.FC<WorkflowMatrixDetailsProps> = ({
  modelPromptTemplatesCount,
  totalArtifacts,
  totalFacts,
}) => {
  return (
    <div className="flex items-center gap-2">
      <Badge variant="info">
        {modelPromptTemplatesCount} model prompt templates
      </Badge>
      <span>X</span>
      <Badge variant="info">{totalArtifacts} artifacts</Badge>
      <span className="text-gray-12">{` = ${totalArtifacts * modelPromptTemplatesCount} total inputs and ${totalFacts * modelPromptTemplatesCount} total facts`}</span>
    </div>
  );
};
