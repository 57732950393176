import { WandSparkles } from 'lucide-react';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useRef } from 'react';
import { toast } from 'sonner';

import { useApiClient } from '@eluve/api-client-provider';
import { Button, P, Textarea, createSidebar } from '@eluve/components';
import {
  useAppointmentId,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { useNamedLogger } from '@eluve/logger';
import { FeatureFlagKeysLookup } from '@eluve/posthog';
import { useCaptureEvent, useFeatureFlagPayload } from '@eluve/posthog-react';
import { useTenantIdFromParams } from '@eluve/session-helpers';

export const {
  DetailsSidebar: CustomInstructionsSidebar,
  DetailsSidebarTrigger: CustomInstructionsSidebarTrigger,
} = createSidebar({
  mode: 'drawer',
});

type CustomInstructionsProps = {
  instructions: string;
  setInstructions: (instructions: string) => void;
};

export const CustomInstructions: React.FC<CustomInstructionsProps> = ({
  instructions,
  setInstructions,
}) => {
  const flag = FeatureFlagKeysLookup.NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS;
  const appointmentId = useAppointmentId();
  const logger = useNamedLogger('CustomInstructions');
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const apiClient = useApiClient();
  const currentSummary = useSummary();
  const tenantId = useTenantIdFromParams();
  const didUserTypeInstruction = useRef<boolean>(false);
  const postHog = usePostHog();
  const exampleChips = useFeatureFlagPayload(flag);

  const captureEvent = useCaptureEvent({
    tenantId,
    appointmentId,
  });
  useEffect(() => {
    textareaRef.current?.focus();
  }, []);

  const handleButtonClick = (instruction: string) => {
    captureEvent('user_clicked_regenerate_note_example_improvement_chip', {
      instruction,
    });

    const newInstructions = `${instructions ? `${instructions}\n` : ''}${instruction} `;
    setInstructions(newInstructions);
    textareaRef.current?.focus();
  };

  const handleRegenerate = async () => {
    logger.info('Regenerating summary with custom instructions', {
      appointmentId,
    });

    captureEvent('user_regenerated_note_with_custom_instructions', {
      instructions,
    });

    try {
      const result =
        await apiClient.llm.resummarizeAppointmentWithUserInstructions({
          body: {
            llmOutputId: currentSummary.llmOutputId!,
            userInstruction: instructions,
          },
          params: {
            tenantId: tenantId!,
            appointmentId,
          },
        });

      if (result.status !== 201) {
        throw new Error(
          'Failed to start summarization job with custom instructions',
        );
      }

      toast.success('Regenerating your summary');
    } catch (e) {
      toast.error('Failed to regenerate summary. Please try again');
    }

    return;
  };

  return (
    <>
      <CustomInstructionsSidebar
        className="w-fit lg:max-w-xl"
        blurBackground={false}
      >
        <div>
          <div className="flex flex-col gap-3 pb-6 pl-6 pr-6">
            <div>
              <P className="text-2xl text-gray-800">Improve Notes</P>
            </div>
            <P className="font-medium text-gray-500">
              Customize your notes by providing specific guidelines. Try to be
              as clear and concise as possible for best results.
            </P>
          </div>
          <hr className="" />
          <div className="flex flex-col p-6">
            <Textarea
              ref={textareaRef} // Attach the ref to the Textarea
              placeholder="Type your instructions here"
              className="min-h-[120px]"
              value={instructions}
              onChange={(e) => {
                if (!didUserTypeInstruction.current) {
                  captureEvent('user_typed_improve_note_text');
                  didUserTypeInstruction.current = true;
                }
                setInstructions(e.target.value);
              }}
            />
            <div className="flex flex-col gap-3 pb-8 pt-8">
              <P className="text-gray-500">Examples</P>
              {exampleChips.map((instruction, index) => (
                <div key={index}>
                  <Button
                    variant="outlinesecondary"
                    size="xs"
                    onClick={() => handleButtonClick(instruction)}
                  >
                    {instruction}
                  </Button>
                </div>
              ))}
            </div>
            <div className="pb-6">
              <P>These changes will only apply to this note.</P>
            </div>

            <div className="flex justify-end">
              <CustomInstructionsSidebarTrigger asChild alwaysShown>
                <Button
                  variant="gray"
                  disabled={!instructions}
                  onClick={handleRegenerate}
                >
                  Regenerate Note
                </Button>
              </CustomInstructionsSidebarTrigger>
            </div>
          </div>
        </div>
      </CustomInstructionsSidebar>
      <CustomInstructionsSidebarTrigger asChild alwaysShown>
        <Button
          variant="outlinesecondary"
          size={'xs'}
          onClick={() => {
            captureEvent('user_opened_improve_note_dialog');
            postHog.capture('$feature_interaction', {
              feature_flag: flag,
              $set: { [`$feature_interaction/${flag}`]: true },
            });
          }}
        >
          <WandSparkles className="mr-2" size={16} />
          Improve Notes
        </Button>
      </CustomInstructionsSidebarTrigger>
    </>
  );
};
