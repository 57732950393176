import {
  ApolloClientOptions,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';

import { AccessTokenProvider } from './types';

export type ApolloClientFactoryOptions = {
  uri: string;
  desiredRole?: string;
  enableSubscriptions?: boolean;
  cacheInstance: InMemoryCache;
  accessTokenProvider: AccessTokenProvider;
} & Pick<ApolloClientOptions<NormalizedCacheObject>, 'defaultOptions'>;

export const defaultApolloClientOptions: ApolloClientOptions<NormalizedCacheObject>['defaultOptions'] =
  {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  };
