import { graphql } from '@eluve/graphql.tada';

export const adminTenantFeatureFlagFragment = graphql(`
  fragment AdminTenantFeatureFlag on TenantFeatureFlags @_unmask {
    __typename
    flag
    isEnabled
    tenantId
    updatedAt
    featureFlag {
      __typename
      name
      description
    }
  }
`);
