export const formatSecondsToTime = (timeInSeconds: number): string => {
  const hours = Math.floor(timeInSeconds / 3600)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((timeInSeconds % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (timeInSeconds % 60).toString().padStart(2, '0');

  return hours !== '00'
    ? `${hours}:${minutes}:${seconds}`
    : `${minutes}:${seconds}`;
};
