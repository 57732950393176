import React, { useEffect } from 'react';
import { useLatest } from 'react-use';

import { useNamedLogger } from '@eluve/logger';

import { useAppointmentTasksActor } from './AppointmentTasksMachineProvider';
import { useDb } from './files-db';
import { getSupportedAudioFormat } from './getSupportedAudioFormat';

export interface PeriodicFileSyncProviderProps {
  isAppointmentInProgress: boolean;
  isUploadInProgress: boolean;
}

export const PeriodicFileSyncProvider: React.FC<
  PeriodicFileSyncProviderProps
> = ({ isAppointmentInProgress, isUploadInProgress }) => {
  const logger = useNamedLogger('PeriodicFileSyncProvider');
  const db = useDb();

  const { send } = useAppointmentTasksActor();

  const isUploadInProgressRef = useLatest(isUploadInProgress);
  const isAppointmentInProgressRef = useLatest(isAppointmentInProgress);

  useEffect(
    function trySyncFile() {
      let isAttemptingFileSync = false;

      const interval = setInterval(
        async () => {
          if (isAppointmentInProgressRef.current) {
            logger.info(
              'Appointment currently in progress, skipping file sync',
            );
            return;
          }

          if (isAttemptingFileSync) {
            logger.info('Sync in progress, skipping');
            return;
          }

          if (isUploadInProgressRef.current) {
            logger.info('Upload in progress, skipping file sync');
            return;
          }

          // TODO(jesse)[ELU-2109]: Add some kind of lock to ensure that we don't try and upload the same file
          // across multiple tabs

          isAttemptingFileSync = true;

          try {
            // Find the least recent upload attempted at
            const files = await db.appointmentFiles
              .orderBy('lastUploadAttemptedAt')
              .filter((x) => x.isBackgroundUpload)
              .toArray();

            const file = files?.[0];
            if (!file) {
              logger.info('No un-uploaded files to sync');
            }
            if (file) {
              const {
                id: fileName,
                appointmentId,
                attemptCount,
                wasDegraded,
              } = file;

              const newAttemptCount = attemptCount + 1;

              await db.appointmentFiles.update(file.id, {
                lastUploadAttemptedAt: new Date().toISOString(),
                attemptCount: newAttemptCount,
              });

              const recordingStartedAt = fileName
                .split('.')
                .slice(0, -1)
                .join('.');

              logger.info('Scheduled file sync', {
                fileName,
                appointmentId,
                attemptCount: newAttemptCount,
              });

              send({
                type: 'TASK.UPLOAD_FILE',
                appointmentId,
                format: getSupportedAudioFormat()!,
                fileName,
                shouldBeTranscribed: true,
                recordingStartedAt,
                isBackgroundUpload: true,
                wasDegraded,
                attemptNumber: newAttemptCount,
              });
            }
          } catch (e) {
            logger.error('Failed to attempt file sync');
          } finally {
            isAttemptingFileSync = false;
          }
        },
        1000 * 60 * 5,
      );

      return () => {
        clearInterval(interval);
      };
    },
    [db, isAppointmentInProgressRef, isUploadInProgressRef, logger, send],
  );

  return null;
};
