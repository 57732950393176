import { z } from 'zod';

export const patientSchema = z.object({
  id: z.number(),
  user: z
    .object({
      id: z.number(),
      first_name: z.string().nullish(),
      last_name: z.string().nullish(),
      email: z.string().nullish(),
    })
    .passthrough()
    .nullish(),
  personal_pronouns: z.string().nullish(),
  date_birth: z.string().nullish(),
  is_patient: z.boolean().nullish(),
  is_provider: z.boolean().nullish(),
  patient_referral: z.string().nullish(),
  referral_details: z.string().nullish(),
  opt_note: z.string().nullish(),
  promo_code: z.string().nullish(),
  onboarding_step: z.string().nullish(),
  completed_onboarding: z.boolean().nullish(),
  phone: z.string().nullish(),
  address_line_1: z.string().nullish(),
  address_line_2: z.string().nullish(),
  address_city: z.string().nullish(),
  address_state: z.string().nullish(),
  address_zip: z.string().nullish(),
  primary_care_doctor_name: z.string().nullish(),
  primary_care_doctor_medical_practice: z.string().nullish(),
  primary_care_doctor_phone: z.string().nullish(),
  primary_care_doctor_fax: z.string().nullish(),
  emergency_contact_name: z.string().nullish(),
  emergency_contact_phone: z.string().nullish(),
  emergency_contact_relation: z.string().nullish(),
  occupation: z.string().nullish(),
  movement_amount: z.string().nullish(),
  goals: z.string().nullish(),
  outlook: z.string().nullish(),
  sleep: z.string().nullish(),
  exercise: z.string().nullish(),
  ability: z.string().nullish(),
  stress: z.string().nullish(),
  pain: z.string().nullish(),
  diet: z.string().nullish(),
  family_history: z.string().nullish(),
  surgeries: z.string().nullish(),
  medications: z.string().nullish(),
  has_guardian: z.boolean().nullish(),
  guardian_first_name: z.string().nullish(),
  guardian_last_name: z.string().nullish(),
  guardian_phone: z.string().nullish(),
  guardian_email: z.string().nullish(),
  guardian_relation: z.string().nullish(),
});

export const appointmentSchema = z
  .object({
    id: z.number(),
    date_created: z.string().nullish(),
    date_updated: z.string().nullish(),
    appointment_scheduled: z
      .object({
        id: z.number(),
        patient: patientSchema.nullish(),
        session_id: z.number().nullish(),
        session_type_id: z.number().nullish(),
        session_type: z
          .object({
            id: z.number(),
            title: z.string().nullish(),
            duration_minutes: z.number().nullish(),
            hourly_rate: z.number().nullish(),
            is_physical_therapy: z.boolean().nullish(),
            hide_availability: z.boolean().nullish(),
            send_calendar_invitations: z.boolean().nullish(),
            css_color: z.string().nullish(),
            location_type: z.string().nullish(),
            description: z.string().nullish(),
            session_meeting_url: z.string().nullish(),
            timezone: z.string().nullish(),
            date_range_start: z.string().nullish(),
            date_range_end: z.string().nullish(),
            scheduling_window_hours: z.number().nullish(),
            time_buffer_before_minutes: z.number().nullish(),
            time_buffer_after_minutes: z.number().nullish(),
            maximum_bookings_per_day: z.number().nullish(),
            address_line_1: z.string().nullish(),
            address_line_2: z.string().nullish(),
            address_city: z.string().nullish(),
            address_state: z.string().nullish(),
            address_zip: z.string().nullish(),
          })
          .passthrough()
          .nullish(),
        start_time: z.string().nullish(),
        end_time: z.string().nullish(),
        timezone: z.string().nullish(),
        start_time_with_buffer: z.string().nullish(),
        end_time_with_buffer: z.string().nullish(),
        time_buffer_before_minutes: z.number().nullish(),
        time_buffer_after_minutes: z.number().nullish(),
        address_line_1: z.string().nullish(),
        address_line_2: z.string().nullish(),
        address_city: z.string().nullish(),
        address_state: z.string().nullish(),
        address_zip: z.string().nullish(),
      })
      .passthrough()
      .nullish(),
    category: z.string().nullish(),
    date: z.string().nullish(),
    duration: z.number().nullish(),
    location: z.string().nullish(),
    discount: z.number().nullish(),
    state: z.string().nullish(),
    place_service: z.string().nullish(),
    total: z.number().nullish(),
    rate: z.number().nullish(),
    is_confirmed: z.boolean().nullish(),
    is_canceled: z.boolean().nullish(),
    is_no_show: z.boolean().nullish(),
    is_completed: z.boolean().nullish(),
    is_upcoming: z.boolean().nullish(),
    date_canceled: z.string().nullish(),
    date_confirmed: z.string().nullish(),
    date_completed: z.string().nullish(),
    notes: z.string().nullish(),
    cancel_note: z.string().nullish(),
    invoice_url: z.string().nullish(),
    note_url: z.string().nullish(),
  })
  .passthrough();

export type Appointment = z.infer<typeof appointmentSchema>;
export type AppointmentsResponse = Appointment[];
export type Patient = z.infer<typeof patientSchema>;
