import {
  ArrowLeft,
  PanelLeftOpenIcon,
  PlayIcon,
  StopCircleIcon,
} from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { tv } from 'tailwind-variants';

import { usePrivacyModeToggle } from '@eluve/blocks';
import {
  Box,
  Button,
  EluveLogo,
  H2,
  H3,
  H5,
  Input,
  Kbd,
  MicrophoneLevel,
  P,
  ReskinContent,
  ReskinHeader,
  ReskinLayout,
  ReskinMain,
  Separator,
  Sidebar,
  SidebarNav,
  SidebarNavItem,
  SidebarNavSpacer,
  SidebarTrigger,
  Switch,
} from '@eluve/components';
import {
  EnableSoundEffects,
  PromptTemplateSettings,
} from '@eluve/frontend-feature-user-settings';
import { useSession, useUserIdFromSession } from '@eluve/session-helpers';
import {
  MicrophoneDeviceSelector,
  useSelectedMicrophoneDevice,
} from '@eluve/smart-blocks';

import { UserHeaderDropdownMenu } from '../tenant/components/UserHeaderDropdownMenu';

import { useShowAppointmentsOwner } from './hooks/useShowAppointmentsOwner';

const settingsPageVariants = tv({
  slots: {
    base: 'border-gray-5 flex h-fit flex-col items-stretch gap-3 overflow-auto rounded-xl border bg-white p-10 pb-2',
    section: 'flex flex-col items-stretch gap-2',
    sectionTitle: 'mb-3 font-normal',
  },
});

const { base, section, sectionTitle } = settingsPageVariants();

export const SettingsPage: React.FC = () => {
  const { setPrivacyMode, privacyMode } = usePrivacyModeToggle();
  const [selectedMicId, setSelectedMicId] = useSelectedMicrophoneDevice();
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [enableMicTest, setEnableMicTest] = useState(false);
  const [showAppointmentsOwner, setShowAppointmentsOwner] =
    useShowAppointmentsOwner();

  useEffect(() => {
    if (!enableMicTest) {
      return;
    }

    let localMediaStream: MediaStream | null = null;
    (async () => {
      localMediaStream = await navigator.mediaDevices.getUserMedia({
        audio: selectedMicId
          ? {
              deviceId: selectedMicId,
            }
          : true,
      });

      setMediaStream(localMediaStream);
    })();

    return () => {
      localMediaStream?.getTracks().forEach((track) => {
        track.stop();
      });
    };
  }, [selectedMicId, enableMicTest]);

  const userId = useUserIdFromSession();

  const navigate = useNavigate();

  const session = useSession();

  const { email } = session;

  return (
    <ReskinLayout>
      <Sidebar>
        <SidebarNav>
          <Link to="/">
            <SidebarNavItem className="mb-2" icon={<EluveLogo />}>
              Eluve Inc.
            </SidebarNavItem>
          </Link>
        </SidebarNav>
        <SidebarNavSpacer />
        <SidebarNav>
          <SidebarNavItem icon={<UserHeaderDropdownMenu />}>
            User
          </SidebarNavItem>
        </SidebarNav>
      </Sidebar>
      <ReskinMain>
        <ReskinHeader>
          <SidebarTrigger asChild>
            <Button size="icon" variant="ghost">
              <PanelLeftOpenIcon />
            </Button>
          </SidebarTrigger>
          <Box hStack className="gap-4">
            <Button variant="outline" size="icon" onClick={() => navigate(-1)}>
              <ArrowLeft />
            </Button>
            <h1 className="text-xl font-bold">Settings</h1>
          </Box>
        </ReskinHeader>
        <ReskinContent variant="smallContainer">
          <div className={base()}>
            <div className={section()}>
              <H2>Settings</H2>
              <P>Manage your Eluve preferences</P>
            </div>

            <Separator />

            <div className={section()}>
              <H3 cn={sectionTitle()}>Audio</H3>
              <H5>Microphone</H5>
              <Box hStack className="w-full">
                <MicrophoneDeviceSelector
                  onDeviceValueChange={(deviceId) => {
                    toast.success('Microphone preferences changed');
                    setSelectedMicId(deviceId);
                  }}
                  selectedMicId={selectedMicId}
                />
                <Button
                  variant="outline"
                  onClick={() => setEnableMicTest(!enableMicTest)}
                >
                  {enableMicTest ? (
                    <>
                      <StopCircleIcon className="mr-2 size-4" />
                      Stop
                    </>
                  ) : (
                    <>
                      <PlayIcon className="mr-2 size-4" />
                      Test
                    </>
                  )}
                </Button>
              </Box>
              {enableMicTest && (
                <MicrophoneLevel
                  stream={mediaStream}
                  key={selectedMicId}
                  className="w-max"
                  variant="compact"
                />
              )}
            </div>

            <Separator />

            <div className={section()}>
              <H3 cn={sectionTitle()}>User Settings</H3>
              <H5>Preferred Chart Style</H5>
              <PromptTemplateSettings />
              <div className="mt-2" />
              <H5>Privacy mode</H5>
              <P className="text-gray-12 font-normal">
                Enable privacy mode to hide patient information in the UI. It
                can be toggled from anywhere in the app by pressing the keyboard
                shortcut <Kbd>Shift</Kbd> + <Kbd>P</Kbd>.
              </P>
              <Box hStack className="gap-4" asChild>
                <label>
                  <Switch
                    checked={privacyMode}
                    onCheckedChange={(checked) => setPrivacyMode(!checked)}
                  />
                  <i className="text-gray-11 text-sm">
                    Ex : Patient name:
                    <strong className="privacy-text"> John Doe</strong>
                  </i>
                </label>
              </Box>
              <EnableSoundEffects />
              <H5 m="mt-2">Show Appointment's owner</H5>
              <P className="text-gray-12 font-normal">
                Displays owner name of the appointment in the agenda view.
              </P>
              <Box hStack className="gap-4" asChild>
                <label>
                  <Switch
                    checked={showAppointmentsOwner}
                    onCheckedChange={(checked) =>
                      setShowAppointmentsOwner(checked)
                    }
                  />
                </label>
              </Box>
            </div>

            <Separator />

            <div className={section()}>
              <H3 cn={sectionTitle()}>Account Details</H3>
              <H5>Email</H5>
              <Input value={email} disabled className="text-gray-12 mb-2" />
              <H5>User ID</H5>
              <Input value={userId} disabled className="text-gray-12" />
            </div>

            <Separator />
            <div className={section()}>
              <H5 cn="mb-2">Privacy & Security</H5>
              <P>
                Audio, transcripts, and clinical notes are stored securely on
                our servers. You may request deletion of them at any time.{' '}
                <a
                  className="underline"
                  href="https://eluveinc.notion.site/Data-Protection-Policies-c9deaab9493048bc96c1fe62adef31a9"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  More info on how we protect data
                </a>
              </P>
            </div>
          </div>
        </ReskinContent>
      </ReskinMain>
    </ReskinLayout>
  );
};
