import some from 'lodash/some';
import {
  ArrowRightLeftIcon,
  DownloadIcon,
  RefreshCwIcon,
  TriangleAlertIcon,
} from 'lucide-react';
import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { P as Predicate, match } from 'ts-pattern';

import { EhrOverview, EhrStatusDetails, TooltipLabel } from '@eluve/blocks';
import { Box, Button, P } from '@eluve/components';
import { useCaptureEventOnMount } from '@eluve/posthog-react';
import type { ExternalEhr } from '@eluve/smart-blocks';
import {
  useEluveExtExists,
  useExternalEhrs,
  useIsFeatureFlagEnabled,
} from '@eluve/smart-blocks';
import { getVendorProvider } from '@eluve/vendor';

import { appConfig } from '../../../../../config';
import { useUserLocations } from '../hooks/useUserLocation';
import { useEhrStatusDetails } from '../useEhrStatusDetails';
import { useImportDataFromEhr } from '../useImportDataFromEhr';

type EhrDataImporterProps = {
  onlyShowIfError?: boolean;
};

const getEventName = (event: string) => ['ehr_data_importer', event].join('_');

const DownloadContent: React.FC = () => {
  useCaptureEventOnMount(getEventName('install_extension_prompt_shown'));

  return (
    <div className="flex max-w-xs flex-row justify-between">
      <div className="w-10">
        <Button
          variant="outline"
          className="h-10 w-10 items-center rounded-sm border-none bg-zinc-100"
          size="icon"
        >
          <DownloadIcon size={19} />
        </Button>
      </div>
      <div className="ml-3 flex flex-col">
        <span className="text-zinc-800 sm:text-sm">Install the extension</span>
        <span className="pt-2 font-normal text-neutral-500 sm:text-xs">
          Get the chrome extension to easily sync your note back to your EHR.
        </span>
      </div>
    </div>
  );
};

const UpdateContent: React.FC = () => {
  useCaptureEventOnMount(getEventName('update_extension_prompt_shown'));

  return (
    <div className="flex max-w-xs flex-row items-center justify-between">
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <TriangleAlertIcon size={18} className="mr-2" />
          <span className="text-zinc-800">Update the extension</span>
        </div>
        <span className="pt-2 font-normal text-neutral-500 sm:text-xs">
          The Eluve Extension is out of date. Please update to the latest
          version to continue syncing with your EHR.
        </span>
        <P className="pt-2 font-light text-gray-500 underline sm:text-xs">
          Update Extension
        </P>
      </div>
    </div>
  );
};

const OnboardingContent: React.FC = () => {
  useCaptureEventOnMount(getEventName('complete_onboarding_prompt_shown'));

  return (
    <div className="flex max-w-xs flex-row justify-between">
      <div className="w-10">
        <Button
          variant="outline"
          className="h-10 w-10 items-center rounded-sm border-none bg-zinc-100"
          size="icon"
        >
          <ArrowRightLeftIcon size={19} />
        </Button>
      </div>
      <div className="ml-3 flex flex-col">
        <span className="text-zinc-800 sm:text-sm">EHR Integration</span>
        <span className="pt-2 font-normal text-neutral-500 sm:text-xs">
          You're almost there! Finish setting up your integration!
        </span>
      </div>
    </div>
  );
};

const MultipleEhrIntegrationComponent: React.FC<{
  isDataImporting: boolean;
  externalEhrs: ExternalEhr[];
}> = ({ isDataImporting, externalEhrs }) => {
  const { data: ehrStatus } = useEhrStatusDetails();
  const { tenantId } = useParams() as { tenantId: string };
  const currentTenantEhrStatus =
    ehrStatus?.eluveDomains?.[appConfig.VITE_API_DOMAIN]?.tenants?.[tenantId];

  return (
    <div className="flex max-w-xs flex-row items-center justify-between">
      <div className="flex flex-col">
        <P className="font-dark sm:text-sm">EHR Integration</P>
        <P className="font-light sm:text-xs">
          {!Object.keys(currentTenantEhrStatus ?? {}).length
            ? ''
            : externalEhrs.every(
                  (ehr) =>
                    currentTenantEhrStatus?.vendors?.[ehr.vendor]?.domains?.[
                      ehr.domain
                    ]?.status === 'CONNECTED',
                )
              ? `${externalEhrs.length} accounts connected`
              : 'Problems syncing with your EHR'}
        </P>
      </div>
      <RefreshCwIcon
        className={`h-5 ${isDataImporting ? 'animate-spin' : ''}`}
      />
    </div>
  );
};

const MultipleEhrIntegrationPanel: React.FC<{
  externalEhrs: ExternalEhr[];
}> = ({ externalEhrs }) => {
  const { data: ehrStatus } = useEhrStatusDetails();
  const { tenantId } = useParams() as { tenantId: string };
  const currentTenantEhrStatus =
    ehrStatus?.eluveDomains?.[appConfig.VITE_API_DOMAIN]?.tenants?.[tenantId];

  const logos = useMemo(() => {
    return externalEhrs.map((ehr) => {
      const { vendor, domain } = ehr;
      const vendorProvider = getVendorProvider(vendor);
      const logoData = vendorProvider.getLogo();
      const logo = (connected: boolean) => (
        <img
          src={logoData}
          alt="Logo"
          className={`${connected ? '' : 'grayscale'}`}
        />
      );
      return {
        vendor,
        domain,
        logo,
      };
    });
  }, [externalEhrs]);

  return logos.map(({ vendor, domain, logo }, index) => {
    const { error, syncedAt, status } =
      currentTenantEhrStatus?.vendors?.[vendor]?.domains?.[domain] ?? {};
    return (
      <div
        key={`multiple-accounts-overview-${vendor}-${index}`}
        className="mt-3"
      >
        <EhrOverview
          vendor={vendor}
          domain={domain}
          logo={logo}
          showStatusDetails
          error={error}
          status={status}
          syncedAt={syncedAt}
        />
      </div>
    );
  });
};

export const EhrDataImporter: React.FC<EhrDataImporterProps> = ({
  onlyShowIfError,
}) => {
  const { tenantId } = useParams() as { tenantId: string };
  const externalEhrs = useExternalEhrs(tenantId);
  const { eluveExtExists, eluveExtVersionSatisfies } = useEluveExtExists();
  const isEhrOnboardingEnabled = useIsFeatureFlagEnabled('EHR_ONBOARDING');

  const {
    importDataFromEhr,
    isDataImporting,
    isImportAllowed,
    isSupportedBrowser,
  } = useImportDataFromEhr(tenantId);
  const { data: ehrStatus, refetch: syncEhrStatus } = useEhrStatusDetails();
  const [showEhrIntegrationPanel, setShowEhrIntegrationPanel] =
    useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const isUserCurrentlyOnboarding = searchParams.get('event') === 'onboarding';

  const {
    isUserPartOfAnyLocation,
    areLocationsAvailable,
    isAnyLocationEhrEnabled,
  } = useUserLocations();

  const currentTenantEhrStatus =
    ehrStatus?.eluveDomains?.[appConfig.VITE_API_DOMAIN]?.tenants?.[tenantId];

  const hasSyncedBefore = some(currentTenantEhrStatus?.vendors, (vendor) =>
    some(vendor?.domains, (domain) => Boolean(domain.syncedAt)),
  );

  const didUserFinishOnboarding =
    isUserPartOfAnyLocation &&
    (isAnyLocationEhrEnabled ? hasSyncedBefore : true);
  const shouldPromptUserToFinishOnboarding =
    areLocationsAvailable &&
    didUserFinishOnboarding === false &&
    !isUserCurrentlyOnboarding &&
    isEhrOnboardingEnabled;

  const performImport = async () => {
    await importDataFromEhr({ showToast: true });
    await syncEhrStatus();
  };

  const { content, ehrIntegrationPanelContent, onClick, tooltipLabel } = match({
    eluveExtExists,
    eluveExtVersionSatisfies,
    externalEhrs,
    shouldPromptUserToFinishOnboarding,
  })
    .with({ eluveExtExists: false }, () => {
      return {
        onClick: () => {
          window.open(
            'https://chromewebstore.google.com/detail/eluve/hkekbppdbafnjcoeacbdghoebaeadlid',
            '_blank',
            'noopener,noreferrer',
          );
        },
        content: <DownloadContent />,
        tooltipLabel: 'Install the extension',
        ehrIntegrationPanelContent: null,
      };
    })
    .with({ eluveExtExists: true, eluveExtVersionSatisfies: false }, () => {
      return {
        onClick: () => {
          window.open(
            'https://eluveinc.notion.site/Updating-the-Eluve-Extension-ffad42d228f14beeada0e06caffdc272',
            '_blank',
            'noopener,noreferrer',
          );
        },
        content: <UpdateContent />,
        tooltipLabel: 'Update extension',
        ehrIntegrationPanelContent: null,
      };
    })
    .with(
      {
        eluveExtExists: true,
        eluveExtVersionSatisfies: true,
        shouldPromptUserToFinishOnboarding: true,
      },
      () => {
        return {
          onClick: () => {
            setSearchParams({ event: 'onboarding' });
          },
          content: <OnboardingContent />,
          tooltipLabel: 'Complete onboarding',
          ehrIntegrationPanelContent: null,
        };
      },
    )
    .with(
      {
        eluveExtExists: true,
        eluveExtVersionSatisfies: true,
        externalEhrs: Predicate.when((ehrs) => ehrs.length > 1),
      },
      ({ externalEhrs }) => {
        return {
          content: (
            <MultipleEhrIntegrationComponent
              isDataImporting={isDataImporting}
              externalEhrs={externalEhrs}
            />
          ),
          tooltipLabel: 'Import patients and appointments',
          onClick: performImport,
          ehrIntegrationPanelContent: (
            <MultipleEhrIntegrationPanel externalEhrs={externalEhrs} />
          ),
        };
      },
    )
    .with(
      {
        eluveExtExists: true,
        eluveExtVersionSatisfies: true,
        externalEhrs: Predicate.when((ehrs) => ehrs.length === 1),
      },
      ({ externalEhrs }) => {
        const { vendor, domain } = externalEhrs[0]!;
        const vendorProvider = getVendorProvider(vendor);
        const logoData = vendorProvider.getLogo();
        const logo = (connected: boolean) => (
          <img
            src={logoData}
            alt="Logo"
            className={`${connected ? '' : 'grayscale'}`}
          />
        );

        const { error, syncedAt, status } =
          currentTenantEhrStatus?.vendors?.[vendor]?.domains?.[domain] ?? {};

        return {
          content: (
            <div className="flex max-w-xs flex-row items-center justify-between">
              <EhrOverview
                vendor={vendor}
                domain={domain}
                logo={logo}
                error={error}
                status={status}
                syncedAt={syncedAt}
              />
              <RefreshCwIcon
                className={`h-5 ${isDataImporting ? 'animate-spin' : ''}`}
              />
            </div>
          ),
          tooltipLabel: 'Import patients and appointments',
          onClick: performImport,
          ehrIntegrationPanelContent: (
            <EhrStatusDetails
              vendor={vendor}
              domain={domain}
              error={error}
              status={status}
              syncedAt={syncedAt}
            />
          ),
        };
      },
    )
    .otherwise(() => ({
      content: null,
      ehrIntegrationPanelContent: null,
      onClick: null,
      tooltipLabel: null,
    }));

  const errorFound =
    isSupportedBrowser &&
    (eluveExtExists === false || eluveExtVersionSatisfies === false);
  let showEhrImportComponent = isSupportedBrowser && Boolean(content);

  if (onlyShowIfError) {
    showEhrImportComponent = errorFound;
  }

  return (
    showEhrImportComponent && (
      <div
        onMouseEnter={() => {
          if (isImportAllowed) {
            setShowEhrIntegrationPanel(true);
          }
        }}
        onMouseLeave={() => {
          if (isImportAllowed) {
            setShowEhrIntegrationPanel(false);
          }
        }}
        className="w-full"
      >
        <div
          onClick={() => {
            if (onClick && !isDataImporting) {
              onClick();
            }
          }}
          className={onClick && !isDataImporting ? 'cursor-pointer' : ''}
        >
          {tooltipLabel ? (
            <TooltipLabel label={tooltipLabel}>
              <Box className="rounded-lg border bg-transparent p-3">
                {content}
              </Box>
            </TooltipLabel>
          ) : (
            <Box className="rounded-lg border bg-transparent p-3">
              {content}
            </Box>
          )}
        </div>
        {showEhrIntegrationPanel && ehrIntegrationPanelContent && (
          <div className="absolute z-50 w-72">
            <div className="h-6"></div>
            <Box className="bg-background rounded-lg border">
              <div className="m-2 ml-4 mr-4 flex flex-col ">
                <div className="items-center">
                  <P className="mb-3 mt-2 text-gray-600 md:text-xs">
                    EHR Integration
                  </P>
                </div>
                <hr className="h-px border-0 bg-gray-300" />
                {ehrIntegrationPanelContent}
              </div>
            </Box>
          </div>
        )}
      </div>
    )
  );
};
