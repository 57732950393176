import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { PlusIcon } from 'lucide-react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { QUERY_ROOT_ID, cacheUtils } from '@eluve/apollo-client';
import { useDialog } from '@eluve/blocks';
import {
  Button,
  Dialog,
  DialogContent,
  Form,
  FormField,
  FormItem,
  FormMessage,
  H2,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from '@eluve/components';
import { LlmModelTypesEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import { useNamedLogger } from '@eluve/logger';

import { allLlmModelArgsFragment } from './ModelsListPage';

export const getModelTypesQuery = graphql(`
  query getModelTypes {
    llmModelTypes {
      __typename
      name
    }
  }
`);

export const insertModelArgsMutation = graphql(`
  mutation insertModelArgs(
    $modelType: LlmModelTypesEnum!
    $args: jsonb
    $name: String
    $description: String
  ) {
    insertLlmModelArgsOne(
      object: {
        modelType: $modelType
        args: $args
        name: $name
        description: $description
        isGlobalDefault: false
        isActive: true
      }
    ) {
      __typename
      id
      modelType
      args
      name
      description
      isGlobalDefault
      isActive
    }
  }
`);

export const InsertModelArgsSchema = z.object({
  modelType: z.string(),
  args: z
    .string()
    .optional()
    .refine((data) => {
      if (!data) return true;

      try {
        JSON.parse(data);
        return true;
      } catch {
        return false;
      }
    }, 'The args field must be valid JSON.'),

  name: z.string().optional(),
  description: z.string().optional(),
});

export type InsertModelArgs = z.infer<typeof InsertModelArgsSchema>;

export const AddModelArgsDialog: React.FC = () => {
  const logger = useNamedLogger('add-model-args-dialog');
  const { isDialogOpen, toggleDialog, setIsDialogOpen } = useDialog();

  const { data: modelTypes } = useQuery(getModelTypesQuery);

  const [insertModelArgs] = useMutation(insertModelArgsMutation, {
    onCompleted: () => {
      toast.success('Successfully added model args');
      form.reset();
      toggleDialog();
    },
    onError: () => toast.error('Failed to add model args'),
    optimisticResponse: (input) => ({
      insertLlmModelArgsOne: {
        __typename: 'LlmModelArgs' as const,
        id: 'temp-id',
        modelType: input.modelType!,
        args: input.args!,
        name: input.name!,
        description: input.description!,
        isActive: true,
        isGlobalDefault: false,
      },
    }),
    update: (_cache, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: allLlmModelArgsFragment,
          key: QUERY_ROOT_ID,
        },
        (existing) => {
          return !data?.insertLlmModelArgsOne
            ? existing
            : {
                __typename: 'query_root' as const,
                llmModelArgs: (existing?.llmModelArgs || []).concat(
                  data.insertLlmModelArgsOne,
                ),
              };
        },
      );
    },
  });

  const form = useForm<InsertModelArgs>({
    resolver: zodResolver(InsertModelArgsSchema),
    mode: 'onChange',
  });

  const onSubmit = async (data: InsertModelArgs) => {
    try {
      await insertModelArgs({
        variables: {
          modelType: data.modelType as LlmModelTypesEnum,
          args: data.args ? JSON.parse(data.args) : null,
          name: data.name,
          description: data.description,
        },
      });
    } catch (e) {
      toast.error('Failed to add model args');
    }
  };

  return (
    <>
      <Button onClick={toggleDialog}>
        <PlusIcon className="mr-2 h-5" />
        Add model args
      </Button>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="flex max-h-[80vh] flex-col gap-5 overflow-y-scroll p-8">
          <H2>Add Model Args</H2>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit, (invalid) =>
                logger.debug(
                  `Invalid form submission attempted: ${JSON.stringify(invalid)}`,
                ),
              )}
              className="flex flex-col gap-4"
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="max-w-lg">
                    <Label>Name</Label>
                    <Input
                      className="bg-white/5"
                      placeholder="Name"
                      {...field}
                    />
                    {error && (
                      <FormMessage className="mt-4">
                        {error.message}
                      </FormMessage>
                    )}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="max-w-lg">
                    <Label>Description</Label>
                    <Textarea
                      className="bg-white/5"
                      placeholder="Description"
                      {...field}
                    />
                    {error && (
                      <FormMessage className="mt-4">
                        {error.message}
                      </FormMessage>
                    )}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="modelType"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="max-w-lg">
                    <Label>Model Type</Label>
                    <Select
                      value={field.value}
                      onValueChange={(val) => field.onChange(val)}
                    >
                      <SelectTrigger className="w-[180px]">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {modelTypes?.llmModelTypes.map((modelType) => (
                          <SelectItem
                            key={modelType.name}
                            value={modelType.name}
                          >
                            {modelType.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    {error && (
                      <FormMessage className="mt-4">
                        {error.message}
                      </FormMessage>
                    )}
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="args"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="max-w-lg">
                    <Label>Args (JSON)</Label>
                    <Textarea
                      className="bg-white/5"
                      placeholder=""
                      {...field}
                    />
                    {error && (
                      <FormMessage className="mt-4">
                        {error.message || 'The args field must be valid JSON.'}
                      </FormMessage>
                    )}
                  </FormItem>
                )}
              />

              <Button
                type="submit"
                className="w-fit"
                disabled={!form.formState.isDirty}
              >
                Add
              </Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};
