import { useQuery } from '@apollo/client';
import React from 'react';

import { Box, ColDefBuilder, DataTable, PageTitle } from '@eluve/components';
import { GlobalFeatureFlagsEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';

import {
  GlobalFeatureFlagsToggle,
  globalFeatureFlagStatusFragment,
} from './GlobalFeatureFlagToggle';

export const globalFeatureFlagsQuery = graphql(
  `
    query getGlobalFeatureFlagsQuery {
      globalFeatureFlags(orderBy: { name: ASC }) {
        __typename
        name
        description
        globalFeatureFlagStatus {
          ...GlobalFeatureFlagStatus
        }
      }
    }
  `,
  [globalFeatureFlagStatusFragment],
);

type TableRow = {
  name: GlobalFeatureFlagsEnum;
  description: string;
  isEnabled: boolean;
  updatedAt: string;
};

const colDefs = new ColDefBuilder<TableRow>()
  .defaultSortable('name')
  .defaultSortable('description')
  .defaultSortable('isEnabled', {
    label: 'Is Enabled',
    cellRenderer: (row) => {
      return <GlobalFeatureFlagsToggle flag={row.name} />;
    },
  })
  .dateSortable('updatedAt', 'Updated At')
  .build();

export const GlobalFeatureFlagsPage: React.FC = () => {
  const { data } = useQuery(globalFeatureFlagsQuery);

  const rows = (data?.globalFeatureFlags ?? []).map<TableRow>((flag) => ({
    isEnabled: flag.globalFeatureFlagStatus?.isEnabled ?? false,
    description: flag.description ?? '',
    name: flag.name as GlobalFeatureFlagsEnum,
    updatedAt: flag.globalFeatureFlagStatus?.updatedAt ?? '',
  }));

  return (
    <div>
      <PageTitle>Global Feature Flags</PageTitle>

      <Box>
        <DataTable
          data={rows}
          columns={colDefs}
          isPaginated={false}
          enableGlobalSearch
          isCompact
        />
      </Box>
    </div>
  );
};
