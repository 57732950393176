import { useLocalStorage } from '@uidotdev/usehooks';

export const SELECTED_MICROPHONE_DEVICE_LOCAL_STORAGE_KEY = 'selectedMic';

export const useSelectedMicrophoneDevice = () => {
  return useLocalStorage<string | null>(
    SELECTED_MICROPHONE_DEVICE_LOCAL_STORAGE_KEY,
    null,
  );
};

export const getSelectedMicrophoneDevice = () => {
  return localStorage.getItem(SELECTED_MICROPHONE_DEVICE_LOCAL_STORAGE_KEY);
};
