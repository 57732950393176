import { ApolloClient } from '@apollo/client';
import { fromPromise } from 'xstate';

import { graphql } from '@eluve/graphql.tada';

export const setAppointmentStartedAtMutation = graphql(`
  mutation setAppointmentStartedAt($id: uuid!) {
    updateAppointments(
      where: { startedAt: { _isNull: true }, id: { _eq: $id } }
      _set: { startedAt: NOW, status: ACTIVE }
    ) {
      returning {
        id
        startedAt
        status
      }
    }
  }
`);

interface Input {
  apolloClient: ApolloClient<unknown>;
  appointmentId: string;
}

export const setAppointmentStartedAt = fromPromise<unknown, Input>(
  async ({ input }) => {
    const { apolloClient, appointmentId } = input;

    await apolloClient.mutate({
      mutation: setAppointmentStartedAtMutation,
      variables: { id: appointmentId },
    });
  },
);
