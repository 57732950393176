import noop from 'lodash/noop';
import { fromCallback } from 'xstate';

import { sessionSoundPlayer } from '@eluve/sounds';

export type SoundEvents = {
  type:
    | 'PLAY.PAUSE'
    | 'PLAY.STOP'
    | 'PLAY.START'
    | 'PLAY.ALERT'
    | 'PLAY.DISCLAIMER'
    | 'PLAY.DISCLAIMER.ENDED';
};

type Input = {
  enabled: boolean;
  soundPlayer: typeof sessionSoundPlayer;
};

export const playSoundEffects = fromCallback<SoundEvents, Input>(
  ({ receive, input: { enabled, soundPlayer }, sendBack }) => {
    receive((event) => {
      if (!enabled) {
        return;
      }
      if (event.type === 'PLAY.PAUSE') {
        soundPlayer.play('pauseStop');
      }
      if (event.type === 'PLAY.STOP') {
        soundPlayer.play('pauseStop');
      }
      if (event.type === 'PLAY.START') {
        soundPlayer.play('start');
      }
      if (event.type === 'PLAY.ALERT') {
        soundPlayer.play('alert');
      }
      if (event.type === 'PLAY.DISCLAIMER') {
        soundPlayer.play('disclaimer');
        soundPlayer.on('disclaimer', 'end', () => {
          sendBack({ type: 'PLAY.DISCLAIMER.ENDED' });
        });
      }
    });
    return noop;
  },
);
