import { useMutation } from '@apollo/client';
import { useLocalStorage } from '@uidotdev/usehooks';
import { LanguagesIcon } from 'lucide-react';
import React from 'react';
import { toast } from 'sonner';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  Box,
  Combobox,
  ComboboxDropdown,
  ComboboxOption,
  ComboboxSelectButton,
  ComboboxSelectCheck,
} from '@eluve/components';
import { appointmentLanguagesFragment } from '@eluve/frontend-appointment-hooks';
import { useAppointmentId } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';
import {
  SupportedLanguages,
  SupportedLanguagesByCode,
} from '@eluve/language-utils';
import { useUserIdFromSession } from '@eluve/session-helpers';
import { useTenantIdFromParams } from '@eluve/session-helpers';

const setAppointmentLanguageMutation = graphql(
  `
    mutation setAppointmentLanguage(
      $tenantId: uuid!
      $appointmentId: uuid!
      $inputLanguage: String!
      $outputLanguage: String!
    ) {
      updateAppointmentsByPk(
        pkColumns: { tenantId: $tenantId, id: $appointmentId }
        _set: { inputLanguage: $inputLanguage, outputLanguage: $outputLanguage }
      ) {
        __typename
        id
        inputLanguage
        outputLanguage
      }
    }
  `,
  [],
);

type LanguageSelectorProps = {
  languageType: 'input' | 'output';
};

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  languageType,
}) => {
  const appointmentId = useAppointmentId();
  const tenantId = useTenantIdFromParams() ?? '';
  const userId = useUserIdFromSession();

  const storageKey = `languageUsage_${userId}_${tenantId}`;

  const [languageUsage, setLanguageUsage] = useLocalStorage<
    Record<string, number>
  >(storageKey, {});

  const updateLanguageUsage = (languageCode: string) => {
    const updatedUsage = {
      ...languageUsage,
      [languageCode]: (languageUsage[languageCode] || 0) + 1,
    };
    setLanguageUsage(updatedUsage);
  };

  const sortedLanguages = Object.entries(SupportedLanguages).sort(
    ([, codeA], [, codeB]) =>
      (languageUsage[codeB] || 0) - (languageUsage[codeA] || 0),
  );

  const appointment = useCompleteFragment({
    fragment: appointmentLanguagesFragment,
    key: {
      id: appointmentId,
    },
  });
  const selectedLanguageCode =
    languageType === 'input'
      ? appointment?.inputLanguage
      : appointment?.outputLanguage;

  const [setAppointmentLanguage] = useMutation(setAppointmentLanguageMutation, {
    optimisticResponse: (input) => ({
      updateAppointmentsByPk: {
        __typename: 'Appointments' as const,
        id: appointmentId,
        inputLanguage:
          languageType === 'input' ? selectedLanguageCode : input.inputLanguage,
        outputLanguage:
          languageType === 'input'
            ? input.outputLanguage
            : selectedLanguageCode,
      },
    }),
    onError: () => toast.error('Failed to update language settings.'),
  });

  return (
    <Combobox
      disabled={
        appointment?.status !== 'NOT_STARTED' && languageType === 'input'
      }
    >
      <ComboboxSelectButton className="hover:bg-gray-3 w-full overflow-hidden border-transparent">
        <Box hStack className="w-full overflow-hidden">
          <LanguagesIcon size={16} />
          {SupportedLanguagesByCode[selectedLanguageCode]}
        </Box>
      </ComboboxSelectButton>
      <ComboboxDropdown searchPlaceholder="Select Tenant">
        {sortedLanguages.map(([languageName, availableLanguageCode]) => (
          <ComboboxOption
            key={availableLanguageCode}
            onSelect={async () => {
              await setAppointmentLanguage({
                variables: {
                  tenantId,
                  appointmentId,
                  inputLanguage:
                    languageType === 'input'
                      ? availableLanguageCode
                      : appointment?.inputLanguage,
                  outputLanguage:
                    languageType === 'input'
                      ? appointment?.outputLanguage
                      : availableLanguageCode,
                },
              });
              updateLanguageUsage(availableLanguageCode);
            }}
          >
            <ComboboxSelectCheck
              className="mr-2"
              selected={availableLanguageCode === selectedLanguageCode}
            />
            {languageName}
          </ComboboxOption>
        ))}
      </ComboboxDropdown>
    </Combobox>
  );
};
