import { Volume2Icon } from 'lucide-react';

import { Box } from '@eluve/components';
import { AppointmentName } from '@eluve/smart-blocks';

export const PlayingDisclaimer: React.FC = () => {
  return (
    <Box vStack className="w-full justify-stretch gap-8 pt-4">
      <AppointmentName readonly />
      <Box center className="bg-mainBackground h-[240px] w-full rounded-lg">
        <Box center className="bg-brand-12 rounded-full p-4">
          <Volume2Icon className="size-14 text-white" />
        </Box>
      </Box>
      <h2 className="text-lg font-bold lg:text-[28px]">Disclaimer</h2>
      <p className="text-gray-12">[Disclaimer transcript goes here.]</p>
    </Box>
  );
};
