import React from 'react';

import { P, ToggleGroup, ToggleGroupItem } from '@eluve/components';

export interface RatingSelectorProps {
  labelFor0?: string;
  labelFor5?: string;
  labelFor10?: string;
  onRatingSelected: (rating: number | null) => void;
}

export const RatingSelector: React.FC<RatingSelectorProps> = ({
  labelFor0,
  labelFor5,
  labelFor10,
  onRatingSelected,
}) => {
  return (
    <div className="flex flex-col gap-1">
      <ToggleGroup
        type="single"
        variant="outline"
        className="items-start gap-0"
        onValueChange={(value) => {
          if (value.length) {
            onRatingSelected(Number(value));
          } else {
            onRatingSelected(null);
          }
        }}
      >
        {Array.from(Array(11).keys()).map((num) => (
          <div key={num} className="flex w-full flex-col">
            <ToggleGroupItem
              key={num}
              value={`${num}`}
              aria-label={`Select ${num}`}
              className={`rounded-none border-l-0 px-1 sm:px-3
            ${num === 0 && 'rounded-none rounded-l-sm border-l'}
            ${num === 10 && 'rounded-none rounded-r-sm border-r'}
          `}
            >
              {num}
            </ToggleGroupItem>
          </div>
        ))}
      </ToggleGroup>

      <div className="flex w-full justify-between">
        <P className="w-full">{labelFor0}</P>
        <P className="w-full text-center">{labelFor5}</P>
        <P className="w-full text-end">{labelFor10}</P>
      </div>
    </div>
  );
};
