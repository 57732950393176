import React from 'react';
import { useMedia } from 'react-use';

import { Box, Label, Switch } from '@eluve/components';
import { useIsTenantAdminFromSession } from '@eluve/session-helpers';

import { useShowAllAppointmentsToggle } from '../hooks/useShowAllAppointmentsToggle';

export const ShowAllAppointmentsToggle: React.FC = () => {
  const isTenantAdmin = useIsTenantAdminFromSession();
  const [showAllAppointments, setShowAllAppointments] =
    useShowAllAppointmentsToggle();
  const isPhoneSize = useMedia('(max-width: 425px)');

  if (!isTenantAdmin) {
    return null;
  }

  return (
    <Box hStack className="w-full sm:w-max sm:border-r sm:pr-6">
      <Switch
        isCompact={isPhoneSize}
        checked={showAllAppointments}
        onCheckedChange={(checked) => setShowAllAppointments(checked)}
      />
      <Label className="text-nowrap text-sm md:text-base">
        Show all appointments
      </Label>
    </Box>
  );
};
