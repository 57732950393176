import React from 'react';
import { z } from 'zod';

import { ColDefBuilder, DataTable, H3 } from '@eluve/components';
import { pfshSchema } from '@eluve/llm-outputs';

import { OutputParsingError } from './OutputParsingError';
import { OutputTitle } from './OutputTitle';

export interface PfshOutputProps {
  content: unknown;
}

type MedicationRow = z.infer<typeof pfshSchema>['medications'][number];

const columns = new ColDefBuilder<MedicationRow>()
  .defaultSortable('name')
  .defaultSortable('dose')
  .defaultSortable('frequency')
  .build();

export const PfshOutput: React.FC<PfshOutputProps> = ({ content }) => {
  const pfsh = pfshSchema.safeParse(content);

  if (!pfsh.success) {
    return <OutputParsingError />;
  }

  return (
    <div className="space-y-4">
      <OutputTitle title="Past, Family and Social History (PFSH)" />

      <div className="space-y-2">
        <H3>Allergies</H3>
        <ul>
          {pfsh.data.allergies.map((allergy) => (
            <li key={allergy}>{allergy}</li>
          ))}
        </ul>
      </div>
      <div>
        <H3>Medications</H3>
        <DataTable
          columns={columns}
          data={pfsh.data.medications}
          isPaginated={false}
        />
      </div>
      <div>
        <H3>Existing Conditions</H3>
        <ul className="list-inside list-disc">
          {pfsh.data.existingConditions.map((condition) => (
            <li key={condition}>{condition}</li>
          ))}
        </ul>
      </div>
      <div>
        <H3>Surgeries</H3>
        <ul className="list-inside list-disc">
          {pfsh.data.surgeries.map((surgery) => (
            <li key={surgery}>{surgery}</li>
          ))}
        </ul>
      </div>
      <div>
        <H3>Social History</H3>
        <ul className="list-inside list-disc">
          {pfsh.data.socialHistory.map((social) => (
            <li key={social}>{social}</li>
          ))}
        </ul>
      </div>
      <div>
        <H3>Family History</H3>
        <ul className="list-inside list-disc">
          {pfsh.data.familyHistory.map((family) => (
            <li key={family}>{family}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
