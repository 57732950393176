import chunk from 'lodash/chunk';
import { z } from 'zod';

import { ERRORS } from '../../errors';
import { AppointmentModel } from '../../models/appointment';
import { PatientType } from '../../models/patient';
import { CanSyncToEhrArgs, GetEhrDataOptions, Vendor } from '../vendor';

import { logo } from './logo';
import { convertTebraDataToAppointmentModel } from './tebra.appointment';
import { Appointment, appointmentSchema, patientSchema } from './types';

const appointmentData = z.object({
  appointments: z.array(z.unknown()),
});

const TEST_PATIENT_IDS = ['176788459'];

export class TebraVendor implements Vendor {
  getChart = (data: unknown) => {
    throw new Error('Method not implemented.');
  };

  getAppointments = (
    data: unknown,
    options: GetEhrDataOptions = {},
  ): AppointmentModel[] => {
    const rawAppointments = appointmentData.parse(data);
    const appointments: AppointmentModel[] = [];
    for (const appointment of rawAppointments.appointments) {
      const appointmentParseResult = appointmentSchema.safeParse(appointment);
      if (!appointmentParseResult.success) {
        throw new Error(
          `Invalid appointment data from 3rd party EHR: ${appointmentParseResult.error}`,
        );
      }
      const appointmentModel = convertTebraDataToAppointmentModel(
        appointmentParseResult.data,
      );
      if (options.mockPii) {
        if (
          TEST_PATIENT_IDS.includes(appointmentModel.externalPatientId ?? '')
        ) {
          appointments.push(appointmentModel);
        }
      } else {
        appointments.push(appointmentModel);
      }
    }

    return appointments;
  };

  getPatient = (
    data: unknown,
    options: GetEhrDataOptions = {},
  ): PatientType | null => {
    const patient = patientSchema.parse(data);
    if (options.mockPii) {
      if (!TEST_PATIENT_IDS.includes(`${patient.patientId}`)) {
        return null;
      }
    }

    return {
      firstName: patient.firstName ?? '',
      lastName: patient.lastName ?? '',
      externalPatientId: `${patient.patientId}`,
      dateOfBirth: patient.dob ?? null,
      email: patient.email ?? null,
      homePhone: patient.phoneNumber ?? null,
      cellPhone: patient.phoneNumber ?? null,
      workPhone: patient.phoneNumber ?? null,
      rawData: data as Record<string, unknown>,
    };
  };

  getPatientEhrUrl = ({
    domain,
    externalPatientId,
  }: {
    domain: string;
    externalPatientId?: string;
  }): string => {
    return `https://${domain}/facesheet-ui/#/Facesheet/${externalPatientId}`;
  };

  getLogo = () => logo;

  getHomeUrl = (domain: string): string => {
    return `https://${domain}`;
  };

  fetchAppointments = async ({
    domain,
    request,
    startDate,
    endDate,
  }: {
    domain: string;
    request: typeof fetch;
    startDate: number;
    endDate: number;
  }) => {
    const query = [
      'maxDaysPerPage=2',
      `minDate=${startDate}`,
      `maxDate=${endDate}`,
    ].join('&');
    const baseUrl = `https://${domain}/dashboard-calendar-ui/api/Appointment/?${query}`;

    let allResults: Appointment[] = [];
    let nextPageToken = '';

    do {
      const url: string = nextPageToken
        ? `${baseUrl}&pageToken=${nextPageToken}`
        : baseUrl;
      const appointmentResponse = await request(url);

      if (!appointmentResponse.ok) {
        if (appointmentResponse.status === 401) {
          return { ok: false, error: ERRORS.NOT_LOGGED_IN };
        }
        return { ok: false, error: ERRORS.FAILED_TO_IMPORT };
      }

      const body = await appointmentResponse.json();
      const { results, nextPageToken: newNextPageToken } = body;
      allResults = allResults.concat(results);
      nextPageToken = newNextPageToken;
    } while (nextPageToken);

    return {
      ok: true,
      data: {
        response: allResults,
        timezone: '+00:00',
      },
    };
  };

  fetchPatientsByIds = async ({
    ids,
    domain,
    request,
  }: {
    ids: number[];
    domain: string;
    request: typeof fetch;
  }) => {
    const patients: any[] = [];
    const batchSize = 3;

    const idChunks = chunk(ids, batchSize);

    let error = '';
    for (const chunk of idChunks) {
      try {
        const responses = await Promise.all(
          chunk.map(async (id) => {
            const response = await request(
              `https://${domain}/facesheet-ui/api/Facesheet/patient/${id}/header`,
            );

            if (!response.ok) {
              error = `Could not fetch patient with id ${id}. Status: ${response.status}`;
              return null;
            }

            return response.json();
          }),
        );

        patients.push(...responses.filter(Boolean));
      } catch (error: unknown) {
        return { ok: false, error: (error as Error)?.message };
      }
    }

    return { ok: true, data: patients, error };
  };

  canSyncNoteToEhr(data: CanSyncToEhrArgs): boolean {
    return Boolean(data.externalPatientId);
  }

  canSignNoteInEhr(): boolean {
    return false;
  }

  canSyncWithManualChartUrl(): boolean {
    return true;
  }

  getChartIdFromChartUrl = (chartUrl: string): string | null => {
    const regex = /\/notes\/(\d+)/;
    const match = chartUrl.match(regex);

    if (match && match[1]) {
      return match[1];
    }

    return null;
  };
}
