import { graphql } from '@eluve/graphql.tada';

export const UserFragment = graphql(`
  fragment User on Users @_unmask {
    __typename
    id
    firstName
    lastName
    email
    displayName
  }
`);

export const TenantUserFragment = graphql(
  `
    fragment TenantUser on TenantUsers @_unmask {
      __typename
      tenantId
      userId
      createdAt
      lastAccessedAt
      role
      user {
        ...User
      }
    }
  `,
  [UserFragment],
);

export const TenantUsersFragment = graphql(
  `
    fragment TenantUsers on Tenants @_unmask {
      tenantUsers {
        ...TenantUser
      }
    }
  `,
  [TenantUserFragment],
);

export const AddUserToTenantMutation = graphql(
  `
    mutation AddUserToTenant($tenantId: uuid!, $userId: uuid!, $role: String!) {
      insertTenantUsersOne(
        object: { tenantId: $tenantId, userId: $userId, role: $role }
      ) {
        ...TenantUser
      }
    }
  `,
  [TenantUserFragment],
);
