import { graphql } from '@eluve/graphql.tada';

export const userSettingsFragment = graphql(`
  fragment UserSetting on UserSettings @_unmask {
    __typename
    userId
    promptTemplateId
    promptTemplateIdOrDefault
    enableSoundEffects
  }
`);

export const userSettingsForUserFragment = graphql(
  `
    fragment UserSettingsForUser on Users @_unmask {
      __typename
      id
      userSettings {
        ...UserSetting
      }
    }
  `,
  [userSettingsFragment],
);

export const activeSOAPPromptTemplatesFragment = graphql(`
  fragment ActiveSOAPPromptTemplates on query_root @_unmask {
    __typename
    activePromptTemplates(
      where: { outputType: { _eq: "SOAP_NOTE" } }
      orderBy: { name: ASC }
    ) {
      __typename
      id
      isCurrentDefault
      name
      description
    }
  }
`);

export const getUserSettingsQuery = graphql(
  `
    query getUserSettings($userId: uuid!) {
      usersByPk(id: $userId) {
        ...UserSettingsForUser
      }
      ...ActiveSOAPPromptTemplates
    }
  `,
  [userSettingsForUserFragment, activeSOAPPromptTemplatesFragment],
);

export const upsertPromptTemplateIdMutation = graphql(
  `
    mutation upsertPromptTemplateIdForUser($promptTemplateId: uuid!) {
      insertUserSettingsOne(
        object: { promptTemplateId: $promptTemplateId }
        onConflict: {
          constraint: user_settings_pkey
          updateColumns: [promptTemplateId]
        }
      ) {
        userId
        __typename
        user {
          ...UserSettingsForUser
        }
      }
    }
  `,
  [userSettingsForUserFragment],
);

export const upsertEnableSoundEffectsMutation = graphql(
  `
    mutation upsertEnableSoundEffects($enableSoundEffects: Boolean!) {
      insertUserSettingsOne(
        object: { enableSoundEffects: $enableSoundEffects }
        onConflict: {
          constraint: user_settings_pkey
          updateColumns: [enableSoundEffects]
        }
      ) {
        ...UserSetting
      }
    }
  `,
  [userSettingsFragment],
);
