import { useCompleteFragment } from '@eluve/apollo-client';
import { tenantFeatureFlagFragment } from '@eluve/frontend-feature-tenants';
import { FeatureFlagsEnum } from '@eluve/graphql-types';
import { useTenantIdFromParams } from '@eluve/session-helpers';

/**
 * Indicates whether the feature flag in question is active for a tenant.
 * The tenant is derived from the current auth session
 */
export const useIsFeatureFlagEnabled = (flag: FeatureFlagsEnum) => {
  const tenantId = useTenantIdFromParams();

  const data = useCompleteFragment({
    fragment: tenantFeatureFlagFragment,
    key: {
      tenantId,
      flag,
    },
    strict: false,
  });

  return data?.isEnabled ?? false;
};
