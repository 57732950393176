import { graphql } from '@eluve/graphql.tada';

export const GetTenantsList = graphql(`
  query getTenantsList {
    assignedTenantUsers(orderBy: { name: ASC }) {
      __typename
      tenantId
      role
      name
      memberSince
      tenantCreatedAt
    }
  }
`);
