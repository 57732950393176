import React from 'react';
import { useParams } from 'react-router-dom';

import { H3, P } from '@eluve/components';

import { PromptTemplateUsers } from './PromptTemplateUsers';

export interface PromptTemplateUsersPageProps {}

export const PromptTemplateUsersPage: React.FC<
  PromptTemplateUsersPageProps
> = () => {
  const { promptTemplateId } = useParams() as { promptTemplateId: string };
  return (
    <div>
      <H3>Users</H3>
      <P className="font-normal">
        These users currently have this prompt template set as their default
      </P>
      <PromptTemplateUsers promptTemplateId={promptTemplateId} />
    </div>
  );
};
