import { useSuspenseQuery } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { ColDefBuilder, DataTable, H3, P, PageTitle } from '@eluve/components';
import { formatToLocale } from '@eluve/date-utils';
import { graphql } from '@eluve/graphql.tada';

import { RemoveArtifactFromDatasetAction } from './RemoveArtifactFromDatasetAction';

const getDatasetDetailsQuery = graphql(`
  query getDatasetDetails($datasetId: uuid!) {
    evalDatasetsByPk(id: $datasetId) {
      __typename
      id
      name
      description
      createdAt
      datasetSourceArtifacts {
        __typename
        id
        createdAt
        sourceArtifact {
          __typename
          id
          name
          description
          content
        }
      }
    }
  }
`);

type DatasetArtifactRow = {
  id: string;
  name: string | null;
  description: string | null;
  content: string;
  createdAt: string;
  datasetSourceArtifactId: string;
};

export const DatasetDetailsPage: React.FC = () => {
  const { datasetId } = useParams() as {
    datasetId: string;
  };

  const artifactColumns = new ColDefBuilder<DatasetArtifactRow>()
    .linkSortable(
      'name',
      (row) => `/admin/fact-verification/artifacts/${row.id}`,
    )
    .defaultSortable('description')
    .defaultSortable('content', {
      cellRenderer: (row) => (
        <P>
          {row.content.length > 300
            ? `${row.content.substring(0, 300)}...`
            : row.content}
        </P>
      ),
    })
    .colDef({
      header: 'Actions',
      cell: ({
        row: {
          original: { datasetSourceArtifactId },
        },
      }) => (
        <RemoveArtifactFromDatasetAction
          datasetSourceArtifactId={datasetSourceArtifactId}
          datasetId={datasetId}
        />
      ),
    })
    .build();

  const {
    data: { evalDatasetsByPk: datasetData },
  } = useSuspenseQuery(getDatasetDetailsQuery, {
    variables: {
      datasetId,
    },
  });

  const artifactRows: DatasetArtifactRow[] = useMemo(
    () =>
      orderBy(
        datasetData?.datasetSourceArtifacts.map((d) => {
          return {
            id: d.sourceArtifact.id,
            name: d.sourceArtifact.name,
            description: d.sourceArtifact.description,
            content: d.sourceArtifact.content,
            createdAt: d.createdAt,
            datasetSourceArtifactId: d.id,
          };
        }),
        (m) => m.createdAt,
        ['desc'],
      ),
    [datasetData?.datasetSourceArtifacts],
  );

  if (!datasetData) {
    toast.error('Failed to load dataset');
    throw new Error('Failed to load dataset');
  }

  return (
    <div className="mt-4 flex flex-col gap-3">
      <PageTitle
        subtitle={
          <div className="flex flex-col gap-2">
            <P className="text-black">{datasetData.description}</P>
            <P>Created at: {formatToLocale(datasetData.createdAt)}</P>
          </div>
        }
      >
        {datasetData.name}
      </PageTitle>

      <div>
        <H3 p="pb-1" cn="hover:cursor-pointer">
          Source Artifacts
        </H3>
        <DataTable columns={artifactColumns} data={artifactRows} />
      </div>
    </div>
  );
};
