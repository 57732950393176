import { cacheUtils } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';

const appointmentWithStatus = graphql(`
  fragment AppointmentWithStatus on Appointments {
    __typename
    id
    status
  }
`);

export const isAppointmentNotStarted = (appointmentId: string) => {
  const appointment = cacheUtils.readFragment({
    fragment: appointmentWithStatus,
    key: {
      id: appointmentId,
    },
    strict: false,
  });

  return appointment?.status !== 'ACTIVE';
};
