import { fromPromise } from 'xstate';

import { Logger } from '@eluve/logger';

import { SWAP_FILE_EXT } from '../../opfs';
import { FileDriftReport, GetFileReferences } from '../types';

export const calculateDrift = fromPromise<
  FileDriftReport,
  { fileRefs: GetFileReferences; logger: Logger }
>(async ({ input }) => {
  const {
    fileRefs: { dbFileRecords, opfsFiles },
    logger,
  } = input;

  if (opfsFiles.type === 'directoryExists') {
    const missingInDb = opfsFiles.files.filter(
      (x) => !dbFileRecords.some((f) => f.id === x.name),
    );

    // Don't add if there is both a swap and a main file
    const swapFiles = missingInDb.filter((x) => x.name.endsWith(SWAP_FILE_EXT));

    if (swapFiles.length) {
      logger.info('Found swap files', {
        count: swapFiles.length,
      });

      logger.info('Swap files', {
        files: swapFiles.map((s) => [
          s.file.name,
          missingInDb.find(
            (x) => x.name === s.file.name.replace(SWAP_FILE_EXT, ''),
          )?.name,
        ]),
      });
    }

    // TODO(jesse)[ELU-2105]: Introduce logic for handling swap files
    // For now we'll just ignore them
    const filesToAdd = missingInDb.filter(
      (f) => !swapFiles.some((s) => (s.appointmentId = f.appointmentId)),
    );

    const missingInOpfs = dbFileRecords.filter(
      (f) => !opfsFiles.files.some((x) => x.name === f.id),
    );
    if (missingInOpfs.length) {
      logger.warn(
        'Found files in db that are missing in OPFS. These references will be removed',
        {
          count: missingInOpfs.length,
        },
      );
    }

    if (filesToAdd.length === 0 && missingInOpfs.length === 0) {
      return {
        type: 'NoDriftDetected',
      };
    }

    return {
      type: 'DriftDetected',
      missingInIdb: filesToAdd.map((f) => ({
        appointmentId: f.appointmentId,
        id: f.name,
      })),
      missingInOpfs: missingInOpfs.map((f) => f.id),
    };
  } else {
    logger.warn(`Unable to calculate file drift: ${opfsFiles.type}`);
    return {
      type: 'NoDriftDetected',
    };
  }
});
