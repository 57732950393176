import { useSuspenseQuery } from '@apollo/client';
import { PlusIcon } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';

import { Button, ColDefBuilder, DataTable, PageTitle } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

const getDatasetsQuery = graphql(`
  query getDatasets {
    evalDatasets {
      __typename
      id
      name
      description
      createdAt
    }
  }
`);

type ArtifactRow = {
  id: string;
  name: string | null;
  description: string | null;
  createdAt: string;
};

const columns = new ColDefBuilder<ArtifactRow>()
  .linkSortable('name', (row) => `./${row.id}`)
  .defaultSortable('description')
  .dateSortable('createdAt')
  .build();

export const DatasetsListPage: React.FC = () => {
  const {
    data: { evalDatasets: datasets },
  } = useSuspenseQuery(getDatasetsQuery);

  return (
    <div>
      <div className="space-between flex w-full items-center">
        <div className="flex-1">
          <PageTitle>Datasets</PageTitle>
        </div>
        <Link to="create">
          <Button>
            <PlusIcon className="mr-2 h-5" />
            Create a new dataset
          </Button>
        </Link>
      </div>
      <DataTable
        columns={columns}
        data={datasets}
        initialSortingState={[
          {
            id: 'createdAt',
            desc: true,
          },
        ]}
      />
    </div>
  );
};
