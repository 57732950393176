import { useLazyQuery } from '@apollo/client';
import sumBy from 'lodash/sumBy';
import { Loader2, Search } from 'lucide-react';
import React, { useMemo, useState } from 'react';
import { useMount } from 'react-use';
import { match } from 'ts-pattern';

import {
  Box,
  Button,
  ColDefBuilder,
  DataTable,
  PageTitle,
  ReskinContent,
  ReskinHeader,
  ReskinMain,
  Skeleton,
  TableSkeleton,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useNamedLogger } from '@eluve/logger';

import { FilesActionCell } from './FilesActionCell';
import { FileRow, readAppointmentFilesFromOpfs } from './opfs';
import { useUserFileSystemStore } from './user-file-system.store';

const appointmentFragment = graphql(`
  fragment Appointment on Appointments @_unmask {
    __typename
    id
    name
    status
    createdAt
    updatedAt
  }
`);

const appointmentsQuery = graphql(
  `
    query getAppointmentsByIds($ids: [uuid!]!) {
      appointments(where: { id: { _in: $ids } }) {
        ...Appointment
      }
    }
  `,
  [appointmentFragment],
);

export interface AppointmentFilesListProps {}

type AppointmentWithFiles = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  appointmentDir: FileSystemDirectoryHandle;
  files: FileRow[];
  onLocalFilesUpdated: (id: string) => Promise<void>;
};

type Row = AppointmentWithFiles & { fileCount: number };

const columns = new ColDefBuilder<Row>()
  .detailsLink((row) => `../appointments/${row.id}`, 'Details')
  .defaultSortable('id', 'Appointment Id')
  .defaultSortable('name')
  .dateSortable('createdAt', 'Created At')
  .dateSortable('updatedAt', 'Updated At')
  .defaultSortable('fileCount', 'File Count')
  .colDef({
    header: 'Actions',
    cell: ({ row }) => {
      return (
        <FilesActionCell
          appointmentId={row.original.id}
          files={row.original.files}
          appointmentDir={row.original.appointmentDir}
          onLocalFilesUpdated={row.original.onLocalFilesUpdated}
        />
      );
    },
  })
  .build();

export const AppointmentFilesList: React.FC<AppointmentFilesListProps> = () => {
  const logger = useNamedLogger('AppointmentFilesList');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const fileSystem = useUserFileSystemStore((state) => state.userFileSystem);
  const [appointmentFiles, setAppointmentFiles] = useState<
    Map<string, AppointmentWithFiles>
  >(new Map<string, AppointmentWithFiles>());

  const [getAppointmentsByIds] = useLazyQuery(appointmentsQuery);

  const totalFiles = sumBy(
    Array.from(appointmentFiles.values()),
    (f) => f.files.length,
  );

  const onRemoveAppointmentFiles = async (id: string) => {
    setAppointmentFiles((prev) => {
      const newMap = new Map(prev);
      newMap.delete(id);
      return newMap;
    });
    await scanFiles();
  };

  const scanFiles = async () => {
    if (fileSystem.type !== 'available') {
      return;
    }

    setIsLoading(true);

    const { appointmentsDir } = fileSystem;

    const scannedAppointmentFiles = new Map<string, AppointmentWithFiles>();
    let dirCount = 0;

    const filePromises: Promise<void>[] = [];
    const scanStart = new Date();
    for await (const [id, handle] of appointmentsDir) {
      if (handle.kind === 'directory') {
        dirCount++;
        const promise = async () => {
          const appointmentFilesResult = await readAppointmentFilesFromOpfs(
            fileSystem,
            id,
          );
          if (
            appointmentFilesResult.type === 'directoryExists' &&
            appointmentFilesResult.files.length
          ) {
            scannedAppointmentFiles.set(id, {
              id,
              files: appointmentFilesResult.files,
              name: '',
              createdAt: '',
              updatedAt: '',
              appointmentDir: handle as FileSystemDirectoryHandle,
              onLocalFilesUpdated: onRemoveAppointmentFiles,
            });
          }
        };

        filePromises.push(promise());
      }
    }

    await Promise.all(filePromises);
    const scanEnd = new Date();
    const duration = scanEnd.getTime() - scanStart.getTime();
    logger.info(`Scanned ${dirCount} directories in ${duration} ms.`, {
      dirCount,
      duration,
    });

    const hasChangedFilesSinceLastScan =
      scannedAppointmentFiles.size !== appointmentFiles.size ||
      Array.from(appointmentFiles.entries()).some(
        ([id, val]) =>
          scannedAppointmentFiles.get(id)?.files?.length !== val.files.length,
      );

    if (hasChangedFilesSinceLastScan) {
      const ids = Array.from(scannedAppointmentFiles.keys());
      const { data } = await getAppointmentsByIds({ variables: { ids } });

      if (!data) {
        return;
      }

      data.appointments.forEach(({ createdAt, id, name, updatedAt }) => {
        scannedAppointmentFiles.set(id, {
          ...scannedAppointmentFiles.get(id)!,
          createdAt,
          id,
          name,
          updatedAt,
        });
      });

      setAppointmentFiles(scannedAppointmentFiles);
    }

    setIsInitialLoad(false);
    setIsLoading(false);
  };

  useMount(scanFiles);

  const data = useMemo(() => {
    return Array.from(appointmentFiles).map<Row>(([_, val]) => ({
      ...val,
      fileCount: val.files.length,
    }));
  }, [appointmentFiles]);

  return (
    <ReskinMain>
      <ReskinHeader>
        <PageTitle
          isFullWidth
          subtitle={
            <Box hStack spaceBetween className="w-full">
              <Box>
                {isInitialLoad ? (
                  <Skeleton className="mt-2 h-6 w-32" />
                ) : (
                  `You currently have ${totalFiles} file(s) on your device`
                )}
              </Box>
              {match(isLoading)
                .with(true, () => (
                  <Button variant="gray" disabled>
                    <Loader2 className="mr-2 animate-spin" />
                    Scanning
                  </Button>
                ))
                .otherwise(() => (
                  <Button variant="gray" onClick={scanFiles}>
                    <Search className="mr-2" />
                    Scan Files
                  </Button>
                ))}
            </Box>
          }
        >
          Appointment Files
        </PageTitle>
      </ReskinHeader>
      <ReskinContent variant="fullWidth">
        {isInitialLoad ? (
          <TableSkeleton numCols={7} numRows={5} />
        ) : (
          match(fileSystem.type)
            .with('available', () => (
              <DataTable
                data={data}
                columns={columns}
                enableGlobalSearch={true}
                initialSortingState={[
                  {
                    id: 'updatedAt',
                    desc: true,
                  },
                ]}
              />
            ))
            .with('unavailable', () => <div>Unavailable</div>)
            .with('pending', () => <div>Pending</div>)
            .exhaustive()
        )}
      </ReskinContent>
    </ReskinMain>
  );
};
