import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'sonner';

import { FCC } from '@eluve/components';
import { FeatureFlagsEnum } from '@eluve/graphql-types';
import { FeatureFlagKeys } from '@eluve/posthog';

import { useIsFeatureFlagEnabled } from './useIsFeatureFlagEnabled';

/**
 * Renders children only if the supplied feature flag is enabled
 * for the user's tenant. Otherwise, returns null
 */
export const FeatureFlaggedComponent: FCC<{
  flag: FeatureFlagsEnum;
}> = ({ children, flag }) => {
  const isEnabled = useIsFeatureFlagEnabled(flag);
  if (!isEnabled) {
    return null;
  }

  return children;
};

export const UserFeatureFlaggedComponent: FCC<{
  flag: FeatureFlagKeys;
}> = ({ children, flag }) => {
  const postHog = usePostHog();
  const isEnabled = postHog.isFeatureEnabled(flag);

  // TODO(jesse)[ELU-2222]: Consider using <PostHogFeature> instead of a custom component
  // for more enriched analytics
  // https://posthog.com/docs/libraries/js

  useEffect(() => {
    if (isEnabled) {
      postHog.capture('$feature_view', { feature_flag: flag });
    }
  }, [isEnabled, postHog, flag]);

  if (!isEnabled) {
    return null;
  }

  return children;
};

/**
 * Redirects the user to an alternative location in the app if they try
 * and access a feature flagged page which is not enabled for their tenant
 */
export const FeatureFlaggedPage: FCC<{
  flag: FeatureFlagsEnum;
  redirectTo?: string;
}> = ({ children, flag, redirectTo = '/' }) => {
  const isEnabled = useIsFeatureFlagEnabled(flag);

  if (!isEnabled) {
    toast('You have been redirected');

    return <Navigate to={redirectTo} />;
  }

  return children;
};
