import { match } from 'ts-pattern';

import { useTranscriptionMachineSelector } from '../machines/transcription/TranscriptionMachineProvider';

import { AppointmentTranscriptionIdleControls } from './AppointmentTranscriptionIdleControls';
import { AppointmentTranscriptionInSessionControls } from './AppointmentTranscriptionInSessionControls';

interface TranscriptionControlsProps {
  appointmentId: string;
}

export const AppointmentTranscriptionPageControls: React.FC<
  TranscriptionControlsProps
> = ({ appointmentId }) => {
  const sessionState = useTranscriptionMachineSelector((state) => {
    if (state.matches('Idle')) {
      return 'Idle';
    }
    if (state.matches('InSession')) {
      if (state.context.sessionAppointmentId !== appointmentId) {
        return 'AnotherAppointmentInSession';
      }
      return 'InSession';
    }
  });

  return match(sessionState)
    .with('Idle', () => (
      <AppointmentTranscriptionIdleControls appointmentId={appointmentId} />
    ))
    .with('InSession', () => <AppointmentTranscriptionInSessionControls />)
    .with('AnotherAppointmentInSession', () => (
      <span>Another appointment in session</span>
    ))
    .otherwise(() => null);
};
