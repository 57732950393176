import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { useEffect, useMemo } from 'react';

/**
 * This hook allows to throttle the callback function and only call it once every 2000ms
 * And when user stops typing for 1000ms, the callback function will be called and throttle will be reset
 * @param callback
 * @param throttleTime default 5000ms
 * @param debounceTime default 1000ms
 */
export const useTypingThrottle = <T extends (...args: any[]) => void>(
  callback: T,
  throttleTime = 5000,
  debounceTime = 1000,
): T => {
  const throttledCallback = useMemo(
    () =>
      throttle(callback, throttleTime, {
        leading: false,
      }),
    [callback, throttleTime],
  );

  const debouncedCallback = useMemo(
    () =>
      debounce((...args: Parameters<T>) => {
        // Cancel any pending throttled callbacks
        throttledCallback.cancel();
        callback(...args);
      }, debounceTime),
    [callback, debounceTime, throttledCallback],
  );

  const handleEvent = useMemo(() => {
    return (...args: Parameters<T>) => {
      throttledCallback(...args);
      debouncedCallback(...args);
    };
  }, [debouncedCallback, throttledCallback]);

  useEffect(() => {
    // Cleanup function to cancel debounced callback on unmount
    return () => {
      debouncedCallback.cancel();
    };
  }, [debouncedCallback]);

  return handleEvent as T;
};
