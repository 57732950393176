import { Gem } from 'lucide-react';
import React from 'react';
import { toast } from 'sonner';

import { useApiClient } from '@eluve/api-client-provider';
import { QUERY_ROOT_ID, cacheUtils } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

export const modelsGlobalDefaultFragment = graphql(`
  fragment ModelsGlobalDefault on query_root @_unmask {
    __typename
    llmModelArgs {
      __typename
      id
      isGlobalDefault
    }
  }
`);

export interface SetGlobalModelArgsActionProps {
  modelArgsId: string;
  isGlobalDefault: boolean;
  isActive: boolean;
}

export const SetGlobalModelArgsAction: React.FC<
  SetGlobalModelArgsActionProps
> = ({ isGlobalDefault, modelArgsId, isActive }) => {
  const apiClient = useApiClient();

  if (isGlobalDefault || !isActive) {
    return null;
  }

  const setDefault = async () => {
    const result =
      await apiClient.prompts.setGlobalDefaultModelArgsForSOAPNoteOutputType({
        body: {
          modelArgsId,
        },
      });

    if (result.status !== 201) {
      toast.error('Failed to set global default model args');
      return;
    }

    cacheUtils.updateFragment(
      {
        fragment: modelsGlobalDefaultFragment,
        key: QUERY_ROOT_ID,
      },
      (existing) => {
        return {
          __typename: 'query_root' as const,
          llmModelArgs: (existing?.llmModelArgs ?? []).map((model) => ({
            __typename: 'LlmModelArgs' as const,
            id: model.id,
            isGlobalDefault: model.id === modelArgsId,
          })),
        };
      },
    );

    toast.success('Successfully set global default model args');
  };

  return (
    <AlertDialog>
      <TooltipLabel label="Make this model args the global default for all SOAP prompts">
        <AlertDialogTrigger asChild>
          <Button size="icon" variant="outline">
            <Gem />
          </Button>
        </AlertDialogTrigger>
      </TooltipLabel>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="text-gray-11">
            Are you sure?
          </AlertDialogTitle>
          <AlertDialogDescription>
            This will update all SOAP Note prompts to use this model as their
            new default configuration
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel autoFocus>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            onClick={setDefault}
          >
            Set Default
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
