import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { CopyTextButton } from '@eluve/blocks';
import {
  Box,
  ColDefBuilder,
  DataTable,
  Link,
  PageSubtitle,
  makeDefaultSortableColumnDef,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import {
  PromptTemplateColDef,
  PromptTemplateColDefProps,
} from '../components/PromptTemplateColDef';

const getAdminTenantAppointmentsQuery = graphql(`
  query getAdminTenantAppointments($tenantId: uuid!) {
    tenantsByPk(id: $tenantId) {
      __typename
      id
      name
      appointments(limit: 1000, orderBy: { startedAt: DESC_NULLS_LAST }) {
        __typename
        id
        name
        startDate
        endDate
        createdAt
        description
        patientId
        updatedAt
        startedAt
        user {
          __typename
          id
          firstName
          lastName
          email
        }
        doctor_interaction {
          __typename
          appointmentId
          noteSignedAt
        }
        transcripts(orderBy: { createdAt: DESC_NULLS_LAST }, limit: 1) {
          __typename
          id
          createdAt
        }
        appointment_llm_outputs(
          orderBy: { output: { updatedAt: DESC_NULLS_LAST } }
          limit: 1
        ) {
          output {
            __typename
            id
            modelPromptTemplate {
              __typename
              id
              template {
                __typename
                name
                id
              }
            }
          }
        }
      }
    }
  }
`);

type AppointmentRow = {
  id: string;
  name: string;
  patientId: string | null;
  userId?: string;
  userName?: string;
  noteSignedAt?: string;
  updatedAt?: string;
  startDate: string | null;
  lastTranscriptCreatedAt?: string;
} & PromptTemplateColDefProps;

const columns = new ColDefBuilder<AppointmentRow>()
  .colDef({
    ...makeDefaultSortableColumnDef('name', 'Name'),
    id: 'name',
    accessorFn: (row) => `${row.id} ${row.name}`,
    cell: ({ row }) => {
      return (
        <Box hStack>
          <CopyTextButton copyText={row.original.id} />
          <Link to={`../appointments/${row.original.id}`} relative="path">
            {row.original.name ? row.original.name : row.original.id}
          </Link>
        </Box>
      );
    },
  })
  .defaultSortable('patientId', 'Patient ID')
  .colDef({
    ...makeDefaultSortableColumnDef('userName', 'User Name'),
    id: 'userName',
    accessorFn: (row) => `${row.userId} ${row.userName}`,
    cell: ({ row }) => {
      return (
        <Box hStack>
          {row.original.userId && (
            <CopyTextButton copyText={row.original.userId} />
          )}
          <p>{row.original.userName}</p>
        </Box>
      );
    },
  })
  .dateSortable('startDate', 'Start Date')
  .dateSortable('noteSignedAt', 'Signed At')
  .dateSortable('lastTranscriptCreatedAt', 'Last Transcript')
  .colDef(PromptTemplateColDef())
  .dateSortable('updatedAt', 'Updated At')
  .build();

export const AdminAppointmentsDash: React.FC = () => {
  const { tenantId } = useParams() as { tenantId: string };
  const { data } = useQuery(getAdminTenantAppointmentsQuery, {
    variables: {
      tenantId: tenantId,
    },
  });

  const rows = useMemo(
    () =>
      (data?.tenantsByPk?.appointments ?? []).map<AppointmentRow>((appt) => {
        const lastTranscriptCreatedAt =
          appt.transcripts[0]?.createdAt ?? undefined;

        const output =
          appt.appointment_llm_outputs[0]?.output.modelPromptTemplate;

        return {
          id: appt.id,
          name: appt.name,
          patientId: appt.patientId,
          startDate: appt.startDate,
          noteSignedAt: appt.doctor_interaction?.noteSignedAt ?? undefined,
          userId: appt.user?.id,
          userName: [appt.user?.firstName, appt.user?.lastName]
            .filter(Boolean)
            .join(' '),
          lastTranscriptCreatedAt,
          promptTemplate: output?.template ?? null,
          updatedAt: appt.updatedAt ?? undefined,
        };
      }),
    [data],
  );

  return (
    <div>
      <PageSubtitle> Appointments</PageSubtitle>
      <div>
        <DataTable
          data={rows}
          columns={columns}
          enableGlobalSearch
          initialSortingState={[{ id: 'lastTranscriptCreatedAt', desc: true }]}
        />
      </div>
    </div>
  );
};
