import { usePostHog } from 'posthog-js/react';
import React, { useCallback, useMemo } from 'react';

import { FCC } from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';
import { FeatureFlagKeys, featureFlagsWithPayloads } from '@eluve/posthog';
import {
  FeatureFlagContextType,
  FeatureFlagProvider as Provider,
} from '@eluve/posthog-react';

export const FeatureFlagProvider: FCC = ({ children }) => {
  const logger = useNamedLogger('FeatureFlagProvider');
  const postHog = usePostHog();

  const getFeatureFlagData = useCallback(
    (flagKey: FeatureFlagKeys) => {
      return postHog.getFeatureFlagPayload(flagKey);
    },
    [postHog],
  );

  const contextData: FeatureFlagContextType = useMemo(() => {
    return {
      flagDefinitions: featureFlagsWithPayloads,
      getFeatureFlagData,
      logger,
    };
  }, [getFeatureFlagData, logger]);

  return <Provider value={contextData}>{children}</Provider>;
};
