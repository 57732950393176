import { cn } from './cn';
import { FCC } from './types';
import { H1, H3 } from './typography';

export const PageTitle: FCC<{
  cName?: string;
  isFullWidth?: boolean;
  subtitle?: React.ReactNode;
}> = ({ children, subtitle, cName, isFullWidth = false }) => {
  return (
    <div className={cn('my-2 py-2', { 'w-full': isFullWidth })}>
      <H1 cn={cName}>
        <div>{children}</div>
      </H1>
      {subtitle && <H3>{subtitle}</H3>}
    </div>
  );
};
