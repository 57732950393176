import { z } from 'zod';

const reviewOfSystemsItemSchema = z
  .object({
    name: z.string().describe('The name of the symptom or sign'),
    presents: z
      .boolean()
      .describe('Whether the patient presents with this symptom'),
    justification: z
      .string()
      .optional()
      .describe('Justification for the symptom presence or absence'),
  })
  .describe('A single item in the review of systems');

export const reviewOfSystemsSchema = z
  .record(z.string(), z.array(reviewOfSystemsItemSchema))
  .describe(
    'Comprehensive Review of Systems based on medical history covering all major bodily systems',
  );
