import { Link } from 'react-router-dom';

import { EluveLogo, FCC, Header } from '@eluve/components';

import { UserHeaderDropdownMenu } from './UserHeaderDropdownMenu';

export const TenantsHeader: FCC = ({ children }) => {
  return (
    <Header>
      <Link to="/" className="flex h-16 shrink-0 items-center">
        <EluveLogo />
      </Link>
      <div className="flex flex-1 items-center">{children}</div>
      <UserHeaderDropdownMenu />
    </Header>
  );
};
