import { useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { usePostHog } from 'posthog-js/react';
import { useNavigate } from 'react-router-dom';

import { InsertAnonymousAppointmentDocument } from '@eluve/client-gql-operations';
import { useNamedLogger } from '@eluve/logger';
import {
  useTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';
import { useConfigStore } from '@eluve/smart-blocks';

export const useStartAnonymousSession = () => {
  const [insertAnonymousAppointment] = useMutation(
    InsertAnonymousAppointmentDocument,
  );

  const logger = useNamedLogger('HomePage');
  const tenantId = useTenantIdFromParams();
  const userId = useUserIdFromSession();
  const { config } = useConfigStore(tenantId, userId);
  const navigate = useNavigate();
  const postHog = usePostHog();

  return async () => {
    const startDate = new Date();

    const { data, errors } = await insertAnonymousAppointment({
      variables: {
        startDate: startDate.toISOString(),
        name: `New Session ${format(startDate, 'yyyy-MM-dd HH:mm')}`,
        locationId: config.activeLocationId,
      },
    });

    const appointmentId = data?.insertAppointmentsOne?.id;
    if (appointmentId) {
      postHog.capture('created_new_session', {
        appointmentId,
      });
      navigate(`/tenants/${tenantId}/appointments/${appointmentId}`);
      return;
    }
    logger.error('Error creating new appointment', { errors });
    return;
  };
};
