import { useMutation } from '@apollo/client';
import React from 'react';
import { toast } from 'sonner';

import { Box, H5, P, Switch } from '@eluve/components';
import { useUserIdFromSession } from '@eluve/session-helpers';

import { upsertEnableSoundEffectsMutation } from './operations';
import { useUserSettings } from './useUserSettings';

export const EnableSoundEffects: React.FC = () => {
  const { userSettings } = useUserSettings();
  const userId = useUserIdFromSession();

  const [upsertEnableSoundEffects] = useMutation(
    upsertEnableSoundEffectsMutation,
    {
      onError: () => {
        toast.error(
          'Failed to update sound effects settings. Please try again.',
        );
      },
      onCompleted: (data) => {
        toast.success(
          `Sound effects ${data.insertUserSettingsOne?.enableSoundEffects ? 'enabled' : 'disabled'}.`,
        );
      },
      optimisticResponse: (data) => {
        return {
          insertUserSettingsOne: {
            __typename: 'UserSettings' as const,
            userId,
            promptTemplateId: userSettings?.promptTemplateId ?? '',
            promptTemplateIdOrDefault: userSettings?.promptTemplateId ?? '',
            enableSoundEffects: data.enableSoundEffects,
          },
        };
      },
    },
  );

  const enableSoundEffects = async (checked: boolean) => {
    await upsertEnableSoundEffects({
      variables: {
        enableSoundEffects: checked,
      },
    });
  };

  return (
    <div>
      <H5 m="my-2">Sound Effects</H5>
      <P className="text-gray-12 mb-2 font-normal">
        Play sound effects when starting/stopping recordings.
      </P>
      <Box hStack className="gap-4" asChild>
        <label>
          <Switch
            checked={userSettings?.enableSoundEffects ?? true}
            onCheckedChange={enableSoundEffects}
          />
        </label>
      </Box>
    </div>
  );
};
