import React, { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { Subject, distinctUntilChanged } from 'rxjs';

import { useNamedLogger } from '@eluve/logger';

import { useTranscriptionMachineActor } from '../TranscriptionMachineProvider';

export const TranscriptionStateTransitionLogger: React.FC = () => {
  const logger = useNamedLogger('TranscriptionStateTransitionLogger');
  const actor = useTranscriptionMachineActor();

  useEffect(() => {
    logger.info('Setting up live transcription logger');
    const subject = new Subject<{
      appointmentId: string | undefined;
      state: string | Record<string, unknown>;
    }>();

    const distinctStateSubscription = subject
      .pipe(distinctUntilChanged(isEqual))
      .subscribe(({ appointmentId, state }) =>
        logger.info('Live Transcription State', {
          state,
          appointmentId,
        }),
      );

    const actorSubscription = actor.subscribe((snapsot) =>
      subject.next({
        appointmentId: snapsot.context.appointmentId,
        state: snapsot.value,
      }),
    );

    return () => {
      logger.info('Cleaning up live transcription logger');
      subject.complete();
      distinctStateSubscription.unsubscribe();
      actorSubscription.unsubscribe();
    };
  }, [logger, actor]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
