import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';

import { PageTitle, TableSkeleton } from '@eluve/components';

import { SessionTypesTable } from './SessionTypesTable';

export const SessionTypesPage: React.FC = () => {
  const { tenantId } = useParams() as { tenantId: string };

  return (
    <div className="container">
      <PageTitle>Session Types</PageTitle>
      <Suspense fallback={<TableSkeleton numRows={10} />}>
        <SessionTypesTable tenantId={tenantId} />
      </Suspense>
    </div>
  );
};
