import { useMutation } from '@apollo/client';

import { useApiClient } from '@eluve/api-client-provider';
import {
  InsertUserUploadDocument,
  UpdateUserUploadDocument,
} from '@eluve/client-gql-operations';
import { useNamedLogger } from '@eluve/logger';

type UploadToGCSArgs = {
  file: File;
  mimeType: string;
  fileExt: string;
};

export const useUploadGenericFile = (tenantId: string) => {
  const logger = useNamedLogger('useUploadGenericFile');
  const apiClient = useApiClient();

  const [insertUserUpload] = useMutation(InsertUserUploadDocument);
  const [updateUserUpload] = useMutation(UpdateUserUploadDocument);

  const onUploadSuccess = async (
    userUploadId: string,
    gcsFilePath: string,
  ): Promise<void> => {
    await updateUserUpload({
      variables: {
        tenantId,
        id: userUploadId,
        gcsFilePath: gcsFilePath,
        status: 'AVAILABLE',
      },
    });
  };

  const onUploadFailure = async (
    userUploadId: string,
    errorMessage: string,
  ): Promise<void> => {
    logger.error('Failed to upload file', { errorMessage });

    await updateUserUpload({
      variables: {
        tenantId,
        id: userUploadId,
        gcsFilePath: null,
        status: 'UPLOAD_ERROR',
      },
    });
  };

  const uploadGenericFileToGCS = async ({
    file,
    mimeType,
    fileExt,
  }: UploadToGCSArgs): Promise<{ url?: string | null }> => {
    const userUploadData = await insertUserUpload();
    const userUploadId = userUploadData.data?.insertUserUploadsOne?.id;
    if (!userUploadId) {
      logger.debug('Failed to create user upload');
      return { url: null };
    }
    const generateSignedUrlResponse =
      await apiClient.gcs.generateSignedUrlForUploadPublicFiles({
        body: {
          fileExt,
          userUploadId,
        },
      });

    if (generateSignedUrlResponse.status !== 201) {
      await onUploadFailure(userUploadId, 'Failed to fetch signed url');
      return { url: null };
    }

    const { gcsFilePath, url: signedUrl } = generateSignedUrlResponse.body;

    const headers = new Headers();
    headers.append('Content-Type', mimeType);

    const uploadResponse = await fetch(signedUrl, {
      method: 'PUT',
      headers,
      body: file,
    });

    if (!uploadResponse.ok) {
      onUploadFailure(userUploadId, 'Could not upload using signed url');
      return { url: null };
    }

    await onUploadSuccess(userUploadId, gcsFilePath);
    return { url: gcsFilePath };
  };

  return {
    uploadGenericFileToGCS,
  };
};
