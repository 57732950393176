import { z } from 'zod';

export const billingCodeSchema = z.array(
  z.object({
    code: z.string().describe('The specific billing code'),
    description: z.string().describe('A description of the billing code'),
    type: z
      .string()
      .default('Unknown')
      .describe('The type of billing code. Eg ICD-10 or CPT'),
    justification: z
      .string()
      .describe(
        'Justification from the source material for why the billing code was chosen',
      ),
    excerpt: z
      .string()
      .default('')
      .describe(
        'An exact excerpt from the source material that explains why this code was chosen',
      ),
  }),
);

export type BillingCodesOutputType = z.infer<typeof billingCodeSchema>;
