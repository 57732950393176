import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const humanOutputsContract = c.router(
  {
    setPreviousHumanOutputAsDefault: {
      method: 'POST',
      path: '/:humanOutputId',
      body: z.object({}),
      responses: {
        200: z.object({
          editedFromLlmOutputId: z.string().nullable(),
        }),
      },
    },
  },
  {
    pathPrefix: '/:tenantId/appointments/:appointmentId/human-outputs',
  },
);
