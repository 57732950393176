import Dexie, { type EntityTable } from 'dexie';
import { createContext, useContext, useMemo } from 'react';

import { FCC } from '@eluve/components';
import { Logger, useNamedLogger } from '@eluve/logger';

export type AppointmentFileIdbRow = {
  id: string;
  appointmentId: string;
  lastUploadAttemptedAt: string;
  attemptCount: number;
  isBackgroundUpload: boolean;
  wasDegraded: boolean;
  segmentId?: string;
};

export const createDb = ({
  userId,
  tenantId,
  logger,
}: {
  userId: string;
  tenantId: string;
  logger: Logger;
}) => {
  const db = new Dexie(`${userId}-${tenantId}`) as Dexie & {
    appointmentFiles: EntityTable<AppointmentFileIdbRow, 'id'>;
  };

  db.version(1).stores({
    appointmentFiles: 'id, appointmentId, lastUploadAttemptedAt, attemptCount',
  });

  db.open().then(() => logger.info('Dexie opened and migrated'));

  return db;
};

export type DexieDb = ReturnType<typeof createDb>;

const DbContext = createContext<DexieDb | null>(null);

export const useDb = () => {
  const context = useContext(DbContext);
  if (!context) {
    throw new Error('useDb must be used within a DbProvider');
  }

  return context;
};

export const DbProvider: FCC<{ userId: string; tenantId: string }> = ({
  userId,
  tenantId,
  children,
}) => {
  const logger = useNamedLogger('DbProvider');
  const db = useMemo(() => {
    logger.info('Creating local db');
    return createDb({ userId, tenantId, logger });
  }, [userId, tenantId, logger]);

  return <DbContext.Provider value={db}>{children}</DbContext.Provider>;
};
