import { TypePolicies } from '@apollo/client';

import { Query_Root } from '@eluve/graphql-types';

import { CapitalizeKeys, TypeSafePolicies } from './types';

export const typeSafePolicies = {
  AppointmentDoctorInteractions: {
    keyFields: ['appointmentId'],
  },
  TenantFeatureFlags: {
    keyFields: ['tenantId', 'flag'],
  },
  TenantLlmOutputs: {
    keyFields: ['tenantId', 'llmOutputName'],
  },
  TenantUsers: {
    keyFields: ['tenantId', 'userId'],
  },
  Locations: {
    fields: {
      location_users: {
        merge: (_existing = [], incoming) => [...incoming],
      },
    },
  },
  LocationUsers: {
    keyFields: ['locationId', 'userId'],
  },
  AppointmentFiles: {
    keyFields: ['appointmentId', 'fileId'],
  },
  UserSettings: {
    keyFields: ['userId'],
  },
  AppointmentHumanOutputs: {
    keyFields: ['humanOutputId'],
  },
  InheritedLocationLogos: {
    keyFields: ['locationId'],
  },
  TenantPromptTemplates: {
    keyFields: ['tenantId', 'promptTemplateId'],
  },
  GlobalFeatureFlagsStatus: {
    keyFields: ['flag'],
  },
  TenantUserSettings: {
    keyFields: ['tenantId', 'userId'],
  },
} satisfies TypeSafePolicies<CapitalizeKeys<Query_Root>>;

export const typePolicies = {
  query_root: { queryType: true },
  muation_root: { mutationType: true },
  ...typeSafePolicies,
} as TypePolicies;
