import some from 'lodash/some';
import { useSearchParams } from 'react-router-dom';
import { tv } from 'tailwind-variants';
import { match } from 'ts-pattern';

import { Box, Button, FCC } from '@eluve/components';
import { urlSafeDate } from '@eluve/date-utils';
import { useAppointmentCounts } from '@eluve/frontend-appointment-hooks';
import { useCaptureEventOnMount } from '@eluve/posthog-react';
import { useTenantIdFromParams } from '@eluve/session-helpers';
import { useEluveExtExists } from '@eluve/smart-blocks';

import { appConfig } from '../../../../../config';
import { useStartAnonymousSession } from '../hooks/useStartAnonymousSession';
import { useUserLocations } from '../hooks/useUserLocation';
import { useEhrStatusDetails } from '../useEhrStatusDetails';
import { useImportDataFromEhr } from '../useImportDataFromEhr';

const TroubleShootingLink =
  'https://eluveinc.notion.site/Troubleshooting-Importing-from-your-EHR-fa0025839396416498427764b1553dbb';

const getEventName = (event: string) => ['empty_home_state', event].join('_');

const EmptyStateBox: FCC = ({ children }) => (
  <Box center className="h-full w-full p-2">
    <Box
      vStack
      className="bg-background w-full max-w-[540px] items-center rounded-lg border p-6 shadow-lg "
    >
      <h3 className="text-gray-12 text-lg font-normal sm:text-2xl">
        No upcoming appointments
      </h3>
      {children}
    </Box>
  </Box>
);

const emptyStateDescription = tv({
  base: 'text-gray-10 p-3 text-center text-sm',
});

const PromptToFirstTimeImport: React.FC = () => {
  const [_searchParams, setSearchParams] = useSearchParams();
  const startAnonymousSession = useStartAnonymousSession();

  useCaptureEventOnMount(getEventName('prompt_to_first_time_import_shown'));

  return (
    <EmptyStateBox>
      <span className={emptyStateDescription()}>
        Finish setting up your EHR integration to sync your appointments with
        Eluve.
      </span>
      <Box hStack>
        <Button
          variant="lightgray"
          className="m-1"
          onClick={() => {
            setSearchParams({ event: 'onboarding' });
          }}
        >
          Import Appointments
        </Button>
        <Button
          variant="lightgray"
          className="m-1"
          onClick={startAnonymousSession}
        >
          <span className="text- sr-only">Start a recording session</span>
          New Session
        </Button>
      </Box>
    </EmptyStateBox>
  );
};

const PromptToRefresh: React.FC = () => {
  const [_searchParams] = useSearchParams();
  const tenantId = useTenantIdFromParams();
  const { importDataFromEhr, isDataImporting } = useImportDataFromEhr(
    tenantId!,
  );

  useCaptureEventOnMount(getEventName('prompt_to_refresh_shown'));

  return (
    <EmptyStateBox>
      <span className={emptyStateDescription()}>
        You don’t have any upcoming appointments for the next 7 days. If you
        recently added a new appointment on your EHR that is not showing up you
        can try refreshing.
      </span>
      <Button
        variant="lightgray"
        className="m-1 w-full max-w-96"
        disabled={isDataImporting}
        onClick={() => {
          importDataFromEhr();
        }}
      >
        Refresh
      </Button>
      <Button
        variant="link"
        className="text-gray-10 m-1 underline"
        onClick={() => {
          window.open(TroubleShootingLink, '_blank', 'noopener,noreferrer');
        }}
      >
        Troubleshoot
      </Button>
    </EmptyStateBox>
  );
};

const PromptToUpdate: React.FC = () => {
  useCaptureEventOnMount(getEventName('prompt_to_update_extension_shown'));

  return (
    <EmptyStateBox>
      <span className={emptyStateDescription()}>
        Please update your extension to get your latest appointment details.
      </span>
      <Button
        variant="lightgray"
        className="m-1 w-96"
        onClick={() => {
          window.open(
            'https://eluveinc.notion.site/Updating-the-Eluve-Extension-ffad42d228f14beeada0e06caffdc272',
            '_blank',
            'noopener,noreferrer',
          );
        }}
      >
        Update Extension
      </Button>
      <Button
        variant="link"
        className="text-gray-10 m-1 underline"
        onClick={() => {
          window.open(TroubleShootingLink, '_blank', 'noopener,noreferrer');
        }}
      >
        Troubleshoot
      </Button>
    </EmptyStateBox>
  );
};

const PromptToInstallExtension: React.FC = () => {
  const startAnonymousSession = useStartAnonymousSession();

  useCaptureEventOnMount(getEventName('prompt_to_install_shown'));

  return (
    <EmptyStateBox>
      <span className={emptyStateDescription()}>
        You don't have any appointments. Download the Eluve extension to get
        seamless integration.
      </span>
      <Box hStack>
        <Button
          variant="lightgray"
          className="m-1"
          onClick={() => {
            window.open(
              'https://chromewebstore.google.com/detail/eluve/hkekbppdbafnjcoeacbdghoebaeadlid',
              '_blank',
              'noopener,noreferrer',
            );
          }}
        >
          Install Extension
        </Button>
        <Button
          variant="lightgray"
          className="m-1"
          onClick={startAnonymousSession}
        >
          <span className="text- sr-only">Start a recording session</span>
          New Session
        </Button>
      </Box>
    </EmptyStateBox>
  );
};

export const EmptyHomePageState: React.FC = () => {
  const tenantId = useTenantIdFromParams();
  const { eluveExtExists, eluveExtVersionSatisfies } = useEluveExtExists();
  const [searchParams] = useSearchParams();
  const { data: ehrStatus } = useEhrStatusDetails();
  const { isSupportedBrowser } = useImportDataFromEhr(tenantId!);
  const fromDate = searchParams.get('date') ?? urlSafeDate();

  const { isUserPartOfAnyLocation } = useUserLocations();
  const isUserCurrentlyOnboarding = searchParams.get('event') === 'onboarding';

  const currentTenantEhrStatus =
    ehrStatus?.eluveDomains?.[appConfig.VITE_API_DOMAIN]?.tenants?.[tenantId!];
  const hasSyncedBefore = some(currentTenantEhrStatus?.vendors, (vendor) =>
    some(vendor?.domains, (domain) => Boolean(domain.syncedAt)),
  );

  const didUserFinishOnboarding = isUserPartOfAnyLocation && hasSyncedBefore;
  const shouldPromptUserToFinishOnboarding =
    !didUserFinishOnboarding && !isUserCurrentlyOnboarding;

  const { totalAppointmentsCount, upcomingAppointmentsCount } =
    useAppointmentCounts({ days: 7, fromDate });

  return match({
    isSupportedBrowser,
    eluveExtExists,
    eluveExtVersionSatisfies,
    shouldPromptUserToFinishOnboarding,
    upcomingAppointmentsCount,
    totalAppointmentsCount,
  })
    .with({ eluveExtExists: false, isSupportedBrowser: true }, () => (
      <PromptToInstallExtension />
    ))
    .with(
      {
        eluveExtExists: true,
        eluveExtVersionSatisfies: false,
      },
      () => <PromptToUpdate />,
    )
    .with(
      {
        eluveExtExists: true,
        eluveExtVersionSatisfies: true,
        shouldPromptUserToFinishOnboarding: true,
      },
      () => <PromptToFirstTimeImport />,
    )
    .with(
      {
        eluveExtExists: true,
        eluveExtVersionSatisfies: true,
        upcomingAppointmentsCount: 0,
        shouldPromptUserToFinishOnboarding: false,
      },
      () => <PromptToRefresh />,
    )
    .otherwise(() => null);
};
