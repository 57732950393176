import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';
import { defaultConfig } from 'tailwind-variants';

type MergeConfig = typeof defaultConfig.twMergeConfig;

const twMergeConfig: MergeConfig = {
  extend: {
    classGroups: {
      'font-size': [
        {
          text: [
            'header0',
            'header1',
            'header2',
            'header3',
            'header4',
            'header5',
            'subHeader1',
            'subHeader2',
            'title1',
            'title2',
            'title3',
            'title4',
            'body1',
            'body2',
            'label1',
            'label2',
          ],
        },
      ],
    },
  },
};

defaultConfig.twMergeConfig = twMergeConfig;

const twMerge = extendTailwindMerge(twMergeConfig);

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};
