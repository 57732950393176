import { DependencyList, useRef } from 'react';
import { useMount, useUpdateEffect } from 'react-use';

export const useAutoScrollContainer = (dependencies: DependencyList) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // scroll to the bottom of the container when the component mounts
  useMount(() => {
    containerRef.current?.scrollTo({
      top: containerRef.current.scrollHeight,
    });
  });

  // scroll to the bottom of the container when the dependencies changes and user has not scrolled up
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useUpdateEffect(() => {
    if (!containerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

    // near the bottom of the container
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, dependencies);

  return containerRef;
};
