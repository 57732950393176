import { useCallback } from 'react';

import { FCC } from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';
import { AppointmentFilesStoreProvider } from '@eluve/user-local-files';
import {
  readAppointmentFilesFromOpfs,
  useUserFileSystemStore,
} from '@eluve/user-local-files';

type AppointmentFileSyncProviderProps = {
  appointmentId: string;
};

export const AppointmentFileSyncProvider: FCC<
  AppointmentFileSyncProviderProps
> = ({ children, appointmentId }) => {
  const logger = useNamedLogger('AppointmentFileSyncProvider');
  const userFileSystem = useUserFileSystemStore(
    (state) => state.userFileSystem,
  );

  const readFilesFromOpfs = useCallback(async () => {
    const appointmentFilesResult = await readAppointmentFilesFromOpfs(
      userFileSystem,
      appointmentId,
    );

    return appointmentFilesResult;
  }, [appointmentId, userFileSystem]);

  const downloadFileFromOpfs = useCallback(
    async (fileName: string) => {
      if (
        userFileSystem.type === 'unavailable' ||
        userFileSystem.type === 'pending'
      ) {
        logger.error('File system is not available.');
        return;
      }

      const { appointmentsDir } = userFileSystem;
      try {
        const appointmentDir = await appointmentsDir.getDirectoryHandle(
          appointmentId,
          { create: false },
        );
        const fileHandle = await appointmentDir.getFileHandle(fileName);
        const file = await fileHandle.getFile();
        const url = URL.createObjectURL(file);

        // Create a temporary anchor element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click(); // Trigger the download
        document.body.removeChild(a);

        URL.revokeObjectURL(url);
      } catch (error) {
        logger.error('Error downloading file:', { error });
      }
    },
    [appointmentId, logger, userFileSystem],
  );

  return (
    <AppointmentFilesStoreProvider
      initializer={() => ({
        files: [],
        readFilesFromOpfs,
        downloadFileFromOpfs,
      })}
    >
      {children}
    </AppointmentFilesStoreProvider>
  );
};
