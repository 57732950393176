import { useSuspenseQuery } from '@apollo/client';
import React, { useMemo } from 'react';

import { ColDefBuilder, DataTable } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { SessionTypesActionCell } from './SessionTypesActionCell';
import { sessionTypesFragment } from './session-type.operations';

const sessionTypesQuery = graphql(
  `
    query getTenantSessionTypes($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        ...SessionTypes
      }
    }
  `,
  [sessionTypesFragment],
);

type Row = {
  tenantId: string;
  id: string;
  name: string;
  context?: string;
  isInitialVisitType: boolean;
};

const columns = new ColDefBuilder<Row>()
  .detailsLink((r) => `${r.id}`, 'Details')
  .defaultSortable('name')
  .defaultSortable('context')
  .defaultBoolean('isInitialVisitType', 'Is Initial Visit')
  .colDef({
    header: 'Actions',
    cell: ({
      row: {
        original: { id, tenantId },
      },
    }) => {
      return <SessionTypesActionCell tenantId={tenantId} id={id} />;
    },
  })
  .build();

export interface SessionTypesTableProps {
  tenantId: string;
}

export const SessionTypesTable: React.FC<SessionTypesTableProps> = ({
  tenantId,
}) => {
  const {
    data: { tenantsByPk: tenant },
  } = useSuspenseQuery(sessionTypesQuery, { variables: { tenantId } });

  const data = useMemo(() => {
    return (tenant?.session_types ?? []).map<Row>((st) => {
      return {
        tenantId,
        id: st.id,
        name: st.name,
        context: st.context ?? undefined,
        isInitialVisitType: st.isInitialVisitType,
      };
    });
  }, [tenant, tenantId]);

  return <DataTable enableGlobalSearch={true} columns={columns} data={data} />;
};
