import { ResultOf } from '@eluve/graphql.tada';
import { formatHumanName } from '@eluve/utils';

import { patientOptionFragment } from './operations';

export type PatientOptionInfo = Omit<
  ResultOf<typeof patientOptionFragment>,
  '__typename'
>;

export const getName = (
  patient: Pick<PatientOptionInfo, 'firstName' | 'lastName'>,
) => formatHumanName(patient.firstName, patient.lastName);

export const externalPatientID = (
  patient: Pick<PatientOptionInfo, 'external_patients_info'>,
) =>
  patient.external_patients_info
    .map((info) => info.externalPatientId)
    .join(', ');
