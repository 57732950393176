import invert from 'lodash/invert';

import { TranscriptionSourceEnum } from '@eluve/graphql-types';

export const SupportedLanguages = {
  Bulgarian: 'bg',
  Catalan: 'ca',
  'Chinese (Mandarin, Simplified)': 'zh',
  'Chinese (Mandarin, China)': 'zh-CN',
  'Chinese (Mandarin, Simplified Script)': 'zh-Hans',
  'Chinese (Mandarin, Traditional)': 'zh-TW',
  'Chinese (Mandarin, Traditional Script)': 'zh-Hant',
  Czech: 'cs',
  Danish: 'da',
  'Danish (Denmark)': 'da-DK',
  Dutch: 'nl',
  English: 'en',
  'English (US)': 'en-US',
  'English (Australia)': 'en-AU',
  'English (UK)': 'en-GB',
  'English (New Zealand)': 'en-NZ',
  'English (India)': 'en-IN',
  Estonian: 'et',
  Finnish: 'fi',
  Flemish: 'nl-BE',
  French: 'fr',
  'French (Canada)': 'fr-CA',
  German: 'de',
  'German (Switzerland)': 'de-CH',
  Greek: 'el',
  Hindi: 'hi',
  Hungarian: 'hu',
  Indonesian: 'id',
  Italian: 'it',
  Japanese: 'ja',
  Korean: 'ko',
  'Korean (Korea)': 'ko-KR',
  Latvian: 'lv',
  Lithuanian: 'lt',
  Malay: 'ms',
  'Multilingual (Spanish + English)': 'multi',
  Norwegian: 'no',
  Polish: 'pl',
  Portuguese: 'pt',
  'Portuguese (Brazil)': 'pt-BR',
  Romanian: 'ro',
  Russian: 'ru',
  Slovak: 'sk',
  Spanish: 'es',
  'Spanish (Latin America)': 'es-419',
  Swedish: 'sv',
  'Swedish (Sweden)': 'sv-SE',
  Thai: 'th',
  'Thai (Thailand)': 'th-TH',
  Turkish: 'tr',
  Ukrainian: 'uk',
  Vietnamese: 'vi',
} as const;

export const SupportedLanguagesByCode = invert(SupportedLanguages);

export const SupportedTranscrptionModels = {};

export const getTranscriptionSourceByLanguage = (
  languageCode: string,
): TranscriptionSourceEnum => {
  return ['en', 'en-US'].includes(languageCode)
    ? 'DEEPGRAM_NOVA_2_MEDICAL'
    : 'DEEPGRAM_NOVA_2_GENERAL';
};

export const TranscriptionSourceToModel: Record<
  TranscriptionSourceEnum,
  string
> = {
  DEEPGRAM_NOVA_2_MEDICAL: 'nova-2-medical',
  DEEPGRAM_NOVA_2_GENERAL: 'nova-2-general',
} as const;

export const getModelByLanguage = (languageCode: string) =>
  TranscriptionSourceToModel[getTranscriptionSourceByLanguage(languageCode)];
