import { useMutation } from '@apollo/client';
import { TooltipContent, TooltipTrigger } from '@radix-ui/react-tooltip';
import { ColumnDef } from '@tanstack/react-table';
import { Trash } from 'lucide-react';
import React, { useMemo, useState } from 'react';

import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import {
  Box,
  Button,
  Combobox,
  ComboboxDropdown,
  ComboboxOption,
  ComboboxSelectButton,
  ComboboxSelectCheck,
  DataTable,
  P,
  SortableColumnHeader,
  Tooltip,
  TooltipProvider,
  useErrorToast,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { externalEhrFragment } from './locations.gql';

export const userFragment = graphql(`
  fragment User on Users @_unmask {
    __typename
    id
    email
    firstName
    lastName
  }
`);

export const locationUserFragment = graphql(
  `
    fragment LocationUser on LocationUsers @_unmask {
      __typename
      userId
      locationId
      user {
        ...User
      }
    }
  `,
  [userFragment],
);

export const locationUsersFragment = graphql(
  `
    fragment LocationUsers on Locations @_unmask {
      __typename
      id
      location_users {
        ...LocationUser
      }
    }
  `,
  [locationUserFragment],
);

export const getTenantLocationUsersQuery = graphql(
  `
    query GetTenantLocationUsers($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        __typename
        id
        locations {
          __typename
          id
          name
          externalEhr {
            ...ExternalEhr
          }
          ...LocationUsers
        }
      }
    }
  `,
  [locationUsersFragment, externalEhrFragment],
);

export const tenantUsersFragment = graphql(
  `
    fragment TenantUsers on Tenants {
      __typename
      tenantUsers {
        __typename
        tenantId
        userId
        role
        user {
          ...User
        }
      }
    }
  `,
  [userFragment],
);

export const removeUserFromLocationMutation = graphql(`
  mutation removeUserFromLocation(
    $tenantId: uuid!
    $locationId: String!
    $userId: uuid!
  ) {
    deleteLocationUsersByPk(
      locationId: $locationId
      tenantId: $tenantId
      userId: $userId
    ) {
      __typename
      userId
      locationId
    }
  }
`);

export const addCurrentUserToLocationMutation = graphql(
  `
    mutation addUserToLocation($locationId: String!) {
      insertLocationUsersOne(
        object: { locationId: $locationId }
        onConflict: { constraint: location_users_pkey, updateColumns: [] }
      ) {
        ...LocationUser
      }
    }
  `,
  [locationUserFragment],
);

export const addUserToLocationMutation = graphql(
  `
    mutation addUserToLocation($locationId: String!, $userId: uuid!) {
      insertLocationUsersOne(
        object: { locationId: $locationId, userId: $userId }
      ) {
        ...LocationUser
      }
    }
  `,
  [locationUserFragment],
);

type UserRow = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  locationId: string;
  tenantId: string;
};

const RemoveUserButton: React.FC<{
  tenantId: string;
  locationId: string;
  userId: string;
}> = (props) => {
  const errorToast = useErrorToast();

  const [removeUserFromLocation] = useMutation(removeUserFromLocationMutation, {
    optimisticResponse: (input) => {
      return {
        deleteLocationUsersByPk: {
          __typename: 'LocationUsers' as const,
          userId: input.userId,
          locationId: input.locationId,
        },
      };
    },
    update: (_cache, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: locationUsersFragment,
          key: {
            id: props.locationId,
          },
        },
        (existing) => {
          if (!existing || !data?.deleteLocationUsersByPk) {
            return existing;
          }
          const locationUsers = (existing.location_users ?? []).filter(
            (lu) => lu.userId !== data.deleteLocationUsersByPk?.userId,
          );

          return {
            ...existing,
            location_users: locationUsers,
          };
        },
      );
    },
  });

  const removeUser = async () => {
    try {
      await removeUserFromLocation({
        variables: props,
      });
    } catch (e) {
      errorToast('Failed to remove user from location');
    }
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            className="m-1"
            variant="destructive"
            size="icon"
            onClick={removeUser}
          >
            <Trash className="h-5 w-5" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <P className="mb-1 rounded-sm bg-white p-2 shadow-sm">
            Remove user from location
          </P>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

const columns: ColumnDef<UserRow>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <SortableColumnHeader column={column} label="ID" />;
    },
    cell: ({ row }) => {
      return <P>{row.original.id}</P>;
    },
  },
  {
    accessorKey: 'firstName',
    header: ({ column }) => (
      <SortableColumnHeader column={column} label="First" />
    ),
    cell: ({ row }) => {
      return <P>{row.original.firstName}</P>;
    },
  },
  {
    accessorKey: 'lastName',
    header: ({ column }) => (
      <SortableColumnHeader column={column} label="Last" />
    ),
    cell: ({ row }) => {
      return <P>{row.original.lastName}</P>;
    },
  },
  {
    accessorKey: 'email',
    header: ({ column }) => (
      <SortableColumnHeader column={column} label="Email" />
    ),
    cell: ({ row }) => {
      return <P>{row.original.email}</P>;
    },
  },
  {
    accessorKey: 'actions',
    header: () => {
      return <P>Actions</P>;
    },
    cell: ({ row }) => {
      const { tenantId, locationId, id } = row.original;
      return (
        <RemoveUserButton
          tenantId={tenantId}
          locationId={locationId}
          userId={id}
        />
      );
    },
  },
];

export interface LocationUsersProps {
  tenantId: string;
  locationId: string;
}

export const LocationUsers: React.FC<LocationUsersProps> = ({
  tenantId,
  locationId,
}) => {
  const [selectedUser, setSelectedUser] = useState<string | undefined>(
    undefined,
  );

  const { tenantUsers } = useCompleteFragment({
    fragment: tenantUsersFragment,
    key: {
      id: tenantId,
    },
  });

  const { location_users } = useCompleteFragment({
    fragment: locationUsersFragment,
    key: {
      id: locationId,
    },
  });

  const usersToAdd = tenantUsers.filter(
    (u) => !location_users.some((lu) => lu.user?.id === u.user.id),
  );

  const errorToast = useErrorToast();

  const [addUserToLocation] = useMutation(addUserToLocationMutation, {
    optimisticResponse: (data) => ({
      insertLocationUsersOne: {
        __typename: 'LocationUsers' as const,
        userId: data.userId,
        locationId,
        user: tenantUsers.find((u) => u.user.id === data.userId)?.user || null,
      },
    }),
    update: (_cache, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: locationUsersFragment,
          key: {
            id: locationId,
          },
        },
        (existing) => {
          if (!existing || !data?.insertLocationUsersOne) {
            return existing;
          }
          const insertedUser = {
            ...data.insertLocationUsersOne,
            user:
              tenantUsers.find(
                (u) => u.user.id === data.insertLocationUsersOne?.userId,
              )?.user ?? null,
          };
          return {
            ...existing,
            location_users: [...existing.location_users, insertedUser],
          };
        },
      );
    },
  });

  const submitNewUser = async () => {
    if (!selectedUser) {
      return;
    }

    setSelectedUser(undefined);

    try {
      await addUserToLocation({
        variables: {
          locationId,
          userId: selectedUser,
        },
      });
    } catch (e) {
      setSelectedUser(undefined);
      errorToast('Failed to add new user');
    }
  };

  const rows = useMemo(
    () =>
      location_users.map<UserRow>((u) => {
        const user = u.user!;
        return {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          locationId,
          tenantId,
        };
      }),
    [location_users, locationId, tenantId],
  );

  return (
    <div className="grid gap-2">
      <div className="flex items-center gap-1">
        <div className=" w-96  rounded-lg border">
          <Combobox>
            <ComboboxSelectButton className="hover:bg-gray-3 w-full border-transparent">
              <Box
                hStack
                className="overflow-hidden text-ellipsis whitespace-nowrap text-sm"
              >
                {(() => {
                  const user = tenantUsers.find(
                    (u) => u.userId === selectedUser,
                  );
                  return user
                    ? `${user.user.firstName} ${user.user.lastName}`
                    : 'Select user';
                })()}
              </Box>
            </ComboboxSelectButton>
            <ComboboxDropdown searchPlaceholder="Search users">
              {usersToAdd.map((user) => (
                <ComboboxOption
                  key={user.userId}
                  onSelect={() => setSelectedUser(user.userId)}
                >
                  <ComboboxSelectCheck
                    className="mr-2"
                    selected={selectedUser === user.userId}
                  />
                  {`${user.user.firstName} ${user.user.lastName}`}
                </ComboboxOption>
              ))}
            </ComboboxDropdown>
          </Combobox>
        </div>
        <Button onClick={submitNewUser} disabled={!selectedUser}>
          Add User
        </Button>
      </div>
      <DataTable data={rows} columns={columns} />
    </div>
  );
};
