import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';

import { InputLabel, MicrophoneLevel } from '@eluve/components';

import { MicrophoneDeviceSelector } from './MicrophoneDeviceSelector';

type AppointmentUserMicSettingsProps = {
  selectedMicId?: string | null;
  setSelectedMicId: (id: string) => void;
};

export const AppointmentUserMicSettings: React.FC<
  AppointmentUserMicSettingsProps
> = ({ selectedMicId, setSelectedMicId }: AppointmentUserMicSettingsProps) => {
  const isSmallScreen = useMedia('(max-width: 640px)');
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  const onEnded = () => {
    setSelectedMicId('');
  };
  const onMuted = () => {
    setSelectedMicId('');
  };

  useEffect(() => {
    let localMediaStream: MediaStream | null = null;
    (async () => {
      localMediaStream = await navigator.mediaDevices.getUserMedia({
        audio: selectedMicId
          ? {
              deviceId: selectedMicId,
            }
          : true,
      });

      const tracks = localMediaStream.getAudioTracks();
      tracks.forEach((track) => {
        track.addEventListener('ended', onEnded);
        track.addEventListener('muted', onMuted);
      });
      if (!selectedMicId) {
        const tracks = localMediaStream.getAudioTracks();
        if (tracks.length) {
          const track = tracks[0];
          const deviceId = track?.getSettings().deviceId;
          if (deviceId) {
            setSelectedMicId(deviceId);
          }
        }
      }

      setMediaStream(localMediaStream);
    })();

    return () => {
      localMediaStream?.getTracks().forEach((track) => {
        track.removeEventListener('ended', onEnded);
        track.removeEventListener('mute', onMuted);
        track.stop();
      });
    };
  }, [selectedMicId, setSelectedMicId]);

  return (
    <>
      <InputLabel label="Source">
        <MicrophoneDeviceSelector
          onDeviceValueChange={setSelectedMicId}
          selectedMicId={selectedMicId}
        />
      </InputLabel>
      <InputLabel label="Input Level">
        <MicrophoneLevel
          stream={mediaStream}
          key={selectedMicId}
          className="w-full"
          variant={isSmallScreen ? 'compact' : 'default'}
        />
      </InputLabel>
    </>
  );
};
