import { useMutation, useSubscription, useSuspenseQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Plus, SaveIcon, Trash, Wand } from 'lucide-react';
import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import { useApiClient } from '@eluve/api-client-provider';
import {
  Box,
  Button,
  Form,
  H3,
  Input,
  Label,
  RadioGroup,
  RadioGroupItem,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useNamedLogger } from '@eluve/logger';

const artifactFactsFormSchema = z.object({
  facts: z.array(
    z.object({
      id: z.string().uuid(),
      statement: z.string(),
      weightMultiplier: z.coerce.number().max(1),
      expectedOutput: z.coerce.boolean(),
      isVerified: z.coerce.boolean(),
    }),
  ),
});

type EditArtifactFactsFormData = z.infer<typeof artifactFactsFormSchema>;

const formDefaults: Partial<EditArtifactFactsFormData> = {
  facts: [],
};

export const sourceArtifactFactFragment = graphql(`
  fragment Fact on EvalSourceArtifactFacts @_unmask {
    __typename
    id
    sourceArtifactId
    statement
    expectedOutput
    weightMultiplier
    isVerified
  }
`);

const sourceArtifactFactsQuery = graphql(
  `
    query getSourceArtifactFacts($sourceArtifactId: uuid!) {
      evalSourceArtifactByPk(id: $sourceArtifactId) {
        __typename
        id
        facts {
          ...Fact
        }
      }
    }
  `,
  [sourceArtifactFactFragment],
);

const listenForFactsSubscription = graphql(
  `
    subscription listenForFacts($sourceArtifactId: uuid!) {
      evalSourceArtifactByPk(id: $sourceArtifactId) {
        __typename
        id
        facts {
          ...Fact
        }
      }
    }
  `,
  [sourceArtifactFactFragment],
);

const upsertFactsMutation = graphql(
  `
    mutation upsertFacts($facts: [EvalSourceArtifactFactsInsertInput!]!) {
      insertEvalSourceArtifactFacts(
        objects: $facts
        onConflict: {
          constraint: source_artifact_facts_pkey
          updateColumns: [
            statement
            weightMultiplier
            expectedOutput
            isVerified
          ]
        }
      ) {
        returning {
          ...Fact
        }
      }
    }
  `,
  [sourceArtifactFactFragment],
);

const deleteFactMutation = graphql(`
  mutation deleteFact($factId: uuid!) {
    deleteEvalSourceArtifactFactsByPk(id: $factId) {
      id
    }
  }
`);

export interface EditArtifactFactsFormProps {
  sourceArtifactId: string;
}

export const EditArtifactFactsForm: React.FC<EditArtifactFactsFormProps> = ({
  sourceArtifactId,
}) => {
  const logger = useNamedLogger('edit-artifact-facts-form');
  const client = useApiClient();

  const { data } = useSuspenseQuery(sourceArtifactFactsQuery, {
    variables: {
      sourceArtifactId,
    },
  });

  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const facts = data?.evalSourceArtifactByPk?.facts.map((f) => ({
    id: f.id,
    statement: f.statement || '',
    weightMultiplier: f.weightMultiplier,
    expectedOutput: f.expectedOutput,
    isVerified: f.isVerified,
  }));

  const defaultValues = facts?.length ? { facts } : formDefaults;

  const form = useForm<EditArtifactFactsFormData>({
    resolver: zodResolver(artifactFactsFormSchema),
    defaultValues,
    mode: 'all',
  });

  useSubscription(listenForFactsSubscription, {
    variables: {
      sourceArtifactId,
    },
    onData: ({ data }) => {
      form.reset({
        facts:
          data?.data?.evalSourceArtifactByPk?.facts.map((f) => ({
            id: f.id,
            statement: f.statement || '',
            weightMultiplier: f.weightMultiplier,
            expectedOutput: f.expectedOutput,
            isVerified: f.isVerified,
          })) || [],
      });
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'facts',
  });

  const [upsertFacts] = useMutation(upsertFactsMutation, {
    onCompleted: () => {
      toast.success(`Successfully updated facts for ${sourceArtifactId}`);
    },
    onError: () => toast.error('Failed to update facts'),
  });

  const [deleteFact] = useMutation(deleteFactMutation);

  const onSubmit = async ({ facts }: EditArtifactFactsFormData) => {
    await upsertFacts({
      variables: {
        facts: facts.map((f) => ({
          ...f,
          sourceArtifactId,
        })),
      },
    });
  };

  const handleFactDelete = async (index: number, factId: string) => {
    await deleteFact({
      variables: {
        factId,
      },
      onCompleted: () => {
        toast.success('Successfully deleted fact');
        remove(index);
      },
      onError: () => toast.error('Failed to update facts'),
    });
  };

  const handleAutomaticallyGenerateFacts = async () => {
    toast.info('Generating facts...');
    setIsFormDisabled(true);

    const apiResult = await client.eval.generateFactsForSourceArtifact({
      body: { sourceArtifactId },
    });

    if (apiResult.status === 201) {
      toast.success('Finished generating facts');
    }

    if (apiResult.status === 422) {
      toast.error(`Failed to generate facts: ${apiResult.body.message}`);
    }

    setIsFormDisabled(false);
  };

  return (
    <Box vStack className="w-full">
      <H3>Facts</H3>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, () => {
            toast.error('Form failed validation');
            logger.debug('Invalid form submission attempted');
          })}
          className="w-full"
        >
          <fieldset disabled={isFormDisabled}>
            <div className="mb-10 grid gap-6">
              {fields.length > 0 && (
                <div className="mb-4 grid grid-cols-[8fr_2fr_1fr_2fr_1fr] items-center gap-6">
                  <span className="font-semibold">Statement</span>
                  <span className="font-semibold">Expected Output</span>
                  <span className="font-semibold">Multiplier</span>
                  <span className="font-semibold">Is Verified</span>
                  <span className="font-semibold">Actions</span>
                </div>
              )}
              {fields.map((field, index) => (
                <div
                  key={field.id}
                  className="grid  grid-cols-[8fr_2fr_1fr_2fr_1fr] items-center gap-6"
                >
                  <Input
                    key={`${field.id}.fact`}
                    {...form.register(`facts.${index}.statement`)}
                  />

                  <RadioGroup
                    {...form.register(`facts.${index}.expectedOutput`)}
                    className="flex gap-2"
                    defaultValue={String(
                      form.getValues(`facts.${index}.expectedOutput`),
                    )}
                  >
                    <RadioGroupItem
                      id={`expected-true-${index}`}
                      value="true"
                    />
                    <Label htmlFor={`expected-true-${index}`} className="pr-3">
                      True
                    </Label>
                    <RadioGroupItem
                      id={`expected-false-${index}`}
                      value="false"
                    />
                    <Label htmlFor={`expected-false-${index}`}>False</Label>
                  </RadioGroup>

                  <Input
                    type="number"
                    step="0.1"
                    key={`${field.id}.weightMultiplier`}
                    className="max-w-[80px]"
                    {...form.register(`facts.${index}.weightMultiplier`)}
                  />

                  <RadioGroup
                    {...form.register(`facts.${index}.isVerified`)}
                    className="flex gap-2"
                    defaultValue={String(
                      form.getValues(`facts.${index}.isVerified`),
                    )}
                  >
                    <RadioGroupItem
                      id={`verified-true-${index}`}
                      value="true"
                    />
                    <Label htmlFor={`verified-true-${index}`} className="pr-3">
                      True
                    </Label>
                    <RadioGroupItem
                      id={`verified-false-${index}`}
                      value="false"
                    />
                    <Label htmlFor={`verified-false-${index}`}>False</Label>
                  </RadioGroup>

                  <Button
                    variant="destructive"
                    type="button"
                    size="icon"
                    disabled={fields.length === 1}
                    onClick={() =>
                      handleFactDelete(
                        index,
                        form.getValues(`facts.${index}.id`),
                      )
                    }
                  >
                    <Trash />
                  </Button>
                </div>
              ))}
              <Box hStack className="justify-between">
                <Button
                  size="sm"
                  type="button"
                  variant="secondary"
                  onClick={() =>
                    append({
                      id: uuidv4(),
                      statement: '',
                      weightMultiplier: 0.5,
                      expectedOutput: true,
                      isVerified: true,
                    })
                  }
                >
                  <Plus className="mr-2" />
                  Add a fact
                </Button>
                <Box hStack>
                  <Button
                    size="sm"
                    type="submit"
                    disabled={!form.formState.isDirty}
                  >
                    <SaveIcon className="pr-1" /> Save Changes
                  </Button>
                  <Button
                    size="sm"
                    type="button"
                    variant="destructive"
                    disabled={!form.formState.isDirty}
                    onClick={() => form.reset()}
                  >
                    <Trash className="pr-1" /> Discard Changes
                  </Button>
                </Box>
              </Box>
              <Button
                size="sm"
                type="button"
                className="w-fit"
                disabled={form.formState.isDirty}
                onClick={handleAutomaticallyGenerateFacts}
              >
                <Wand />
                Automatically Generate Facts
              </Button>
            </div>
          </fieldset>
        </form>
      </Form>
    </Box>
  );
};
