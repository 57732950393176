import { useLazyQuery, useSubscription } from '@apollo/client';
import maxBy from 'lodash/maxBy';
import { useMemo, useRef } from 'react';

import { cacheUtils } from '@eluve/apollo-client';
import {
  GetLatestTranscriptDocument,
  ListenAppointmentTranscriptStreamDocument,
} from '@eluve/client-gql-operations';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { useAppointmentId } from './appointment.context';
import { appointmentTranscriptFragment } from './operations';

/**
 * Listens to the new chunks in real time using a streaming subscription and updates the transcript
 * in apollo cache so that other components can easily use a fragment to read the full transcript
 * without having to do any other work
 */
export const useAppointmentTranscriptionSync = (
  startingTimestamp?: string | null,
) => {
  const maxObservedChunk = useRef(-1);
  const tenantId = useTenantIdFromParams();
  const appointmentId = useAppointmentId();

  const initialValue = useMemo(
    () => startingTimestamp ?? new Date(0).toISOString(),
    [startingTimestamp],
  );

  const [getTranscript] = useLazyQuery(GetLatestTranscriptDocument, {
    variables: {
      tenantId: tenantId!,
      appointmentId,
    },
  });

  useSubscription(ListenAppointmentTranscriptStreamDocument, {
    variables: {
      tenantId: tenantId!,
      appointmentId,
      initialValue,
    },
    onData: async ({ data }) => {
      // Chunk comparison here is in place for HMR during development.
      // When HMR occurs, the subscription will fire again with the same values
      // and we don't want to append the same transcript twice in development.
      // This should not be a problem in a deployed environment
      const transcripts = data?.data?.transcriptsStream ?? [];
      const lastTranscript = maxBy(transcripts, (t) => t.createdAt);
      const maxChunk = lastTranscript
        ? new Date(lastTranscript.createdAt).valueOf()
        : -1;

      let latestTranscript = '';
      let shouldConcatToExisting = true;

      if (transcripts.some((t) => t.transcriptType === 'BATCH')) {
        // if we recevie a BATCH transcript, we need to fetch the latest transcript from the server
        const { data: getTranscriptData } = await getTranscript();

        latestTranscript =
          getTranscriptData?.appointmentsByPk?.transcription?.transcript ?? '';
        shouldConcatToExisting = false;
      } else {
        // if we are only receiving LIVE chunks, we can just concatenate them
        const newChunks = transcripts.filter(
          (t) => new Date(t.createdAt).valueOf() > maxObservedChunk.current,
        );

        const combined = newChunks.map((t) => t.transcript ?? '').join(' ');

        if (combined.length > 0) {
          maxObservedChunk.current = maxChunk;
        }

        latestTranscript = combined;
      }

      // Update the transcript in the cache by appending the new chunk data
      cacheUtils.updateFragment(
        {
          fragment: appointmentTranscriptFragment,
          key: {
            id: appointmentId,
          },
        },
        (existing) => {
          return {
            __typename: 'Appointments' as const,
            transcription: {
              __typename: 'AppointmentTranscripts' as const,
              id: appointmentId,
              transcript: shouldConcatToExisting
                ? [existing?.transcription?.transcript, latestTranscript]
                    .filter(Boolean)
                    .join(' ')
                    .trim()
                : latestTranscript,
              updatedAt: new Date().toISOString(),
            },
          };
        },
      );
    },
  });
};
