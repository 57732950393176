import { useSuspenseQuery } from '@apollo/client';

import { getUserSettingsQuery } from '@eluve/frontend-feature-user-settings';
import { useUserIdFromSession } from '@eluve/session-helpers';

export const UserSettings: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const userId = useUserIdFromSession();
  useSuspenseQuery(getUserSettingsQuery, { variables: { userId } });
  return children;
};
