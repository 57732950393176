import { MatchingRecord } from '@eluve/utils';

import { EvalSourceArtifactTypesEnum } from './types';

export const EvalSourceArtifactTypeLookup: MatchingRecord<EvalSourceArtifactTypesEnum> =
  {
    DE_IDENTIFIED_TRANSCRIPT: 'DE_IDENTIFIED_TRANSCRIPT',
    LLM_GENERATED_SOAP_NOTE: 'LLM_GENERATED_SOAP_NOTE',
    FINAL_ELUVE_SOAP_NOTE: 'FINAL_ELUVE_SOAP_NOTE',
    FINAL_EHR_SOAP_NOTE: 'FINAL_EHR_SOAP_NOTE',
  } as const satisfies MatchingRecord<EvalSourceArtifactTypesEnum>;
