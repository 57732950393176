import type { Logger as DDLogger } from '@datadog/browser-logs';
import { ConsolaInstance, createConsola } from 'consola';

import { LogMetadata, Loggable, isDebugEnabled } from './base';

/**
 * Datadog logger implementation
 * This class is responsible for logging messages to the console and Datadog.
 * Console logging is not enabled in production.
 * To enable console logging in production, set the LOG_LEVEL environment variable to 'debug'.
 */
export class DatadogBrowserLogger implements Loggable {
  private datadogLogger?: DDLogger;
  private consoleLogger: ConsolaInstance;
  private commonMetadata: LogMetadata;

  constructor(prefix: string, metadata?: LogMetadata) {
    this.consoleLogger = createConsola();
    this.loadDatadogLogger();
    this.commonMetadata = { context: prefix, ...metadata };
  }

  private loadDatadogLogger = async () => {
    const datadogModule = await import('@datadog/browser-logs');
    this.datadogLogger = datadogModule.datadogLogs.logger;
  };

  info = (message: string, logMetadata?: LogMetadata) => {
    const metadata = { ...this.commonMetadata, ...logMetadata };
    if (isDebugEnabled()) {
      this.consoleLogger.info(message, metadata);
    }
    this.datadogLogger?.info(message, metadata);
  };

  warn = (message: string, logMetadata?: LogMetadata) => {
    const metadata = { ...this.commonMetadata, ...logMetadata };
    if (isDebugEnabled()) {
      this.consoleLogger.warn(message, metadata);
    }
    this.datadogLogger?.warn(message, metadata);
  };

  error = (message: string, logMetadata?: LogMetadata) => {
    const metadata = { ...this.commonMetadata, ...logMetadata };
    this.consoleLogger.error(message, metadata);
    this.datadogLogger?.error(message, metadata);
  };

  debug = (message: string, logMetadata?: LogMetadata) => {
    const metadata = { ...this.commonMetadata, ...logMetadata };
    this.consoleLogger.debug(message, metadata);
  };
}
