import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import {
  PageTitle,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@eluve/components';

import { AdminAppointmentsDash } from './appointments/AdminAppointmentsDash';
import { AdminFeedbackDash } from './feedback/AdminFeedbackDash';

const TenantDashTabSchema = z
  .enum(['APPOINTMENTS', 'FEEDBACK'])
  .nullable()
  .transform((val) => val ?? 'APPOINTMENTS');

type TenantDashTab = z.infer<typeof TenantDashTabSchema>;

export const TenantDash: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = searchParams.get('tab');

  const tab = TenantDashTabSchema.parse(currentTab);

  const setTab = (newTab: TenantDashTab) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('tab', newTab);
    setSearchParams(urlSearchParams, { replace: true });
  };

  return (
    <Tabs value={tab} className="w-full">
      <div className="flex w-full flex-row items-center justify-between ">
        <PageTitle>Dash</PageTitle>

        <TabsList>
          <TabsTrigger
            value="APPOINTMENTS"
            onClick={() => setTab('APPOINTMENTS')}
            className="px-8"
          >
            Appointments
          </TabsTrigger>
          <TabsTrigger
            value="FEEDBACK"
            onClick={() => setTab('FEEDBACK')}
            className="px-8"
          >
            Feedback
          </TabsTrigger>
        </TabsList>
      </div>
      <TabsContent value="APPOINTMENTS">
        <AdminAppointmentsDash />
      </TabsContent>
      <TabsContent value="FEEDBACK">
        <AdminFeedbackDash />
      </TabsContent>
    </Tabs>
  );
};
