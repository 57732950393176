import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  appointmentFileTypesSchema,
  supportedVendorsSchema,
} from '@eluve/graphql-types';

const c = initContract();

const importAppointmentsRequestSchema = z.object({
  data: z.record(z.unknown()),
  vendor: supportedVendorsSchema,
  domain: z.string(),
  timezone: z.string().nullish(),
});

export type ImportAppointmentsRequest = z.infer<
  typeof importAppointmentsRequestSchema
>;

const prepareAppointmentFileUploadRequestSchema = z.object({
  uploadId: z.string(),
  extension: z.string(),
  fileType: appointmentFileTypesSchema,
  fileName: z.string(),
  fileSize: z.number(),
  fileHash: z.string(),
});
export type PrepareAppointmentFileUploadRequest = z.infer<
  typeof prepareAppointmentFileUploadRequestSchema
>;

const markFileUploadCompleteRequestSchema = z.object({
  uploadId: z.string().uuid(),
  shouldBeTranscribed: z.boolean(),
  recordingStartedAt: z.string(),
  recordingDuration: z.number().nullish(),
  wasSegmentDegraded: z.boolean(),
});

export type MarkFileUploadCompleteRequest = z.infer<
  typeof markFileUploadCompleteRequestSchema
>;

export const appointmentsContract = c.router(
  {
    getAppointments: {
      method: 'GET',
      path: '/',
      responses: {
        200: z.array(
          z.object({
            appointmentId: z.string(),
            externalAppointmentId: z.string().nullish(),
            name: z.string(),
            description: z.string().nullish(),
            startDate: z.date(),
            endDate: z.date().nullish(),
          }),
        ),
      },
      query: z.object({
        startDate: z.string(),
        endDate: z.string(),
        status: z
          .array(z.enum(['NOT_STARTED', 'COMPLETED', 'ACTIVE', 'CANCELLED']))
          .default(['NOT_STARTED']),
        vendor: supportedVendorsSchema.optional(),
        domain: z.string().optional(),
      }),
      summary: 'Get appointments',
    },
    importAppointments: {
      method: 'POST',
      path: 'import',
      responses: {
        201: z.array(
          z.object({
            id: z.string(),
          }),
        ),
      },
      body: importAppointmentsRequestSchema,
      summary: 'Import appointments',
    },
    getOrCreatePdfUrl: {
      method: 'POST',
      path: ':appointmentId/pdf',
      responses: {
        201: z.object({
          pdfUrl: z.string(),
        }),
      },
      body: z.object({}),
      summary: 'Get or create a PDF URL for an appointment',
    },
    /**
     * Prepare a file upload for an appointment by creating necessary DB records
     * to track the attempt and then returning a presigned upload URL
     */
    prepareAppointmentFileUpload: {
      method: 'POST',
      path: ':appointmentId/file-upload',
      body: prepareAppointmentFileUploadRequestSchema,
      responses: {
        201: z.object({
          gcsFilePath: z.string(),
          uploadURL: z.string(),
        }),
      },
    },
    /**
     * Mark a file upload that we previously prepped as complete once the
     * file has been succesfully uploaded to GCS
     */
    markFileUploadComplete: {
      method: 'POST',
      path: ':appointmentId/file-upload/complete',
      body: markFileUploadCompleteRequestSchema,
      responses: {
        200: z.object({}),
      },
    },
  },
  {
    pathPrefix: '/:tenantId/appointments/',
  },
);
