import React, { useMemo } from 'react';

import { TranscriptWithHighlights } from '@eluve/blocks';
import { Box, P } from '@eluve/components';
import {
  useAppointmentContext,
  useAppointmentTranscript,
} from '@eluve/frontend-appointment-hooks';
import {
  PendingTranscriptionLoader,
  useBillingCodeStore,
} from '@eluve/frontend-feature-appointment';
import { toSentences } from '@eluve/utils';

import { useAutoScrollContainer } from '../hooks/useAutoScrollContainer';

import { SessionStartedAtTimestamp } from './SessionStartedAtTimestamp';
import { UploadingFileLoader } from './UploadingFileLoader';

export const AppointmentCurrentTranscript: React.FC = () => {
  const { appointmentId, tenantId } = useAppointmentContext();
  const transcript = useAppointmentTranscript();
  const containerRef = useAutoScrollContainer([transcript]);

  const formattedTranscript = useMemo(
    () => toSentences(transcript),
    [transcript],
  );

  const highlightedExcerpts = useBillingCodeStore(
    (s) => s.highlightedTranscriptExcerpts,
  );

  return (
    <Box className="h-full overflow-y-auto" ref={containerRef}>
      <SessionStartedAtTimestamp />
      {transcript ? (
        <div className="text-gray-11 whitespace-pre-line align-baseline font-normal !leading-[2.5rem]">
          <TranscriptWithHighlights
            transcript={formattedTranscript}
            excerpts={highlightedExcerpts ?? []}
          />
        </div>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <P className="text-gray-8">No transcript.</P>
        </div>
      )}
      <PendingTranscriptionLoader
        tenantId={tenantId}
        appointmentId={appointmentId}
      />
      <UploadingFileLoader appointmentId={appointmentId} />
    </Box>
  );
};
