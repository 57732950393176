export const CONNECT = 'connect';
export const DISCONNECT = 'disconnect';
export const CONNECT_ERROR = 'connect_error';

export const START_RECORDING = 'start-recording';
export const STOP_RECORDING = 'stop-recording';
export const AUDIO_PACKET = 'audio-packet';
export const INITIALIZE_AUDIO_HANDLER = 'initialize-audio-handler';
export const TRANSCRIPT_EVENT = 'transcript';

export type ServerEvent = {
  action?: string;
  error?: string;
  ok: boolean;
};

export type TranscriptEvent = {
  transcript: string;
  isFinal: boolean;
};

export type AudioPacket = {
  data: Blob;
  timestamp: number;
  packetNumber: number;
};

export type InitializePacketHandler = {
  encoding: string;
  segmentId: string;
  audioFormat: string;
  userAgent: string;
  microphoneName: string | null;
  recordingStartedAt: string;
};
