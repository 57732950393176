import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import Markdown from 'react-markdown';

import { H1 } from '@eluve/components';
import { SOAPSchema, SOAP_KEYS } from '@eluve/llm-outputs';

import { OutputParsingError } from './OutputParsingError';

export interface SoapNoteOutputProps {
  content: Record<string, any>;
}

export const SoapNoteOutput: React.FC<SoapNoteOutputProps> = ({ content }) => {
  if (isEmpty(content)) {
    return;
  }

  const parsed = SOAPSchema.safeParse(content);

  if (!parsed.success) {
    return <OutputParsingError />;
  }

  const soapNote = parsed.data;

  return (
    <div className="space-y-4">
      {SOAP_KEYS.map((key) => {
        const sectionContent = soapNote[key];
        if (!sectionContent) {
          return null;
        }

        return (
          <div key={key}>
            <H1>{capitalize(key)}</H1>
            <Markdown className="prose ml-2 [&>ul]:list-disc">
              {sectionContent}
            </Markdown>
          </div>
        );
      })}
    </div>
  );
};
