import { useSuspenseQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Button, ColDefBuilder, DataTable } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

const getSourceArtifactsPendingReview = graphql(`
  query getSourceArtifactsPendingReview {
    evalSourceArtifact(where: { facts: { isVerified: { _eq: false } } }) {
      id
      name
      description
      createdAt
      factsAggregate(where: { isVerified: { _eq: false } }) {
        aggregate {
          count
        }
      }
    }
  }
`);

type SourceArtifactRow = {
  id: string;
  name: string | null;
  description: string | null;
  createdAt: string;
  numFactsPendingReview: number;
};

const columns = new ColDefBuilder<SourceArtifactRow>()
  .linkSortable('name', (row) => `./${row.id}`)
  .defaultSortable('description')
  .dateSortable('createdAt', 'Created At')
  .defaultSortable('numFactsPendingReview', 'Num Facts Pending Review')
  .colDef({
    header: 'Actions',
    cell: ({ row }) => {
      return (
        <div className="flex items-center gap-1">
          <Link to={`./${row.original.id}`}>
            <Button>Review</Button>
          </Link>
        </div>
      );
    },
  })
  .build();

export const SourceArtifactPendingReviewList: React.FC = () => {
  const { data } = useSuspenseQuery(getSourceArtifactsPendingReview);

  const rows = useMemo(() => {
    return data.evalSourceArtifact.map((sourceArtifact) => {
      return {
        id: sourceArtifact.id,
        name: sourceArtifact.name,
        description: sourceArtifact.description,
        createdAt: sourceArtifact.createdAt,
        numFactsPendingReview:
          sourceArtifact.factsAggregate.aggregate?.count ?? 0,
      };
    });
  }, [data]);

  return (
    <div className="rounded-md bg-white p-2">
      <DataTable columns={columns} data={rows} enableGlobalSearch />
    </div>
  );
};
