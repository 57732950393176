import orderBy from 'lodash/orderBy';
import { ArrowBigRight, ThumbsDown, ThumbsUp } from 'lucide-react';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  Button,
  ColDefBuilder,
  DataTable,
  P,
  SortableColumnHeader,
} from '@eluve/components';

import { SetDefaultPromptTemplateModelAction } from './SetDefaultPromptTemplateModelAction';
import { promptTemplateModelsFragment } from './prompt.operations';

type PromptModelRow = {
  promptTemplateId: string;
  modelArgsId: string;
  id: string;
  modelName: string;
  modelArgs: Record<any, any>;
  isDefault: boolean;
  isActive: boolean;
  totalFeedbackCount: number;
  positiveFeedbackCount: number;
  negativeFeedbackCount: number;
  averageRating: number | null;
};

const columns = new ColDefBuilder<PromptModelRow>()
  .colDef({
    header: 'View',
    cell: ({ row }) => (
      <Link to={`${row.original.id}`}>
        <Button variant="outline" size="icon">
          <ArrowBigRight />
        </Button>
      </Link>
    ),
  })
  .defaultSortable('modelName', 'Type')
  .colDef({
    accessorKey: 'modelArgs',
    header: 'Model Args',
    cell: ({ row }) => <pre>{JSON.stringify(row.original.modelArgs)}</pre>,
  })
  .defaultBoolean('isDefault', 'Default')
  .defaultSortable('totalFeedbackCount', 'Feedback')
  .colDef({
    accessorKey: 'positiveFeedbackCount',
    header: ({ column }) => (
      <SortableColumnHeader
        column={column}
        label={
          <TooltipLabel label="Positive Feedback">
            <ThumbsUp className="size-5" />
          </TooltipLabel>
        }
      />
    ),
    cell: ({ row }) => <P>{row.original.positiveFeedbackCount}</P>,
  })
  .colDef({
    accessorKey: 'negativeFeedbackCount',
    header: ({ column }) => (
      <SortableColumnHeader
        column={column}
        label={
          <TooltipLabel label="Negative Feedback">
            <ThumbsDown className="size-5" />
          </TooltipLabel>
        }
      />
    ),
    cell: ({ row }) => <P>{row.original.negativeFeedbackCount}</P>,
  })
  .colDef({
    accessorKey: 'averageRating',
    header: ({ column }) => (
      <SortableColumnHeader
        column={column}
        label={
          <TooltipLabel label="Average Rating">
            <span>Rating</span>
          </TooltipLabel>
        }
      />
    ),
    cell: ({ row }) => <P>{row.original.averageRating?.toFixed(2)}</P>,
  })
  .colDef({
    header: 'Actions',
    cell: ({
      row: {
        original: { modelArgsId, isDefault, isActive, promptTemplateId },
      },
    }) =>
      isActive ? (
        <div>
          <SetDefaultPromptTemplateModelAction
            promptTemplateId={promptTemplateId}
            modelArgsId={modelArgsId}
            isDefault={isDefault}
          />
        </div>
      ) : null,
  })
  .build();

export const PromptTemplateModelArgsList: React.FC<{
  promptTemplateId: string;
}> = ({ promptTemplateId }) => {
  const data = useCompleteFragment({
    fragment: promptTemplateModelsFragment,
    key: {
      id: promptTemplateId,
    },
  });

  const rows = useMemo(() => {
    return orderBy(
      (data.prompt_models ?? []).map((pm) => {
        const { feedback_aggregates: agg } = pm ?? {};

        return {
          promptTemplateId,
          id: pm.id,
          modelName: pm.model_args.modelType,
          modelArgs: pm.model_args.args ?? {},
          modelArgsId: pm.modelArgsId,
          isDefault: pm.isDefault,
          isActive: pm.isActive,
          totalFeedbackCount: agg?.totalFeedbackCount ?? 0,
          negativeFeedbackCount: agg?.negativeFeedbackCount ?? 0,
          positiveFeedbackCount: agg?.positiveFeedbackCount ?? 0,
          averageRating: agg?.averageRating ?? null,
        };
      }),
      (pm) => pm.isDefault,
      ['desc'],
    );
  }, [data, promptTemplateId]);

  return (
    <div className="overflow-x-scroll rounded-md">
      <DataTable columns={columns} data={rows} />
    </div>
  );
};
