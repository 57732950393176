import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';

import { useAppointmentId } from './appointment.context';

export const appointmentStatusFragment = graphql(`
  fragment appointmentStatus on Appointments @_unmask {
    status
  }
`);

export const useAppointmentStatus = () => {
  const id = useAppointmentId();

  const data = useCompleteFragment({
    fragment: appointmentStatusFragment,
    key: {
      id,
    },
  });

  return data.status;
};
