import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';
import { useUserIdFromSession } from '@eluve/session-helpers';

import { useAppointmentId } from './appointment.context';

const appointmentUserIdFragment = graphql(`
  fragment UserId on Appointments {
    __typename
    id
    userId
  }
`);

export const useIsAppointmentReadonly = () => {
  const userId = useUserIdFromSession();
  const appointmentId = useAppointmentId();

  const { userId: appointmentUserId } = useCompleteFragment({
    fragment: appointmentUserIdFragment,
    key: {
      id: appointmentId,
    },
  });

  if (userId !== appointmentUserId) {
    return true;
  }

  return false;
};
