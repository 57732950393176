import clsx from 'clsx';
import { ExternalLink, Pencil, Trash } from 'lucide-react';
import React from 'react';

import { TooltipLabel } from '@eluve/blocks';
import { Badge, Button, H3, P } from '@eluve/components';
import { billingCodeSchema } from '@eluve/llm-outputs';

import { OutputParsingError } from './OutputParsingError';
import { OutputTitle } from './OutputTitle';

export interface BillingCodeOutputProps {
  content: unknown;
}

export const BillingCodeOutput: React.FC<BillingCodeOutputProps> = ({
  content,
}) => {
  const billingCodes = billingCodeSchema.safeParse(content);

  if (!billingCodes.success) {
    return <OutputParsingError />;
  }

  return (
    <div className="space-y-4">
      <OutputTitle title="Billing Code Recommendations" />
      {billingCodes.data.map(
        ({ code, description, justification, type, excerpt }) => (
          <div className="space-y-2 border p-4 shadow-md" key={code}>
            <div className="items-top flex justify-between gap-6">
              <div className="flex items-center gap-2">
                <Badge
                  className={clsx({
                    'bg-cyan-500': type === 'CPT',
                    'bg-primary': type === 'ICD-10',
                    'bg-gray-10': type !== 'CPT' && type !== 'ICD-10',
                  })}
                >
                  {type}
                </Badge>
                <H3>{code}</H3>
                {type === 'ICD-10' && (
                  <a
                    href={`https://icd.who.int/browse10/2019/en#${code}`}
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                  >
                    <ExternalLink className="size-4" />
                  </a>
                )}
              </div>
              <div className="flex items-center gap-1">
                <TooltipLabel label="Edit this diagnosis">
                  <Button variant="outline" size="smallIcon">
                    <Pencil className="size-5" />
                  </Button>
                </TooltipLabel>

                <TooltipLabel label="Remove this diagnosis">
                  <Button variant="outline" size="smallIcon">
                    <Trash className="size-5" />
                  </Button>
                </TooltipLabel>
              </div>
            </div>
            <P className="font-normal italic">{description}</P>
            <P className="mt-2">
              <span className="font-extrabold">Justification: </span>
              {justification}
            </P>
            <P className="mt-2">
              <span className="font-extrabold">Excerpt: </span>
              {excerpt}
            </P>
          </div>
        ),
      )}
    </div>
  );
};
