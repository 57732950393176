import { useMutation } from '@apollo/client';
import { TextIcon } from 'lucide-react';
import { useMemo, useState } from 'react';
import { toast } from 'sonner';
import { v4 as uuid } from 'uuid';

import { cacheUtils } from '@eluve/apollo-client';
import { InsertTranscriptDocument } from '@eluve/client-gql-operations';
import { Box, Button, Label, Textarea } from '@eluve/components';
import { appointmentStatusFragment } from '@eluve/frontend-appointment-hooks';
import { useNamedLogger } from '@eluve/logger';
import { useTenantIdFromParams } from '@eluve/session-helpers';
import { useAppointmentTasksSelector } from '@eluve/user-local-files';

import { useSummarizeAppointment } from '../../hooks/useSummarizeAppointment';

interface SummarizeTranscriptProp {
  appointmentId: string;
}

export const SummarizeTranscript: React.FC<SummarizeTranscriptProp> = ({
  appointmentId,
}) => {
  const logger = useNamedLogger('SummarizeTranscript');
  const [transcript, setTranscript] = useState('');
  const [insertTranscript, { loading }] = useMutation(InsertTranscriptDocument);
  const tenantId = useTenantIdFromParams();
  const regenerateSummary = useSummarizeAppointment();
  const hasPendingAppointmentTask = useAppointmentTasksSelector(({ context }) =>
    context.tasks.some((t) => t.appointmentId === appointmentId),
  );

  const disabled = useMemo(
    () => !transcript.trim() || loading,
    [transcript, loading],
  );

  const summarizeTranscript = async () => {
    if (!transcript) {
      return;
    }

    const segmentId = uuid();

    const { errors } = await insertTranscript({
      context: {
        headers: {
          'x-hasura-role': 'eluve-admin',
        },
      },
      variables: {
        tenantId: tenantId ?? '',
        appointmentId: appointmentId,
        transcript: transcript,
        segmentId,
      },
    });

    if (errors) {
      logger.error('Failed to upload transcript');
      toast.error('Failed to upload transcript');
      return;
    }

    setTranscript('');

    const { status } = cacheUtils.readFragment({
      fragment: appointmentStatusFragment,
      key: {
        id: appointmentId,
      },
    });

    if (status !== 'COMPLETED') return;

    // We only need to call regenerate here if the appointment is already completed
    // If it wasn't completed before the call to insertTranscript, then it will
    // be marked as completed for us which will automatically trigger the regeneration
    // making this operation unnecessary.
    // See https://linear.app/eluve/issue/ELU-562/duplicate-summaries-on-staging
    regenerateSummary(tenantId!, appointmentId);
  };

  return (
    <Box vStack>
      <Label className="px-2">Summarize Transcript</Label>
      <Textarea
        className="box-shadow-none text-gray-12 h-[70px] w-full resize-none outline-none focus-visible:ring-0"
        value={transcript ?? ''}
        placeholder="Paste transcript here!"
        onChange={(e) => setTranscript(e.target.value)}
      />
      <Button
        type="button"
        className="w-fit"
        disabled={disabled || hasPendingAppointmentTask}
        onClick={summarizeTranscript}
      >
        <TextIcon className="mr-2 h-4 w-4" />
        Summarize Transcript
      </Button>
    </Box>
  );
};
