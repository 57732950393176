import { useEffect, useState } from 'react';

/* This return the current time every minute */
export const useTime = () => {
  const [time, setTime] = useState(new Date());

  const updateTime = () => {
    setTime(new Date());
  };

  useEffect(() => {
    const nextMinute = 60 - new Date().getSeconds();
    // Update time on the next minute
    let tick = setTimeout(() => {
      updateTime();
      // Update time every minute
      tick = setInterval(updateTime, 60000);
    }, nextMinute * 1000);

    return () => {
      clearTimeout(tick);
    };
  }, [time]);

  return time;
};
