import React, { createContext, useContext, useMemo } from 'react';

import { LogMetadata, Logger } from './main';

export type LoggerArgs = { name: string; metadata?: LogMetadata };

export interface LoggingContextProps {
  children: React.ReactNode;
  loggerInput: Logger | LoggerArgs;
}

type LoggingContextState = {
  logger: Logger;
  extend: (args: LoggerArgs) => Logger;
};

export const LoggingContext = createContext<LoggingContextState | undefined>(
  undefined,
);

export const useLoggingContext = () => {
  const context = useContext(LoggingContext);
  if (!context) {
    throw new Error(
      'useLoggingContext must be used within a LoggingContextProvider',
    );
  }
  return context;
};

export const useLogger = () => {
  const context = useLoggingContext();
  return context.logger;
};

export const useExtendedLogger = (args: LoggerArgs) => {
  const context = useLoggingContext();
  const extended = useMemo(() => context.extend(args), [context, args]);
  return extended;
};

export const useNamedLogger = (name: string) => {
  const context = useLoggingContext();
  const extended = useMemo(() => context.extend({ name }), [context, name]);
  return extended;
};

export const LoggingContextProvider: React.FC<LoggingContextProps> = ({
  children,
  loggerInput,
}) => {
  const context: LoggingContextState = useMemo(() => {
    const logger =
      loggerInput instanceof Logger
        ? loggerInput
        : new Logger(loggerInput.name, { metadata: loggerInput.metadata });

    return {
      logger,
      extend: ({ name, metadata: meta }) => {
        return new Logger(name, { metadata: { ...logger.metadata, ...meta } });
      },
    };
  }, [loggerInput]);

  return (
    <LoggingContext.Provider value={context}>
      {children}
    </LoggingContext.Provider>
  );
};
