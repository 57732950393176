import { useSuspenseQuery } from '@apollo/client';
import React from 'react';

import { ColDefBuilder, DataTable } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

export const getWorkflowsQuery = graphql(`
  query getWorkflows {
    evalFactVerificationWorkflows(orderBy: { createdAt: DESC }) {
      __typename
      id
      name
      startedById
      createdAt
    }
  }
`);

type WorkflowRow = {
  id: string;
  name: string;
  startedById: string;
  createdAt: string;
};

const columns = new ColDefBuilder<WorkflowRow>()
  .detailsLink((row) => row.id)
  .defaultSortable('name')
  .dateSortable('createdAt', 'Created At')
  .defaultSortable('startedById', 'Started By')
  .build();

export const FactVerificationWorkflowsList: React.FC = () => {
  const {
    data: { evalFactVerificationWorkflows: workflows },
  } = useSuspenseQuery(getWorkflowsQuery);

  const rows = workflows as WorkflowRow[];

  return (
    <div className="rounded-md bg-white p-2">
      <DataTable columns={columns} data={rows} />
    </div>
  );
};
