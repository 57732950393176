import { usePostHog } from 'posthog-js/react';
import { useMount } from 'react-use';

/**
 * A hook that will capture an explicit event whenever the component it is used inside is mounted.
 * This is useful for example to track page views for a specific page so we can measure its
 * adoption
 */
export const useCaptureEventOnMount = (
  eventName: string,
  properties?: Record<string, unknown>,
) => {
  const postHog = usePostHog();
  useMount(() => {
    postHog.capture(eventName, properties);
  });
};
