import { useMutation, useSuspenseQuery } from '@apollo/client';
import { LinkIcon } from 'lucide-react';
import React, { useState } from 'react';
import { toast } from 'sonner';

import { useCompleteFragment } from '@eluve/apollo-client';
import { useDialog } from '@eluve/blocks';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  H2,
  H4,
  Label,
  P,
  RadioGroup,
  RadioGroupItem,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

const sourceArtifactDatasetsFragment = graphql(`
  fragment SourceArtifactDatasetsFragment on EvalSourceArtifact @_unmask {
    __typename
    id
    datasetSourceArtifacts {
      __typename
      id
      dataset {
        __typename
        id
      }
    }
  }
`);

const getAllDatasetsQuery = graphql(`
  query getAllDatasets {
    evalDatasets {
      __typename
      id
      name
      description
    }
  }
`);

const addSourceArtifactToDatasetMutation = graphql(
  `
    mutation addSourceArtifactToDataset(
      $sourceArtifactId: uuid!
      $datasetId: uuid!
    ) {
      insertEvalDatasetSourceArtifactsOne(
        object: { sourceArtifactId: $sourceArtifactId, datasetId: $datasetId }
      ) {
        __typename
        id
        createdAt
        sourceArtifact {
          ...SourceArtifactDatasetsFragment
        }
      }
    }
  `,
  [sourceArtifactDatasetsFragment],
);

export interface AddSourceArtifactToDatasetDialogProps {
  sourceArtifactId: string;
}

export const AddSourceArtifactToDatasetDialog: React.FC<
  AddSourceArtifactToDatasetDialogProps
> = ({ sourceArtifactId }) => {
  const { isDialogOpen, toggleDialog, setIsDialogOpen } = useDialog();

  const {
    data: { evalDatasets: allDatasets },
  } = useSuspenseQuery(getAllDatasetsQuery);

  const data = useCompleteFragment({
    fragment: sourceArtifactDatasetsFragment,
    key: { id: sourceArtifactId },
  });

  const datasetsAvailable = allDatasets.filter(
    (dataset) =>
      !data.datasetSourceArtifacts.some(
        (existing) => existing.dataset.id === dataset.id,
      ),
  );

  const [selectedDatasetId, setSelectedDatasetId] = useState<
    string | undefined
  >(undefined);

  const [addSourceArtifactToDataset] = useMutation(
    addSourceArtifactToDatasetMutation,
    {
      onCompleted: () => {
        toast.success('Successfully added source artifact to dataset');
        setSelectedDatasetId(undefined);
        toggleDialog();
      },
      onError: () => {
        toast.error('Failed to add source artifact to dataset');
      },
    },
  );

  const onConfirm = async () => {
    addSourceArtifactToDataset({
      variables: {
        sourceArtifactId,
        datasetId: selectedDatasetId!,
      },
    });
  };

  return (
    <>
      <Button
        size="sm"
        onClick={toggleDialog}
        disabled={!datasetsAvailable.length}
      >
        <LinkIcon className="mr-2 h-5" />
        Add to dataset
      </Button>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="flex max-h-[80vh] flex-col gap-5 overflow-y-scroll p-8">
          <H2>Select a dataset to add this artifact to</H2>

          <div>
            <RadioGroup
              defaultValue="comfortable"
              onValueChange={(value) => setSelectedDatasetId(value)}
            >
              {datasetsAvailable.map((dataset) => (
                <div key={dataset.id} className="flex items-center space-x-2">
                  <RadioGroupItem value={dataset.id} id={dataset.id} />
                  <Label htmlFor="r1" className="m-1 flex flex-col gap-0.5 p-1">
                    <H4>{dataset.name}</H4>
                    <P>{dataset.description}</P>
                  </Label>
                </div>
              ))}
            </RadioGroup>
          </div>

          <DialogFooter>
            <Button onClick={onConfirm} disabled={!setSelectedDatasetId}>
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
