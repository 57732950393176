import { Slot } from '@radix-ui/react-slot';
import { isToday } from 'date-fns';
import dayjs from 'dayjs';
import React, { HTMLProps, useRef } from 'react';
import { useMemo } from 'react';
import { useMount } from 'react-use';

import { cn } from '@eluve/components';

import { useTime } from './useTime';

const getGridRow = (time: Date, day = dayjs(time).startOf('day')) => {
  const minutes = dayjs(time).diff(day, 'minute');
  return minutes + 1;
};

type TimeRange = [Date, Date];

const roundupTimeRange = (range: TimeRange) =>
  range.map((time) => dayjs(time).startOf('minute').toDate()) as TimeRange;

const isTimeRangesOverlaps = (
  [start1, end1]: TimeRange,
  [start2, end2]: TimeRange,
) => (start1 >= start2 && start1 < end2) || (end1 > start2 && end1 <= end2);

export const groupOverlapItems = <T,>(
  items: T[],
  getTimeRange: (item: T) => TimeRange,
) => {
  const itemsWithRange = items.map(
    (item, index) =>
      [index, roundupTimeRange(getTimeRange(item))] as [number, TimeRange],
  );

  const sortedItems = itemsWithRange.sort((a, b) => {
    const [startA] = a[1];
    const [startB] = b[1];
    return dayjs(startA).diff(startB);
  });

  const groupedItems: [number, TimeRange][][] = [];

  for (const item of sortedItems) {
    const [, itemRange] = item;
    let added = false;

    for (const group of groupedItems) {
      if (
        group.some(([, groupItemRange]) =>
          isTimeRangesOverlaps(itemRange, groupItemRange),
        )
      ) {
        group.push(item);
        added = true;
        break;
      }
    }

    if (!added) {
      groupedItems.push([item]);
    }
  }

  return groupedItems.map((group) => group.map(([index]) => items[index]));
};

interface TimeLabelProps {
  day: Date;
  showGrid?: boolean;
}

const TimeLabels: React.FC<TimeLabelProps> = ({ day, showGrid = true }) => {
  const startOfDay = dayjs(day).startOf('day');

  const timeRange = useMemo(() => {
    return Array(24)
      .fill(0)
      .map((_, i) => startOfDay.add(i, 'hour').toDate());
  }, [startOfDay]);

  return timeRange.map((time, index) => {
    const gridRow = index * 60 + 1;

    return (
      <React.Fragment key={index}>
        <div
          className="col-[1/2] -mt-2 text-nowrap border-r pr-4 text-right text-sm"
          style={{
            gridRowStart: gridRow,
            gridRowEnd: gridRow + 60,
          }}
        >
          {dayjs(time).format('h A')}
        </div>
        {showGrid && (
          <div
            className="col-[2/-1] border-t"
            style={{
              gridRowStart: gridRow,
              gridRowEnd: gridRow + 1,
            }}
          ></div>
        )}
      </React.Fragment>
    );
  });
};

export const TimeIndicator = React.forwardRef<HTMLDivElement, { time: Date }>(
  ({ time }, ref) => {
    const gridRowStart = getGridRow(time);

    return (
      <div
        className="col-[1/-1] flex max-h-[0px] w-full items-center"
        ref={ref}
        style={{
          gridRowStart: gridRowStart,
          gridRowEnd: gridRowStart + 1,
        }}
      >
        <div className="bg-primary text-gray-1 -ml-2 text-nowrap rounded-l-lg px-2 py-1 text-center text-xs">
          {dayjs(time).format('h:mm A')}
        </div>
        <div className="border-l-primary -mr-[14px] h-0 w-0 border-[12px] border-transparent" />
        <div className="bg-primary h-[3px] w-full rounded-r-full" />
      </div>
    );
  },
);

interface AgendaTimelineItemProps extends HTMLProps<HTMLDivElement> {
  startTime: Date;
  endTime: Date;
  asChild?: boolean;
}

export const AgendaTimelineItem = React.forwardRef<
  HTMLDivElement,
  AgendaTimelineItemProps
>(
  (
    { startTime, endTime, children, style, className, asChild, ...props },
    ref,
  ) => {
    const gridRowStart = getGridRow(startTime);
    const gridRowEnd = getGridRow(endTime, dayjs(startTime).startOf('day'));

    const Comp = asChild ? Slot : 'div';

    return (
      <Comp
        className={cn(
          'bg-gray-3 !pointer-events-auto col-[1/-1] h-full w-full rounded-md p-3',
          'hover:z-10 hover:shadow-lg',
          'transition-all duration-200 ease-in-out',
          className,
        )}
        style={{
          ...style,
          gridRowStart,
          gridRowEnd,
        }}
        {...props}
        ref={ref}
      >
        {children}
      </Comp>
    );
  },
);

interface AgendaTimelineGroupProps extends HTMLProps<HTMLDivElement> {
  asChild?: boolean;
}

export const AgendaTimelineGroup = React.forwardRef<
  HTMLDivElement,
  AgendaTimelineGroupProps
>(({ children, className, asChild, ...props }, ref) => {
  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      className={cn(
        'pointer-events-none col-[1/-1] row-[1/1441] grid w-full grid-rows-subgrid gap-x-1',
        '*:!col-auto',
        className,
      )}
      {...props}
      ref={ref}
    >
      {children}
    </Comp>
  );
});

interface AgendaTimelineProps extends HTMLProps<HTMLDivElement> {
  day: Date;
  asChild?: boolean;
  showGrid?: boolean;
}

export const AgendaTimeline = React.forwardRef<
  HTMLDivElement,
  AgendaTimelineProps
>(({ showGrid = true, day, children, className, asChild, ...props }, ref) => {
  const currentTime = useTime();
  const timeIndicatorRef = useRef<HTMLDivElement>(null);

  useMount(() => {
    if (timeIndicatorRef.current) {
      timeIndicatorRef.current.scrollIntoView({
        block: 'center',
        inline: 'center',
      });
    }
  });

  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      className={cn(
        'xs:p-4 grid h-full w-full grid-cols-[60px_8px_1fr_auto] gap-y-[1px] rounded-lg p-2',
        { 'overflow-y-auto': showGrid },
        className,
      )}
      ref={ref}
      {...props}
    >
      <TimeLabels day={day} showGrid={showGrid} />
      <div className="col-[3/-1] row-[1/1441] grid w-full grid-cols-[1fr] grid-rows-subgrid gap-x-1 overflow-x-auto">
        {children}
      </div>
      {isToday(day) && showGrid && (
        <TimeIndicator ref={timeIndicatorRef} time={currentTime} />
      )}
    </Comp>
  );
});
