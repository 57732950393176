import { LogLevel, Logger } from '@eluve/logger';

export const logMessage = (
  machine: { context: { logger: Logger } },
  params: {
    level: LogLevel;
    message: string;
    logMeta?: Record<string, unknown>;
  },
) => {
  const {
    context: { logger },
  } = machine;
  const { level, message, logMeta = {} } = params;
  logger[level](message, logMeta);
};
