import React from 'react';

import { H2 } from '@eluve/components';

export interface OutputTitleProps {
  title: string;
}

export const OutputTitle: React.FC<OutputTitleProps> = ({ title }) => {
  return <H2 cn="underline">{title}</H2>;
};
