import { graphql } from '@eluve/graphql.tada';

export const llmModelPromptTemplatesDetailsFragment = graphql(`
  fragment LlmModelPromptTemplatesDetails on LlmModelPromptTemplates @_unmask {
    __typename
    id
    isDefault
    isActive
    modelArgsId
    promptTemplateId
    feedback_aggregates {
      __typename
      id
      averageRating
      negativeFeedbackCount
      positiveFeedbackCount
      totalFeedbackCount
    }
    model_args {
      __typename
      id
      modelType
      args
    }
  }
`);

export const promptTemplateModelsFragment = graphql(
  `
    fragment PromptTemplateModels on PromptTemplates @_unmask {
      __typename
      id
      prompt_models {
        ...LlmModelPromptTemplatesDetails
      }
    }
  `,
  [llmModelPromptTemplatesDetailsFragment],
);
