import { Slot } from '@radix-ui/react-slot';
import { PencilIcon } from 'lucide-react';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';

import { Box } from '@eluve/components';
import { Button } from '@eluve/components';

import { TooltipLabel } from './TooltipLabel';

const contentEditableVariant = tv({
  base: 'focus:border-solid-bg -mb-0.5 block border-b-2 border-transparent focus:outline-none ',
});

interface ContentEditableProps extends React.HTMLAttributes<HTMLElement> {
  editButtonLabel?: React.ReactNode;
  onContentChange?: (content: string) => void;
}

export const ContentEditable = React.forwardRef<
  HTMLElement,
  ContentEditableProps
>(
  (
    { className, editButtonLabel, onContentChange, children, ...props },
    ref,
  ) => {
    const currentContent = useRef<string | null>(null);
    const [editing, setEditing] = useState(false);

    const containerRef = useRef<HTMLElement>(null);

    useImperativeHandle(ref, () => containerRef.current as HTMLElement);

    return (
      <Box className="group w-full">
        <Slot
          {...props}
          className={contentEditableVariant({ className })}
          ref={containerRef}
          contentEditable
          onFocus={(e) => {
            currentContent.current = e.currentTarget.textContent;
            setEditing(true);
            if (containerRef.current) {
              window.getSelection()?.selectAllChildren(containerRef.current);
            }
            window.getSelection()?.collapseToEnd();
          }}
          onBlur={(e) => {
            setEditing(false);
            const newContent = e.currentTarget.textContent;

            if (newContent === currentContent.current) {
              return;
            }

            if (!newContent) {
              e.currentTarget.textContent = currentContent.current ?? '';
              return;
            }

            onContentChange?.(newContent);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.currentTarget.blur();
            }
          }}
          suppressContentEditableWarning
        >
          {children}
        </Slot>
        {!editing && (
          <TooltipLabel label={editButtonLabel ?? 'Edit'}>
            <Button
              variant="ghost"
              size="icon"
              className="text-solid-bg hover:text-solid-bg-hovered hover:bg-ui-bg-hovered ml-1 opacity-70 group-hover:opacity-100"
              onClick={() => {
                containerRef.current?.focus();
              }}
            >
              <PencilIcon />
            </Button>
          </TooltipLabel>
        )}
      </Box>
    );
  },
);
