import { format } from 'date-fns';
import {
  CalendarIcon,
  ClockIcon,
  MapPinIcon,
  MoveHorizontalIcon,
  UserIcon,
} from 'lucide-react';

import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import { Box, DetailsSidebar, Divider } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { useUserName } from '../../../hooks/useUserName';

import { AppointmentDetailsFragment } from './AppointmentDetails';
import { PatientDetails } from './PatientDetails';

interface ReadonlyAppointmentDetailsProps {
  appointmentId: string;
}

const TimeFormat = 'hh : mm a';

const LocationNameFragment = graphql(`
  fragment Location on Locations {
    __typename
    id
    name
  }
`);

export const ReadonlyAppointmentDetails: React.FC<
  ReadonlyAppointmentDetailsProps
> = ({ appointmentId }) => {
  const { patientId, startDate, endDate, locationId, userId } =
    useCompleteFragment({
      fragment: AppointmentDetailsFragment,
      key: {
        id: appointmentId,
      },
    });

  const locationName = locationId
    ? cacheUtils.readFragment({
        fragment: LocationNameFragment,
        key: {
          id: locationId,
        },
        strict: false,
      })?.name
    : 'No location specified';

  const username = useUserName(userId ?? '');

  return (
    <DetailsSidebar className="w-full max-w-[340px] gap-4 px-0">
      <Box vStack className="h-full">
        {patientId && (
          <>
            <div className="px-3">
              <PatientDetails patientId={patientId} />
            </div>
            <Divider />
          </>
        )}
        <Box vStack className="w-full gap-4 px-4 pt-4">
          <h4 className="text-semibold text-gray-12">Appointment</h4>
          <Box className="grid grid-cols-[auto_auto] items-center gap-x-8 gap-y-4 text-sm">
            <span className="text-gray-10">Date</span>
            <Box hStack>
              <CalendarIcon className="mr-2 h-4 w-4" />
              {startDate ? (
                format(new Date(startDate), 'PPP')
              ) : (
                <span>No date specified</span>
              )}
            </Box>
            <span className="text-gray-10">Time</span>

            <Box hStack>
              <ClockIcon className="text-gray-10 w-4" />
              <span>
                {startDate
                  ? format(new Date(startDate), TimeFormat)
                  : 'Start Time'}
              </span>
              <MoveHorizontalIcon className="text-gray-10 w-4" />
              <span>
                {endDate ? format(new Date(endDate), TimeFormat) : 'End Time'}
              </span>
            </Box>

            <span className="text-gray-10">Location</span>
            <Box
              hStack
              className="overflow-hidden text-ellipsis whitespace-nowrap text-sm"
            >
              <MapPinIcon size={16} />
              {locationName ?? 'No location Specified'}
            </Box>

            <span className="text-gray-10">Created by</span>
            <Box hStack>
              <UserIcon size={16} />
              <span className="text-gray-11">{username}</span>
            </Box>
          </Box>
        </Box>
      </Box>
    </DetailsSidebar>
  );
};
