import { Editor, Node, Range, mergeAttributes } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { PluginKey } from '@tiptap/pm/state';
import Suggestion from '@tiptap/suggestion';

import { billingCodesDB } from '@eluve/indexed-db';

import { renderItems } from './renderItems';

// limit the number of search results
const SEARCH_RESULT_MAX = 10;
// change to force clients to refresh billing codes

interface CommandProps {
  editor: Editor;
  range: Range;
}

type SuggestedItem = {
  title: string;
  description: string;
  identifier: string;
  command?: ({ editor, range }: CommandProps) => void;
};

type Options = {
  HTMLAttributes: Record<string, any>;
  suggestion: Record<string, any>;
};

type OptionRenderHtmlType = {
  options: Options;
  node: ProseMirrorNode;
};

type RenderHtmlType = {
  node: ProseMirrorNode;
};

const Command = Node.create({
  name: 'billing-codes',
  addOptions() {
    return {
      HTMLAttributes: {},
      renderHTML({ options, node }: OptionRenderHtmlType) {
        return [
          'span',
          mergeAttributes(this.HTMLAttributes, {
            'data-type': 'billing-codes',
            'data-code': node.attrs.code,
            'data-description': node.attrs.description,
            'data-identifier': node.attrs.identifier,
          }),
          `${options.suggestion.char}${node.attrs.code}: ${node.attrs.description}`,
        ];
      },
      suggestion: {
        allowSpaces: true,
        char: '#',
        command: async ({
          editor,
          range,
          props,
        }: {
          editor: Editor;
          range: Range;
          props: any;
        }) => {
          props.command({ editor, range });
        },
      },
    };
  },
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      code: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-code'),
        renderHTML: (attributes) => {
          if (!attributes.code) {
            return {};
          }
          return {
            'data-code': attributes.code,
          };
        },
      },

      description: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-description'),
        renderHTML: (attributes) => {
          if (!attributes.description) {
            return {};
          }

          return {
            'data-description': attributes.description,
          };
        },
      },

      identifier: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-identifier'),
        renderHTML: (attributes) => {
          if (!attributes.identifier) {
            return {};
          }

          return {
            'data-identifier': attributes.identifier,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ node }: RenderHtmlType) {
    const html = this.options.renderHTML({
      options: this.options,
      node,
    });

    return html;
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        pluginKey: new PluginKey('billing-codes'),
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

const getSuggestionItems = async ({ query }: { query: string }) => {
  const billingCodes = await billingCodesDB.get();
  const suggestedItems: SuggestedItem[] = billingCodes.map((bc) => ({
    title: bc.code,
    description: bc.description,
    identifier: bc.identifier,
    command: ({ editor, range }: CommandProps) => {
      editor
        .chain()
        .focus()
        .insertContentAt(range, [
          {
            type: 'billing-codes',
            attrs: {
              code: bc.code,
              description: bc.description,
              identifier: bc.identifier,
            },
          },
        ])
        .run();
    },
  }));

  return suggestedItems?.reduce((acc: SuggestedItem[], item) => {
    if (
      acc.length < SEARCH_RESULT_MAX &&
      typeof query === 'string' &&
      query.length > 0
    ) {
      const search = query.toLowerCase().replace(/\./g, '');
      if (
        item.title.toLowerCase().replace(/\./g, '').includes(search) ||
        item.description.toLowerCase().includes(search)
      ) {
        acc.push(item);
      }
    }
    return acc;
  }, []);
};

const BillingCodes = Command.configure({
  suggestion: {
    items: getSuggestionItems,
    render: renderItems,
  },
  pluginKey: 'billing-codes',
});

export default BillingCodes;
