import { useSuspenseQuery } from '@apollo/client';
import { CubeIcon } from '@radix-ui/react-icons';
import { FileCodeIcon, TriangleAlertIcon } from 'lucide-react';
import { Suspense } from 'react';

import { TooltipLabel } from '@eluve/blocks';
import { Divider, Label, Skeleton } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

const GetAppointmentLatestPromptTemplateDocument = graphql(`
  query getAppointmentLatestPromptTemplate($assessmentId: uuid!) {
    appointmentLlmOutputs(
      where: {
        appointmentId: { _eq: $assessmentId }
        output: {
          modelPromptTemplate: { template: { outputType: { _eq: SOAP_NOTE } } }
        }
      }
      limit: 1
      orderBy: { output: { updatedAt: DESC } }
    ) {
      output {
        id
        modelPromptTemplate {
          id
          model_args {
            id
            args
          }
          template {
            name
            id
          }
        }
      }
    }
  }
`);

interface LatestAppointmentTemplateProps {
  appointmentId: string;
}

const LatestAppointmentTemplateContent: React.FC<
  LatestAppointmentTemplateProps
> = ({ appointmentId }) => {
  const { data } = useSuspenseQuery(
    GetAppointmentLatestPromptTemplateDocument,
    {
      variables: {
        assessmentId: appointmentId,
      },
    },
  );

  const output = data.appointmentLlmOutputs[0]?.output;

  if (!output) {
    return (
      <div className="flex w-full gap-3 rounded-lg border border-amber-300 bg-amber-50 p-3 text-sm text-amber-500">
        <TriangleAlertIcon className="h-5 w-5" />
        No summary generated for this appointment.
      </div>
    );
  }

  const {
    template,
    model_args: { args: modelArgs },
  } = output.modelPromptTemplate;

  return (
    <div className="text-gray-11 border-ui-border-gray flex flex-col gap-0 rounded-lg border text-sm">
      <div className="flex flex-1 items-center gap-2 p-2">
        <TooltipLabel label="Template">
          <FileCodeIcon className="mx-1 h-6 w-6" />
        </TooltipLabel>
        <div>{template.name}</div>
      </div>
      {modelArgs && (
        <>
          <Divider className="ml-12" />
          <div className="flex flex-1 items-center gap-2 p-2 ">
            <TooltipLabel label="Model">
              <CubeIcon className="mx-1 h-6 w-6" />
            </TooltipLabel>
            <div className="flex flex-col space-y-1">
              <p>{modelArgs.model}</p>
              <div>
                Temperature: <b>{modelArgs.temperature}</b>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const LatestAppointmentTemplateLoader = () => (
  <div className="flex flex-col space-y-4">
    <div className="text-gray-11 border-ui-border-gray flex flex-col gap-0 rounded-lg border text-sm">
      <div className="flex flex-1 items-center gap-2 p-2">
        <TooltipLabel label="Template">
          <FileCodeIcon className="mx-1 h-6 w-6" />
        </TooltipLabel>
        <Skeleton className="h-[10px] w-[200px] rounded-full" />
      </div>
      <Divider className="ml-12" />
      <div className="flex flex-1 items-center gap-2 p-2 ">
        <TooltipLabel label="Model">
          <CubeIcon className="mx-1 h-6 w-6" />
        </TooltipLabel>
        <Skeleton className="h-[10px] w-[200px] rounded-full" />
      </div>
    </div>
  </div>
);

export const LatestAppointmentTemplate: React.FC<
  LatestAppointmentTemplateProps
> = ({ appointmentId }) => {
  return (
    <div className="space-y-2">
      <Label className="px-1">Latest summary info</Label>
      <Suspense fallback={<LatestAppointmentTemplateLoader />}>
        <LatestAppointmentTemplateContent appointmentId={appointmentId} />
      </Suspense>
    </div>
  );
};
