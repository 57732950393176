import { useCompleteFragment } from '@eluve/apollo-client';
import { useUserIdFromSession } from '@eluve/session-helpers';

import { userSettingsForUserFragment } from './operations';

export const useUserSettings = () => {
  const userId = useUserIdFromSession();

  const { userSettings } =
    useCompleteFragment({
      fragment: userSettingsForUserFragment,
      key: {
        id: userId,
      },
      strict: false,
    }) ?? {};

  return { userSettings };
};
