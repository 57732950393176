import { Download, Headphones, MessageSquareQuote } from 'lucide-react';
import React from 'react';
import { toast } from 'sonner';

import { useApiClient } from '@eluve/api-client-provider';
import { TooltipLabel } from '@eluve/blocks';
import { Box, Button } from '@eluve/components';

export interface AppointmentFileActionsColumnProps {
  tenantId: string;
  appointmentId: string;
  fileId: string;
  filePath: string;
}

export const AppointmentFileActionsColumn: React.FC<
  AppointmentFileActionsColumnProps
> = ({ appointmentId, fileId, filePath, tenantId }) => {
  const api = useApiClient();

  const getFile = async (type: 'download' | 'listen') => {
    if (!filePath) return;
    const result = await api.adminAppointment.getAppointmentFileSignedUrl({
      params: { tenantId, appointmentId, fileId },
    });

    if (result.status === 200 && result.body.presignedUrl) {
      if (type === 'listen') {
        window.open(result.body.presignedUrl, '_blank');
      } else {
        const fetched = await fetch(result.body.presignedUrl);
        const blob = await fetched.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = filePath.split('/').pop() ?? '';
        link.click();

        window.URL.revokeObjectURL(url);
      }
    } else {
      toast.error('Failed to generate file URL');
    }
  };

  if (!filePath) return null;

  return (
    <Box hStack>
      <TooltipLabel label="Listen to audio">
        <Button
          variant="outline"
          size="icon"
          onClick={() => getFile('listen')}
          disabled={!filePath}
        >
          <Headphones />
        </Button>
      </TooltipLabel>

      <TooltipLabel label="Download">
        <Button
          variant="outline"
          size="icon"
          onClick={() => getFile('download')}
          disabled={!filePath}
        >
          <Download />
        </Button>
      </TooltipLabel>
      <TooltipLabel label="Transcribe this audio">
        <Button variant="outline" size="icon" disabled={!filePath}>
          <MessageSquareQuote />
        </Button>
      </TooltipLabel>
    </Box>
  );
};
