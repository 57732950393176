import { fromPromise } from 'xstate';

import { Logger } from '@eluve/logger';

import { AppointmentFilesTableDependency, FileDriftReport } from '../types';

export const reconcileDrift = fromPromise<
  void,
  {
    logger: Logger;
    driftReport: FileDriftReport;
    appointmentFilesTable: AppointmentFilesTableDependency;
  }
>(async ({ input }) => {
  const { appointmentFilesTable, driftReport, logger } = input;
  // throw new Error('reconcileDrift not implemented');
  if (driftReport.type === 'NoDriftDetected') {
    logger.warn('No drift detected. Nothing to reconcile');
    return;
  }

  const { missingInIdb, missingInOpfs } = driftReport;

  if (missingInOpfs.length) {
    try {
      await appointmentFilesTable.bulkDelete(missingInOpfs);
    } catch (e) {
      logger.error(
        'Failed to remove file references from idb that are missing in OPFS',
        {
          error: e,
        },
      );
    }
  }

  if (missingInIdb.length) {
    logger.warn('Found files in OPFS that are missing in db', {
      count: missingInIdb.length,
    });

    try {
      await appointmentFilesTable.bulkAdd(
        missingInIdb.map((x) => ({
          id: x.id,
          appointmentId: x.appointmentId,
          lastUploadAttemptedAt: new Date(0).toISOString(),
          attemptCount: 0,
          // Assume that both of these are true since this is a lost file and we
          // don't have any other info
          isBackgroundUpload: true,
          wasDegraded: true,
        })),
      );
    } catch (e) {
      logger.warn('Failed to add missing files to idb');
    }
  }
});
