import { createZustandContext } from '@eluve/stores';

type EHRSyncStoreState = {
  lastSuccessfulEhrSyncAt?: string;
  isDataImporting?: boolean;
  setIsDataImporting: (isDataImporting: boolean) => void;
};

export const {
  StoreProvider: EHRSyncStoreProvider,
  useStore: useEHRSyncStore,
  useStoreApi: useEHRSyncStoreApi,
} = createZustandContext<EHRSyncStoreState>();
