import { QUERY_ROOT_ID, useCompleteFragment } from '@eluve/apollo-client';
import { useUserIdFromSession } from '@eluve/session-helpers';

import {
  activeSOAPPromptTemplatesFragment,
  userSettingsForUserFragment,
} from './operations';

export const usePromptTemplates = () => {
  const userId = useUserIdFromSession();

  const { userSettings } = useCompleteFragment({
    fragment: userSettingsForUserFragment,
    key: {
      id: userId,
    },
  });

  const { activePromptTemplates: activeSOAPPromptTemplates } =
    useCompleteFragment({
      fragment: activeSOAPPromptTemplatesFragment,
      key: QUERY_ROOT_ID,
    });

  const defaultPromptTemplateId = activeSOAPPromptTemplates?.find(
    (template) => template.isCurrentDefault,
  )?.id;

  const userSettingsPromptTemplateId = userSettings?.promptTemplateId;

  return {
    activeSOAPPromptTemplates,
    defaultPromptTemplateId,
    userSettingsPromptTemplateId,
  };
};
