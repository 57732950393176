import { jwtDecode } from 'jwt-decode';
import * as Session from 'supertokens-auth-react/recipe/session';

import { SessionSchema } from '@eluve/api-contract';
import { Logger } from '@eluve/logger';

import { AccessTokenProvider, ISessionProvider } from './types';

/**
 * Uses the SuperTokens web sdk to provide session tokens.
 */
export class BrowserSessionProvider implements ISessionProvider {
  getAccessToken = Session.getAccessToken;
  getAccessTokenPayloadSecurely = Session.getAccessTokenPayloadSecurely;
}

export const sessionAccessTokenProvider: (
  sessionProvider: ISessionProvider,
) => AccessTokenProvider = (sessionProvider) => async () => {
  let role = '';

  const payload = await sessionProvider.getAccessTokenPayloadSecurely();
  const result = SessionSchema.safeParse(payload);
  if (result.success) {
    role = result.data['https://hasura.io/jwt/claims']['x-hasura-default-role'];
  }
  const jwt = (await sessionProvider.getAccessToken()) ?? '';

  return {
    jwt,
    role,
  };
};

export const tenantAccessTokenProvider: (
  tenantId: string,
  updateSession: () => Promise<void>,
) => AccessTokenProvider = (tenantId, updateSession) => {
  const logger = new Logger('Tenant Access Token Provider');
  let jwt = '';
  let role = '';

  const processSession = async () => {
    let sessionJwt = '';
    let sessionRole = '';
    let tenantIdFromPayload: string | undefined = undefined;

    const payload = await Session.getAccessTokenPayloadSecurely();
    sessionJwt = (await Session.getAccessToken()) ?? '';

    const result = SessionSchema.safeParse(payload);
    if (result.success) {
      sessionRole =
        result.data['https://hasura.io/jwt/claims']['x-hasura-default-role'];

      tenantIdFromPayload =
        result.data['https://hasura.io/jwt/claims']['x-hasura-tenant-id'];
    }

    return {
      sessionJwt,
      sessionRole,
      tenantIdFromPayload,
    };
  };

  return async () => {
    if (!jwt || jwtDecode(jwt)!.exp! < Date.now() / 1000) {
      const { tenantIdFromPayload, ...rest } = await processSession();

      if (tenantIdFromPayload && tenantIdFromPayload !== tenantId) {
        // The active tenant in the session is different from the one we want to use
        // This could be due to the user opening a new tab and switching to a different tenant
        logger.info(
          `Tenant id mismatch. Expected: ${tenantId}, Found: ${tenantIdFromPayload}. Attempting to reconcile`,
        );
        await updateSession();
        const result = await processSession();
        // If after updating the session the tenant id is still incorrect, throw an error
        if (result.tenantIdFromPayload !== tenantId) {
          throw new Error('Tenant id mismatch');
        }

        logger.info(
          `Tenant id reconciled. Successfully acquired access token for tenant id: ${tenantId}`,
        );
        jwt = result.sessionJwt;
        role = result.sessionRole;
      } else {
        jwt = rest.sessionJwt;
        role = rest.sessionRole;
      }
    }

    return {
      jwt,
      role,
    };
  };
};
