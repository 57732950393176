import copy from 'copy-to-clipboard';
import { CopyIcon } from 'lucide-react';
import { useState } from 'react';

import {
  Button,
  ButtonProps,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from '@eluve/components';

interface CopyTextButtonProps {
  copyText: string | (() => string);
  hideContentInTooltip?: boolean;
}

export const CopyTextButton: React.FC<ButtonProps & CopyTextButtonProps> = ({
  copyText,
  hideContentInTooltip = false,
  children,
  ...buttonProps
}) => {
  const defaultTooltipContent =
    !hideContentInTooltip && typeof copyText === 'string'
      ? `Copy: ${copyText}`
      : 'Copy to clipboard';

  const [tooltipContent, setTooltipContent] = useState(defaultTooltipContent);
  const [open, setOpen] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const text = typeof copyText === 'function' ? copyText() : copyText;
    copy(text);
    setTooltipContent('Copied!');
    setOpen(true);
    setTimeout(() => setTooltipContent(defaultTooltipContent), 3000);
    e.currentTarget.blur();
  };

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0} open={open} onOpenChange={setOpen}>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            onClick={handleClick}
            {...buttonProps}
          >
            {children ?? <CopyIcon className="h-4 w-4" />}
          </Button>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent sideOffset={8}>{tooltipContent}</TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  );
};
