import { VendorTypesEnum } from '@eluve/graphql-types';
import { MessageClient } from '@eluve/message-client';
import { PostMessenger } from '@eluve/post-messenger';
import { extensionVersionRequired } from '@eluve/smart-blocks';
import { MemoryCache } from '@eluve/utils';
import { WebAppContract } from '@eluve/vendor';
import { ConfigContract } from '@eluve/vendor';

import { appConfig } from '../../../config';

import { logger } from './logger';

type Ehr = {
  domain: string;
  vendor: VendorTypesEnum;
};

type InitData = {
  ehrs: Ehr[];
  tenantId: string;
  tenantName: string;
};

const postMessenger = new PostMessenger({ name: 'web-app' });
let isPostMessengerConnecting = false;
export let isPostMessengerConnected = false;

const sendRequest = async (
  procedureName: string,
  extensionVersionRequired: string,
  data?: Record<string, unknown>,
) => {
  const response = await postMessenger.sendRequest(
    procedureName,
    { data, extensionVersionRequired },
    {
      timeout: 120000,
    },
  );

  if (procedureName === 'config' && data?.get) {
    cache.set(
      procedureName,
      response as unknown as ConfigContract['EHR_STATUS_DETAILS'],
      10000,
    );
  }
  return response;
};

const cache = new MemoryCache<{
  config: ConfigContract[keyof ConfigContract];
}>();

let cachedInitData: InitData | null = null;

const { proxy: messageClient } = new MessageClient<WebAppContract>({
  messageSender: async ({ procedureName, args }) => {
    const data = args[0];

    if (procedureName === 'config') {
      const configArgs = data as unknown as {
        get?: { key?: ConfigContract[keyof ConfigContract] };
      };
      if (configArgs?.get?.key === 'EHR_STATUS_DETAILS') {
        const result = cache.get(procedureName);
        if (result) {
          return result as ConfigContract['EHR_STATUS_DETAILS'];
        }
      }
    }

    if (isPostMessengerConnected) {
      return sendRequest(procedureName, extensionVersionRequired, data);
    }
    if (!isPostMessengerConnecting && cachedInitData) {
      await connect(cachedInitData);
      return sendRequest(procedureName, extensionVersionRequired, data);
    }

    throw new Error(
      'Lost connection with Eluve extension. Page refresh needed',
    );
  },
});

const connect = async (connectionData: InitData) => {
  if (isPostMessengerConnecting || isPostMessengerConnected) {
    return;
  }
  const { ehrs, tenantId, tenantName } = connectionData;
  const vendors = ehrs.reduce(
    (acc, ehr) => {
      if (!acc[ehr.vendor]) {
        acc[ehr.vendor] = {
          domains: {},
        };
      }
      acc[ehr.vendor].domains[ehr.domain] = {};
      return acc;
    },
    {} as Record<
      VendorTypesEnum,
      { domains: { [key: string]: Record<string, unknown> } }
    >,
  );
  const data: ConfigContract['EHR_STATUS_DETAILS'] = {
    eluveDomains: {
      [appConfig.VITE_API_DOMAIN]: {
        tenants: {
          [tenantId]: {
            tenantName,
            vendors,
          },
        },
      },
    },
  };

  isPostMessengerConnecting = true;
  try {
    await postMessenger.connect({
      targetOrigin: window.location.origin,
      targetWindow: window.parent,
      data,
    });
    isPostMessengerConnected = true;
  } catch (error) {
    isPostMessengerConnected = false;
  } finally {
    isPostMessengerConnecting = false;
  }
  logger.debug(`PostMessenger connected at ${new Date().toISOString()}`);
};

const initPostMessenger = async (
  ehrs: Ehr[],
  tenantId: string,
  tenantName: string,
) => {
  cachedInitData = { ehrs, tenantId, tenantName };
  await connect({ ehrs, tenantId, tenantName });
};

export { initPostMessenger, postMessenger, messageClient };
