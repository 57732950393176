import { ApolloClient } from '@apollo/client';
import { fromPromise } from 'xstate';

import { graphql } from '@eluve/graphql.tada';

export const markAppointmentAsCompleteMutation = graphql(`
  mutation markAppointmentAsComplete($id: uuid!) {
    updateAppointments(
      where: { id: { _eq: $id } }
      _set: { status: COMPLETED }
    ) {
      returning {
        id
        status
      }
    }
  }
`);

export interface Input {
  apolloClient: ApolloClient<unknown>;
  appointmentId: string;
}

export const markAppointmentAsComplete = fromPromise<unknown, Input>(
  async ({ input }) => {
    const { apolloClient, appointmentId } = input;

    await apolloClient.mutate({
      mutation: markAppointmentAsCompleteMutation,
      variables: { id: appointmentId },
    });
  },
);
