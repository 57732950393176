import { LlmOutputTypesEnum } from '@eluve/graphql-types';

export const LLM_OUTPUT_TYPE_VARIABLES: Record<LlmOutputTypesEnum, string[]> = {
  SOAP_NOTE: ['transcript', 'schema'],
  FACT_VERIFICATION: ['summary', 'statement'],
  CLIENT_RECAP: ['transcript'],
  BILLING_CODE_RECOMMENDATION: ['transcript', 'schema'],
  DIFFERENTIAL_DIAGNOSES: ['transcript', 'schema'],
  GENERIC_OUTPUT: [],
  REVIEW_OF_SYSTEMS: ['transcript', 'schema'],
  PFSH: ['transcript', 'schema'],
  FACT_GENERATION: ['artifactContent', 'schema'],
  CHIEF_COMPLAINT: ['transcript', 'schema'],
  PII_REMOVAL: ['text', 'schema'],
};
