import dayjs from 'dayjs';
import { TbMailForward } from 'react-icons/tb';
import { toast } from 'sonner';

import { cacheUtils } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import { Button } from '@eluve/components';
import { useAppointmentId } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';
import { useNamedLogger } from '@eluve/logger';
import { SummarySectionMarkdownAction } from '@eluve/smart-blocks';

const externalPatientInfoFragment = graphql(`
  fragment ExternalPatientInfo on Appointments {
    __typename
    patient {
      id
      external_patients_info {
        __typename
        id
        email
      }
    }
  }
`);

export const ClientRecapMailToAction: SummarySectionMarkdownAction = ({
  getSummary,
}) => {
  const logger = useNamedLogger('ClientRecapMailToAction');
  const appointmentId = useAppointmentId();

  const handleMailTo = () => {
    const summary = getSummary();

    if (!summary) {
      toast.info('Client recap summary is empty');
      return;
    }

    const patientExternalInfoData = cacheUtils.readFragment({
      fragment: externalPatientInfoFragment,
      key: {
        id: appointmentId,
      },
      strict: false,
    });

    const patient = patientExternalInfoData?.patient?.external_patients_info[0];

    logger.info('Sending client recap to patient', {
      recapLength: summary.length,
      patientId: patient?.id,
      patientEmailLength: patient?.email?.length,
    });

    const subject = `Follow up from today's session (${dayjs().format('MMM DD')})`;

    window.open(
      `mailto:${patient?.email ?? ''}?subject=${subject}&body=${encodeURIComponent(summary)}`,
      '_blank',
    );
  };

  return (
    <TooltipLabel label="Send mail to patient">
      <Button
        size="icon"
        variant="ghost"
        className="hover:text-brand-9 hover:bg-transparent"
        onClick={handleMailTo}
      >
        <TbMailForward className="size-5" />
      </Button>
    </TooltipLabel>
  );
};
