import { graphql } from '@eluve/graphql.tada';

const patientFragment = graphql(`
  fragment patient on Patients @_unmask {
    __typename
    id
    firstName
    lastName
    dateOfBirth
    external_patients_info {
      __typename
      id
      externalPatientId
      homePhoneNumber
      cellPhoneNumber
      workPhoneNumber
      email
      external_ehr {
        __typename
        id
        vendor
        domain
      }
    }
  }
`);

export const patientsFragment = graphql(
  `
    fragment TenantPatients on Tenants @_unmask {
      patients {
        __typename
        ...patient
      }
    }
  `,
  [patientFragment],
);

export const getPatientsQuery = graphql(
  `
    query getTenantPatients($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        __typename
        id
        ...TenantPatients
      }
    }
  `,
  [patientsFragment],
);
