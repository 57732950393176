import { useEffect } from 'react';
import { create } from 'zustand';

export const createSidebarControl = () => {
  const useSidebarOpen = create<{
    // To control the open state of the sheet
    isOpen: boolean;
    // To hide the trigger when the sheet is not mounted
    mounted: boolean;
  }>(() => ({
    isOpen: false,
    mounted: false,
  }));

  const setSidebarOpen = (isOpen: boolean | ((prev: boolean) => boolean)) => {
    useSidebarOpen.setState((state) => ({
      ...state,
      isOpen: typeof isOpen === 'function' ? isOpen(state.isOpen) : isOpen,
    }));
  };

  const useSidebar = () => {
    const isOpen = useSidebarOpen(({ isOpen }) => isOpen);

    useEffect(() => {
      useSidebarOpen.setState({ mounted: true });
      return () => {
        useSidebarOpen.setState({ mounted: false, isOpen: false });
      };
    }, []);

    return {
      isOpen,
      setSidebarOpen,
    };
  };

  const useSidebarTrigger = () => {
    const mounted = useSidebarOpen(({ mounted }) => mounted);
    return { isVisible: mounted, setSidebarOpen };
  };

  return {
    useSidebar,
    useSidebarTrigger,
  };
};
