import chunk from 'lodash/chunk';
import { z } from 'zod';

import { ERRORS } from '../../errors';
import { AppointmentModel } from '../../models/appointment';
import { PatientType } from '../../models/patient';
import { CanSyncToEhrArgs, Vendor } from '../vendor';

import { logo } from './logo';
import { convertMovementXDataToAppointmentModel } from './movement-x.appointment';
import { Appointment, appointmentSchema, patientSchema } from './types';

const appointmentData = z.object({
  appointments: z.array(z.unknown()),
});
export class MovementXVendor implements Vendor {
  getChart = (data: unknown) => {
    throw new Error('Method not implemented.');
  };

  getAppointments = (data: unknown): AppointmentModel[] => {
    const rawAppointments = appointmentData.parse(data);

    const appointments: AppointmentModel[] = [];
    for (const appointment of rawAppointments.appointments) {
      const appointmentParseResult = appointmentSchema.safeParse(appointment);

      if (!appointmentParseResult.success) {
        throw new Error(
          `Invalid appointment data from 3rd party EHR: ${appointmentParseResult.error}`,
        );
      }
      const appointmentModel = convertMovementXDataToAppointmentModel(
        appointmentParseResult.data,
      );

      appointments.push(appointmentModel);
    }

    return appointments;
  };

  getPatient = (data: unknown): PatientType | null => {
    const parsedPatient = patientSchema.parse(data);

    return {
      externalPatientId: `${parsedPatient.id}`,
      firstName: parsedPatient.user?.first_name ?? '',
      lastName: parsedPatient.user?.last_name ?? '',
      dateOfBirth: parsedPatient.date_birth ?? null,
      email: parsedPatient?.user?.email ?? null,
      homePhone: null,
      cellPhone: parsedPatient?.phone ?? null,
      workPhone: null,
      rawData: data as Record<string, unknown>,
    };
  };

  getPatientEhrUrl = ({
    domain,
    externalPatientId,
  }: {
    domain: string;
    externalPatientId?: string;
  }): string => {
    return `https://${domain}/#/providers/patients/view/${externalPatientId}`;
  };

  getLogo = () => logo;

  getHomeUrl = (domain: string): string => {
    return `https://${domain}`;
  };

  fetchAppointments = async ({
    domain,
    request,
    startDate,
    endDate,
  }: {
    domain: string;
    request: typeof fetch;
    startDate: number;
    endDate: number;
  }) => {
    const query = [
      `start_time=${new Date(startDate).toISOString()}`,
      `end_time=${new Date(endDate).toISOString()}`,
    ].join('&');
    const baseUrl = `https://movementx.ai/api/session/?${query}`;

    let allResults: Appointment[] = [];

    const appointmentResponse = await request(baseUrl);

    if (!appointmentResponse.ok) {
      if (appointmentResponse.status === 401) {
        return { ok: false, error: ERRORS.NOT_LOGGED_IN };
      }
      return { ok: false, error: ERRORS.FAILED_TO_IMPORT };
    }

    const body = await appointmentResponse.json();

    allResults = allResults.concat(body.data);

    return {
      ok: true,
      data: {
        response: allResults,
        timezone: '+00:00',
      },
    };
  };

  fetchPatientsByIds = async ({
    ids,
    domain,
    request,
  }: {
    ids: number[];
    domain: string;
    request: typeof fetch;
  }) => {
    const patients: any[] = [];
    const batchSize = 3;

    const idChunks = chunk(ids, batchSize);

    for (const chunk of idChunks) {
      try {
        const responses = await Promise.all(
          chunk.map(async (id) => {
            const response = await request(
              `https://movementx.ai/api/patient?id=${id}`,
            );

            if ([401, 403].includes(response.status)) {
              return {
                ok: false,
                error: ERRORS.NOT_LOGGED_IN,
              };
            }

            if (!response.ok) {
              const body = await response.json();
              return {
                ok: false,
                error: `Could not fetch patient ${id} by ID: ${JSON.stringify({ body, status: response.status })}`,
              };
            }

            const { data } = await response.json();
            return data[0];
          }),
        );

        patients.push(...responses);
      } catch (error: unknown) {
        return { ok: false, error: (error as Error)?.message };
      }
    }

    return { ok: true, data: patients };
  };

  canSyncNoteToEhr(data: CanSyncToEhrArgs): boolean {
    return (
      Boolean(data.externalAppointmentId) && Boolean(data.externalPatientId)
    );
  }

  canSignNoteInEhr(): boolean {
    return false;
  }

  canSyncWithManualChartUrl(): boolean {
    return false;
  }
}
