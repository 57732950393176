import { TriangleAlertIcon } from 'lucide-react';
import { useEffect, useState } from 'react';

import {
  Box,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@eluve/components';

type MicrophoneOption = {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
};

interface MicrophoneDeviceSelectorProps {
  onDeviceValueChange?: (deviceId: string) => void;
  selectedMicId?: string | null;
}

export const MicrophoneDeviceSelector: React.FC<
  MicrophoneDeviceSelectorProps
> = ({ onDeviceValueChange, selectedMicId }) => {
  const [microphoneOptions, setMicrophoneOptions] = useState<
    MicrophoneOption[]
  >([]);

  const loadMicrophoneOptions = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioDevices = devices.filter(
      (device) => device.kind === 'audioinput' && device.deviceId,
    );
    setMicrophoneOptions(audioDevices as MicrophoneOption[]);
  };

  const selectedMic =
    microphoneOptions.find((mic) => mic.deviceId === selectedMicId)?.label ??
    'Select Microphone';

  useEffect(() => {
    loadMicrophoneOptions();
  }, [selectedMicId]);

  const handleMicChange = (deviceId: string) => {
    onDeviceValueChange?.(deviceId);
  };

  return (
    <Select
      onValueChange={handleMicChange}
      onOpenChange={loadMicrophoneOptions}
    >
      <SelectTrigger className="text-gray-12">
        <SelectValue className="text-gray-12" placeholder={selectedMic} />
      </SelectTrigger>
      <SelectContent>
        {microphoneOptions.length === 0 && (
          <Box
            vStack
            center
            fullWidth
            className="bg-amber-100 p-4 text-amber-600"
          >
            <TriangleAlertIcon size={24} />
            No microphone found
            <span className="text-xs">
              Please check your microphone permissions if you have audio input
              devices connected
            </span>
          </Box>
        )}
        {microphoneOptions.map((device) => {
          return (
            <SelectItem
              key={device.label}
              className="focus:bg-brand-4 pl-7"
              value={device.deviceId}
            >
              {device.label}
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
};
