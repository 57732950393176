import { ArrowUpFromLineIcon } from 'lucide-react';

import {
  Alert,
  AlertDescription,
  AlertTitle,
  Progress,
} from '@eluve/components';
import { useAppointmentTasksSelector } from '@eluve/user-local-files';

export const UploadingFileLoader: React.FC<{
  appointmentId: string;
}> = ({ appointmentId }) => {
  const uploadingFileLoaderProgress = useAppointmentTasksSelector((state) => {
    const { activeTask } = state.context;
    if (
      activeTask &&
      activeTask.appointmentId === appointmentId &&
      activeTask.type === 'TASK.UPLOAD_FILE'
    ) {
      return activeTask.progress;
    }

    return undefined;
  });

  if (uploadingFileLoaderProgress === undefined) {
    return null;
  }

  return (
    <Alert variant="default" ref={(e) => e?.scrollIntoView()}>
      <ArrowUpFromLineIcon className="mr-4 size-5" />
      <AlertTitle>Uploading Recording</AlertTitle>
      <AlertDescription>
        <Progress
          value={uploadingFileLoaderProgress}
          className="mt-4 h-2 w-full"
        />
      </AlertDescription>
    </Alert>
  );
};
