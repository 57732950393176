import clsx from 'clsx';
import React from 'react';

import { Badge, P, cn } from '@eluve/components';

export interface MedicalCodeProps {
  code: string;
  type: string;
}

export const MedicalCode: React.FC<MedicalCodeProps> = ({ code, type }) => {
  const typeLabel = type === 'ICD_10' ? 'ICD-10' : type;

  return (
    <>
      <Badge
        className={cn(
          'pointer-events-none',
          clsx({
            'bg-cyan-500': type === 'CPT',
            'bg-primary': typeLabel === 'ICD-10',
            'bg-gray-10': typeLabel !== 'CPT' && typeLabel !== 'ICD-10',
          }),
        )}
      >
        {type}
      </Badge>
      <P>{code}</P>
    </>
  );
};
