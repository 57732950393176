import { z } from 'zod';

import { makeFeatureFlagPayloadValidator } from './utils';

export const noteRegenerationExampleChips = makeFeatureFlagPayloadValidator({
  defaultEnabled: false,
  schema: z.array(z.string()),
  defaultValue: [
    'Remove parts about...',
    'Include discussion about...',
    'Make notes more concise...',
    'Add more details in the plan section...',
  ],
});
