import { Slot } from '@radix-ui/react-slot';
import React, { useId } from 'react';
import { tv } from 'tailwind-variants';

import { Box } from './box';

interface InputLabelProps
  extends Omit<React.HTMLProps<HTMLLabelElement>, 'label'> {
  label: React.ReactNode;
}

const labelVariant = tv({
  base: 'text-gray-10 flex flex-col gap-2 text-sm font-semibold uppercase',
});

export const InputLabel = React.forwardRef<HTMLLabelElement, InputLabelProps>(
  ({ children, className, label, ...props }, ref) => {
    const id = useId();

    return (
      <Box vStack fullWidth>
        <label
          {...props}
          ref={ref}
          className={labelVariant({ className })}
          htmlFor={id}
        >
          {label}
        </label>
        <Slot id={id}>{children}</Slot>
      </Box>
    );
  },
);
