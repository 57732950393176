import { useSuspenseQuery } from '@apollo/client';
import { UserIcon } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { CopyTextButton } from '@eluve/blocks';
import {
  ColDefBuilder,
  DataTable,
  P,
  PageTitle,
  SortableColumnHeader,
  makeDefaultSortableColumnDef,
  makeVisibilityToggleColumnDef,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { AddUserAction } from './components/AddUserAction';
import { RoleSelector } from './components/RoleSelector';
import { UserRemoveButton } from './components/UserRemoveButton';
import { TenantUserFragment } from './operations';

export type TenantUserRow = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  role: string;
  lastAccessedAt: string;
  tenantId: string;
};

const columns = new ColDefBuilder<TenantUserRow>()
  .colDef(
    makeVisibilityToggleColumnDef({
      ...makeDefaultSortableColumnDef('id', 'Id'),
      id: 'id',
      accessorFn: (row) => row.id,
      hiddenCell: ({ row }) => (
        <CopyTextButton copyText={row.original.id}>
          <UserIcon className="h-6 w-6" />
        </CopyTextButton>
      ),
    }),
  )
  .defaultSortable('firstName', 'First')
  .defaultSortable('lastName', 'Last')
  .defaultSortable('email')
  .dateSortable('createdAt', 'Created At')
  .dateSortable('lastAccessedAt', 'Last Signed In')
  .colDef({
    accessorKey: 'role',
    header: ({ column }) => (
      <SortableColumnHeader column={column} label="Role" />
    ),
    cell: ({ row }) => {
      return (
        <RoleSelector
          tenantId={row.original.tenantId}
          userId={row.original.id}
        />
      );
    },
  })
  .colDef({
    accessorKey: 'action',
    header: () => <P>Actions</P>,
    cell: ({ row }) => {
      return (
        <div>
          <UserRemoveButton
            user={row.original}
            tenantId={row.original.tenantId}
          />
        </div>
      );
    },
  })
  .build();

const GetTenantUsersDocument = graphql(
  `
    query GetTenantUsers($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        __typename
        id
        tenantUsers {
          ...TenantUser
        }
      }
    }
  `,
  [TenantUserFragment],
);

export const AdminTenantUsers: React.FC = () => {
  const { tenantId } = useParams() as { tenantId: string };
  const { data } = useSuspenseQuery(GetTenantUsersDocument, {
    variables: {
      tenantId: tenantId,
    },
  });

  const rows = (data?.tenantsByPk?.tenantUsers ?? []).map<TenantUserRow>(
    (t) => {
      return {
        id: t.userId,
        firstName: t.user?.firstName ?? '',
        lastName: t.user?.lastName ?? '',
        email: t.user?.email ?? '',
        createdAt: t.createdAt,
        role: t.role ?? '',
        lastAccessedAt: t.lastAccessedAt,
        tenantId: t.tenantId,
      };
    },
  );

  return (
    <div>
      <div className="flex justify-between">
        <PageTitle>Users</PageTitle>
        <AddUserAction
          tenantId={tenantId}
          addedUserIds={rows.map((r) => r.id)}
        />
      </div>
      <DataTable data={rows} columns={columns} enableGlobalSearch />
    </div>
  );
};
