import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { supportedVendorsSchema } from '@eluve/graphql-types';

const c = initContract();

export const chartsContract = c.router(
  {
    importChart: {
      method: 'POST',
      path: '/import',
      responses: {
        201: z.object({}),
      },
      body: z.object({
        data: z.object({
          chart: z.record(z.unknown()),
          appointmentId: z.string().optional(),
        }),
        vendor: supportedVendorsSchema,
        domain: z.string(),
      }),
      summary: 'Import charts from EHR',
    },
    getMissingCharts: {
      method: 'GET',
      path: '/missing-charts',
      responses: {
        200: z.array(
          z.object({
            appointmentId: z.string(),
            externalPatientId: z.string(),
            externalChartUrl: z.string(),
            externalChartId: z.string(),
          }),
        ),
      },
      query: z.object({
        vendor: supportedVendorsSchema,
        domain: z.string(),
      }),
      summary: 'Get patient and appointment IDs of missing charts',
    },
    updateLastImportAttempt: {
      method: 'PUT',
      path: '/last-import-attempt',
      responses: {
        200: z.object({}),
      },
      body: z.object({
        vendor: supportedVendorsSchema,
        domain: z.string(),
        chartIds: z.array(z.string()),
      }),
      summary: 'Update last import attempt for charts',
    },
    createChart: {
      method: 'POST',
      path: '/create',
      responses: {
        201: z.object({
          id: z.string(),
        }),
        422: z.object({
          message: z.string(),
        }),
      },
      body: z.object({
        appointmentId: z.string(),
        chartUrl: z.string(),
        externalPatientId: z.string(),
        patientId: z.string(),
        externalAppointmentId: z.string().nullish(),
        externalEhrId: z.string(),
        externalChartId: z.string(),
      }),
      summary: 'Upserts basic chart details',
    },
  },
  {
    pathPrefix: '/:tenantId/charts',
  },
);
