import { useSuspenseQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';

import { getAppointmentOutputQuery } from './AppointmentDetailsOutput';
import { AppointmentPdf } from './AppointmentPdf';

export interface AppointmentPdfPageProps {}

export const AppointmentPdfPage: React.FC<AppointmentPdfPageProps> = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  const { data } = useSuspenseQuery(getAppointmentOutputQuery, {
    variables: { appointmentId, outputType: 'SOAP_NOTE' },
  });

  const soapData = data.appointmentLlmOutputs[0]?.output?.content ?? {};

  return (
    <AppointmentPdf
      tenantId={tenantId}
      appointmentId={appointmentId}
      data={soapData}
    />
  );
};
