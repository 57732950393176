import React, { useState } from 'react';
import { validate as validUUID } from 'uuid';

import { useApiClient } from '@eluve/api-client-provider';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  H2,
  H3,
  Input,
  P,
  PageTitle,
  toast,
} from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';

export const AssetDeletionDash: React.FC = () => {
  const logger = useNamedLogger('AssetDeletionDash');
  const [appointmentId, setAppointmentId] = useState<string>('');
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const apiClient = useApiClient();

  const submitAssetDeletionRequest = async () => {
    logger.debug('submitAssetDeletionRequest');

    if (!appointmentId) {
      return;
    }

    setIsSubmitting(true);

    const response = await apiClient.adminAppointment.deleteAppointmentAssets({
      body: {},
      params: {
        appointmentId,
      },
    });

    if (response.status === 200) {
      setAppointmentId('');
      toast({
        duration: 10 * 1000,
        variant: 'success',
        title: 'Success!',
        description: 'Job scheduled for deletion',
      });
    }

    if (response.status === 400) {
      toast({
        duration: 10 * 1000,
        variant: 'destructive',
        title: 'Failed to submit asset deletion request',
        description: response.body.message,
      });
    }

    setIsSubmitting(false);
    setConfirmationDialogOpen(false);
  };

  return (
    <div>
      <PageTitle> Asset Deletion Dash</PageTitle>
      <div className="w-full py-10">
        <div className="flex flex-col gap-4">
          <H3>Appointment Asset Deletion</H3>

          <P>
            Schedule a job to delete transcripts and audio data for an
            appointment. This job will be executed 5 days after it's submitted.
          </P>

          <div className="flex flex-row gap-4 ">
            <Input
              value={appointmentId}
              onChange={(e) => setAppointmentId(e.target.value)}
              className="w-[400px]"
            />
            <Button
              variant="destructive"
              onClick={() => setConfirmationDialogOpen(true)}
              disabled={!validUUID(appointmentId)}
            >
              Delete Appointment Asset
            </Button>
          </div>

          <Dialog
            open={confirmationDialogOpen}
            onOpenChange={setConfirmationDialogOpen}
          >
            <DialogContent className="gap-6">
              <H2>Are you sure?</H2>
              <P>
                Are you sure you want to delete audio files and transcripts for
                appointment{' '}
                <span className="text-brand-9">{appointmentId}</span>?
              </P>
              <P className="text-xs">
                If yes, this job will be scheduled for execution 5 days from
                now. If you'd like to cancel the job before it runs, you can
                delete the entry from the graphile_worker.jobs database table.
              </P>
              <DialogFooter>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  className="border-gray-5 text-gray-10 border bg-white hover:bg-white"
                  onClick={() => setConfirmationDialogOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={submitAssetDeletionRequest}
                  disabled={isSubmitting}
                >
                  Yes, I'm sure
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
