import React from 'react';

import { useAppointmentId } from '@eluve/frontend-appointment-hooks';

import { useTranscriptionMachineSelector } from '../machines/transcription/TranscriptionMachineProvider';

import { AppointmentCurrentTranscript } from './AppointmentCurrentTranscript';
import { AppointmentInSessionTranscript } from './AppointmentInSessionTranscript';

export const AppointmentTranscription: React.FC = () => {
  const appointmentId = useAppointmentId();
  const currentAppointmentInSession = useTranscriptionMachineSelector(
    (s) =>
      s.matches({ InSession: 'Recording' }) &&
      s.context.sessionAppointmentId === appointmentId,
  );

  if (currentAppointmentInSession) {
    return <AppointmentInSessionTranscript />;
  }
  return <AppointmentCurrentTranscript />;
};
