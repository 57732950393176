import {
  ApolloCache,
  Cache,
  Reference,
  TypedDocumentNode,
} from '@apollo/client';
import { ResultOf, VariablesOf } from '@graphql-typed-document-node/core';

import { getFragmentDefinition } from './getFragmentDefinition';
import {
  FragmentOptions,
  KeyFields,
  OnlyStringKeys,
  Schema,
  TypeSafePolicies,
  ValidQueryRoot,
} from './types';

type ReadFragmentOptions<
  QueryRoot extends ValidQueryRoot,
  TSP extends TypeSafePolicies<QueryRoot>,
  T extends TypedDocumentNode,
> = Omit<
  Cache.ReadFragmentOptions<ResultOf<T>, VariablesOf<T>>,
  'fragment' | 'id'
> &
  FragmentOptions<QueryRoot, TSP, T>;

type UpdateFragmentOptions<
  QueryRoot extends ValidQueryRoot,
  TSP extends TypeSafePolicies<QueryRoot>,
  T extends TypedDocumentNode,
> = Omit<
  Cache.UpdateFragmentOptions<ResultOf<T>, VariablesOf<T>>,
  'fragment' | 'id'
> &
  FragmentOptions<QueryRoot, TSP, T>;

type WriteFragmentOptions<
  QueryRoot extends ValidQueryRoot,
  TSP extends TypeSafePolicies<QueryRoot>,
  T extends TypedDocumentNode,
> = Omit<
  Cache.WriteFragmentOptions<ResultOf<T>, VariablesOf<T>>,
  'fragment' | 'id'
> &
  FragmentOptions<QueryRoot, TSP, T>;

export class CacheUtils<
  QueryRoot extends ValidQueryRoot,
  TSP extends TypeSafePolicies<QueryRoot> = TypeSafePolicies<QueryRoot>,
  S = Schema<QueryRoot>,
> {
  policies = {} as TSP;
  private _cache: ApolloCache<any> | undefined;

  constructor(cache?: ApolloCache<any>) {
    if (cache) {
      this._cache = cache;
    }
  }

  get cache() {
    if (!this._cache) {
      throw new Error('Cache is not defined');
    }
    return this._cache;
  }

  withCache(cache: ApolloCache<any>) {
    this._cache = cache;
    return this;
  }

  withPolicies<NewTSP extends TSP>(policies: NewTSP) {
    this.policies = policies;
    return this as unknown as Omit<
      CacheUtils<QueryRoot, NewTSP>,
      'withPolicies'
    >;
  }

  /**
   * A function that returns the cache id for a given entity.
   * if the entity is not found in the cache, it will return undefined.
   * @param typeName The typename of the entity
   * @param key The key fields of the entity
   *
   * @returns The cache id of the entity or undefined
   */
  getCacheId<K extends OnlyStringKeys<keyof S>>(
    typeName: K,
    key: KeyFields<QueryRoot, TSP, K, S>,
  ) {
    return this.cache.identify({
      __typename: typeName,
      ...(key as Record<string, unknown>),
    });
  }

  /**
   * A function that returns the cache id for a given entity.
   * if the entity is not found in the cache, it will throw an error.
   * @param typeName The typename of the entity
   * @param key The key fields of the entity
   *
   * @returns The cache id of the entity
   */
  getCacheIdOrThrow<K extends OnlyStringKeys<keyof S>>(
    typeName: K,
    key: KeyFields<QueryRoot, TSP, K, S>,
  ) {
    const id = this.getCacheId(typeName, key);

    if (!id) {
      throw new Error(
        `Could not find entity in cache. type: ${typeName}, key: ${JSON.stringify(key)}`,
      );
    }

    return id;
  }

  /**
   * A function that returns the result of a fragment if it is complete.
   * If the fragment is not complete, it will throw an error.
   * If the strict option is set to false, it will return null instead of throwing an error.
   *
   * @param options The options for the fragment
   *
   * @param options.fragment The fragment document
   * @param options.key The key fields of the entity
   * @param options.strict Whether to throw an error if the fragment is not complete
   * @param options.fragmentName The name of the fragment (optional)
   */
  readFragment<T extends TypedDocumentNode>(
    options: ReadFragmentOptions<QueryRoot, TSP, T> & { strict: false },
  ): ResultOf<T> | null;
  readFragment<T extends TypedDocumentNode>(
    options: ReadFragmentOptions<QueryRoot, TSP, T> & { strict?: true },
  ): ResultOf<T>;
  readFragment<T extends TypedDocumentNode>(
    options: ReadFragmentOptions<QueryRoot, TSP, T> & { strict?: boolean },
  ) {
    const fragmentDefinition = getFragmentDefinition(
      options.fragment,
      options.fragmentName,
    );

    const tableName = fragmentDefinition.typeCondition.name.value;

    const id = this.cache.identify({
      __typename: tableName,
      ...options.key,
    });

    if (!id && options.strict !== false) {
      throw new Error(
        `Could not find entity in cache. type: ${tableName}, key: ${JSON.stringify(options.key)}`,
      );
    }

    const fragment = this.cache.readFragment<ResultOf<T>, VariablesOf<T>>({
      fragmentName: options.fragmentName ?? fragmentDefinition.name.value,
      ...options,
      id: id ?? '',
    });

    if (!fragment) {
      if (options.strict !== false) {
        throw new Error('Fragment is not complete');
      }

      return null;
    }

    return fragment as ResultOf<T>;
  }

  /**
   * A function that updates a fragment in the cache.
   *
   * @param options The options for the fragment
   * @param options.fragment The fragment document
   * @param options.key The key fields of the entity
   *
   * @param update A function which returns the updated fragment with params of current fragment data if exists
   */
  updateFragment<T extends TypedDocumentNode>(
    options: UpdateFragmentOptions<QueryRoot, TSP, T>,
    update: (data: ResultOf<T> | null) => ResultOf<T> | null | void,
  ) {
    const fragmentDefinition = getFragmentDefinition(
      options.fragment,
      options.fragmentName,
    );
    const tableName = fragmentDefinition.typeCondition.name.value;

    return this.cache.updateFragment<ResultOf<T>, VariablesOf<T>>(
      {
        fragmentName: options.fragmentName ?? fragmentDefinition.name.value,
        ...options,
        id: this.cache.identify({
          __typename: tableName,
          ...options.key,
        }),
      },
      update,
    );
  }

  /**
   * A function that writes a new fragment to the cache.
   *
   * @param options The options for the fragment
   * @param options.fragment The fragment document
   * @param options.key The key fields of the entity
   *
   */
  writeFragment<T extends TypedDocumentNode>(
    options: WriteFragmentOptions<QueryRoot, TSP, T>,
  ): Reference | undefined {
    const fragmentDefinition = getFragmentDefinition(
      options.fragment,
      options.fragmentName,
    );
    const tableName = fragmentDefinition.typeCondition.name.value;

    return this.cache.writeFragment<ResultOf<T>, VariablesOf<T>>({
      fragmentName: options.fragmentName ?? fragmentDefinition.name.value,
      ...options,
      id: this.cache.identify({
        __typename: tableName,
        ...options.key,
      }),
    });
  }

  /**
   * A function that modifies a field in the cache.
   *
   * @param options The options for the field modification
   * @param options.typeName The typename of the entity
   * @param options.key The key fields of the entity
   */
  modify<K extends OnlyStringKeys<keyof S>>(
    options: Omit<
      Cache.ModifyOptions<
        S[K] extends Record<string, unknown> ? S[K] : Record<string, unknown>
      >,
      'id'
    > & {
      typeName: K;
      key: KeyFields<QueryRoot, TSP, K, S>;
    },
  ) {
    const { typeName, key, ...rest } = options;

    return this.cache.modify({
      ...rest,
      id: this.getCacheIdOrThrow(typeName, key),
    });
  }

  evict<K extends OnlyStringKeys<keyof S>>({
    typeName,
    key,
    ...options
  }: Omit<Cache.EvictOptions, 'id'> & {
    typeName: K;
    key: KeyFields<QueryRoot, TSP, K, S>;
  }) {
    return this.cache.evict({
      id: this.getCacheId(typeName, key),
      ...options,
    });
  }
}
