import { useQuery } from '@apollo/client';
import React from 'react';

import { CopyTextButton } from '@eluve/blocks';
import {
  Badge,
  Box,
  ColDefBuilder,
  DataTable,
  Link,
  P,
  PageSubtitle,
  makeDefaultSortableColumnDef,
  makeVisibilityToggleColumnDef,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import {
  ModelArgsColDef,
  ModelArgsColDefProps,
} from '../components/ModelArgsColDef';
import {
  PromptTemplateColDef,
  PromptTemplateColDefProps,
} from '../components/PromptTemplateColDef';

type SummaryFeedbackRow = {
  id: string;
  appointmentId: string;
  type: string | null;
  comment: string | null;
  tags: string[];
  createdAt: string;
  rating: number | null;
} & PromptTemplateColDefProps &
  ModelArgsColDefProps;

const getFeedbackQuery = graphql(`
  query getFeedback {
    summaryFeedback(orderBy: { createdAt: DESC }) {
      id
      createdAt
      type
      comment
      rating
      appointmentId
      summaryFeedbackTags {
        feedbackTag {
          name
        }
      }
      llm_output {
        modelPromptTemplate {
          id
          model_args {
            id
            args
          }
          template {
            name
            id
          }
        }
      }
    }
  }
`);

const columns = new ColDefBuilder<SummaryFeedbackRow>()
  .colDef({
    ...makeDefaultSortableColumnDef('appointmentId', 'Appointment'),
    id: 'appointmentId',
    accessorFn: (row) => row.appointmentId,
    cell: ({ row }) => (
      <Box hStack>
        <Link
          to={`../appointments/${row.original.appointmentId}`}
          relative="path"
        >
          {row.original.appointmentId}
        </Link>
        <CopyTextButton
          copyText={row.original.appointmentId}
          variant="ghost"
          className=" hover:bg-transparent"
        />
      </Box>
    ),
  })
  .colDef(
    makeVisibilityToggleColumnDef({
      ...makeDefaultSortableColumnDef('id', 'Feedback'),
      id: 'feedbackId',
      accessorFn: (row) => row.id,
      hiddenCell: ({ row }) => (
        <Box center>
          <CopyTextButton copyText={row.original.id} />
        </Box>
      ),
      cell: ({ row }) => {
        return row.original.id;
      },
    }),
  )
  .defaultSortable('type', {
    cellRenderer: ({ type }) => (
      <P className={type === 'POSITIVE' ? 'text-green-700' : 'text-red-700'}>
        {type}
      </P>
    ),
  })
  .defaultSortable('rating')
  .defaultSortable('comment')
  .colDef({
    accessorKey: 'tags',
    header: 'Tags',
    accessorFn: (row) => row.tags.join(', '),
    cell: ({ row }) => {
      return (
        <>
          {row.original.tags.map((tag) => (
            <Badge variant="outline" className="my-1" key={tag}>
              {tag}
            </Badge>
          ))}
        </>
      );
    },
  })
  .colDef(PromptTemplateColDef())
  .colDef(ModelArgsColDef())
  .dateSortable('createdAt', 'Created At')
  .build();

export const AdminFeedbackDash: React.FC = () => {
  const { data } = useQuery(getFeedbackQuery);

  return (
    <div>
      <PageSubtitle>Feedback</PageSubtitle>
      <DataTable
        data={
          data?.summaryFeedback?.map((sf) => {
            const output = sf.llm_output?.modelPromptTemplate;
            return {
              id: sf.id,
              appointmentId: sf.appointmentId,
              comment: sf.comment,
              type: sf.type,
              createdAt: sf.createdAt,
              tags: (sf.summaryFeedbackTags || []).map(
                (sft) => sft.feedbackTag.name,
              ),
              rating: sf.rating,
              promptTemplate: output?.template || null,
              modelArgs: output?.model_args || null,
            };
          }) ?? []
        }
        enableGlobalSearch
        columns={columns}
      />
    </div>
  );
};
