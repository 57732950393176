import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useApiClient } from '@eluve/api-client-provider';
import { RelatedArtifactsList } from '@eluve/frontend-feature-eval';

export const AdminAppointmentSourceArtifacts: React.FC = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };
  const client = useApiClient();

  const [hashedTenantId, setHashedTenantId] = useState<string | null>(null);
  const [hashedAppointmentId, setHashedAppointmentId] = useState<string | null>(
    null,
  );

  useEffect(() => {
    const fetchHashedIds = async () => {
      const resTenant = await client.eval.getEvalHashedId({
        query: { id: tenantId },
      });

      if (resTenant.status === 200) {
        setHashedTenantId(resTenant.body.hashedId);
      }

      const resAppt = await client.eval.getEvalHashedId({
        query: { id: appointmentId },
      });

      if (resAppt.status === 200) {
        setHashedAppointmentId(resAppt.body.hashedId);
      }
    };

    fetchHashedIds();
  }, [tenantId, appointmentId, client]);

  if (!hashedTenantId || !hashedAppointmentId) {
    return null;
  }

  return (
    <RelatedArtifactsList
      tenantId={hashedTenantId}
      appointmentId={hashedAppointmentId}
    />
  );
};
