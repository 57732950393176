import { AppointmentModel } from '../../models/appointment';

import { Appointment } from './types';

export const convertJaneDataToAppointmentModel = (
  data: Appointment,
): AppointmentModel => {
  const { patient } = data;

  const appointmentModel: AppointmentModel = {
    externalId: `${data.id}`,
    externalPatientId: data.patient_id ? `${data.patient_id}` : undefined,
    firstName: patient?.first_name ?? '',
    lastName: patient?.last_name ?? '',
    startTime: data.start_at,
    endTime: data.end_at ?? '',
    name: data.treatment_name ?? '',
    description: data.notes_text ?? '',
    rawEhrData: data,
    status: undefined,
    isArchived: Boolean(data.archived_at || data.cancelled),
    // Do not import appointments that are not booked or arrived or missing patient
    isImportable:
      ['booked', 'arrived'].includes(data.state ?? '') &&
      Boolean(data.patient_id),
    sessionType: data.treatment_name ?? undefined,
  };

  return appointmentModel;
};
