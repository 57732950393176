import { useMutation } from '@apollo/client';
import React from 'react';

import { Select, SelectTrigger } from '@eluve/components';
import { useUserIdFromSession } from '@eluve/session-helpers';

import { PromptTemplateSelectContent } from './PromptTemplateSelectContent';
import { upsertPromptTemplateIdMutation } from './operations';
import { usePromptTemplates } from './usePromptTemplates';

export const PromptTemplateSettings: React.FC = () => {
  const userId = useUserIdFromSession();

  const {
    activeSOAPPromptTemplates,
    defaultPromptTemplateId,
    userSettingsPromptTemplateId,
  } = usePromptTemplates();

  const [upsertPromptTemplateId] = useMutation(upsertPromptTemplateIdMutation, {
    optimisticResponse: (data) => {
      return {
        insertUserSettingsOne: {
          __typename: 'UserSettings' as const,
          userId,
          user: {
            __typename: 'Users' as const,
            id: userId,
            userSettings: {
              __typename: 'UserSettings' as const,
              userId,
              promptTemplateId: data.promptTemplateId,
              promptTemplateIdOrDefault: data.promptTemplateId,
              enableSoundEffects: true,
            },
          },
        },
      };
    },
  });

  const performUpsertPromptTemplateId = async (promptTemplateId: string) => {
    await upsertPromptTemplateId({
      variables: {
        promptTemplateId,
      },
    });
  };

  const selectedPromptTemplateId =
    userSettingsPromptTemplateId ?? defaultPromptTemplateId;

  const selectedPromptTemplate = activeSOAPPromptTemplates?.find(
    (pT) => pT.id === selectedPromptTemplateId,
  );

  return (
    <div>
      <Select
        value={selectedPromptTemplate?.name ?? ''}
        onValueChange={performUpsertPromptTemplateId}
      >
        <SelectTrigger className="text-gray-12">
          {selectedPromptTemplate?.name}
        </SelectTrigger>

        <PromptTemplateSelectContent />
      </Select>
    </div>
  );
};
