import { UploadIcon } from 'lucide-react';
import { useRef, useState } from 'react';
import { toast } from 'sonner';

import { Box, Button, Input, Label } from '@eluve/components';
import {
  supportedAudioFormats,
  useAppointmentTasksActor,
  useAppointmentTasksSelector,
  useUserFileSystemStore,
} from '@eluve/user-local-files';

interface UploadTranscriptProp {
  appointmentId: string;
}

export const UploadTranscript: React.FC<UploadTranscriptProp> = ({
  appointmentId,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const userFileSystem = useUserFileSystemStore(
    (state) => state.userFileSystem,
  );
  const { send } = useAppointmentTasksActor();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const hasPendingAppointmentTask = useAppointmentTasksSelector(({ context }) =>
    context.tasks.some((t) => t.appointmentId === appointmentId),
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const uploadFile = async (): Promise<void> => {
    if (!selectedFile) {
      return;
    }

    const fileExtension = selectedFile.name.split('.').pop();

    const audioFormat =
      supportedAudioFormats.find(
        (audioFormat) => audioFormat.extension === fileExtension,
      ) ??
      ({
        extension: fileExtension,
        mimeType: selectedFile.type,
        encoding: 'aac',
      } as any);

    if (!audioFormat) {
      toast.error('Unsupported file format');
      return;
    }

    if (userFileSystem.type !== 'available') {
      toast.error('File system not available');
      return;
    }

    try {
      const appointmentDir =
        await userFileSystem.appointmentsDir.getDirectoryHandle(appointmentId, {
          create: true,
        });

      const localFile = await appointmentDir.getFileHandle(selectedFile.name, {
        create: true,
      });

      if ('createWritable' in localFile) {
        const writable = await localFile.createWritable();
        await writable.write(selectedFile);
        await writable.close();
      }
    } catch (error) {
      toast.error('Error uploading file');
    }

    send({
      type: 'TASK.UPLOAD_FILE',
      appointmentId,
      fileName: selectedFile.name,
      format: audioFormat,
      wasDegraded: false,
      shouldBeTranscribed: true,
      recordingStartedAt: new Date().toISOString(),
      isBackgroundUpload: false,
      attemptNumber: 1,
    });

    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <Box vStack>
      <Label className="px-1">Upload Transcript</Label>
      <Box hStack>
        <Input
          type="file"
          disabled={hasPendingAppointmentTask}
          name="file"
          onChange={handleFileChange}
          ref={fileInputRef}
        />

        <Button
          type="button"
          disabled={hasPendingAppointmentTask || !selectedFile}
          onClick={uploadFile}
        >
          <UploadIcon className="mr-2 h-4 w-4" />
          Upload
        </Button>
      </Box>
    </Box>
  );
};
