import { useApiClient } from '@eluve/api-client-provider';
import { useNamedLogger } from '@eluve/logger';

import { TRANSCRIPT_TAB, useAppointmentTabNav } from '../useAppointmentTabNav';

export const useSummarizeAppointment = () => {
  const logger = useNamedLogger('useSummarizeAppointment');
  const apiClient = useApiClient();
  const { setTab } = useAppointmentTabNav();

  const generateSummary = async (tenantId: string, appointmentId: string) => {
    const result = await apiClient.llm.summarizeAppointment({
      body: {},
      params: {
        tenantId,
        appointmentId,
      },
    });

    if (result.status !== 201) {
      throw new Error('Failed to start summarization job');
    }

    setTab(TRANSCRIPT_TAB);

    logger.debug(`Regenerating job with id ${result.body.jobId}`);
  };

  return generateSummary;
};
