import { match } from 'ts-pattern';

import { useApiClient } from '@eluve/api-client-provider';
import { combineSummaryWithNotes } from '@eluve/frontend-feature-ehr-sync';
import { SupportedVendors } from '@eluve/graphql-types';
import { AppointmentSummary } from '@eluve/utils';

import { messageClient } from '../../utils/post-messenger';

type SyncToEhrArgs = {
  tenantId: string;
  appointmentId: string;
  vendor: SupportedVendors;
  domain: string;
  patientExternalId: string;
  appointmentName: string;
  additionalUserNotes?: string | null;
  shouldSignExternalChart: boolean;
  appointmentExternalId?: string;
  summary: AppointmentSummary;
};
export const useSyncToEhr = () => {
  const api = useApiClient();
  return async (
    args: SyncToEhrArgs,
  ): Promise<{
    ok: boolean;
    data?: { chartUrl: string; chartId: string };
    error?: string;
  }> => {
    const {
      appointmentId,
      vendor,
      domain,
      patientExternalId,
      appointmentName,
      additionalUserNotes,
      shouldSignExternalChart,
      summary,
      appointmentExternalId,
      tenantId,
    } = args;

    return match(vendor)
      .with('JANE', 'TEBRA', 'MOVEMENT_X', async (vendor) => {
        return messageClient.syncToEhr({
          vendor,
          domain,
          data: {
            sign: shouldSignExternalChart,
            summary: combineSummaryWithNotes(summary, additionalUserNotes),
            patientExternalId: patientExternalId!,
            description: appointmentName,
            appointmentExternalId,
          },
        });
      })
      .with('EXPERITY', async (vendor) => {
        const pdfResult = await api.appointments.getOrCreatePdfUrl({
          params: {
            appointmentId,
            tenantId,
          },
        });

        if (pdfResult.status !== 201) {
          throw new Error('Failed to get PDF URL for appointment');
        }

        return messageClient.syncToEhr({
          tenantId,
          vendor,
          domain,
          data: {
            patientExternalId: patientExternalId!,
            appointmentExternalId,
            category: 'Transcription',
            tenant: 'CRHGA',
            description: appointmentName,
            pdfUrl: pdfResult.body.pdfUrl,
          },
        });
      })
      .exhaustive();
  };
};
