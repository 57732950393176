import { z } from 'zod';

import { stringBooleanSchema } from '@eluve/utils';

const configSchema = z.object({
  VITE_SUPERTOKENS_WEBSITE_DOMAIN: z.string().url(),
  VITE_API_DOMAIN: z.string().url(),
  VITE_APOLLO_CLIENT_URI: z.string().url(),
  VITE_ENV: z.enum(['development', 'production', 'test']).default('production'),
  VITE_METRICS_ENV: z.string().default('local-dev'),
  VITE_ENABLE_SOCIAL_AUTH: stringBooleanSchema,
  VITE_ENABLE_EMAIL_PASSWORD_AUTH: stringBooleanSchema,
  VITE_APP_VERSION: z.string().default('0.0.0'),
  VITE_REACT_APP_PUBLIC_POSTHOG_KEY: z.string().min(3),
  VITE_REACT_APP_PUBLIC_POSTHOG_HOST: z
    .string()
    .default('https://us.i.posthog.com'),
});

export const appConfig = configSchema.parse(import.meta.env);
