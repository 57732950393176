import { Helmet } from 'react-helmet';

import { Main, PageLayout, PageTitle } from '@eluve/components';

import { TenantsHeader } from './components/TenantsHeader';
import { TenantsList } from './components/TenantsList';

export const TenantsPage = () => {
  return (
    <PageLayout>
      <Helmet>
        <title>Select a Tenant | Eluve</title>
      </Helmet>
      <TenantsHeader />
      <Main className="px-3 pb-6">
        <PageTitle cName="border-gray-5 border-b">Select a Tenant</PageTitle>
        <TenantsList />
      </Main>
    </PageLayout>
  );
};
