import semver from 'semver';

export const doMajorAndMinorVersionsMatch = (version1 = '', version2 = '') => {
  const semVersion1 = semver.coerce(version1);
  const semVersion2 = semver.coerce(version2);
  return Boolean(
    semVersion1 &&
      semVersion2 &&
      semVersion1.major === semVersion2.major &&
      semVersion1.minor === semVersion2.minor,
  );
};
