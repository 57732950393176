import { useCompleteFragment } from '@eluve/apollo-client';

import { useAppointmentId } from './appointment.context';
import { AppointmentPatientIdFragment, patientFragment } from './operations';

export const useAppointmentPatient = () => {
  const appointmentId = useAppointmentId();

  const { patientId } = useCompleteFragment({
    fragment: AppointmentPatientIdFragment,
    key: {
      id: appointmentId,
    },
  });

  const patient = useCompleteFragment({
    fragment: patientFragment,
    key: {
      id: patientId ?? '',
    },
    strict: false,
  });

  return patient;
};
