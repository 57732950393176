import { v4 as uuidv4 } from 'uuid';

import {
  ab2str,
  decodeBase64,
  encodeBase64,
  sleep,
  str2ab,
} from '@eluve/utils';

export type MessageData<T> = T;
export type MessageType = 'REQUEST' | 'RESPONSE';

export type MessageRequest<T> = {
  messageId: string;
  action: string;
  data?: MessageData<T>;
  messageType: MessageType;
};

export type MessageResponse<T> = {
  ok: boolean;
  error?: string;
  data?: T;
};

type MessageResponseEnvelope<T> = {
  messageId: string;
  action: string;
  data?: MessageData<T>;
};

type EventMessage<T> = {
  data?: MessageRequest<T>;
} & WindowEventMap['message'];

export type Handler<T, S> = (
  data: MessageData<T>,
  event: EventMessage<T>,
) => Promise<S>;

type RequestOptions = {
  timeout?: number;
};

type ConnectionInitArgs = {
  targetWindow: Window;
  targetOrigin: string;
  maxRetries?: number;
  data?: Record<string, unknown>;
  force?: boolean;
};

type Algorithm = {
  iv: Uint8Array;
  name: string;
};

type EncryptionValues = {
  iv?: Uint8Array;
  algorithm?: Algorithm;
  requestKey?: CryptoKey;
};

type IncomingConnectionDetails<T> = {
  iv: Uint8Array;
  jsonRequestKey: JsonWebKey;
  origin: string;
  data?: T;
};

type PostMessengerInitArgs = {
  name: string;
};

type Connection = {
  name: string;
};

export class PostMessenger {
  private targetOrigin: string | null = null;
  private targetWindow: Window | null = null;
  private static readonly TIMEOUT = 3000;
  private static readonly AESCBC = 'AES-CBC';
  private static readonly CONNECTION_HANDSHAKE =
    'POST_MESSENGER_CONNECTION_HANDSHAKE';
  private static readonly NOT_CONNECTED_ERROR = 'POST_MESSENGER_NOT_CONNECTED';
  private handlers: Map<string, Handler<unknown, unknown>> = new Map();
  private connected = false;
  private encryptionValues: EncryptionValues = {};
  private listening = false;
  private name: string;
  private initialConnectionArgs?: ConnectionInitArgs;
  private connection: Connection | null = null;

  constructor(args: PostMessengerInitArgs) {
    this.name = args.name;
  }

  private sendResponse = <T>(message: MessageResponseEnvelope<T>): void => {
    const { messageId, action, data = {} } = message;
    this.sendMessage({
      messageType: 'RESPONSE',
      messageId,
      action,
      data,
    });
  };

  registerHandler = (
    action: string,
    handler: Handler<unknown, unknown>,
  ): void => {
    this.handlers.set(action, handler);
  };

  removeListener = (action: string): void => {
    this.handlers.delete(action);
  };

  registerHandlers = (
    handlers: Record<string, Handler<unknown, unknown>>,
  ): void => {
    Object.entries(handlers).forEach(([action, handler]) => {
      this.registerHandler(action, handler);
    });
  };

  private handleMessage = async <T>(
    message: EventMessage<T>,
  ): Promise<void> => {
    const messageData = message?.data;
    if (!messageData) {
      return;
    }
    if (message.origin !== this.targetOrigin) {
      return;
    }
    const { action, data, messageId, messageType } = messageData;

    if (messageType !== 'REQUEST' || !action || !messageId) {
      return;
    }

    const handler = this.handlers.get(action);
    if (!handler) {
      return;
    }

    let decryptedData = data;
    if (action !== PostMessenger.CONNECTION_HANDSHAKE) {
      if (!this.connected) {
        return this.postMessage({
          messageType: 'RESPONSE',
          messageId,
          action,
          data: { ok: false, error: PostMessenger.NOT_CONNECTED_ERROR },
        });
      }
      decryptedData = await this.decrypt(data);
    }

    try {
      const response = await handler(decryptedData, message);
      this.sendResponse({
        messageId,
        data: response,
        action,
      });
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error';
      this.sendResponse({
        messageId,
        action,
        data: { ok: false, error: errorMessage },
      });
    }
  };

  acceptConnection = async <T>({
    origin,
    onData,
  }: {
    origin: string;
    onData?: (data: T) => Promise<void>;
  }): Promise<void> => {
    this.targetOrigin = origin;
    this.listen();
    return new Promise((resolve, reject) => {
      this.registerHandler(
        PostMessenger.CONNECTION_HANDSHAKE,
        async (connectionData, event): Promise<MessageResponse<Connection>> => {
          if (!event || !connectionData) {
            reject(
              this.getErrorMessage('event and connectionData are required'),
            );
          }
          const { origin, iv, jsonRequestKey, data } =
            connectionData as IncomingConnectionDetails<T>;

          if (data && onData) {
            await onData(data);
          }

          try {
            this.setTarget(event.source as Window, origin);
            const algorithm = { iv, name: PostMessenger.AESCBC };
            const requestKey = await crypto.subtle.importKey(
              'jwk',
              jsonRequestKey,
              { name: PostMessenger.AESCBC },
              false,
              ['encrypt', 'decrypt'],
            );
            this.encryptionValues = {
              iv,
              algorithm,
              requestKey,
            };
            resolve();
            this.connected = true;
            return { ok: true, data: { name: this.name } };
          } catch (error) {
            const errorMessage =
              error instanceof Error ? error.message : 'Unknown error';
            reject(errorMessage);
            return { ok: false, error: errorMessage };
          } finally {
            this.removeListener(PostMessenger.CONNECTION_HANDSHAKE);
          }
        },
      );
    });
  };

  reconnect = async (): Promise<void> => {
    if (this.initialConnectionArgs) {
      await this.connect({ ...this.initialConnectionArgs, force: true });
    }
  };

  connect = async (args: ConnectionInitArgs): Promise<void> => {
    const {
      targetWindow,
      targetOrigin,
      data = {},
      maxRetries = 10,
      force = false,
    } = args;
    this.initialConnectionArgs = args;
    this.setTarget(targetWindow, targetOrigin);
    if (!this.connected || force) {
      const iv = crypto.getRandomValues(new Uint8Array(16));
      const requestKey = await crypto.subtle.generateKey(
        { length: 256, name: PostMessenger.AESCBC },
        true,
        ['encrypt', 'decrypt'],
      );
      const jsonRequestKey = await crypto.subtle.exportKey('jwk', requestKey);

      const algorithm = { iv, name: PostMessenger.AESCBC };
      this.encryptionValues = {
        iv,
        algorithm,
        requestKey,
      };

      let remainingRetries = maxRetries;
      while (remainingRetries > 0) {
        try {
          const connectionResponse = await this.sendRequest<Connection>(
            PostMessenger.CONNECTION_HANDSHAKE,
            {
              iv,
              jsonRequestKey,
              origin: window.location.origin,
              data,
            },
          );
          if (connectionResponse?.ok && connectionResponse?.data) {
            this.connection = connectionResponse.data;
            this.connected = true;
            this.listen();
            return;
          }
          break;
        } catch (_error) {
          // Ignore error
        }
        remainingRetries -= 1;
        await sleep(1000);
      }
      throw new Error(this.getErrorMessage('Unable to connect'));
    }
  };

  listen = () => {
    if (!this.listening) {
      window.addEventListener('message', this.handleMessage);
      this.listening = true;
    }
  };

  stopListening = () => {
    if (this.listening) {
      window.removeEventListener('message', this.handleMessage);
      this.listening = false;
    }
  };

  setTarget = (targetWindow: Window, targetOrigin: string): void => {
    if (!targetWindow || !targetOrigin) {
      throw new Error(
        this.getErrorMessage('targetWindow and targetOrigin must be set'),
      );
    }
    this.targetWindow = targetWindow;
    const targetUrl = new URL(targetOrigin);
    this.targetOrigin = targetUrl.origin;
  };

  sendRequest = async <T>(
    action: string,
    data: unknown,
    options?: RequestOptions,
  ): Promise<MessageResponse<T>> => {
    const response = await this.sendRequestAndWaitForResponse<T>(
      action,
      data,
      options,
    );

    if (response?.error !== PostMessenger.NOT_CONNECTED_ERROR) {
      return response;
    }

    await this.reconnect();
    return this.sendRequestAndWaitForResponse<T>(action, data, options);
  };

  private sendRequestAndWaitForResponse = async <T>(
    action: string,
    data: unknown,
    options?: RequestOptions,
  ): Promise<MessageResponse<T>> => {
    const timeout = options?.timeout || PostMessenger.TIMEOUT;
    const messageId = uuidv4();
    const responsePromise = new Promise((resolve, reject) => {
      const messageListener = (event: MessageEvent) => {
        if (
          event.data?.messageId === messageId &&
          event.data?.messageType === 'RESPONSE'
        ) {
          clearTimeout(timeoutId);
          window.removeEventListener('message', messageListener);
          resolve(event.data?.data);
        }
      };

      const timeoutId = setTimeout(() => {
        window.removeEventListener('message', messageListener);
        reject(
          this.getErrorMessage(`Timeout for ${action} after ${timeout} ms`),
        );
      }, timeout);

      window.addEventListener('message', messageListener);
    });

    let dataToSend: unknown = data;
    if (action !== PostMessenger.CONNECTION_HANDSHAKE) {
      dataToSend = await this.encrypt(data);
    }

    this.sendMessage({
      messageType: 'REQUEST',
      messageId,
      action,
      data: dataToSend,
    });
    return responsePromise as Promise<MessageResponse<T>>;
  };

  private sendMessage = async <T>(message: MessageRequest<T>) => {
    if (!this.targetWindow || !this.targetOrigin) {
      throw new Error(
        this.getErrorMessage('targetWindow and targetOrigin must be set'),
      );
    }
    this.postMessage(message, this.targetWindow, this.targetOrigin);
  };

  private postMessage = async <T>(
    message: MessageRequest<T>,
    targetWindow: Window = window,
    targetOrigin = '*',
  ) => {
    const { data = {}, messageType, action, messageId } = message;
    targetWindow.postMessage(
      {
        messageType,
        messageId,
        action,
        data,
      },
      targetOrigin,
    );
  };

  // Inspired by https://github.com/mdn/dom-examples/blob/main/web-crypto/encrypt-decrypt/aes-cbc.js
  private encrypt = async (data: unknown): Promise<string> => {
    if (!this.encryptionValues.algorithm || !this.encryptionValues.requestKey) {
      throw new Error(
        this.getErrorMessage(
          'encryptionValues must be set before calling encrypt',
        ),
      );
    }

    const encoder = new TextEncoder();
    const encodedData = encoder.encode(JSON.stringify(data));

    const encryptedAB = await crypto.subtle.encrypt(
      this.encryptionValues.algorithm,
      this.encryptionValues.requestKey,
      encodedData,
    );

    const encryptedText = ab2str(encryptedAB);
    const base64Text = encodeBase64(encryptedText);
    return base64Text;
  };

  private decrypt = async <T>(data: string): Promise<MessageData<T | null>> => {
    if (!this.encryptionValues.algorithm || !this.encryptionValues.requestKey) {
      throw new Error(
        this.getErrorMessage(
          'encryptionValues must be set before calling decrypt',
        ),
      );
    }

    const base64Decoded = decodeBase64(data);
    const encodedData = str2ab(base64Decoded);
    const decryptedData = await crypto.subtle.decrypt(
      this.encryptionValues.algorithm,
      this.encryptionValues.requestKey,
      encodedData,
    );

    if (decryptedData.byteLength === 0) {
      return null;
    }

    const textDecoder = new TextDecoder();
    const decryptedText = textDecoder.decode(decryptedData);
    return JSON.parse(decryptedText);
  };

  private getErrorMessage = (message: string): string => {
    return `[${this.constructor.name}] [${this.name}] ${message}`;
  };
}
