import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';

export const pendingFilesFragment = graphql(`
  fragment AppointmentPendingFiles on Appointments @_unmask {
    __typename
    id
    appointmentFiles(
      where: {
        fileType: { _eq: USER_BACKUP_AUDIO }
        isTranscriptionInProgress: { _eq: true }
      }
    ) {
      __typename
      fileId
      appointmentId
    }
  }
`);

export const useAppointmentTranscriptionPendingFiles = (
  appointmentId: string,
) => {
  const pendingFiles = useCompleteFragment({
    fragment: pendingFilesFragment,
    key: {
      id: appointmentId,
    },
    strict: false,
  });

  return pendingFiles?.appointmentFiles?.length ?? 0;
};
