import { graphql } from '@eluve/graphql.tada';

export const appointmentSegmentFragment = graphql(`
  fragment AppointmentSegmentFragment on AppointmentSegments {
    __typename
    id
    appointmentId
    wasDegraded
  }
`);
