import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';

import { useAppointmentId } from './appointment.context';

export const appointmentActivityFragment = graphql(`
  fragment appointmentActiveState on Appointments {
    isSummarizationInProgress
    isAnySummarizationInProgress
    isTranscriptionInProgress
    isPendingAudioFileUpload
  }
`);

export const useAppointmentActivity = () => {
  const id = useAppointmentId();

  const data = useCompleteFragment({
    fragment: appointmentActivityFragment,
    key: {
      id,
    },
  });

  const {
    isSummarizationInProgress,
    isTranscriptionInProgress,
    isPendingAudioFileUpload,
    isAnySummarizationInProgress,
  } = data;

  return {
    isSummarizationInProgress: isSummarizationInProgress ?? false,
    isAnySummarizationInProgress,
    isTranscriptionInProgress:
      Boolean(isTranscriptionInProgress) || Boolean(isPendingAudioFileUpload),
  };
};
