import {
  TypedDocumentNode,
  UseFragmentOptions,
  useApolloClient,
  useFragment,
} from '@apollo/client';
import { ResultOf, VariablesOf } from '@graphql-typed-document-node/core';
import { useMemo } from 'react';

import { CacheUtils } from './CacheUtils';
import { getFragmentDefinition } from './getFragmentDefinition';
import { FragmentOptions, TypeSafePolicies, ValidQueryRoot } from './types';

/** Hooks Types */

type UseCompleteFragmentOptions<
  QueryRoot extends ValidQueryRoot,
  TSP extends TypeSafePolicies<QueryRoot>,
  T extends TypedDocumentNode,
> = Omit<UseFragmentOptions<ResultOf<T>, VariablesOf<T>>, 'fragment' | 'from'> &
  FragmentOptions<QueryRoot, TSP, T>;

export const createCacheUtilsHooks = <
  QueryRoot extends ValidQueryRoot,
  TSP extends TypeSafePolicies<QueryRoot>,
>(
  _cacheUtils: Omit<CacheUtils<QueryRoot, TSP>, 'withPolicies'>,
) => {
  /**
   * A hook which returns the result of a fragment if it is complete.
   * If the fragment is not complete, it will throw an error.
   * If the strict option is set to false, it will return null instead of throwing an error.
   *
   * @param options The options for the fragment
   *
   * @param options.fragment The fragment document
   * @param options.key The key fields of the entity
   * @param options.strict Whether to throw an error if the fragment is not complete
   * @param options.fragmentName The name of the fragment (optional)
   */
  function useCompleteFragment<T extends TypedDocumentNode>(
    options: UseCompleteFragmentOptions<QueryRoot, TSP, T> & { strict: false },
  ): ResultOf<T> | null;
  function useCompleteFragment<T extends TypedDocumentNode>(
    options: UseCompleteFragmentOptions<QueryRoot, TSP, T> & { strict?: true },
  ): ResultOf<T>;
  function useCompleteFragment<T extends TypedDocumentNode>(
    options: UseCompleteFragmentOptions<QueryRoot, TSP, T> & {
      strict?: boolean;
    },
  ) {
    const { cache } = useApolloClient();

    const fragmentDefinition = useMemo(
      () => getFragmentDefinition(options.fragment, options.fragmentName),
      [options.fragment, options.fragmentName],
    );

    const tableName = fragmentDefinition.typeCondition.name.value;

    const id = useMemo(
      () =>
        cache.identify({
          __typename: tableName,
          ...options.key,
        }),
      [tableName, options.key],
    );

    if (!id && options.strict !== false) {
      throw new Error(
        `Could not find entity in cache. type: ${tableName}, key: ${JSON.stringify(options.key)}`,
      );
    }

    const fragment = useFragment({
      fragmentName: options.fragmentName ?? fragmentDefinition.name.value,
      ...options,
      from: id ?? '',
    });

    if (!fragment.complete) {
      if (options.strict !== false) {
        throw new Error(
          `Fragment is not complete. ${JSON.stringify(fragment.missing)}`,
        );
      }

      return null;
    }

    return fragment.data as ResultOf<T>;
  }

  return {
    useCompleteFragment,
  };
};
