import bytes from 'bytes';

import { Logger } from '@eluve/logger';

import { UserFileSystem } from './user-file-system.store';

export type FileRow = {
  name: string;
  size: string;
  handle: FileSystemHandle;
  file: File;
};

export type AppointmentFileRow = FileRow & { appointmentId: string };

export const SWAP_FILE_EXT = '.crswap';
const NOT_FOUND_ERROR = 'NotFoundError';

export type AppointmentFilesResponse =
  | { type: 'fileSystemUnavailable' }
  | { type: 'directoryExists'; files: AppointmentFileRow[] }
  | { type: 'directoryNotFound' };

/**
 * Retrieves all the files related to a given appointment from the user's file system.
 */
export const readAppointmentFilesFromOpfs = async (
  userFileSystem: UserFileSystem,
  appointmentId: string,
): Promise<AppointmentFilesResponse> => {
  if (userFileSystem.type !== 'available') {
    return { type: 'fileSystemUnavailable' };
  }

  const { appointmentsDir } = userFileSystem;

  try {
    const appointmentDir = await appointmentsDir.getDirectoryHandle(
      appointmentId,
      { create: false },
    );

    const files: AppointmentFileRow[] = [];
    const filePromises: Promise<void>[] = [];
    for await (const [name, handle] of appointmentDir) {
      filePromises.push(
        handle.getFile().then((f) => {
          const size = bytes(f.size);
          files.push({
            name,
            size,
            handle,
            file: f,
            appointmentId,
          });
        }),
      );
    }

    await Promise.all(filePromises);
    return { type: 'directoryExists', files };
  } catch (e) {
    if (e instanceof DOMException && e.name === NOT_FOUND_ERROR) {
      return { type: 'directoryNotFound' };
    } else {
      throw e;
    }
  }
};

/**
 * Attempts to recursively discover all files on the user's device related to appointments.
 */
export const readAllAppointmentFilesFromOpfs = async (
  fileSystem: UserFileSystem,
  logger: Logger,
): Promise<AppointmentFilesResponse> => {
  if (fileSystem.type !== 'available') {
    return { type: 'fileSystemUnavailable' };
  }

  const { appointmentsDir } = fileSystem;

  const filePromises: Promise<AppointmentFileRow[]>[] = [];
  let dirCount = 0;
  const scanStart = new Date();
  for await (const [id, handle] of appointmentsDir) {
    if (handle.kind === 'directory') {
      dirCount++;
      const promise: () => Promise<AppointmentFileRow[]> = async () => {
        const appointmentFilesResult = await readAppointmentFilesFromOpfs(
          fileSystem,
          id,
        );

        if (appointmentFilesResult.type === 'directoryNotFound') {
          // We shouldn't get here because we found the id for this directory in the parent directory
          logger.warn(
            'Directory not found during iteration. This should not happen',
          );
        }

        if (
          appointmentFilesResult.type === 'directoryExists' &&
          appointmentFilesResult.files.length
        ) {
          return appointmentFilesResult.files;
        }

        return [];
      };

      filePromises.push(promise());
    }
  }

  const allFiles = (await Promise.all(filePromises)).flat();
  const scanEnd = new Date();

  const duration = scanEnd.getTime() - scanStart.getTime();
  logger.info(`Scanned ${dirCount} directories in ${duration} ms.`, {
    dirCount,
    duration,
    fileCount: allFiles.length,
  });

  return {
    type: 'directoryExists',
    files: allFiles,
  };
};
