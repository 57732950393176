import { ApolloClient } from '@apollo/client';
import { fromPromise } from 'xstate';

import { graphql } from '@eluve/graphql.tada';

export const getCurrentTranscript = graphql(`
  query getCurrentTranscript($tenantId: uuid!, $appointmentId: uuid!) {
    appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
      __typename
      id
      __typename
      transcription {
        __typename
        id
        transcript
        updatedAt
      }
    }
  }
`);

export interface Input {
  apolloClient: ApolloClient<unknown>;
  tenantId: string;
  appointmentId: string;
}

export const checkForTranscriptionLength = fromPromise<unknown, Input>(
  async ({ input }) => {
    const { apolloClient, tenantId, appointmentId } = input;

    const { data, error } = await apolloClient.query({
      query: getCurrentTranscript,
      variables: { tenantId, appointmentId },
    });

    const transcription = data?.appointmentsByPk?.transcription?.transcript;

    if (error ?? !transcription) {
      throw new Error('No transcription found');
    }

    if (transcription.length < 300) {
      throw new Error('This session is too short to generate a note');
    }

    return transcription;
  },
);
