import { ChevronDownIcon } from 'lucide-react';
import React from 'react';
import { NavLink, Route, Routes, useParams } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  cn,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { FeatureFlaggedComponent } from '@eluve/smart-blocks';

export const tenantLocationsFragment = graphql(`
  fragment TenantLocations on Tenants {
    __typename
    id
    locations {
      id
      name
    }
  }
`);

const ActiveLocationBreadcrumb = () => {
  const { locationId, tenantId } = useParams();
  const tenantLocations = useCompleteFragment({
    fragment: tenantLocationsFragment,
    key: {
      id: tenantId!,
    },
    strict: false,
  });

  const activeLocation = tenantLocations?.locations.find(
    (location) => location.id === locationId,
  );

  if (!activeLocation) {
    return null;
  }

  const locations = (tenantLocations?.locations ?? [])
    .filter((location) => location.id !== locationId)
    .map((location) => ({
      id: location.id,
      name: location.name,
    }));

  if (locations.length === 0) {
    return <BreadcrumbItem>{activeLocation.name}</BreadcrumbItem>;
  }

  return (
    <BreadcrumbItem>
      <DropdownMenu>
        <DropdownMenuTrigger className="flex items-center gap-1">
          {activeLocation.name}
          <ChevronDownIcon size={18} />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          {locations.map((location) => (
            <DropdownMenuItem key={location.id} asChild>
              <NavLink to={`../locations/${location.id}`}>
                {location.name}
              </NavLink>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </BreadcrumbItem>
  );
};

const Separator = () => (
  <BreadcrumbSeparator>
    <div className="bg-gray-7 h-4 w-px" />
  </BreadcrumbSeparator>
);

const SmartBreadcrumbLink: React.FC<{ label: string; to: string }> = ({
  label,
  to,
}) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        cn('hover:text-foreground cursor-pointer text-lg transition-colors', {
          underline: isActive,
        })
      }
    >
      {label}
    </NavLink>
  );
};

export const AdminLocationsBreadcrumbs = () => {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <FeatureFlaggedComponent flag="SESSION_TYPES">
          <BreadcrumbItem>
            <SmartBreadcrumbLink label="Session Types" to="session-types" />
          </BreadcrumbItem>
          <Separator />
        </FeatureFlaggedComponent>
        <BreadcrumbItem>
          <SmartBreadcrumbLink label="Layers" to="layers" />
        </BreadcrumbItem>
        <Separator />
        <BreadcrumbItem>
          <SmartBreadcrumbLink label="Locations" to="locations" />
        </BreadcrumbItem>
        <Routes>
          <Route
            path="/locations/:locationId"
            element={
              <>
                <BreadcrumbSeparator />
                <ActiveLocationBreadcrumb />
              </>
            }
          />
        </Routes>
      </BreadcrumbList>
    </Breadcrumb>
  );
};
